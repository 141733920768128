import * as React from "react";
import { Routes, Route } from "react-router-dom";
import { StyledEngineProvider } from '@mui/material/styles';

// layouts
import MainLayout from "./layouts/MainLayout";
import DashboardLayout from "./layouts/DashboardLayout";
import SubLayout from "./layouts/SubLayout";
import EmptyLayout from "./layouts/EmptyLayout";

// views - Front Page
import Main from './views/Main';
import Price from "./views/Price";
import Use from "./views/Use";
import Privacy from "./views/Privacy";
import Pay from "./views/Pay";
import GroupPay from "./views/GroupPay";
import Download from "./views/Download";
import Faq from "./views/Faq";
import Order from "./views/Order";
import News from "./views/News";
import NewsContent from "./views/NewsContent";
import Qna from "./views/Qna";
import NonMemberQnaInfo from "./views/NonMemberQnaInfo";
import NonMemberQnaInfoMore from "./views/NonMemberQnaInfoMore";

// views - English Main
import EnglishMain from './views/EnglishMain';

// views - auth
import LoginView from './views/auth/LoginView';
import LeaveLoginView from './views/auth/LeaveLoginView';
import RegisterView from './views/auth/RegisterView';
import GroupRegisterView from "./views/auth/GroupRegisterView";
import PasswordChange from "./views/auth/PasswordChange";
import RegisterAgree from "./views/auth/RegisterAgree";
import RegisterStudentView from "./views/auth/RegisterStudentView";
import EmailChange from "./views/auth/EmailChange";
import EmailResend from "./views/auth/EmailResend";
import EmailChangeSub from "./views/auth/EmailChangeSub";
import RegisterComplete from "./views/auth/RegisterComplete";
import RegisterGroupComplete from "./views/auth/RegisterGroupComplete";

// views - users
import UserProfile from "./views/users/UserProfile";
import UserList from "./views/users/UserList";
import UserPasswordChange from "./views/users/UserPasswordChange";

// views - group users
import GroupUserProfile from "./views/users/GroupUser/UserProfile";
import GroupUserList from "./views/users/GroupUser/UserList";
import GroupUserPasswordChange from "./views/users/GroupUser/UserPasswordChange";

// views - dashboard
import DashboardMain from "./views/dashboard/DashboardMain";

// views - category
import CategoryWrite from "./views/admin/Category/CategoryWrite";
import CategoryList from "./views/admin/Category/CategoryList";
import CategoryModify from "./views/admin/Category/CategoryModify";
import CategoryView from "./views/admin/Category/CategoryView";

// views - knowledge category
import KnowledgeCategoryWrite from "./views/admin/KnowledgeCategory/CategoryWrite";
import KnowledgeCategoryList from "./views/admin/KnowledgeCategory/CategoryList";
import KnowledgeCategoryModify from "./views/admin/KnowledgeCategory/CategoryModify";
import KnowledgeCategoryView from "./views/admin/KnowledgeCategory/CategoryView";

// views - knowledge
import KnowledgeWrite from "./views/admin/Knowledge/KnowledgeWrite";
import KnowledgeList from "./views/admin/Knowledge/KnowledgeList";
import KnowledgeModify from "./views/admin/Knowledge/KnowledgeModify";
import KnowledgeView from "./views/admin/Knowledge/KnowledgeView";

// views - mission category
import MissionCategoryWrite from "./views/admin/MissionCategory/CategoryWrite";
import MissionCategoryList from "./views/admin/MissionCategory/CategoryList";
import MissionCategoryModify from "./views/admin/MissionCategory/CategoryModify";
import MissionCategoryView from "./views/admin/MissionCategory/CategoryView";

// views - mission
import MissionWrite from "./views/admin/Mission/MissionWrite";
import MissionList from "./views/admin/Mission/MissionList";
import MissionModify from "./views/admin/Mission/MissionModify";
import MissionView from "./views/admin/Mission/MissionView";

// views - notice
import NoticeWrite from "./views/admin/Notice/NoticeWrite";
import NoticeList from "./views/admin/Notice/NoticeList";
import NoticeModify from "./views/admin/Notice/NoticeModify";
import NoticeView from "./views/admin/Notice/NoticeView";
import NoticeListClient from "./views/admin/Notice/NoticeListClient";
import NoticeViewClient from "./views/admin/Notice/NoticeViewClient";

// views - product
import ProductWrite from "./views/admin/Product/ProductWrite";
import ProductList from "./views/admin/Product/ProductList";
import ProductModify from "./views/admin/Product/ProductModify";
import ProductView from "./views/admin/Product/ProductView";

// views - order
import OrderWrite from "./views/users/Order/OrderWrite";
import OrderList from "./views/users/Order/OrderList";
import OrderModify from "./views/users/Order/OrderModify";
import OrderView from "./views/users/Order/OrderView";

// views - payment
import PaymentWrite from "./views/admin/Payment/PaymentWrite";
import PaymentList from "./views/admin/Payment/PaymentList";
import PaymentView from "./views/admin/Payment/PaymentView";

// views - refund
import RefundList from "./views/users/Refund/RefundList";

// views - student
import StudentList from "./views/users/Student/StudentList";
import StudentWrite from "./views/users/Student/StudentWrite";
import StudentView from "./views/users/Student/StudentView";
import StudentModify from "./views/users/Student/StudentModify";

// views - package
import PackageList from "./views/users/Package/PackageList";

// views - Board
import BoardList from "./views/Board/List";
import BoardWrite from "./views/Board/Write";
import BoardReply from "./views/Board/Reply";
import BoardView from "./views/Board/View";
import BoardModify from "./views/Board/Modify";

// views - User Mypage
import MypageReport from "./views/ParentMypage/MypageReport";
import MypageStudentInfo from "./views/ParentMypage/MypageStudentInfo";
import MypageParentsInfo from "./views/ParentMypage/MypageParentsInfo";
import MypagePayInfo from "./views/ParentMypage/MypagePayInfo";
import MypageStudentRegister from "./views/ParentMypage/MypageStudentRegister";
import MypageStudentInfoFix from "./views/ParentMypage/MypageStudentInfoFix";
import MypagePayInfoMore from "./views/ParentMypage/MypagePayInfoMore";
import MypageInappInfoMore from "./views/ParentMypage/MypageInappInfoMore";
import MypageStudentAdd from "./views/ParentMypage/MypageStudentAdd";
import MypageCoupon from "./views/ParentMypage/MypageCoupon";
import MypageCouponDetail from "./views/ParentMypage/MypageCouponDetail";
import MypageQnaInfo from "./views/ParentMypage/MypageQnaInfo";
import MypageQnaInfoMore from "./views/ParentMypage/MypageQnaInfoMore";

// views - Group User Mypage
import GroupMypageReport from "./views/GroupParentMypage/GroupMypageReport";
import GroupMypageStudentInfo from "./views/GroupParentMypage/GroupMypageStudentInfo";
import GroupMypageParentsInfo from "./views/GroupParentMypage/GroupMypageParentsInfo";
import GroupMypagePayInfo from "./views/GroupParentMypage/GroupMypagePayInfo";
import GroupMypageStudentRegister from "./views/GroupParentMypage/GroupMypageStudentRegister";
import GroupMypageStudentInfoFix from "./views/GroupParentMypage/GroupMypageStudentInfoFix";
import GroupMypagePayInfoMore from "./views/GroupParentMypage/GroupMypagePayInfoMore";
import GroupMypageInappInfoMore from "./views/GroupParentMypage/GroupMypageInappInfoMore";
import GroupMypageStudentAdd from "./views/GroupParentMypage/GroupMypageStudentAdd";
import GroupMypageCoupon from "./views/GroupParentMypage/GroupMypageCoupon";
import GroupMypageCouponDetail from "./views/GroupParentMypage/GroupMypageCouponDetail";
import GroupMypageQnaInfo from "./views/GroupParentMypage/GroupMypageQnaInfo";
import GroupMypageQnaInfoMore from "./views/GroupParentMypage/GroupMypageQnaInfoMore";
import GroupMypageClassReport from "./views/GroupParentMypage/GroupMypageClassReport";

// views - notice
import YameNoticeWrite from "./views/admin/YameNotice/NoticeWrite";
import YameNoticeList from "./views/admin/YameNotice/NoticeList";
import YameNoticeModify from "./views/admin/YameNotice/NoticeModify";
import YameNoticeView from "./views/admin/YameNotice/NoticeView";

// views - coupon
import CouponList from "./views/admin/Coupon/CouponList";
import CouponDetailList from "./views/admin/Coupon/CouponDetailList";

// views - solution
import SolutionList from "./views/admin/Solution/SolutionList";
import SolutionModify from "./views/admin/Solution/SolutionModify";
import SolutionReply from "./views/admin/Solution/SolutionReply";
import SolutionView from "./views/admin/Solution/SolutionView";

// views - news
import NewsList from "./views/admin/News/NewsList";
import NewsWrite from "./views/admin/News/NewsWrite";
import NewsView from "./views/admin/News/NewsView";
import NewsModify from "./views/admin/News/NewsModify";

// views - ranking
import RankingList from "./views/admin/Ranking/RankingList";
import RankingWrite from "./views/admin/Ranking/RankingWrite";
import RankingView from "./views/admin/Ranking/RankingView";
import RankingModify from "./views/admin/Ranking/RankingModify";
import RankingChallengeList from "./views/admin/Ranking/RankingChallengeList";
import RankingTotalList from "./views/admin/Ranking/RankingTotalList";

// views - inapp product
import InappProductWrite from "./views/admin/InappProduct/InappProductWrite";
import InappProductList from "./views/admin/InappProduct/InappProductList";
import InappProductModify from "./views/admin/InappProduct/InappProductModify";
import InappProductView from "./views/admin/InappProduct/InappProductView";

// views - inapp payment
import InappPaymentList from "./views/admin/InappPayment/InappPaymentList";
import InappPaymentView from "./views/admin/InappPayment/InappPaymentView";

// views - group order
import GroupOrderWrite from "./views/admin/GroupOrder/OrderWrite";
import GroupOrderList from "./views/admin/GroupOrder/OrderList";
import GroupOrderModify from "./views/admin/GroupOrder/OrderModify";
import GroupOrderView from "./views/admin/GroupOrder/OrderView";

// 404 NotFound
import NotFound from "./views/NotFound";

import Test from "./views/Test";

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <div className="App">
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route index path="" element={<Main />} />
            <Route path="Price" element={<Price />} />
            <Route path="Use" element={<Use />} />
            <Route path="Privacy" element={<Privacy />} />
            <Route path="test" element={<Test />} />
            <Route path="Pay" element={<Pay />} />
            <Route path="GroupPay" element={<GroupPay />} />
            <Route path="Download" element={<Download />} />
            <Route path="Faq" element={<Faq />} />
            <Route path="Order" element={<Order />} />
            <Route path="News" element={<News />} />
            <Route path="News/:board_page" element={<News />} />
            <Route path="NewsContent/:idx/:prevPage" element={<NewsContent />} />
            <Route path="Qna" element={<Qna />} />
            <Route path="NonMemberQnaInfo/:name/:email" element={<NonMemberQnaInfo />} />
            <Route path="NonMemberQnaInfoMore/:idx/:name/:email" element={<NonMemberQnaInfoMore />} />

            <Route index path="BoardList/:board_name" element={<BoardList />} />
            <Route index path="BoardList/:board_name/:page" element={<BoardList />} />
            <Route index path="BoardList/:board_name/:page/:condition/:keyword" element={<BoardList />} />
            <Route index path="BoardWrite/:board_name" element={<BoardWrite />} />
            <Route index path="BoardReply/:board_name/:bbs_idx/:prevPage" element={<BoardReply />} />
            <Route index path="BoardView/:board_name/:idx/:prevPage" element={<BoardView />} />
            <Route index path="BoardView/:board_name/:idx/:prevPage/:condition/:keyword" element={<BoardView />} />
            <Route index path="BoardModify/:board_name/:idx/:prevPage" element={<BoardModify />} />
            <Route index path="BoardModify/:board_name/:idx/:prevPage/:condition/:keyword" element={<BoardModify />} />
          </Route>

          <Route path="/sub" element={<EmptyLayout />}>
            <Route index path="" element={<EnglishMain />} />
          </Route>

          <Route path="/auth" element={<SubLayout />}>
            <Route index path="login" element={<LoginView />} />
            <Route index path="leavelogin" element={<LeaveLoginView />} />
            <Route index path="register" element={<RegisterView />} />
            <Route index path="groupRegister" element={<GroupRegisterView />} />
            <Route index path="PasswordChange" element={<PasswordChange />} />
            <Route index path="RegisterAgree" element={<RegisterAgree />} />
            <Route index path="RegisterStudentView" element={<RegisterStudentView />} />
            <Route index path="EmailChange" element={<EmailChange />} />
            <Route index path="EmailResend" element={<EmailResend />} />
            <Route index path="EmailChangeSub" element={<EmailChangeSub />} />
            <Route index path="RegisterComplete" element={<RegisterComplete />} />
            <Route index path="RegisterGroupComplete" element={<RegisterGroupComplete />} />
          </Route>

          <Route path="/UserMypage" element={<MainLayout />}>
            <Route index path="Report" element={<MypageReport />} />
            <Route index path="StudentInfo" element={<MypageStudentInfo />} />
            <Route index path="ParentsInfo" element={<MypageParentsInfo />} />
            <Route index path="PayInfo" element={<MypagePayInfo />} />
            <Route index path="PayInfo/:page" element={<MypagePayInfo />} />
            <Route index path="PayInfo/:page/:condition" element={<MypagePayInfo />} />
            <Route index path="PayInfoMore/:ord_idx" element={<MypagePayInfoMore />} />
            <Route index path="PayInfoMore/:ord_idx/:page" element={<MypagePayInfoMore />} />
            <Route index path="PayInfoMore/:ord_idx/:page/:condition" element={<MypagePayInfoMore />} />
            <Route index path="StudentRegister" element={<MypageStudentRegister />} />
            <Route index path="StudentInfoFix/:mst_id" element={<MypageStudentInfoFix />} />
            <Route index path="InappInfoMore/:ina_idx" element={<MypageInappInfoMore />} />
            <Route index path="InappInfoMore/:ina_idx/:page" element={<MypageInappInfoMore />} />
            <Route index path="InappInfoMore/:ina_idx/:page/:condition" element={<MypageInappInfoMore />} />
            <Route index path="MypageStudentAdd" element={<MypageStudentAdd />} />
            <Route index path="CouponInfo" element={<MypageCoupon />} />
            <Route index path="CouponInfo/:page" element={<MypageCoupon />} />
            <Route index path="CouponInfo/:page/:condition" element={<MypageCoupon />} />
            <Route index path="CouponInfoDetail/:cou_number/:page" element={<MypageCouponDetail />} />
            <Route index path="CouponInfoDetail/:cou_number/:page/:condition" element={<MypageCouponDetail />} />
            <Route index path="QnaInfo" element={<MypageQnaInfo />} />
            <Route index path="QnaInfo/:page" element={<MypageQnaInfo />} />
            <Route index path="QnaInfo/:page/:condition" element={<MypageQnaInfo />} />
            <Route index path="QnaInfoMore/:idx" element={<MypageQnaInfoMore />} />
            <Route index path="QnaInfoMore/:idx/:page" element={<MypageQnaInfoMore />} />
            <Route index path="QnaInfoMore/:idx/:page/:condition" element={<MypageQnaInfoMore />} />
          </Route>

          <Route path="/GroupUserMypage" element={<MainLayout />}>
            <Route index path="GroupReport" element={<GroupMypageReport />} />
            <Route index path="GroupStudentInfo" element={<GroupMypageStudentInfo />} />
            <Route index path="GroupParentsInfo" element={<GroupMypageParentsInfo />} />
            <Route index path="GroupPayInfo" element={<GroupMypagePayInfo />} />
            <Route index path="GroupStudentRegister" element={<GroupMypageStudentRegister />} />
            <Route index path="GroupStudentInfoFix/:mst_id" element={<GroupMypageStudentInfoFix />} />
            <Route index path="GroupPayInfoMore/:ord_idx" element={<GroupMypagePayInfoMore />} />
            <Route index path="GroupInappInfoMore/:ina_idx" element={<GroupMypageInappInfoMore />} />
            <Route index path="GroupMypageStudentAdd" element={<GroupMypageStudentAdd />} />
            <Route index path="GroupCouponInfo" element={<GroupMypageCoupon />} />
            <Route index path="GroupCouponInfo/:page" element={<GroupMypageCoupon />} />
            <Route index path="GroupCouponInfo/:page/:condition" element={<GroupMypageCoupon />} />
            <Route index path="GroupCouponInfoDetail/:cou_number/:page" element={<GroupMypageCouponDetail />} />
            <Route index path="GroupCouponInfoDetail/:cou_number/:page/:condition" element={<GroupMypageCouponDetail />} />
            <Route index path="GroupQnaInfo" element={<GroupMypageQnaInfo />} />
            <Route index path="GroupQnaInfoMore/:idx" element={<GroupMypageQnaInfoMore />} />
            <Route index path="GroupQnaInfoMore/:idx/:page" element={<GroupMypageQnaInfoMore />} />
            <Route index path="GroupQnaInfoMore/:idx/:page/:condition" element={<GroupMypageQnaInfoMore />} />
            <Route index path="GroupMypageClassReport/:idx/:mc_name/:pre_page" element={<GroupMypageClassReport />} />
          </Route>

          <Route path="/dashboard" element={<DashboardLayout />}>

            <Route index path="" element={<DashboardMain />} />
            <Route index path="profile" element={<UserProfile />} />
            <Route index path="UserPasswordChange" element={<UserPasswordChange />} />
            <Route index path="userList" element={<UserList />} />
            <Route index path="userList/:page" element={<UserList />} />
            <Route index path="userList/:page/:condition/:keyword" element={<UserList />} />

            <Route index path="Group/profile" element={<GroupUserProfile />} />
            <Route index path="Group/UserPasswordChange" element={<GroupUserPasswordChange />} />
            <Route index path="Group/userList" element={<GroupUserList />} />
            <Route index path="Group/userList/:page" element={<GroupUserList />} />
            <Route index path="Group/userList/:page/:condition/:keyword/:classvalue" element={<GroupUserList />} />
            
            <Route index path="categoryWrite" element={<CategoryWrite />} />
            <Route index path="CategoryList" element={<CategoryList />} />
            <Route index path="CategoryModify/:category_idx" element={<CategoryModify />} />
            <Route index path="CategoryView/:category_idx" element={<CategoryView />} />

            <Route index path="KnowledgecategoryWrite" element={<KnowledgeCategoryWrite />} />
            <Route index path="KnowledgeCategoryList" element={<KnowledgeCategoryList />} />
            <Route index path="KnowledgeCategoryModify/:category_idx" element={<KnowledgeCategoryModify />} />
            <Route index path="KnowledgeCategoryView/:category_idx" element={<KnowledgeCategoryView />} />

            <Route index path="KnowledgeWrite" element={<KnowledgeWrite />} />
            <Route index path="KnowledgeList" element={<KnowledgeList />} />
            <Route index path="KnowledgeModify/:idx" element={<KnowledgeModify />} />
            <Route index path="KnowledgeView/:idx" element={<KnowledgeView />} />

            <Route index path="KnowledgecategoryWrite" element={<KnowledgeCategoryWrite />} />
            <Route index path="KnowledgeCategoryList" element={<KnowledgeCategoryList />} />
            <Route index path="KnowledgeCategoryModify/:category_idx" element={<KnowledgeCategoryModify />} />
            <Route index path="KnowledgeCategoryView/:category_idx" element={<KnowledgeCategoryView />} />

            <Route index path="KnowledgeWrite" element={<KnowledgeWrite />} />
            <Route index path="KnowledgeList" element={<KnowledgeList />} />
            <Route index path="KnowledgeModify/:idx" element={<KnowledgeModify />} />
            <Route index path="KnowledgeView/:idx" element={<KnowledgeView />} />

            <Route index path="MissioncategoryWrite" element={<MissionCategoryWrite />} />
            <Route index path="MissionCategoryList" element={<MissionCategoryList />} />
            <Route index path="MissionCategoryModify/:category_idx" element={<MissionCategoryModify />} />
            <Route index path="MissionCategoryView/:category_idx" element={<MissionCategoryView />} />

            <Route index path="MissionWrite/:list_category_idx" element={<MissionWrite />} />
            <Route index path="MissionList" element={<MissionList />} />
            <Route index path="MissionList/:list_category_idx" element={<MissionList />} />
            <Route index path="MissionModify/:idx/:list_category_idx" element={<MissionModify />} />
            <Route index path="MissionView/:idx/:list_category_idx" element={<MissionView />} />

            <Route index path="NoticeList/:board_name" element={<NoticeList />} />
            <Route index path="NoticeList/:board_name/:page" element={<NoticeList />} />
            <Route index path="NoticeWrite/:board_name" element={<NoticeWrite />} />
            <Route index path="NoticeModify/:board_name/:idx/:prevPage" element={<NoticeModify />} />
            <Route index path="NoticeView/:board_name/:idx/:prevPage" element={<NoticeView />} />

            <Route index path="NoticeListClient/:board_name" element={<NoticeListClient />} />
            <Route index path="NoticeListClient/:board_name/:page" element={<NoticeListClient />} />
            <Route index path="NoticeViewClient/:board_name/:idx/:prevPage" element={<NoticeViewClient />} />
            
            <Route index path="ProductWrite" element={<ProductWrite />} />
            <Route index path="ProductList" element={<ProductList />} />
            <Route index path="ProductModify/:prd_idx" element={<ProductModify />} />
            <Route index path="ProductView/:prd_idx" element={<ProductView />} />

            <Route index path="OrderWrite" element={<OrderWrite />} />
            <Route index path="OrderList" element={<OrderList />} />
            <Route index path="OrderModify/:ord_idx" element={<OrderModify />} />
            <Route index path="OrderView/:ord_idx" element={<OrderView />} />

            <Route index path="PaymentWrite" element={<PaymentWrite />} />
            <Route index path="PaymentList" element={<PaymentList />} />
            <Route index path="PaymentView/:pay_idx" element={<PaymentView />} />

            <Route index path="RefundList" element={<RefundList />} />

            <Route index path="StudentList" element={<StudentList />} />
            <Route index path="StudentWrite" element={<StudentWrite />} />
            <Route index path="StudentView/:mst_id" element={<StudentView />} />
            <Route index path="StudentModify/:mst_id" element={<StudentModify />} />

            <Route index path="PackageList" element={<PackageList />} />

            <Route index path="YameNoticeList/:board_name" element={<YameNoticeList />} />
            <Route index path="YameNoticeList/:board_name/:prevPage" element={<YameNoticeList />} />
            <Route index path="YameNoticeWrite/:board_name" element={<YameNoticeWrite />} />
            <Route index path="YameNoticeModify/:board_name/:idx/:prevPage" element={<YameNoticeModify />} />
            <Route index path="YameNoticeView/:board_name/:idx/:prevPage" element={<YameNoticeView />} />

            <Route index path="CouponList" element={<CouponList />} />
            <Route index path="CouponList/:page" element={<CouponList />} />
            <Route index path="CouponList/:page/:condition/:keyword" element={<CouponList />} />

            <Route index path="CouponDetailList/:prev/:idx/:limit" element={<CouponDetailList />} />
            <Route index path="CouponDetailList/:prev/:idx/:limit/:page" element={<CouponDetailList />} />
            <Route index path="CouponDetailList/:prev/:idx/:limit/:page/:condition/:keyword" element={<CouponDetailList />} />

            <Route index path="SolutionList" element={<SolutionList />} />
            <Route index path="SolutionList/:prevPage" element={<SolutionList />} />
            <Route index path="SolutionReply/:idx/:prevPage" element={<SolutionReply />} />
            <Route index path="SolutionModify/:idx/:prevPage" element={<SolutionModify />} />
            <Route index path="SolutionView/:idx/:prevPage" element={<SolutionView />} />

            <Route index path="NewsList" element={<NewsList />} />
            <Route index path="NewsList/:prevPage" element={<NewsList />} />
            <Route index path="NewsWrite" element={<NewsWrite />} />
            <Route index path="NewsModify/:idx/:prevPage" element={<NewsModify />} />
            <Route index path="NewsView/:idx/:prevPage" element={<NewsView />} />

            <Route index path="RankingList" element={<RankingList />} />
            <Route index path="RankingList/:prevPage" element={<RankingList />} />
            <Route index path="RankingWrite" element={<RankingWrite />} />
            <Route index path="RankingModify/:idx/:prevPage" element={<RankingModify />} />
            <Route index path="RankingView/:idx/:prevPage" element={<RankingView />} />
            <Route index path="RankingChallengeList/:idx/:order/:prevPage" element={<RankingChallengeList />} />
            <Route index path="RankingTotalList/:idx/:order/:prevPage" element={<RankingTotalList />} />

            <Route index path="InappProductWrite" element={<InappProductWrite />} />
            <Route index path="InappProductList" element={<InappProductList />} />
            <Route index path="InappProductModify/:ina_idx" element={<InappProductModify />} />
            <Route index path="InappProductView/:ina_idx" element={<InappProductView />} />

            <Route index path="InappPaymentList" element={<InappPaymentList />} />
            <Route index path="InappPaymentView/:inp_idx" element={<InappPaymentView />} />

            <Route index path="GroupOrderWrite" element={<GroupOrderWrite />} />
            <Route index path="GroupOrderList" element={<GroupOrderList />} />
            <Route index path="GroupOrderModify/:ord_idx" element={<GroupOrderModify />} />
            <Route index path="GroupOrderView/:ord_idx" element={<GroupOrderView />} />

          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </StyledEngineProvider>
  );
}

export default App;