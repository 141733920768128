import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import styles from "../../css/common.module.css";
import $ from 'jquery';
import { useNavigate, useParams } from 'react-router-dom';

import { groupOrderSelect } from "../../actions/group_order";
import { groupPaymentCreate, groupPaymentUpdate } from "../../actions/group_payment";
import * as common from "../../lib";
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';

import DocFileUpload from '../../common/DocFileUpload';

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

export default function GroupMypagePayInfoMore() {

	const authState = useSelector(state => state.auth);
    // console.log("authState : ", authState);

	const ord_idx  = useParams().ord_idx;

	const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

	let language = localStorage.getItem("language");

	const initialOrderState = {
		bord_idx: null,
		bord_no: null,
		bord_count: null,
		bord_amt: null,
		bord_stat_cd: null,
		bord_date: null,
		bord_valid_date: null,
		prd_name: null,
		prd_idx: null,
		prd_desc: null,
		sell_prc_unit: null,
		prd_photo_file: null,
		prd_photo_path: null,
		mem_idx: null,
		mem_name: null,
		mem_email: null,
		mem_phone: null,
		bpay_idx: null,
		bpay_no: null,
		bpay_amt: null,
		bpay_date: null,
		bpay_state: null,
		bank_num: null,
		bank_owner: null,
		bank_name: null,
		tax_email: null,
		bl_photo_file: null,
		bl_photo_path: null,
		bc_photo_file: null,
		bc_photo_path: null,
		option1: null,
		option2: null,
		file1: null,
        fileName1: null,
        filePath1: null,
        fileChange1: false,
		file2: null,
        fileName2: null,
        filePath2: null,
        fileChange2: false,
	};
	
	const [order, setOrder] = useState(initialOrderState);
	const [totalPrice, setTotalPrice] = useState(0);
	const [totalPriceString, setTotalPriceString] = useState("");

	const dispatch = useDispatch();	
	const navigate = useNavigate();

	const mypage_tab_btn_areaRef =  useRef();
    const hover_bgRef =  useRef();
    const onRef =  useRef();

	const pay_info_list_pagebtn_areaRef =  useRef();

	useEffect(() => {

		// 여기에서 jQuery를 초기화 합니다.
		// jquery ready 호출. jquery3 부터 변경됨
		$(function(){
			// console.log("jquery ready!");

            //마이페이지 탭 버튼(활성화 효과)
			$(`.${styles.mypage_tab_btn_area} li`).on('click',function()
			{
				let tg=$(this);
				
				$(`.${styles.mypage_tab_btn_area} li`).removeClass(`${styles.on}`);
				tg.addClass(`${styles.on}`);
			});
            
            //마이페이지 탭 버튼(후버 효과)
            $(window).resize(function()
			{
				if (window.innerWidth < 1200) 
				{ 
					$(`.${styles.mypage_tab_btn_area} li.${styles.hover_bg}`).css('display','none');
				}
				else
				{
					let nowbtnNum=$(`.${styles.mypage_tab_btn_area} li.${styles.on}`).index();
					let hoverBg=$(`.${styles.mypage_tab_btn_area} li.${styles.hover_bg}`);

					$(`.${styles.mypage_tab_btn_area} ul`).on('mouseenter',function()
					{
						
						$(`.${styles.mypage_tab_btn_area} li`).removeClass(`${styles.on}`);
						hoverBg.css('display','block');
						
						$(`.${styles.mypage_tab_btn_area} li`).on('mouseenter',function()
						{
							let tgIndexinfo=$(this);
							hoverBg.stop().css('left',tgIndexinfo['0']['offsetLeft']).css('width',tgIndexinfo['0']['clientWidth']);

							// let tgIndex=$(this).index();
							
							// if(tgIndex===1)
							// {
							// 	hoverBg.stop().css('left','167px').css('width','116px');
							// }
							// else if(tgIndex===2)
							// {
							// 	hoverBg.stop().css('left','300px').css('width','150px');
							// }
							// else if(tgIndex===3)
							// {
							// 	hoverBg.stop().css('left','467px').css('width','116px');
							// }
							// else if(tgIndex===4)
							// {
							// 	hoverBg.stop().css('left','624px').css('width','102px');
							// }
							// else if(tgIndex===5)
							// {
							// 	hoverBg.stop().css('left','774px').css('width','102px');
							// }
							// else if(tgIndex===6)
							// {
							// 	hoverBg.stop().css('left','924px').css('width','102px');
							// };
						});
						
					}).on('mouseleave',function()
					{
						$(`.${styles.mypage_tab_btn_area} li`).eq(nowbtnNum).addClass(`${styles.on}`);
						hoverBg.css('display','none');
						
						if(nowbtnNum===0)
						{
							hoverBg.css({left:'167px'}).css({width:'116px'});
						}
						else if(nowbtnNum===1)
						{
							hoverBg.css({left:'300px'}).css({width:'150px'});
						}
						else if(nowbtnNum===2)
						{
							hoverBg.css({left:'467px'}).css({width:'116px'});
						}
						else if(nowbtnNum===3)
						{
							hoverBg.css({left:'624px'}).css({width:'102px'});
						}
						else if(nowbtnNum===4)
						{
							hoverBg.css({left:'774px'}).css({width:'102px'});
						}
						else if(nowbtnNum===5)
						{
							hoverBg.css({left:'924px'}).css({width:'102px'});
						};

					});
					
				}
			}).resize();
			
			//결제 정보 페이지 페이징 버튼	
			$(pay_info_list_pagebtn_areaRef.current).find('li').on('click',function()
			{
				let tg=$(this);
				let num=tg.index();
				let prev;
				let next;
				let last;
				let nowBtn=$(pay_info_list_pagebtn_areaRef.current).children(`${styles.on}`);
				let nowIndex=nowBtn.index();
				
				$(pay_info_list_pagebtn_areaRef.current).children('li').removeClass(`${styles.on}`);
				
				if(tg.hasClass(`${styles.arrow_btn}`)==true)
				{
					if(tg.hasClass(`${styles.first}`)==true)
					{
						tg=$(pay_info_list_pagebtn_areaRef.current).find('li').eq(2);
					}
					else if(tg.hasClass(`${styles.prev}`)==true)
					{
						prev=nowIndex-1;
						
						if(nowIndex===2)
						{
							tg=$(pay_info_list_pagebtn_areaRef.current).find('li').eq(2);
						}else
						{
							tg=$(pay_info_list_pagebtn_areaRef.current).find('li').eq(prev);
						};
					}
					else if(tg.hasClass(`${styles.next}`)==true)
					{
						let lastNum=$(pay_info_list_pagebtn_areaRef.current).find('li').last().index();
						next=nowIndex+1;
						last=lastNum-2;
						
						if(nowIndex===last)
						{
							tg=$(pay_info_list_pagebtn_areaRef.current).find('li').eq(last);
						}else
						{
							tg=$(pay_info_list_pagebtn_areaRef.current).find('li').eq(next);
						};
					}
					else if(tg.hasClass(`${styles.last}`)==true)
					{
						last=num-2;
						tg=$(pay_info_list_pagebtn_areaRef.current).find('li').eq(last);
					};
					
					tg.addClass(`${styles.on}`);
					
				}
				else
				{
					tg.addClass(`${styles.on}`);
				};
			});

		});

		// console.log("dispatch orderSelect");
        dispatch(groupOrderSelect(ord_idx))
        .then(data => 
        {
            // console.log("data : ", data[0]);

			setOrder({
				bord_idx: data[0].bord_idx,
				bord_no: data[0].bord_no,
				bord_count: data[0].bord_count,
				bord_amt: data[0].bord_amt,
				bord_stat_cd: data[0].bord_stat_cd,
				bord_date: data[0].bord_date,
				bord_valid_date: data[0].bord_valid_date,
				prd_name: data[0].prd_name,
				prd_idx: data[0].prd_idx,
				prd_desc: data[0].prd_desc,
				sell_prc_unit: data[0].sell_prc_unit,
				prd_photo_file: data[0].prd_photo_file,
				prd_photo_path: data[0].prd_photo_path,
				mem_idx: data[0].mem_idx,
				mem_name: data[0].mem_name,
				mem_email: data[0].mem_email,
				mem_phone: data[0].mem_phone,
				bpay_idx: data[0].bpay_idx,
				bpay_no: data[0].bpay_no,
				bpay_amt: data[0].bpay_amt,
				bpay_date: data[0].bpay_date,
				bpay_state: data[0].bpay_state,
				bank_num: data[0].bank_num,
				bank_owner: data[0].bank_owner,
				bank_name: data[0].bank_name,
				tax_email: data[0].tax_email,
				bl_photo_file: data[0].bl_photo_file,
				bl_photo_path: data[0].bl_photo_path,
				bc_photo_file: data[0].bc_photo_file,
				bc_photo_path: data[0].bc_photo_path,
				option1: data[0].option1,
				option2: data[0].option2
			});

			setTotalPriceString(totalPriceRetrun(data[0].option1, data[0].option2));
            
        })
        .catch(e => {
            console.log(e);
        });

	}, [$, dispatch]);

	const vBankNumCopy = (event, value, ttt) =>
	{
		event.preventDefault();

		const t = document.createElement("textarea");
		document.body.appendChild(t);
		t.value = value;
		t.select();
		document.execCommand('copy');
		document.body.removeChild(t);

		// return alert("복사완료!");
		return alert(`${ttt("MypagePayInfo.alert.string10")}`);
	};

	const totalPriceRetrun = (option1, option2) =>
	{
		let price1 = (parseInt(option1[0].bopo1_amt) * parseInt(option1[0].bopo1_count));
		let price2 = 0;
		let totalPrice = 0;

		for (let i=0; i<option2.length; i++)
		{
			price2 += (parseInt(option2[i].bopo2_price) * parseInt(option2[i].bopo2_count));
		}

		totalPrice = price1 + price2;

		// 부과세 계산
		let SAL_VAT   = (totalPrice)*0.1;    // 부가세(VAT)
		let SAL_PRICE = Math.round(totalPrice + SAL_VAT); // 단가(반올림)
		SAL_VAT   = Math.round(SAL_VAT);    // 부가세(반올림)

		setTotalPrice(SAL_PRICE);
		
		// return common.priceToString(SAL_PRICE) + "원 (" + common.priceToString(SAL_VAT) + "원 부과세 포함된 가격)";
		return t("MypagePayInfo.string74", {amt1: common.priceToString(SAL_PRICE), amt2: common.priceToString(SAL_VAT)});
	};

	const handleImgFileChange1 = (file) => {
        // console.log("file1 : ", file);
        setOrder({ ...order, file1: file, fileName1: file.name, fileChange1: true });
    };

	const handleImgFileDelete1 = (file) => {
        // console.log("file1 : ", file);
        setOrder({ ...order, file1: null, fileName1: null, fileChange1: false });
    };

	const handleImgFileChange2 = (file) => {
        // console.log("file2 : ", file);
        setOrder({ ...order, file2: file, fileName2: file.name, fileChange2: true });
    };

	const handleImgFileDelete2 = (file) => {
        // console.log("file2 : ", file);
        setOrder({ ...order, file2: null, fileName2: null, fileChange2: false });
    };

	const handleInputChange = event => {
        const { name, value } = event.target;
        setOrder({ ...order, [name]: value });
    };

	const submitClick = (event) =>
	{
		event.preventDefault();

		// const PaymentData = {
		// 	bpay_idx: order.bpay_idx,
		// 	bord_no: order.bord_no,
		// 	bpay_amt: totalPrice,
		// 	bank_num: order.bank_num,
		// 	bank_owner: order.bank_owner,
		// 	bank_name: order.bank_name,
		// 	tax_email: order.tax_email,
		// 	docFile1: order.file1,
        // 	fileName1: order.fileName1,
		// 	docFile2: order.file2,
        // 	fileName2: order.fileName2,
		// };

		// console.log("PaymentData : ", PaymentData);

		const formData = new FormData();
		formData.append('bpay_idx', order.bpay_idx);
        formData.append('bord_no', order.bord_no);
		formData.append('bpay_amt', totalPrice);
		formData.append('bank_num', order.bank_num);
		formData.append('bank_owner', order.bank_owner);
		formData.append('bank_name', order.bank_name);
		formData.append('tax_email', order.tax_email);
		formData.append('docFile', order.file1);
		formData.append('fileName1', order.fileName1);
		formData.append('docFile', order.file2);
		formData.append('fileName2', order.fileName2);

		
		if (order.bpay_idx === null) 	// 결제번호가 있다면 처음 등록 아니라면 수정으로
		{
			// 여기서 디스패치로~
			dispatch(groupPaymentCreate(formData))
			.then(data => 
			{
				// console.log("data : ", data);
				if (!data.success)
					alert(data.msg);

				navigate("/GroupUserMypage/GroupPayInfo");
	
			})
			.catch(e => {
				console.log(e);
			});
		}
		else
		{
			// 여기서 디스패치로~
			dispatch(groupPaymentUpdate(formData))
			.then(data => 
			{
				// console.log("data : ", data);
				if (!data.success)
					alert(data.msg);

			})
			.catch(e => {
				console.log(e);
			});
		}
	};

	const onReadyClick = () => 
	{
		// alert("서비스 중비중입니다.");
		alert(`${t("Common.service_wait")}`);
	};

  return (
    <>
      	{/* Header 분리됨 MainLayout Header에 있음 */}

		<div className={styles.mypage_top_line_banner}>
			<div className={styles.inner}>
				<span>{/* 학부모 페이지 */}{t('Common.Mypage_title2')}</span>
			</div>
		</div>
		<div className={styles.mypage_tab_btn_area} ref={mypage_tab_btn_areaRef}>
			<div className={styles.inner}>
				<ul>
					<li className={styles.hover_bg} ref={hover_bgRef}></li>
					<li><a href="/GroupUserMypage/GroupReport">{/* 클래스 관리 */}{t('Common.Group_SubMenu.menu1')}</a></li>
					<li><a href="/GroupUserMypage/GroupParentsInfo">{/* 관리자 정보 */}{t('Common.Group_SubMenu.menu2')}</a></li>
					{/* <li className={styles.on} ref={onRef}><a href="/GroupUserMypage/GroupPayInfo"> */}{/* 결제 관리 */}{/* {t('Common.Group_SubMenu.menu3')}</a></li> */}
					{ language === null || language === 'ko' ? <li className={styles.on} ref={onRef}><a href="/GroupUserMypage/GroupPayInfo">{/* 결제 관리 */}{t('Common.Group_SubMenu.menu3')}</a></li> : "" }
					<li><a href="/GroupUserMypage/GroupCouponInfo">{/* 쿠폰관리 */}{t('Common.Group_SubMenu.menu4')}</a></li>
					{/* <li><a href="/GroupUserMypage/GroupStudentInfo">공지사항</a></li>
					<li><a href="/GroupUserMypage/GroupQnaInfo">질문과 답변</a></li> */}
					<li><a href="/GroupUserMypage/GroupQnaInfo">{/* 문의 내역 */}{t('Common.SubMenu.menu6')}</a></li>
					<li><a href="#!" onClick={()=>onReadyClick()}>{/* 공지사항 */}{t('Common.Group_SubMenu.menu5')}</a></li>
					<li><a href="#!" onClick={()=>onReadyClick()}>{/* 질문과 답변 */}{t('Common.Group_SubMenu.menu6')}</a></li>
				</ul>
			</div>
		</div>
		<div className={`${styles.mypage_tab} ${styles.mypage_pay_info_more}`}>
			<div className={styles.inner}>
				<span className={styles.my_tit}>{/* 결제 상세정보 */}{t('MypagePayInfo.string26')}</span>
				<div className={styles.pay_info_more_area}>
					 <ul>
						<li>
							<span className={styles.head}>{/* 주문일 */}{t('MypagePayInfo.string5')}</span>
							<p>{common.payDateChangeReturn(order.bord_date)}{/*2022.03.25 - 16시35분*/}</p>
						</li>
						<li>
							<span className={styles.head}>{/* 결제 완료일 */}{t('MypagePayInfo.string52')}</span>
							<p>{common.payDateChangeReturn(order.bpay_date)}{/*2022.04.25 - 16시35분*/}</p>
						</li>
						<li>
							<span className={styles.head}>{/* 주문번호 */}{t('MypagePayInfo.string28')}</span>
							<p>{order.bord_no}</p>
						</li>
						<li>
							<span className={styles.head}>{/* 주문자 */}{t('MypagePayInfo.string7')}</span>
							<p>{order.mem_name}</p>
						</li>
						<li>
							<span className={styles.head}>{/* 상품명 */}{t('MypagePayInfo.string29')}</span>
							<p>{order.prd_name}</p>
						</li>
						<li>
							<span className={styles.head}>{/* 상품설명 */}{t('MypagePayInfo.string30')}</span>
							<p>{order.prd_desc}</p>
						</li>
						<li>
							<span className={styles.head}>{/* 야미 코딩 결제내역 */}{t('MypagePayInfo.string53')}</span>
							<p>
								{
									order?.option1?.length !== 0 
									? 
										// "앱 이용권 " + common.priceToString(order?.option1?.[0].bopo1_amt || "") + "원 * " + order?.option1?.[0].bopo1_count + "개 = " + 
										// (common.priceToString(parseInt(order?.option1?.[0].bopo1_amt) * parseInt(order?.option1?.[0].bopo1_count) || "")) + "원"
										t('MypagePayInfo.string54', {amt:common.priceToString(order?.option1?.[0].bopo1_amt || ""), count:order?.option1?.[0].bopo1_count, 
										total:(common.priceToString(parseInt(order?.option1?.[0].bopo1_amt) * parseInt(order?.option1?.[0].bopo1_count) || ""))})
									: 
										""
								}
							</p>
						</li>
						{
							order?.option2?.length !== 0 ? order?.option2 && order?.option2?.map((row, index) => {
								return (
									<li key={index}>
										<span className={styles.head}></span>
										<p>
											{/* {row.bopo2_name}({common.priceToString(row.bopo2_price) || ""}) * 
											{row.bopo2_count} = {common.priceToString((parseInt(row.bopo2_price) * parseInt(row.bopo2_count) || ""))}원 */}
											{t('MypagePayInfo.string55', {name:row.bopo2_name, amt:common.priceToString(row.bopo2_price) || "",
												count:row.bopo2_count, total:common.priceToString((parseInt(row.bopo2_price) * parseInt(row.bopo2_count) || ""))})}
										</p>
									</li>
								);
							})
							:
							<></>
						}
						<li>
							<span className={styles.head}>{/* 총 결제 요청 금액 */}{t('MypagePayInfo.string56')}</span>
							<p>{totalPriceString}</p>
						</li>
						{/* <li>
							<span className={styles.head}>수량</span>
							<p>{order.bord_count || ""} 개</p>
						</li> */}
						<li>
							<span className={styles.head}>{/* 입금 계좌명 */}{t('MypagePayInfo.string57')}</span>
							<p>{/* (주)위클러버 이성진 */}{t('MypagePayInfo.string58')}</p>
						</li>
						<li className={styles.info_txt}>
							<span className={styles.head}>{/* 입금계좌번호 */}{t('MypagePayInfo.string59')}</span>
							<div>
								<div className={styles.top_area}>
									<p>140-012-855560</p> 
									<button  type="button"  className={styles.fake_account_number_copy} onClick={(e) => vBankNumCopy(e, "140-012-855560", t)}>{/* 복사 */}{t('MypagePayInfo.string36')}</button>
								</div>
								<p className={styles.info}>{/* * 복사 버튼을 누르면 계좌번호가 복사됩니다. */}{t('MypagePayInfo.string60')}</p>
							</div>
							
						</li>
						<li>
							<span className={styles.head}>{/* 입금 은행명 */}{t('MypagePayInfo.string61')}</span>
							<p>{/* 신한은행 */}{t('MypagePayInfo.string62')}</p>
						</li>
						{/* 추가입금정보 */}{t('MypagePayInfo.string63')}
					
						<li>
							<span className={styles.head}>{/* 계좌명(예금주) */}{t('MypagePayInfo.string64')}</span>
							<div style={{width:'300px'}}>
								<TextField
									fullWidth
									required
									id="bank_owner"
									name="bank_owner"
									// label="계좌명"
									label={t('MypagePayInfo.string64')}
									value={order.bank_owner || ""}
									onChange={handleInputChange}
								/>
							</div>
						</li>
						<li>
							<span className={styles.head}>{/* 계좌번호 */}{t('MypagePayInfo.string65')}</span>
							<div style={{width:'350px'}}>
								<TextField
									fullWidth
									required
									id="bank_num"
									name="bank_num"
									// label="계좌번호"
									label={t('MypagePayInfo.string65')}
									value={order.bank_num || ""}
									onChange={handleInputChange}
								/>
							</div>
						</li>
						<li>
							<span className={styles.head}>{/* 은행명 */}{t('MypagePayInfo.string66')}</span>
							<div style={{width:'300px'}}>
								<TextField
									fullWidth
									required
									id="bank_name"
									name="bank_name"
									// label="은행명"
									label={t('MypagePayInfo.string66')}
									value={order.bank_name || ""}
									onChange={handleInputChange}
								/>
							</div>
						</li>
						<li>
							<span className={styles.head}>{/* 세금 발행 이메일 */}{t('MypagePayInfo.string67')}</span>
							<div style={{width:'350px'}}>
								<TextField
									fullWidth
									required
									id="tax_email"
									name="tax_email"
									// label="세금 발행 이메일"
									label={t('MypagePayInfo.string67')}
									value={order.tax_email || ""}
									onChange={handleInputChange}
								/>
							</div>
						</li>
						<li>
							<span className={styles.head}>{/* 통장사본 */}{t('MypagePayInfo.string68')}</span>
							<div style={{width:'400px'}}>
								{order.bl_photo_file === null || order.bl_photo_file === "" ? "" : <a href={ process.env.REACT_APP_IMG_URL + order.bl_photo_path + "/" + order.bl_photo_file}>{/* 통장사본 다운로드 */}{t('MypagePayInfo.string69')}</a> }
								<DocFileUpload handleImgFileChange={handleImgFileChange1} FileName="docFile1" handleImgFileDelete={handleImgFileDelete1} />
							</div>
						</li>
						<li>
							<span className={styles.head}>{/* 사업자등록증 */}{t('MypagePayInfo.string70')}</span>
							<div style={{width:'400px'}}>
								{order.bc_photo_file === null || order.bc_photo_file === "" ? "" : <a href={ process.env.REACT_APP_IMG_URL + order.bc_photo_path + "/" + order.bc_photo_file}>{/* 사업자등록증 다운로드 */}{t('MypagePayInfo.string71')}</a> }
								<DocFileUpload handleImgFileChange={handleImgFileChange2} FileName="docFile2" handleImgFileDelete={handleImgFileDelete2} />
							</div>
						</li>
						<li className={styles.pay_more_total}>
							<span className={styles.head}>{/* 결제상태 */}{t('MypagePayInfo.string9')}</span>
							<p>{order.bpay_state === null ? common.returnOrderState(order.bord_stat_cd) : common.returnGroupPaymentState(order.bpay_state)} <br/></p>
						</li>
						{
							order.bpay_state === "active" ?
								<li className={styles.pay_more_total}>
									<span className={styles.head}>{/* 총 결제금액 */}{t('MypagePayInfo.string40')}</span>
									<p>{common.priceToString(order.bpay_amt) || ""}{/* 원 */}{t('MypagePayInfo.string32')}</p>
								</li>
							:
								<li className={styles.pay_more_total}>
									<span className={styles.head}>{/* 총 결제금액 */}{t('MypagePayInfo.string65')}</span>
									<p>0{/* 원 */}{t('MypagePayInfo.string32')}</p>
								</li>
						}
						{/* {
							order.order_stat_cd === "A" ?
								<li>
									<span className={styles.head}>영수증</span>
									<p><a href={order.receipt_url || "#!"} target="_blank">영수증 확인</a></p>
								</li>			
							:
							""
						} */}
						{/* {
							order.refund_stat_cd === "cancelled" ?
								<>
								<li>
									<span className={styles.head}>환불일</span>
									<p>{common.payDateChangeReturn(order.refund_date)}</p>
								</li>
								<li className={styles.pay_more_refund}>
									<span className={styles.head}>환불금액</span>
									<div>
										<p>-{common.priceToString(order.refund_amt)}원</p>
										<p className={styles.info}>* 실시간 계좌와 가상 계좌는 환불 처리가 완료되어도 입금까지 영업일 기준(주말, 공휴일 제외) 1~2일 소요됩니다.</p>
									</div>
								</li>	
								</>	
							:
							""
						} */}
						<li>
							<span className={styles.head}></span>
							<p>
								{/* <Button variant="contained" onClick={(e) => submitClick(e)}>{order.bpay_idx === null ? "입금확인 요청 작성" : "입금확인 요청 수정"}</Button> */}
								<Button variant="contained" style={{ textTransform: 'none' }} onClick={(e) => submitClick(e)}>{order.bpay_idx === null ? t('MypagePayInfo.string72') : t('MypagePayInfo.string73')}</Button>
							</p>
						</li>
					 </ul>
					 {/* <Button>입금확인 요청</Button> */}
					<a href="/GroupUserMypage/GroupPayInfo">{/* 목록 */}{t('Common.List')}</a>
				</div>
			</div>{/* inner */}
		</div>{/* mypage_tab */}
		
		{/* Footer 분리됨 MainLayout Footer에 있음 */}
    </>
  );
}