import * as React from 'react';
import styles from "../../css/common.module.css";

import Grid from '@mui/material/Grid';
import { useState } from "react";
import { useDispatch } from "react-redux";
import { emailChange } from "../../actions/auth";
import { useNavigate } from 'react-router-dom';

import TextField from '@mui/material/TextField';

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

export default function EmailChangeSub2() {

    const initialAuthState = {
        email: ""
    };

    const [auth, setAuth] = useState(initialAuthState);
    const [auth2, setAuth2] = useState(initialAuthState);
    const [myPassword, setPassword] = useState("");

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

    let language = localStorage.getItem("language");

    const handleInputChange = event => {
        const { name, value } = event.target;
        setAuth({ ...auth, [name]: value });
    };

    const handleInputChange2 = event => {
        const { name, value } = event.target;
        setAuth2({ ...auth2, [name]: value });
    };

    const handleInputChange3 = event => {
        const { name, value } = event.target;
        setPassword(value);
    };

    const handleSubmit = (event) => {

        event.preventDefault();

        const { email } = auth;
        const { email2 } = auth2;
        const password = myPassword;

        if (email === "")
        {
            // return alert("기존 이메일을 입력해주세요!");
            return alert(`${t("Auth.alert.string24")}`);
        }

        if (email2 === "")
        {
            // return alert("새로운 이메일을 입력해주세요!");
            return alert(`${t("Auth.alert.string25")}`);
        }

        const regEmail = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
        
        if (regEmail.test(email) === false) 
        {
            // return alert('입력된 값은 이메일 형식이 아닙니다.');
            return alert(`${t("Auth.alert.string7")}`);
        }

        if (regEmail.test(email2) === false) 
        {
            // return alert('입력된 값은 이메일 형식이 아닙니다.');
            return alert(`${t("Auth.alert.string7")}`);
        }

        let senedData = {
            email: email,
            email2: email2,
            password: password
        }

        // console.log("senedData : ", senedData);

        // 여기서 디스패치로~
        dispatch(emailChange(senedData))
        .then(data => 
        {
            // console.log("data : ", data);
            
            if (!data.success)
            {
                alert(data.msg);
                return;
            }

            // alert("이메일이 변경되었습니다. 바뀐 메일로 인증을 해주세요.");
            alert(`${t("Auth.alert.string26")}`);
            navigate("/auth/login");
        })
        .catch(e => {
            console.log(e);
        });
    };

    return (
        <Grid container component={styles.main} sx={{ height: '100vh' }}>
            <div className={`${styles.login_bg_wrapper} ${styles.password_bg_wrapper}`}>
                <div className={`${styles.email_reset_area2} ${styles.middle}`}>
                    <div className={styles.login_top}>
                        <a href="/" className={styles.logo}>
                            {/* <img src="/assets/logo@2x.png" alt="logo"/> */}
                            {
								{ 
									'ko' : <img src="/assets/logo@2x.png" alt="logo"/>,
									'en' : <img src="/assets/logo@2x_en.png" alt="logo"/>,
									'jp' : <img src="/assets/logo@2x_jp.png" alt="logo"/>
								}[language]
							}
                        </a>
                    </div>
                    <span className={styles.login_title}>{/* 이메일 변경 */}<Trans i18nKey="Auth.email_change_string7"/></span>
                    <div className={`${styles.email_reset_wrap} ${styles.center_align}`}>
                        <p>{/* 회원가입 시 입력한 메일을 입력해 주세요. <br/>변경된 메일로 인증메일을 발송합니다. */}
                        <Trans i18nKey="Auth.email_change_string8"/></p>
                        <div className={`${styles.password_reset_input_area} ${styles.input_area}`}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                // label="기존 이메일"
                                label={t('Auth.email_change_string9')}
                                name="email"
                                autoComplete="email"
                                autoFocus
                                onChange={handleInputChange}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email2"
                                // label="새로운 이메일"
                                label={t('Auth.email_change_string10')}
                                name="email2"
                                autoComplete="email2"
                                onChange={handleInputChange2}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                // label="패스워드"
                                label={t('Auth.common.password')}
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={handleInputChange3}
                            />
                        </div>
                        <a href="#" className={styles.ok} onClick={handleSubmit}>{/* 이메일 변경 */}{t('Auth.email_change_string7')}</a>
                    </div>
                </div>
            </div>
        </Grid>
    );
}