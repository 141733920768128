import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import Divider from '@mui/material/Divider';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" style={{ textTransform: 'none' }} onClick={handleClickOpen}>학습자 랭킹</Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          학습자 추가
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            클래스의 이름은 중복되지 않습니다. <br></br>모든 데이터를 입력해주세요.
          </Typography>
        </DialogContent>
		<Box
		component="form"
		sx={{
			'& .MuiTextField-root': { m: 1, width: '350px', marginTop: '20px', marginLeft: '20px', marginRight: '20px' },
		}}
		noValidate
		autoComplete="off"
		>
		<div>
			<TextField
			required
			id="class-name"
			label="클래스 이름"
			defaultValue=""
			/>
		</div>
		</Box>
		<Box
		component="form"
		sx={{
			'& .MuiTextField-root': { m: 1, width: '350px', marginTop: '20px', marginLeft: '20px' },
		}}
		noValidate
		autoComplete="off"
		>
		<div>
			<TextField
			required
			id="school-name"
			label="학교"
			defaultValue=""
			/>
		</div>
		</Box>
		<Box
		component="form"
		sx={{
			'& .MuiTextField-root': { m: 1, width: '100px', marginTop: '20px', marginLeft: '20px' },
		}}
		noValidate
		autoComplete="off"
		>
		<div>
			<TextField
			required
			id="school-grade"
			label="학년"
			defaultValue=""
			/>
		</div>
		</Box>
		<Box
		component="form"
		sx={{
			'& .MuiTextField-root': { m: 1, width: '100px', marginTop: '20px', marginLeft: '20px', marginBottom: '20px' },
		}}
		noValidate
		autoComplete="off"
		>
		<div>
			<TextField
			required
			id="school-class"
			label="반"
			defaultValue=""
			/>
		</div>
		</Box>

		<Divider />
		
        <DialogActions>
          <Button autoFocus onClick={handleClose} style={{ textTransform: 'none' }}>
            생성
          </Button>
		  <Button autoFocus onClick={handleClose} style={{ textTransform: 'none' }}>
            취소
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}