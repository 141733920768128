import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import styles from "../css/common.module.css";
import $ from 'jquery';
import { userSelect, userPhoneModify } from "../actions/users";
import { studentList } from "../actions/student";
// import http from "../http-common";
// import { orderCreate } from "../actions/order";
import { useNavigate } from 'react-router-dom';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { productGroupOption1List, productGroupOption2List } from "../actions/product";
import { groupOrderCreate } from "../actions/group_order";

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

export default function Main() {

	// 일반 회원정보
	const [email, setEmail] = useState("");
	const [name, setName] = useState("");
	const [nickname, setNickname] = useState("");
	const [phone, setPhone] = useState("");
	const [type, setType] = useState("");
	const [smsRecive, setsmsRecive] = useState(0);
	const [emailRecive, setEmailRecive] = useState(0);
	const [mgStatus, setMgStatus] = useState(0);
	const [prdIdx, setPrdIdx] = useState(0);

	const [option1Date, setOption1Date] = useState(null);
	const [option1DateValue, setOption1DateValue] = useState("");
	const [option1PriceValue, setOption1PriceValue] = useState("");
	const [option1Price, setOption1Price] = useState(null);
	const [option1PriceCopy, setOption1PriceCopy] = useState(null);
	const [option2, setOption2] = useState(null);

	const [TicketPrice, setTicketPrice] = useState(0);			// 이용권의 금액을 저장하는 변수
	const [impositionTax, setImpositionTax] = useState(0);		// 부과세를 저장하는 변수
	const [addPrice, setAddPrice] = useState(0);				// 추가금액을 저장하는 변수
	const [totalPrice, setTotalPrice] = useState(0);			// 부과세를 저장하는 변수
	const [studentCount, setStudentCount] = useState(1);		// 학숩자의 수를 저장하는 변수

	const [checked, setChecked] = useState([]);					// 체크박스를 위한 배열

	const authState = useSelector(state => state.auth);
    // console.log("authState : ", authState);

	const dispatch = useDispatch();	
	const navigate = useNavigate();

	const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

	useEffect(() => {

		// 여기에서 jQuery를 초기화 합니다.
		$(document).ready(function(){

			// 언어가 바뀌었을 때 한글이 아니면 첫번쩨 페이지로 이동합니다.
			let language = localStorage.getItem("language");
			if (language !== 'ko')
			{
				document.location.href = '/';	// 메일 페이지로 이동
			}

			// console.log("jquery ready!");

			//전화번호 추가 입력 팝업에서 '취소' 클릭했을 때
			$(`.${styles.pay_add_info_popup} .${styles.cancle}`).on('click',function(event)
			{
				event.preventDefault();

				$(`.${styles.pay_add_info_popup}`).css('display','none');
				blackBg(2);
				$(`#${styles.pay_add_phonenumber}`).val("");
			});

			//천마다 콤마
			function priceToString(price) 
			{
				if (price === null)
					price = 0;

				return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
			}

			//구매하기 페이지 상품선택
			let chooseProduct;
			
			$(`.${styles.product_area} ul li`).on('click',function(){
				let tg=$(this);
				
				chooseProduct=tg.attr('name');
				// console.log(chooseProduct);
				
				$(`.${styles.product_area} ul li`).removeClass(`${styles.on}`);
				tg.addClass(`${styles.on}`);
			});

		});

		dispatch(userSelect(authState.user.email))
		.then(data => 
		{
			// console.log("data : ", data);

			setMgStatus(data.mg_status);
			setPrdIdx(data.prd_idx);

			dispatch(productGroupOption1List(data.prd_idx))
			.then(data => 
			{
				// console.log("productGroupOption1List data : ", data);
				
				let ArrayValue1 = [];
				let ArrayValue2 = [];

				for (let i=0; i<data.length; i++)
				{
					ArrayValue1[i] = 
					{
						expiration_date_unit: data[i].expiration_date_unit,
						expiration_date: data[i].expiration_date,
						name: data[i].expiration_date + data[i].expiration_date_unit
					};
				}

				for (let i=0; i<data.length; i++)
				{
					ArrayValue2[i] = 
					{
						expiration_date_unit: data[i].expiration_date_unit,
						expiration_date: data[i].expiration_date,
						bopo1_count_start: data[i].po1_count_start,
						bopo1_count_end: data[i].po1_count_end, 
						bopo1_amt: data[i].po1_amt,
						name: data[i].po1_count_end !== 0 ? data[i].po1_count_start + " ~ " + data[i].po1_count_end + "개 (" + priceToString(data[i].po1_amt) + ")원" 
																: data[i].po1_count_start + "개 이상 (" + priceToString(data[i].po1_amt) + ")원" ,
					};
				}

				ArrayValue1 = ArrayValue1.filter((v, i) => ArrayValue1.findIndex(x => x.name === v.name) === i);
				ArrayValue2 = ArrayValue2.filter((v, i) => ArrayValue2.findIndex(x => x.name === v.name) === i);

				setOption1Date(ArrayValue1);
				setOption1Price(ArrayValue2);
				
				selectPriceDataChange(ArrayValue2, 1, 'day');
				
			})
			.catch(e => {
				console.log(e);
			});

			dispatch(productGroupOption2List(data.prd_idx))
			.then(data => 
			{
				// console.log("productGroupOption2List data : ", data);

				let ArrayValue = [];

				for (let i=0; i<data.length; i++)
				{
					ArrayValue[i] = 
					{
						po2_name: data[i].po2_name,
        				po2_price: data[i].po2_price,
						apply:false,	// 적용할지 말지
					};
				}

				// console.log("productGroupOption2List data : ", ArrayValue);

				setOption2(ArrayValue);

				for (let i=0; i<ArrayValue.length; i++)
				{
					setChecked({...checked, [i]: false});
				}
				
			})
			.catch(e => {
				console.log(e);
			});
		})
		.catch(e => {
			console.log(e);
		});

	}, [$]);

	// 시간의 정보를 받아서 가격의 셀렉트에 보여질 데이터를 조정합니다.
	const selectPriceDataChange = (option, day, unit) => 
	{

		// console.log("option : ", option);

		let newArray = option.filter((x)=> {
			if(x.expiration_date == day && x.expiration_date_unit == unit) 
			{
				return x.expiration_date
			}
		})

		// console.log("newArray : ", newArray);

		setOption1PriceCopy(newArray);
	};

	//검정 반투명 배경 제어
	const blackBg = (i) => 
	{
		if(i===1)
		{
			$(`.${styles.body_black}`).css('display','block');
			// $('body').css('overflow','hidden');
			// console.log('배경보임');
		}
		else
		{
			$(`.${styles.body_black}`).css('display','none');
			// $('body').css('overflow','visible');
			// console.log('배경꺼짐');
		};
	};

	//구매하기 클릭시 팝업
	const payPhoneNumber = (event, i) =>
	{
		event.preventDefault();

		// console.log("payPhoneNumber click ", i);
		// console.log("studentCount : ", studentCount);

		if(studentCount==='0')
		{
			/* return alert('학습자는 1명부터 구매 가능합니다.'); */
			return alert(`${t("Pay.alert.pay_string1")}`);
		}
		else if(studentCount==='')
		{
			/* return alert('학습자는 1명부터 구매 가능합니다.'); */
			return alert(`${t("Pay.alert.pay_string1")}`);
		}
		else if(isNaN(studentCount) === true)
		{
			/* return alert('학습자는 1명부터 구매 가능합니다.'); */
			return alert(`${t("Pay.alert.pay_string1")}`);
		}
		else if(option1DateValue==='')
		{
			// return alert(`기간을 선택해주세요.`);
			return alert(`${t("Pay.alert.pay_string14")}`);
		}
		else if(option1PriceValue==='')
		{
			// return alert(`가격을 선택해주세요.`);
			return alert(`${t("Pay.alert.pay_string15")}`);
		}
		else
		{
			// 로그인 정보를 확인합니다.
			if (!authState.isLoggedIn)
			{
				// console.log(" isLoggedIn check ");
				// alert("구매하기는 로그인이 필요합니다.");
				alert(`${t("Pay.alert.pay_login")}`);
				document.location.href = "/auth/login";
			}
			else
			{
				// 결제에 필요한 정보들이 모두 있는지 체크합니다.
				if (authState?.user?.email !== null && authState?.user?.email !== undefined)
				{
					// 학습자가 있는지 검사합니다. 소셜 로그인의 경우 학습자가 없을 수 있지만
					// 그래도 주문하면 시스템에서 주문을 해도 유료로 적용이 안되는 문제가 발생할 수 있습니다.
					// 그래서 학습자 생성 페이지로 이동시킵니다.

					// 학생 리스트 불러오기
					dispatch(studentList())
					.then(data => 
					{
						// console.log("studentList data : ", data);
						// 학생의 개수를 체크해서 하나도 없으면 페이지를 이동합니다.
			
						if (data.length === 0)
						{
							// alert("구매를 하시기 위해서는 최소 1명 이상의 학습자가 필요합니다. 학습자 생성 후에 다시 구매해주세요!");
							alert(`${t("Pay.alert.pay_string2")}`);
							window.location.href = "/UserMypage/MypageStudentAdd";
						}
						else
						{
							dispatch(userSelect(authState.user.email))
							.then(data => 
							{
								// console.log("data : ", data);
					
								setEmail(data.mem_email);
								setName(data.mem_name);
								setNickname(data.mem_nickname);
								setPhone(data.mem_phone);
								setType(data.mem_type);
								setsmsRecive(data.mem_recive_sms);
								setEmailRecive(data.mem_recive_email);

								// 전화번호가 있는지 검사합니다. 주문을 위해서는 전화번호 정보가 반드시 필요합니다.
								if (data.mem_phone === null || data.mem_phone === "")
								{
									blackBg(1);
									$(`.${styles.pay_add_info_popup}`).css('display','block');
									return false;
								}

								let copiedItems = [...option2];
		
								/* 복사한 배열에 수정할 값을 할당하고 */
								for (let i=0; i<copiedItems.length; i++)
								{
									copiedItems[i].amount = studentCount;
								}

								let expirationArray = option1DateValue.split("-");

								const OrderData = {
									product_idx: prdIdx,
									option1_amount: studentCount,
									expiration_date: expirationArray[0],
									expiration_date_unit: expirationArray[1],
									option1_price: TicketPrice,
									option2_amount: option2,
									totalPrice: totalPrice,
								};

								// console.log("OrderData : ", OrderData);

								// 여기서 디스패치로~
								dispatch(groupOrderCreate(OrderData))
								.then(data => 
								{
									// console.log("groupOrderCreate : ", data);

									// alert("결제정보에서 결제상세보기에 들어가셔서 추가정보를 입력하시고 입금확인 요청을 해주세요!");
									alert(`${t("Pay.alert.pay_string12")}`);
									navigate("/GroupUserMypage/GroupPayInfo");
					
								})
								.catch(e => {
									console.log(e);
								});

							})
							.catch(e => {
								console.log(e);
							});
						}
					})
					.catch(e => {
						console.log(e);
					});
				}

				// 현재 페이지의 결제 정보를 주문 페이지로 보내서 결제를 처리합니다.
				// document.location.href = "/Order";
			}
		}
	}

	// 전화번호 클릭시 함수
	const phoneConfirmClick = (event) =>
	{
		event.preventDefault();

		let phoneNumber = $(`#${styles.pay_add_phonenumber}`).val();
		// console.log(phoneNumber);
		const phoneReg = /^01([0|1|6|7|8|9])-([0-9]{3,4})-([0-9]{4})$/;

		if(phoneNumber === "")
		{
			return alert('전화번호를 입력해주세요.');
		}
		else
		{
			// 휴대전화 정규표현식
			if (phoneReg.test(phoneNumber) === false) 
			{
				return alert('000-0000-0000 형식으로 중간에 -(하이픈)을 넣어주세요.');
			}
			else
			{
				// console.log(phoneNumber);
				setPhone(phoneNumber);

				if (authState?.user?.email !== null && authState?.user?.email !== undefined)
				{
					// 전화번호를 받아서 수정합니다.
					// 여기에서 데이터를 입력합니다.
					let sendData = {
						email:email,
						type:type,
						name:name,
						nickname:nickname,
						phone: phoneNumber,
						password:"",
						recive_sms:smsRecive,
						recive_email:emailRecive
					};

					// console.log("sendData : ", sendData);

					// 여기서 디스패치로~
					dispatch(userPhoneModify(sendData))
					.then(data => 
					{
						// console.log("data : ", data);
						if (!data.success)
						{
							alert(data.msg);
							return;
						}
						else
						{
							alert("전화번호가 수정되었습니다. 다시 구매버튼을 클릭해주세요!");
							document.location.reload();
						}
					})
					.catch(e => {
						console.log(e);
					});
				}
				else
				{
					alert("구매하기는 로그인이 필요합니다.");
					document.location.href = "/auth/login";
				}


				$(`.${styles.pay_add_info_popup}`).css('display','none');
				$(`#${styles.pay_add_phonenumber}`).val("");
				blackBg(2);
			}
		};
	}

	const initCheckBox = () => {

		setChecked([]);

		for (let i=0; i<checked.length; i++)
		{
			setChecked({...checked, [i]: false});
		}

		/* 새로운 변수를 선언해 기존의 배열을 복사하는 과정을 거쳐야 한다.
		useState로 만든 변수는 set함수로만 값을 변경할 수 있기 때문이다. */
		let copiedItems = [...option2];
		
		/* 복사한 배열에 수정할 값을 할당하고 */
		for (let i=0; i<copiedItems.length; i++)
		{
			copiedItems[i].apply = false;
		}
		
		/* setItems를 사용해 수정한 배열로 items를 업데이트 한다. */
		setOption2(copiedItems);

		// console.log(option2);
	}

	const handleCheckBoxChange = (event, index, rowdata) => {
		setChecked(event.target.checked);

		// console.log(event.target.checked);
		// console.log(index);

		setChecked({...checked, [index]: event.target.checked});
		// console.log(option2);
		// console.log(rowdata);

		let findIndex = option2.findIndex(item => item.po2_name === rowdata.po2_name);
		// console.log(findIndex);

		/* 새로운 변수를 선언해 기존의 배열을 복사하는 과정을 거쳐야 한다.
		useState로 만든 변수는 set함수로만 값을 변경할 수 있기 때문이다. */
		let copiedItems = [...option2];
		
		/* 복사한 배열에 수정할 값을 할당하고 */
		copiedItems[findIndex].apply = event.target.checked;

		/* 그 값을 삭제하고 다시 넣는다. */
		// if (!event.target.checked)
		// 	copiedItems.splice(findIndex, 1);
		
		/* setItems를 사용해 수정한 배열로 items를 업데이트 한다. */
		setOption2(copiedItems);

		// console.log(option2);

		// 추가됨 금액을 계산해서 토탈금액에 넣어줌

		let AddPrice = 0;	// 추가금액

		// 총금액에 추가금액도 추가해서 총금액에 넣습니다.
		for (let i=0; i<option2.length; i++)
		{
			if (option2[i].apply)
				AddPrice = parseInt(option2[i].po2_price) + parseInt(AddPrice);

		}

		// console.log(AddPrice);
		setAddPrice(AddPrice);

		let price = TicketPrice;
		// console.log("AddPrice : ", AddPrice);
		// console.log("final price : ", parseInt(price) + parseInt(AddPrice));

		displayDetails(parseInt(price) + parseInt(AddPrice));	// 상세내역 표시
	};

	const handleDateSelectChange = (event) => {
		// console.log(event.target.value);

		// 가격 셀렉트 박스의 값을 바꾼다.
		let splitString = event.target.value.split("-");
		selectPriceDataChange(option1Price, splitString[0], splitString[1]);

		setOption1DateValue(event.target.value);
		setTicketPrice(0);
		displayDetails(0);	// 상세내역 표시

		// 이용권의 가격을 바꾼다.
		let inputArea;
		
		// $(`.${styles.product_area} ul li`).each(function(){
		$(`.${styles.product_area} ul`).each(function(){

			inputArea=$(this).find('input[name=studentCount]');
		});

		inputArea.val(1);
		setStudentCount(1);
		initCheckBox();
	};

	const handlePriceSelectChange = (event) => {
		
		// console.log(event.target.value);

		setOption1PriceValue(event.target.value);
		setTicketPrice(event.target.value);
		displayDetails(parseInt(event.target.value));	// 상세내역 표시

		let inputArea;
		
		// $(`.${styles.product_area} ul li`).each(function(){
		$(`.${styles.product_area} ul`).each(function(){

			inputArea=$(this).find('input[name=studentCount]');
		});

		inputArea.val(1);
		setStudentCount(1);
		initCheckBox();
	};

	const clickPlus = () => {

		// console.log("TicketPrice : ", TicketPrice);

		let minus;
		let plus;
		let inputArea;
		let student;
		let totalTxt;
		let price = TicketPrice;
		let total;
		
		// $(`.${styles.product_area} ul li`).each(function(){
		$(`.${styles.product_area} ul`).each(function(){

			minus=$(this).find(`.${styles.count_btn_area} button:last-child`);
			plus=$(this).find(`.${styles.count_btn_area} button:first-child`);
			inputArea=$(this).find('input[name=studentCount]');
			student=1;
			totalTxt=$(this).find(`.${styles.product_total_price}`);
		});

		student=inputArea.val();
		
		if(student>10001)
		{
			// alert('1만명 이상 구매 시 문의바랍니다.');
			alert(`${t("Pay.alert.pay_string13")}`);
			return;
		};
		
		student++;
		total=price*student;
		const totalPrice=priceToString(total);

		// 부과세 계산 로직
		// SAL_AMT = document.search_form.SAL_AMT.value; // 판매금액

		// 부과세 포함
		// SAL_VAT   = ((SAL_AMT/1.1)*0.1);    // 부가세(VAT)
		// SAL_PRICE = Math.round(SAL_AMT - SAL_VAT); // 단가(반올림)
		// SAL_VAT   = Math.round(SAL_VAT);    // 부가세(반올림)

		// 부과세 별도
		// SAL_VAT = 0;
  		// SAL_PRICE = SAL_AMT;
		
		inputArea.val(student);
		totalTxt.text(totalPrice);

		// console.log("total : ", total);
		// console.log("addPrice : ", addPrice);
		// console.log("final price : ", parseInt(total) + parseInt(addPrice));

		displayDetails(parseInt(total) + parseInt(addPrice*student));	// 상세내역 표시
		setStudentCount(student);
	};

	const clickMinus = () => {

		// console.log("TicketPrice : ", TicketPrice);

		let minus;
		let plus;
		let inputArea;
		let student;
		let totalTxt;
		let price = TicketPrice;
		let total;
		
		// $(`.${styles.product_area} ul li`).each(function(){
		$(`.${styles.product_area} ul`).each(function(){

			minus=$(this).find(`.${styles.count_btn_area} button:last-child`);
			plus=$(this).find(`.${styles.count_btn_area} button:first-child`);
			inputArea=$(this).find('input[name=studentCount]');
			student=1;
			totalTxt=$(this).find(`.${styles.product_total_price}`);
		});

		student=inputArea.val();
		
		if(student==1)
		{
			return;
		};
		
		student--;
		total=price*student;
		const totalPrice=priceToString(total);
		
		inputArea.val(student);
		totalTxt.text(totalPrice);

		// console.log("total : ", total);
		// console.log("addPrice : ", addPrice);
		// console.log("final price : ", parseInt(total) + parseInt(addPrice));
		
		displayDetails(parseInt(total) + parseInt(addPrice*student));	// 상세내역 표시
		setStudentCount(student);
	};

	const onChangeStudentCount = () => {

		// console.log("TicketPrice : ", TicketPrice);

		let inputArea;
		let student;
		let totalTxt;
		let price = TicketPrice;
		let total;

		// $(`.${styles.product_area} ul li`).each(function(){
		$(`.${styles.product_area} ul`).each(function(){

			inputArea=$(this).find('input[name=studentCount]');
			student=1;
			totalTxt=$(this).find(`.${styles.product_total_price}`);
		});

		// if(inputArea.val() < 1)
		// {
		// 	/* alert('학습자 수는 1명부터 입력해주세요.'); */
		// 	alert(`${t("Pay.alert.pay_string7")}`);
		// 	inputArea.val(1);
		// }
		// else 
		
		if(inputArea.val()>=10001)
		{
			/* alert('1만명 이상 구매 시 문의하시기 바랍니다.'); */
			alert(`${t("Pay.alert.pay_string13")}`);
			inputArea.val(10000);
		}
		else if(inputArea.val().includes('.')===true)
		{
			/* alert('학습자 수는 1명부터 입력해주세요.'); */
			alert(`${t("Pay.alert.pay_string7")}`);
			inputArea.val(1);
		}
		else
		{
			// console.log('input의 값을 수정하였습니다');
		};
		
		student=inputArea.val();
		total=price*student;
		
		const totalPrice=priceToString(total);

		inputArea.val(student);
		totalTxt.text(totalPrice);
		
		displayDetails(parseInt(total) + parseInt(addPrice*student));	// 상세내역 표시
		setStudentCount(student);
	};

	//천마다 콤마
	const priceToString = (price) => {

		if (price === null)
			price = 0;

		return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	};

	const displayDetails = (total) => {
		
		// 부과세 계산
		let SAL_VAT   = total*0.1;    // 부가세(VAT)
		let SAL_PRICE = Math.round(total + SAL_VAT); // 단가(반올림)
		SAL_VAT   = Math.round(SAL_VAT);    // 부가세(반올림)

		setImpositionTax(SAL_VAT);

		setTotalPrice(SAL_PRICE);
	};

  return (
    <>
      	{/* Header 분리됨 MainLayout Header에 있음 */}

		<div className={styles.body_black}></div>

		<div className={styles.pay_add_info_popup}>
			<div>
				<p>전화번호를 입력해주세요.</p>
				<input type="text" id={styles.pay_add_phonenumber}/>
				<div className={styles.btn_area}>
					<button type="button" className={styles.confirm} onClick={(e)=> phoneConfirmClick(e)}>확인</button>
					<button type="button" className={styles.cancle}>취소</button>
				</div>
			</div>
		</div>

		<div className={styles.pay_area}>
			<div className={styles.pay_top_line_banner}>
				<div className={styles.inner}>
					<span>{/* 야미 구매하기 */}{t("Pay.title")}</span>
				</div>
			</div>
			<div className={styles.pay_wrap}>
				<div className={styles.inner}> 
					<div className={styles.product_area}>
						<div className={styles.top_top_area}>
							<img src="/assets/pay_banner@2x_2.png" alt="pay banner"/>
						</div>
						<ul>
							<li className={styles.on} name="day30">
								<div className={styles.scratch}></div>
								<div className={styles.product_name}>
									<span className={styles.period}>{/* 야미 */}{t("Pay.group_pay.pay_string1")}</span>
									<p>{/* 이용권 구매 */}{t("Pay.group_pay.pay_string2")}</p>
								</div>
								<div className={styles.product_price}>
									<div className={styles.left_area}>
										{/* <p>₩16,500</p> */}
										{/* <span className={styles.price}>₩18,000</span> */}
										<FormControl 
											sx={{
												width: '190px',
												marginLeft: '20px',
												marginBottom: '15px',
											}}
										>
											<InputLabel id="user-data-select-label">{/* 기간 */}{t("Pay.group_pay.Period")}</InputLabel>
											<Select
											labelId="user-data-select-label"
											id="user-data-select"
											// label="기간"
											label={t("Pay.group_pay.Period")}
											name="optionDate"
											// value={props.joinGroup.type || 0}
											onChange={handleDateSelectChange}
											size="small"
											value={option1DateValue || ""}
											>
											<MenuItem value={""}>
												<em>{/* 선택 */}{t("Pay.group_pay.select")}</em>
											</MenuItem>
											{option1Date && option1Date.map((item, index) => (
											<MenuItem value={item.expiration_date + "-" + item.expiration_date_unit} key={index}>
												{item.name}
											</MenuItem>
											))}
											</Select>
										</FormControl>

										<FormControl 
											sx={{
												width: '190px',
												marginLeft: '20px',
												marginBottom: '10px',
											}}
										>
											<InputLabel id="user-price-select-label">{/* 가격 */}{t("Pay.group_pay.Price")}</InputLabel>
											<Select
											labelId="user-price-select-label"
											id="user-price-select"
											// label="가격"
											label={t("Pay.group_pay.Price")}
											name="optionAmount"
											onChange={handlePriceSelectChange}
											size="small"
											value={option1PriceValue || ""}
											>
											<MenuItem value={""}>
												<em>{/* 선택 */}{t("Pay.group_pay.select")}</em>
											</MenuItem>
											{option1PriceCopy && option1PriceCopy.map((item, index) => (
											<MenuItem value={item.bopo1_amt} key={index}>
												{item.name}
											</MenuItem>
											))}
											</Select>
										</FormControl>
										
										<FormControl
										sx={{
											width: '190px',
											marginLeft: '20px',
											marginBottom: '10px',
											fontSize: '15px',
											color: 'red',
										}}>
										{/* * LMS 사용료는 이벤트 기간만 무료입니다. */}{t("Pay.group_pay.pay_string3")}
										</FormControl>

										<FormGroup
										sx={{
											width: '190px',
											marginLeft: '20px',
											marginBottom: '5px',
										}}>
											{option2 && option2.map((item, index) => (
												<div key={index}>
													<FormControlLabel control={<Checkbox checked={checked[index] || false} onChange={(e) => handleCheckBoxChange(e, index, item)} />} 
													label={`${item.po2_name}(${priceToString(item.po2_price)})`} />
												</div>
											))}
										</FormGroup>

									</div>
									{/* <span className={styles.discount_area}>-40%</span> */}
								</div>
								<div className={styles.student_count_area}>
									<div className={styles.count_area}>
										<span>{/* 학습자 */}{t("Pay.group_pay.pay_string4")}</span>
										<input type="number" name="studentCount" defaultValue="1" onChange={(e) => onChangeStudentCount(e)}/>
									</div>
									<div className={styles.count_btn_area}>
										<button type="button" onClick={(e) => clickPlus(e)}><img src="/assets/product_plus.png" alt="플러스"/></button>
										<button type="button" onClick={(e) => clickMinus(e)}><img src="/assets/product_minus.png" alt="마이너스"/></button>
									</div>
								</div>
								<div className={styles.product_total_price_area}>
									<span>{/* 이용권 금액 */}{t("Pay.group_pay.pay_string5")}</span>
									<span className={styles.product_total_price} name={TicketPrice}>{priceToString(TicketPrice)}</span>
								</div>
								
								<div className={styles.btn_area}>
									<button type="button" onClick={(e) => payPhoneNumber(e, 1)}>{/* 구매 하기 */}{t("Pay.group_pay.pay_string6")}</button>
								</div>
							</li>
							<li name="day90">
								<div className={styles.product_name}>
									<span className={styles.period}>{/* 야미 */}{t("Pay.group_pay.pay_string1")}</span>
									<p>{/* 결제 상세 내역 */}{t("Pay.group_pay.pay_string7")}</p>
								</div>
								<div className={styles.product_total_price_area}>
									<span>{/* 이용권 가격 */}{t("Pay.group_pay.pay_string8")}</span>
									<span className={styles.product_total_price2} name={TicketPrice}>{priceToString(TicketPrice)}{/* 원 */}{t("Pay.group_pay.monetary_unit")} * {studentCount}</span>
								</div>
								{option2 && option2.map((item, index) => (
									<div className={styles.product_total_price_area} key={index}>
										<span>{item.po2_name}</span>
										<span className={styles.product_total_price10} name={item.apply === true ? item.po2_price : 0}>{priceToString(item.apply === true ? item.po2_price : 0)}{/* 원 */}{t("Pay.group_pay.monetary_unit")} * {studentCount}</span>
									</div>
								))}
								<div className={styles.product_total_price_area}>
									<span>{/* 부과세 */}{t("Pay.group_pay.pay_string9")}</span>
									<span className={styles.product_total_price5} name={impositionTax}>{priceToString(impositionTax)}{/* 원 */}{t("Pay.group_pay.monetary_unit")}</span>
								</div>
								<div className={styles.product_total_price_area}>
									<span>{/* 총 결제 금액(부과세 포함) */}{t("Pay.group_pay.pay_string10")}</span>
									{/* <span className={styles.product_total_price6} name={totalPrice}>{priceToString(totalPrice)}</span> */}
								</div>
								<div className={styles.product_total_price_area}>
									{/* <span>총 금액(부과세 포함)</span> */}
									<span className={styles.product_total_price6} name={totalPrice}>{priceToString(totalPrice)}{/* 원 */}{t("Pay.group_pay.monetary_unit")}</span>
								</div>
							</li>
						</ul>
					</div>
					<ul className={styles.pay_guide}>
						<li><a href="/Use#refund_policy">{/* 본 상품은 당사의 환불정책에 따라 <br/>환불할 수 있습니다. */}<Trans i18nKey="Pay.guide.pay_string1"/></a></li>
						<li>{/* 서비스 이용 기간 <br/>: 서비스 종료 시까지 학습할 수 있습니다. <br/>야미의 학습 시간은 결제 시작부터 차감됩니다. */}<Trans i18nKey="Pay.guide.pay_string2"/></li>
						<li>{/* 야미코딩은 인터넷 연결이 필요합니다. */}<Trans i18nKey="Pay.guide.pay_string3"/></li>
					</ul>
				</div>{/* inner */}
				<div className={styles.pay_product_info_top_line}></div>
				<div className={styles.pay_product_info_area}>
					<div className={styles.inner}>
						<span className={styles.pay_product_title}>{/* '개념을 익히고, 원리를 알아내며,<br/>아이들이 스스로 학습할 수 있는 야미코딩' */}
						<Trans i18nKey="Pay.product.pay_string1"/></span>
						<div className={styles.pay_product_3d_area}>
							<div className={styles.pay_product_top_area}>
								<span>{/* Full 3D 야미월드 */}<Trans i18nKey="Pay.product.pay_string2"/></span>
								<p>{/* 2D 이미지로 구성된 코딩블록 서비스와 차별적으로 아이들이 창의력과 상상력을 최대한 이끌어내기 위해 야미 콘텐츠의 공간은 3D로 제작 되었습니다. */}
								<Trans i18nKey="Pay.product.pay_string3"/></p>
							</div>
							<div className={styles.pay_product_3d_bottom_area}>
								<div className={styles.img_area}>
									<img src="/assets/3d_img1@2x.png" alt="3d"/>
									<p>{/* 메타버스 컨셉의 야미 월드 <br/>지속 적으로 섬을 확장해 나갈 예정인 <br/>야미월드의 시작 섬. */}
									<Trans i18nKey="Pay.product.pay_string4"/></p>
								</div>
								<div className={styles.img_area}>
									<img src="/assets/3d_img2@2x.png" alt="3d"/>
									<p>{/* 순차, 반복, 선택 그리고 특별한 미션! <br/>오픈월드 수업 방식으로 원하는 <br/>수업을 선택해서 진행합니다. */}
									<Trans i18nKey="Pay.product.pay_string5"/></p>
								</div>
								<div className={styles.img_area}>
									<img src="/assets/3d_img3@2x.png" alt="3d"/>
									<p>{/* 지식 은행 <br/>매일 매일 출제되는 문제 풀이로 <br/>소프트웨어 지식을 쌓아 갑니다. */}
									<Trans i18nKey="Pay.product.pay_string6"/></p>
								</div>
							</div>
						</div>
						<div className={styles.pay_product_contents_area}>
							<div className={styles.pay_product_top_area}>
								<span>{/* 학습의 향상을 위한 다양한 콘텐츠 */}<Trans i18nKey="Pay.product.pay_string7"/></span>
								<p>{/* 흥미를 가지고 학습하기 어려운 코딩블록 수업을 아이들이 집중해서 재미있게 수업을 진행할 수 있도록 차별화된 부가적인 콘텐츠를 준비했습니다. */}
								<Trans i18nKey="Pay.product.pay_string8"/></p>
							</div>
							<div className={styles.pay_product_contents_bottom_area}>
								<div className={styles.top_area}>
									<div className={styles.img_area}>
										<div>
											<img src="/assets/contents_img1@2x.png" alt="contents"/>
											<img src="/assets/contents_img2@2x.png" alt="contents"/>
										</div>
										<p>{/* 야미 캐릭터<br/>코딩수업을 야미 캐릭터로 진행합니다. <br/>아이들이 자신만의 개성있는 캐릭터로 <br/>꾸미기가 가능합니다. */}
										<Trans i18nKey="Pay.product.pay_string9"/></p>
									</div>
								</div>
								<div className={styles.bottom_area}>
									<div className={styles.img_area}>
										<img src="/assets/contents_img3@2x.png" alt="contents"/>
										<p>{/* 컨트롤 미션<br/>직접 컨트롤 해서 사물을 움직여 <br/>미션을 수행합니다. */}
										<Trans i18nKey="Pay.product.pay_string10"/></p>
									</div>
									<div className={styles.img_area}>
										<div>
											<img src="/assets/contents_img4@2x.png" alt="contents"/>
											<img src="/assets/contents_img5@2x.png" alt="contents"/>
										</div>
										{/* <p>수학 미션 <br/>반복해서 수업을 진행해도 <br className={styles.mobile}/>같은 문제가 나오지 않습니다. <br className={styles.mobile}/>야미 랜덤 시스템으로 <br/>'백만가지의 수학 미션'을 제공합니다.</p> */}
										<p>
											{/* 수학 미션 */}<Trans i18nKey="Pay.product.pay_string11-1"/> <br/>{/* 반복해서 수업을 진행해도 */}<Trans i18nKey="Pay.product.pay_string11-2"/> <br className={styles.mobile}/>{/* 같은 문제가 나오지 않습니다. */}<Trans i18nKey="Pay.product.pay_string11-3"/> <br className={styles.mobile}/>{/* 야미 랜덤 시스템으로 <br/> '백만가지의 수학 미션'을 제공합니다. */}<Trans i18nKey="Pay.product.pay_string11-4"/>
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className={styles.pay_product_etc_area}>
							<div className={styles.pay_product_top_area}>
								<span>{/* 아니 이런것 까지?! */}<Trans i18nKey="Pay.product.pay_string12"/></span>
								<p>{/* 아이들의 지친 마음과 뇌를 달래주기 위해 특별한 음악을 야미코딩에 담았습니다.<br/>'뇌&음악' 이라는 뇌에 좋은 영향을 주는 곡들을 전문으로 작곡하는 스튜디오에서 야미를 위한 8개의 곡을 제작했습니다. */}
								<Trans i18nKey="Pay.product.pay_string13"/></p>
								<span className={styles.second}>{/* 뇌음악이란 */}<Trans i18nKey="Pay.product.pay_string14"/></span>
								<p>{/* 신경가소성과 관련된 진동활동을 유도하는 요소와 긍정적 정서와 낮은 각성 수준의 음계를 사용.<br/>스트레스의 지표가 되는 바이오마커들의 활성수준을 감소시키는 음계를 사용. */}
								<Trans i18nKey="Pay.product.pay_string15"/></p>
							</div>
							<img src="/assets/pay_product_box_bg3_img.png" alt="뇌음악"/>
						</div>
					</div>{/* inner */}
				</div>{/* pay_product_info_area */}
				<div className={styles.pay_bottom_btn_area}>
					<div className={styles.inner}>
						<a href="/GroupPay">{/* 야미 구매 하기 */}<Trans i18nKey="Pay.product.pay_string16"/></a>
					</div>
				</div>		
			</div>{/* pay_wrap */}
		</div>{/* pay_area */}
		
		{/* Footer 분리됨 MainLayout Footer에 있음 */}
    </>
  );
}