import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import styles from "../../css/common.module.css";
import $ from 'jquery';
import * as common from "../../lib";

import { useNavigate, useParams } from 'react-router-dom';
import { studentSelect, studentModify, studentPayInfo, studentPackageInfo } from "../../actions/student";
import { couponUserSelect } from "../../actions/coupon";

export default function GroupMypageStudentInfoFix() {

	const authState = useSelector(state => state.auth);
    // console.log("authState : ", authState);

	const dispatch = useDispatch();	
	const navigate = useNavigate();
    const mst_id  = useParams().mst_id;
    // console.log("mst_id : ", mst_id);

	const [name, setName] = useState("");
	const [id, setId] = useState("");
	const [nickname, setNickname] = useState("");
	const [birthdayYear, setBirthdayYear] = useState("");
	const [birthdayMonth, setBirthdayMonth] = useState("");
	const [birthdayDay, setBirthdayDay] = useState("");
	const [gender, setGender] = useState(1);
	const [status, setStatus] = useState(0);
	const [password, setPassword] = useState("");
	const [repassword, setRepassword] = useState("");
	const [payInfo, setPayInfo] = useState(null);
	const [packageInfo, setPackageInfo] = useState(null);
	const [couponInfo, setCouponInfo] = useState(null);

	const mypage_tab_btn_areaRef = useRef();
    const hover_bgRef = useRef();
    const onRef = useRef();

	const my_info_fix_year_selectboxRef = useRef();
	const month_list_select_areaRef = useRef();
	const mypage_student_infofixRef = useRef();

	const handleSubmit = (event) => {

		event.preventDefault();
		// console.log("name : ", name);
		// console.log("id : ", id);
		// console.log("nickname : ", nickname);
		// console.log("birthdayYear : ", birthdayYear);
		// console.log("birthdayMonth : ", birthdayMonth);
		// console.log("gender : ", gender);
		// console.log("status : ", status);
		// console.log("password : ", password);
		// console.log("repassword : ", repassword);

		if (password !== "" || repassword !== "")
		{
			// 정규 표현식으로 비밀번호 생성 규칙에 맞는지 체크합니다.
			let reg = new RegExp(/(?=.*\d{1,20})(?=.*[~`!@#$%\^&*()-+=]{1,20})(?=.*[a-zA-Z]{1,20}).{8,20}$/);
						
			// console.log(reg.test(join.password));
			if( !reg.test(password) ) 
			{
				return  alert("비밀번호 생성 규칙은 영문, 숫자, 특수문자 포함 8자 이상 20자가지 입니다.");
			}

			if (password !== repassword)
			{
				alert("비밀번호가 일치하지 않습니다.");
				return;
			}
		}
		else
		{
			// 비밀번호가 둘 다 있어야지 변경합니다.
			// 아니면 초기화 합니다.
			setPassword("");
			setRepassword("");
		}

		const sendData = {
            id: id,
			name:name,
			nickname:nickname,
			birthday:birthdayYear + "-" + birthdayMonth + "-" + birthdayDay,
			gender:gender,
			password:password
        };

		// console.log("sendData : ", sendData);

		// 여기서 디스패치로~
		dispatch(studentModify(sendData))
		.then(data => 
		{
		//  console.log("studentModify data : ", data);
			if (!data.success)
				alert(data.msg);
			
			navigate("/GroupUserMypage/GroupStudentInfo");
		})
		.catch(e => {
			console.log(e);
		});
	};

	const handleInputChange = event => {
        const { name, value } = event.target;

		if (name === "nickname")
		{
			setNickname(value);
		}
		else if (name === "password")
		{
			setPassword(value);
		}
		else if (name === "repassword")
		{
			setRepassword(value);
		}
    };
	
	useEffect(() => {

		// 여기에서 jQuery를 초기화 합니다.
		// jquery ready 호출. jquery3 부터 변경됨
		$(function(){
			// console.log("jquery ready!");

            //마이페이지 탭 버튼(활성화 효과)
			$(`.${styles.mypage_tab_btn_area} li`).on('click',function()
			{
				let tg=$(this);
				
				$(`.${styles.mypage_tab_btn_area} li`).removeClass(`${styles.on}`);
				tg.addClass(`${styles.on}`);
			});
            
            //마이페이지 탭 버튼(후버 효과)
            $(window).resize(function()
			{
				if (window.innerWidth < 1200) 
				{ 
					$(`.${styles.mypage_tab_btn_area} li.${styles.hover_bg}`).css('display','none');
				}
				else
				{
					let nowbtnNum=$(`.${styles.mypage_tab_btn_area} li.${styles.on}`).index();
					let hoverBg=$(`.${styles.mypage_tab_btn_area} li.${styles.hover_bg}`);

					$(`.${styles.mypage_tab_btn_area} ul`).on('mouseenter',function()
					{
						
						$(`.${styles.mypage_tab_btn_area} li`).removeClass(`${styles.on}`);
						hoverBg.css('display','block');
						
						$(`.${styles.mypage_tab_btn_area} li`).on('mouseenter',function()
						{
							let tgIndexinfo=$(this);
							hoverBg.stop().css('left',tgIndexinfo['0']['offsetLeft']).css('width',tgIndexinfo['0']['clientWidth']);

							// let tgIndex=$(this).index();
							
							// if(tgIndex===1)
							// {
							// 	hoverBg.stop().css('left','167px').css('width','116px');
							// }
							// else if(tgIndex===2)
							// {
							// 	hoverBg.stop().css('left','300px').css('width','150px');
							// }
							// else if(tgIndex===3)
							// {
							// 	hoverBg.stop().css('left','467px').css('width','116px');
							// }
							// else if(tgIndex===4)
							// {
							// 	hoverBg.stop().css('left','624px').css('width','102px');
							// }
							// else if(tgIndex===5)
							// {
							// 	hoverBg.stop().css('left','774px').css('width','102px');
							// }
							// else if(tgIndex===6)
							// {
							// 	hoverBg.stop().css('left','924px').css('width','102px');
							// };
						});
						
					}).on('mouseleave',function()
					{
						$(`.${styles.mypage_tab_btn_area} li`).eq(nowbtnNum).addClass(`${styles.on}`);
						hoverBg.css('display','none');
						
						if(nowbtnNum===0)
						{
							hoverBg.css({left:'167px'}).css({width:'116px'});
						}
						else if(nowbtnNum===1)
						{
							hoverBg.css({left:'300px'}).css({width:'150px'});
						}
						else if(nowbtnNum===2)
						{
							hoverBg.css({left:'467px'}).css({width:'116px'});
						}
						else if(nowbtnNum===3)
						{
							hoverBg.css({left:'624px'}).css({width:'102px'});
						}
						else if(nowbtnNum===4)
						{
							hoverBg.css({left:'774px'}).css({width:'102px'});
						}
						else if(nowbtnNum===5)
						{
							hoverBg.css({left:'924px'}).css({width:'102px'});
						};

					});
					
				}
			}).resize();

		});

		//생년월일 셀렉트 박스
		//출처 : https://sawol-today.tistory.com/866

		var date=new Date();
		var selYear=date.getFullYear();

		//현재연도를 기준으로 호출
		getYears(selYear);
		//현재연도를 select함
		// $(my_info_fix_year_selectboxRef.current).val(selYear);
		//바뀐 연도를 기준으로 다시 option을 세팅
		$(my_info_fix_year_selectboxRef.current).change(function()
		{
			var chgYear=$(this).val();
			// getYears(chgYear);
			$(my_info_fix_year_selectboxRef.current).val(chgYear);
			setBirthdayYear(chgYear);
			// console.log("year : ", chgYear);
		});

		function getYears(getY)
		{
			//올해 기준으로 -30년을 보여줌
			var stY=Number(getY)-80;
			var nY=Number(getY);
			
			// for(var y=stY; y<=nY; y++)
			for(let y=nY; y>=stY; y--)
			{
				$(my_info_fix_year_selectboxRef.current).append("<option value='"+y+"'>"+y+"년</option>");
			}
		}

		//제작한 셀렉트박스 클릭시 팝업
		$(month_list_select_areaRef.current).on('click',function()
		{
			$(month_list_select_areaRef.current).children('ul').toggle();
		});

		$('html').click(function(e)
		{ 
			
			if(!$(month_list_select_areaRef.current).has(e.target).length)
			{
				// console.log('레이어팝업 외의 영역입니다');
				$(month_list_select_areaRef.current).children('ul').css('display','none');
			}
			
		});

		//셀렉트 박스 리스트 클릭시 텍스트 변경, 선택한 리스트에 따라 값 변경
		let month;
		
		$(month_list_select_areaRef.current).find(`.${styles.select_list}`).on('click',function()
		{
			let tg=$(this);
			let txt=tg.text();
			
			month=txt;
			// console.log("month : ", month);
			$(month_list_select_areaRef.current).find(`.${styles.select_list}`).removeClass(`.${styles.on}`);
			tg.addClass(`.${styles.on}`);
			
			$(month_list_select_areaRef.current).find(`.${styles.selected_option}`).text(txt);

			switch (month)
			{
				case "1월":
					month="01";
					break;

				case "2월":
					month="02";
					break;

				case "3월":
					month="03";
					break;

				case "4월":
					month="04";
					break;

				case "5월":
					month="05";
					break;

				case "6월":
					month="06";
					break;

				case "7월":
					month="07";
					break;

				case "8월":
					month="08";
					break;

				case "9월":
					month="09";
					break;

				case "10월":
					month="10";
					break;
				case "11월":
					month="11";
					break;

				case "12월":
					month="12";
					break;
			}

			setBirthdayMonth(month);
		});

		//셀렉트 박스 이벤트
		let gender;
		$(mypage_student_infofixRef.current).find(`.${styles.gender}`).find('input').on('click', function()
		// $(`.${styles.mypage_student_infofix} .${styles.gender} input`).on('click', function()
		{
			gender=$(mypage_student_infofixRef.current).find(`.${styles.gender}`).find('input[name=gender]:checked').val();
			
			setGender(gender === "boy" ? 1 : 2);
		});

		// 학습자 데이터 불러오기
		// console.log("dispatch studentSelect");
        dispatch(studentSelect(mst_id))
        .then(data => 
        {
            // console.log("studentSelect data : ", data); 

			let year = data.birthday?.split('-')[0] || 0;
			let month = data.birthday?.split('-')[1] || 0;
			let day = data.birthday?.split('-')[2] || 0;

			setName(data.name);
			setId(data.id);
			setNickname(data.nickname);
			setBirthdayYear(year || selYear);
			setBirthdayMonth(month);
			setBirthdayDay(day);
			setGender(data.gender || 1);
			setStatus(data.status);

			defaultBirthday(year || selYear, month || "01", day || "01");
			defaultGender(data.gender || 1);

			if (data.cou_number !== "")
			{
				// 여기서 디스패치로~
				dispatch(couponUserSelect(data.cou_number))
				.then(data => 
				{
					// console.log("couponUserSelect data : ", data);
					setCouponInfo(data);
				})
				.catch(e => {
					console.log(e);
				});
			}
        })
        .catch(e => {
            console.log(e);
        });

		// 학습자 결제 데이터 불러오기
        dispatch(studentPayInfo(mst_id))
        .then(data => 
        {
            // console.log("studentPayInfo data : ", data);

			if(data.success)
			{
				if (data.pay_info !== undefined)
					setPayInfo(data.pay_info);
			}
			
        })
        .catch(e => {
            console.log(e);
        });

		// 학습자 패키지 데이터 불러오기
        dispatch(studentPackageInfo(mst_id))
        .then(data => 
        {
            // console.log("studentPackageInfo data : ", data);

			if(data.success)
			{
				if (data.package_info !== undefined)
					setPackageInfo(data.package_info);
			}
			
        })
        .catch(e => {
            console.log(e);
        });

	}, [$, dispatch]);

	const defaultBirthday = (year, month, day) => 
	{
		// let monthIndex = 0;
		// let monthString = "";

		$(my_info_fix_year_selectboxRef.current).val(year);

		// console.log("month : ", month);

		// switch (month) {
		// 	case "01":
		// 		monthIndex = 0;
		// 		monthString = "1월";
		// 		break;
		// 	case "02":
		// 		monthIndex = 1;
		// 		monthString = "2월";
		// 		break;
		// 	case "03":
		// 		monthIndex = 2;
		// 		monthString = "3월";
		// 		break;
		// 	case "04":
		// 		monthIndex = 3;
		// 		monthString = "4월";
		// 		break;
		// 	case "05":
		// 		monthIndex = 4;
		// 		monthString = "5월";
		// 		break;
		// 	case "06":
		// 		monthIndex = 5;
		// 		monthString = "6월";
		// 		break;
		// 	case "07":
		// 		monthIndex = 6;
		// 		monthString = "7월";
		// 		break;
		// 	case "08":
		// 		monthIndex = 7;
		// 		monthString = "8월";
		// 		break;
		// 	case "09":
		// 		monthIndex = 8;
		// 		monthString = "9월";
		// 		break;
		// 	case "10":
		// 		monthIndex = 9;
		// 		monthString = "10월";
		// 		break;
		// 	case "11":
		// 		monthIndex = 10;
		// 		monthString = "11월";
		// 		break;
		// 	case "12":
		// 		monthIndex = 11;
		// 		monthString = "12월";
		// 		break;
		
		// 	default:
		// 		break;
		// }

		// $(month_list_select_areaRef.current).find(`.${styles.select_list}`).removeClass(`.${styles.on}`);
		// $(month_list_select_areaRef.current).find(`li`).eq(monthIndex).addClass(`.${styles.on}`)
		// $(month_list_select_areaRef.current).find(`.${styles.selected_option}`).text(monthString);
	}

	const defaultGender = (gender) => 
	{
		if (gender === 1)
		{
			$(mypage_student_infofixRef.current).find(`.${styles.gender}`).find(":radio[name='gender'][value='boy']").attr('checked', true);
		}
		else
		{
			$(mypage_student_infofixRef.current).find(`.${styles.gender}`).find(":radio[name='gender'][value='girl']").attr('checked', true);
		}
	}

	// const onChangeMonth = (event) => {
	// 	event.preventDefault();

	// 	console.log(event.target.value);

	// 	let month;
	// 	switch (event.target.value)
	// 	{
	// 		case "1":
	// 			month="01";
	// 			break;

	// 		case "2":
	// 			month="02";
	// 			break;

	// 		case "3":
	// 			month="03";
	// 			break;

	// 		case "4":
	// 			month="04";
	// 			break;

	// 		case "5":
	// 			month="05";
	// 			break;

	// 		case "6":
	// 			month="06";
	// 			break;

	// 		case "7":
	// 			month="07";
	// 			break;

	// 		case "8":
	// 			month="08";
	// 			break;

	// 		case "9":
	// 			month="09";
	// 			break;

	// 		case "10":
	// 			month="10";
	// 			break;
	// 		case "11":
	// 			month="11";
	// 			break;

	// 		case "12":
	// 			month="12";
	// 			break;
	// 	}
	// 	setBirthdayMonth(month);
	// }

	const onChangeMonth = (event) => {
		event.preventDefault();

		// console.log(event.target.value.padStart(2, '0'));
		setBirthdayMonth(event.target.value.padStart(2, '0'));
	}

	const onChangeDay = (event) => {
		event.preventDefault();

		// console.log(event.target.value.padStart(2, '0'));
		setBirthdayDay(event.target.value.padStart(2, '0'));
	}

	const returnPackageList = (myPackageList) => 
	{
		// console.log("myPackageList : ", myPackageList);
		// null을 맨 뒤로 보내고 날짜가 제일 앞선 것을 위로 한다.
		// myPackageList?.sort((a, b) => b.mm_idx - a.mm_idx);
		myPackageList?.sort((a, b) => 
		{
				if (a.mm_valid_end_date === null) {
					return 1;
				}
			
				if (b.mm_valid_end_date === null) {
					return -1;
				}
			
				if (a.mm_valid_end_date === b.mm_valid_end_date) {
					return 0;
				}
			
				return b.mm_valid_end_date?.replace("-", "").replace("-", "").substr(0, 8) < a.mm_valid_end_date?.replace("-", "").replace("-", "").substr(0, 8) ? -1 : 1;
			}
		);

		// console.log("myPackageList : ", myPackageList);

		return <>
			{
				myPackageList !== null ?
				<>
					<span>{ myPackageList[0]?.mm_state_cd === "F" ? `무료 계정` : `${myPackageList[0]?.mm_name} (${myPackageList[0]?.mm_type})`} {myPackageList?.length > 1 ? `외 (${myPackageList?.length - 2})개 사용 중` : "" }</span>
					<span>{ myPackageList[0]?.mm_state_cd === "F" ? `기간 제한 없음` : `종료일 ${common.payDateChangeReturn(myPackageList[0]?.mm_valid_end_date)} 까지` }</span>
				</>
				:
				<></>
			} 
		</>; 
	};

  return (
    <>
      	{/* Header 분리됨 MainLayout Header에 있음 */}

		<div className={styles.mypage_top_line_banner}>
			<div className={styles.inner}>
				<span>학부모 페이지</span>
			</div>
		</div>
		<div className={styles.mypage_tab_btn_area} ref={mypage_tab_btn_areaRef}>
			<div className={styles.inner}>
				<ul>
					<li className={styles.hover_bg} ref={hover_bgRef}></li>
					<li><a href="/GroupUserMypage/GroupReport">학습 리포트</a></li>
					<li className={styles.on} ref={onRef}><a href="/GroupUserMypage/GroupStudentInfo">학습자 정보/관리</a></li>
					<li><a href="/GroupUserMypage/GroupParentsInfo">학부모 정보</a></li>
					<li><a href="/GroupUserMypage/GroupPayInfo">결제 정보</a></li>
					<li><a href="/GroupUserMypage/GroupCouponInfo">쿠폰 관리</a></li>
					<li><a href="/GroupUserMypage/GroupQnaInfo">문의 내역</a></li>
				</ul>
			</div>
		</div>
		<div className={`${styles.mypage_tab} ${styles.mypage_student_register} ${styles.mypage_student_infofix}`} ref={mypage_student_infofixRef}>
			<div className={styles.inner}>
				<span className={styles.my_tit}>정보 수정</span>
				<div className={styles.student_register_area}>
					 <ul>
					 	<li className={`${styles.infofix_normal} ${styles.infofix_vertical}`}>
							<span className={styles.head}>구매 상품</span>
							<div className={styles.right_area}>
								{/* <span>{couponInfo !== undefined ? couponInfo?.cou_name || "" : payInfo?.prd_name || ""}</span>
								<span>{couponInfo !== undefined ? common.payDateChangeReturn(couponInfo?.cou_valid_end_date || "") || "" : common.payDateChangeReturn(payInfo?.ord_valid_date || "")} 까지</span> */}
								{packageInfo !== undefined ? returnPackageList(packageInfo) : ""}
							</div>
						</li>
						<li className={styles.infofix_normal}>
							<span className={styles.head}>학습자 아이디</span>
							<div className={styles.right_area}>
								<span>{id || ""}</span>
							</div>
						</li>
						<li className={styles.infofix_normal}>
							<span className={styles.head}>이름</span>
							<div className={styles.right_area}>
								<span>{name || ""}</span>
							</div>
						</li>
						<li className={styles.type1}>
							<span className={styles.head}>닉네임</span>
							<div className={styles.right_area}>
								<input type="text" placeholder="닉네임" id={styles.student_register_nikname_input} value={nickname || ""} name="nickname" onChange={handleInputChange}  maxLength='7'/>
								<p className={styles.info}>* 닉네임은 7자까지 입력 가능합니다.</p>
							</div>
						</li>
						<li className={styles.addstring}>
							<span className={styles.head}>생년월일</span>
							<div className={styles.right_area}>
								<select id={styles.my_info_fix_year_selectbox} ref={my_info_fix_year_selectboxRef}></select>
								{/* <div className={styles.month_list_select_area} ref={month_list_select_areaRef}>
									<div className={styles.select_head}>
										<span className={styles.selected_option}>선택</span>
									</div>
									<ul>
										<li className={styles.select_list}>1월</li>
										<li className={styles.select_list}>2월</li>
										<li className={styles.select_list}>3월</li>
										<li className={styles.select_list}>4월</li>
										<li className={styles.select_list}>5월</li>
										<li className={styles.select_list}>6월</li>
										<li className={styles.select_list}>7월</li>
										<li className={styles.select_list}>8월</li>
										<li className={styles.select_list}>9월</li>
										<li className={styles.select_list}>10월</li>
										<li className={styles.select_list}>11월</li>
										<li className={styles.select_list}>12월</li>
									</ul>
								</div> */}
								<select name="month_list" id={styles.my_info_fix_month_list_select}  value={birthdayMonth} onChange={(e)=>onChangeMonth(e)}>
									<option value="" disabled >선택</option>
									<option value="01">1월</option>
									<option value="02">2월</option>
									<option value="03">3월</option>
									<option value="04">4월</option>
									<option value="05">5월</option>
									<option value="06">6월</option>
									<option value="07">7월</option>
									<option value="08">8월</option>
									<option value="09">9월</option>
									<option value="10">10월</option>
									<option value="11">11월</option>
									<option value="12">12월</option>
								</select>
								<select name="day_list" id={styles.my_register_day_list_select} value={birthdayDay} onChange={(e)=>onChangeDay(e)}>
									<option value="" disabled >선택</option>
									<option value="01">1</option>
									<option value="02">2</option>
									<option value="03">3</option>
									<option value="04">4</option>
									<option value="05">5</option>
									<option value="06">6</option>
									<option value="07">7</option>
									<option value="08">8</option>
									<option value="09">9</option>
									<option value="10">10</option>
									<option value="11">11</option>
									<option value="12">12</option>
									<option value="13">13</option>
									<option value="14">14</option>
									<option value="15">15</option>
									<option value="16">16</option>
									<option value="17">17</option>
									<option value="18">18</option>
									<option value="19">19</option>
									<option value="20">20</option>
									<option value="21">21</option>
									<option value="22">22</option>
									<option value="23">23</option>
									<option value="24">24</option>
									<option value="25">25</option>
									<option value="26">26</option>
									<option value="27">27</option>
									<option value="28">28</option>
									<option value="29">29</option>
									<option value="30">30</option>
									<option value="31">31</option>
								</select>
							</div>
						</li>
						<li className={styles.gender}>
							<span className={styles.head}>성별</span>
							<div className={styles.right_area}>
								<div className={styles.radio_btn_area}>
									<input type="radio" name="gender" value="boy" defaultChecked />
									<span>남</span>
								</div>
								<div className={styles.radio_btn_area}>
									<input type="radio" name="gender" value="girl"/>
									<span>여</span>
								</div>
							</div>
						</li>
						<li className={styles.type1}>
							<span className={styles.head}>비밀번호</span>
							<div className={styles.right_area}>
								<input type="password" placeholder="비밀번호" id={styles.parents_info_password} name="password" onChange={handleInputChange} maxLength={100} />
								<p className={styles.info}>* 비밀번호는 영문, 숫자, 특수문자 조합으로 8~20자리를 권장합니다</p>
							</div>
						</li>
						<li>
							<span className={styles.head}>비밀번호 확인</span>
							<div className={styles.right_area}>
								<input type="password" placeholder="비밀번호 확인" id={styles.parents_info_password_confirm}  name="repassword" onChange={handleInputChange} maxLength={100} />
							</div>
						</li>
					 </ul>
					 <div className={styles.btn_area}>
						<a href="/GroupUserMypage/GroupStudentInfo">취소</a>
						<button type="submit" onClick={handleSubmit}>확인</button>
					 </div>
				</div>
			</div> {/* inner */}
		</div> {/* mypage_tab */}
		
		{/* Footer 분리됨 MainLayout Footer에 있음 */}
    </>
  );
}