import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import Divider from '@mui/material/Divider';

import { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";

import { classCreate, classListAll, classNameCheck } from "../../actions/class";

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs() {
  
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [school, setSchool] = useState("");
  const [grade, setGrade] = useState("");
  const [classNumber, setClassNumber] = useState("");
  const [nameCheck, setNameCheck] = useState(false);
  const [helperText, setHelperText] = useState("");

  const dispatch = useDispatch();	

  const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

  const handleSubmit = (event) => {

	event.preventDefault();
	// console.log("name : ", name);
	// console.log("school : ", school);
	// console.log("grade : ", grade);
	// console.log("classNumber : ", classNumber);
	// console.log("nameCheck : ", nameCheck);

	// 임시 사용자의 정보는 반드시 변경되어야 사용이 가능합니다.
	// 그 조건을 만족해야지 업데이트를 하게 됩니다.

	// 학습자 아이디가 입력이 됬는지 중복체크는 통과했는지 검사함
	if (name === "")
	{
		// return alert("클래스의 이름을 입력해주세요!");
		return alert(`${t("ClassCommonDlg.alert.string1")}`);
	}
	else
	{
		if (!nameCheck)
		{
			// return alert("클래스 이름의 중복확인을 해주세요!");
			return alert(`${t("ClassCommonDlg.alert.string2")}`);
		}

		const regId =   /^.{1,40}$/;		// 단어의 수 제한 1개 이상 40개 이하
		if (!regId.test(name)) 
		{
			// return alert('클래스의 이름은 2자리 이상 50자리 이하만 입력해주세요.');
			return alert(`${t("ClassCommonDlg.alert.string3")}`);
		}
	}
	
	// 구매 상품을 선택했는지 체크, 이름, 닉네임, 생년월일, 성별 데이터가 있는지 체크
	if (school === "")
	{
		// return alert("학교를 입력해주세요!");
		return alert(`${t("ClassCommonDlg.alert.string4")}`);
	}
	else if (grade === "" || grade === null)
	{
		// return alert("학년을 입력해주세요!");
		return alert(`${t("ClassCommonDlg.alert.string5")}`);
	}
	else if (classNumber === "" || classNumber === "")
	{
		// return alert("반을 입력해주세요!");
		return alert(`${t("ClassCommonDlg.alert.string6")}`);
	}

	// 정규 표현식으로 비밀번호 생성 규칙에 맞는지 체크합니다.
	let reg = new RegExp(/^[0-9]+$/);
	// console.log(reg.test(join.password));
	if( !reg.test(grade) ) 
	{
		// return  alert("학년은 숫자만 입력 가능합니다.");
		return alert(`${t("ClassCommonDlg.alert.string7")}`);
	}
	
	if( !reg.test(classNumber) ) 
	{
		// return  alert("반은 숫자만 입력 가능합니다.");
		return alert(`${t("ClassCommonDlg.alert.string8")}`);
	}

	// 통과된 데이터로 학습자 정보를 변경합니다.
	let sendData = {
		mc_name: name,
		mc_school: school,
		mc_grade: grade,
		mc_class: classNumber,
	};

	// console.log("sendData : ", sendData);

	// 여기서 디스패치로~
	dispatch(classCreate(sendData))
	.then(data => 
	{
		// console.log("data : ", data);
		if (!data.success)
		{
			alert(data.msg);
			return;
		}
		else
		{
			setHelperText("");
			// alert("클래스 추가가 완료되었습니다.");
			alert(`${t("ClassCommonDlg.alert.string9")}`);

			const boardListSend = {
				board_page : 1,
			}
		  
			// 클래스의 리스트 불러오기
			dispatch(classListAll(boardListSend))
			.then(data => 
			{
			// console.log("classListAll data : ", data);
				setOpen(false);
			})
			.catch(e => {
				console.log(e);
			});
		}
	})
	.catch(e => {
		console.log(e);
	});
	
};

const handleInputChange = event => {

	const { name, value } = event.target;

	if (name === "mc_name")
	{
		setName(value);
	}
	else if (name === "mc_school")
	{
		setSchool(value);
	}
	else if (name === "mc_grade")
	{
		setGrade(value);
	}
	else if (name === "mc_class")
	{
		setClassNumber(value);
	}
};

const parentIDCheck = (name, event) => {

	event.preventDefault();

    // console.log("name : ", name);
    if(name === "")
    {
		setHelperText("");
		// return alert("클래스의 이름을 입력해주세요!");
		return alert(`${t("ClassCommonDlg.alert.string1")}`);
    }

	// 학습자 중복 아이디 검사
	// console.log("dispatch classNameCheck");

    dispatch(classNameCheck(name))
    .then(data => 
    {
        // console.log("data : ", data);
        if(!data.success)
        {
		  setNameCheck(true);
		//   setHelperText(name + "은 사용가능합니다.");
			setHelperText(`${t("ClassCommonDlg.alert.string10", {name : name})}`);
        }
        else
        {
		  setNameCheck(false);
		//   setHelperText(name + "은 중복된 이름입니다. 다른 이름을 사용하세요.");
			setHelperText(`${t("ClassCommonDlg.alert.string11", {name : name})}`);
		  
        }
    })
    .catch(e => {
        console.log(e);
    });
}

  const handleClickOpen = () => {
	setHelperText("");
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" style={{ textTransform: 'none' }} onClick={handleClickOpen}>{/* 클래스 만들기 */}{t('ClassCommonDlg.title1')}</Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {/* 클래스 생성 */}{t('ClassCommonDlg.string1')}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
		  	{/* 클래스의 이름은 중복되지 않습니다. <br></br>클래스 중복체크를 해주세요. */}<Trans i18nKey="ClassCommonDlg.string2"/>
          </Typography>
        </DialogContent>
		
		<Box
		component="form"
		sx={{
			display: 'flex',
    		alignItems: 'center',
			'& .MuiTextField-root': { m: 1, width: '350px', marginTop: '20px', marginLeft: '20px', marginRight: '5px'},
			'& .MuiButton-root': { m: 1, marginLeft: '10px', marginRight: '10px'},
		}}
		noValidate
		autoComplete="off"
		>
			<TextField
			required
			id="mc_name"
			name='mc_name'
			// label="클래스 이름"
			label={t('ClassCommonDlg.string3')}
			defaultValue=""
			onChange={handleInputChange}
			inputProps={{ maxLength: 40 }}
			helperText={helperText}
			/>
			<Button style={{ textTransform: 'none' }} onClick={(e)=>parentIDCheck(name, e)}>{/* 중복확인 */}{t('Auth.common.duplicate')}</Button>
		</Box>
		
		<Box
		component="form"
		sx={{
			'& .MuiTextField-root': { m: 1, width: '350px', marginTop: '20px', marginLeft: '20px' },
		}}
		noValidate
		autoComplete="off"
		>
		<div>
			<TextField
			required
			id="mc_school"
			name='mc_school'
			// label="학교"
			label={t('ClassCommonDlg.string4')}
			defaultValue=""
			onChange={handleInputChange}
			/>
		</div>
		</Box>
		<Box
		component="form"
		sx={{
			'& .MuiTextField-root': { m: 1, width: '100px', marginTop: '20px', marginLeft: '20px' },
		}}
		noValidate
		autoComplete="off"
		>
		<div>
			<TextField
			required
			id="mc_grade"
			name='mc_grade'
			// label="학년"
			label={t('ClassCommonDlg.string5')}
			defaultValue=""
			onChange={handleInputChange}
			/>
		</div>
		</Box>
		<Box
		component="form"
		sx={{
			'& .MuiTextField-root': { m: 1, width: '100px', marginTop: '20px', marginLeft: '20px', marginBottom: '20px' },
		}}
		noValidate
		autoComplete="off"
		>
		<div>
			<TextField
			required
			id="mc_class"
			name='mc_class'
			// label="반"
			label={t('ClassCommonDlg.string6')}
			defaultValue=""
			onChange={handleInputChange}
			/>
		</div>
		</Box>

		<Divider />
		
        <DialogActions>
          <Button autoFocus onClick={handleSubmit} style={{ textTransform: 'none' }}>
            {/* 생성 */}{t('ClassCommonDlg.string7')}
          </Button>
		  <Button autoFocus onClick={handleClose} style={{ textTransform: 'none' }}>
            {/* 취소 */}{t('Common.cancel')}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}