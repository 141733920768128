import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

export default function StudentExcelUploadErrorDialog(props) {
  const [openAlert, setOpenAlert] = React.useState(props.openAlert);

  const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

  // console.log(props);

  const handleClickOpen = () => {
    setOpenAlert(true);
  };

  const handleClose = () => {
    setOpenAlert(false);
  };

  const Item = ({ text }) => {
    return <p style={{fontSize:'14px'}}>
      {text.split("\n").map((txt) => (
          <>
            {txt}
            <br />
          </>
        ))}
    </p>;
  };

  return (
    <div style={{fontSize:'13px'}}>
      {props.errorMsg !== "" ? <Button variant="outlined" style={{ textTransform: 'none' }} color="error" size="small" onClick={handleClickOpen}>{/* 확인 */}{t('Common.confirm')}</Button> : /* "없음" */ t('ClassStudentCommonDlg.string56') }
      <Dialog
        open={openAlert}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {/* 오류확인 */}{t('ClassStudentCommonDlg.string44')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"
          >
            <Item text={props.errorMsg} />
    {/*         {props.RowData.mst_id}는 {props.RowData.valid_date}까지 유효기간입니다.<br></br>
            한번 사용된 이용권은 다시 적용할 수 없습니다. <br></br> 이용권을 추가하시겠습니까? */}

            {/* <Trans i18nKey="ClassStudentCommonDlg.string41" values={{id:props.RowData.mst_id, valid_date:props.RowData.valid_date}} /> */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus variant="outlined" size="small" style={{ textTransform: 'none' }}>
            {/* 확인 */}{t('Common.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}