import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import PopupDom from '../../common/PopupDom';
import PopupPostCode from '../../common/PopupPostCode';

import { useState } from "react";
import { useDispatch } from "react-redux";
import { userCreate, userGroupCreate } from "../../actions/users";
import { useNavigate } from 'react-router-dom';

// 단체 가입 추가 정보
const GroupUserInfo = (props) =>{

    // 팝업창 상태 관리
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handleInputChange = event => {
        const { name, value } = event.target;
        // console.log("event target name : ", name);
        // console.log("event target value : ", value);
        props.setJoinGroup({ ...props.joinGroup, [name]: value });
      };

    // 팝업창 열기
    const openPostCode = () => {
        setIsPopupOpen(true)
    };

    // 팝업창 닫기
    const closePostCode = () => {
        setIsPopupOpen(false)
    };

    // 자식의 주소 값을 가져와서 갱신합니다.
    const setAddressFun = (addr1, addr2, addr3) => {
    console.log("addressData1 : ", addr1);
    props.setAddressJson(addr1);

    console.log("addressData2 : ", addr2);
    props.setFullAddress(addr2);

    console.log("addressData3 : ", addr3);
    props.setZoneCode(addr3);
    };

	return (
			<>
			  <Box
                    component="span"
                    sx={{
                    display: 'block',
                    p: 1,
                    m: 1,
                    }}
                >
                    <TextField
                        required
                        fullWidth
                        id="companyName"
                        label="회사 이름"
                        name="companyName"
                        autoComplete="username"
                        onChange={handleInputChange}
                        value={props.joinGroup.companyName}
                        size="small"
                    />
                </Box>
                <Box
                    sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    p: 1,
                    m: 1,
                    }}
                >
                    <div style={{width:'20%', display:'flex', alignItems:'center'}}>사업자번호</div>
                    <TextField
                        sx={{
                            width:'25%',
                            marginRight:'1em'
                            }}
                        required
                        fullWidth
                        name="bizCode1"
                        id="bizCode1"
                        autoComplete="username"
                        onChange={handleInputChange}
                        value={props.joinGroup.bizCode1}
                        size="small"
                    />
                    <TextField
                        sx={{
                            width:'25%',
                            marginRight:'1em'
                            }}
                        required
                        fullWidth
                        name="bizCode2"
                        id="bizCode2"
                        autoComplete="username"
                        onChange={handleInputChange}
                        value={props.joinGroup.bizCode2}
                        size="small"
                    />
                    <TextField
                        sx={{
                            width:'25%',
                            }}
                        required
                        fullWidth
                        name="bizCode3"
                        id="bizCode3"
                        autoComplete="username"
                        onChange={handleInputChange}
                        value={props.joinGroup.bizCode3}
                        size="small"
                    />
                </Box>
                <Box
                    sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    p: 1,
                    m: 1,
                    }}
                >
                    <div style={{width:'25%', display:'flex', alignItems:'center'}}>회사 전화번호</div>
                    <TextField
                        sx={{
                            width:'25%',
                            marginRight:'1em'
                            }}
                        required
                        fullWidth
                        id="companyPhone1"
                        name="companyPhone1"
                        autoComplete="username"
                        onChange={handleInputChange}
                        value={props.joinGroup.companyPhone1}
                        size="small"
                    />
                    <TextField
                        sx={{
                            width:'25%',
                            marginRight:'1em'
                            }}
                        required
                        fullWidth
                        id="companyPhone2"
                        name="companyPhone2"
                        autoComplete="username"
                        onChange={handleInputChange}
                        value={props.joinGroup.companyPhone2}
                        size="small"
                    />
                    <TextField
                        sx={{
                            width:'25%',
                            }}
                        required
                        fullWidth
                        id="companyPhone3"
                        name="companyPhone3"
                        autoComplete="username"
                        onChange={handleInputChange}
                        value={props.joinGroup.companyPhone3}
                        size="small"
                    />
                </Box>
                <Box
                    sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    p: 1,
                    m: 1,
                    }}
                >
                    <div style={{width:'25%', display:'flex', alignItems:'center'}}>팩스번호</div>
                    <TextField
                        sx={{
                            width:'25%',
                            marginRight:'1em'
                            }}
                        required
                        fullWidth
                        id="companyFax1"
                        name="companyFax1"
                        autoComplete="username"
                        onChange={handleInputChange}
                        value={props.joinGroup.companyFax1}
                        size="small"
                    />
                    <TextField
                        sx={{
                            width:'25%',
                            marginRight:'1em'
                            }}
                        required
                        fullWidth
                        id="companyFax2"
                        name="companyFax2"
                        autoComplete="username"
                        onChange={handleInputChange}
                        value={props.joinGroup.companyFax2}
                        size="small"
                    />
                    <TextField
                        sx={{
                            width:'25%',
                            }}
                        required
                        fullWidth
                        id="companyFax3"
                        name="companyFax3"
                        autoComplete="username"
                        onChange={handleInputChange}
                        value={props.joinGroup.companyFax3}
                        size="small"
                    />
                </Box>
                <Box 
                    sx={{ 
                        display: 'block',
                        p: 1,
                        m: 1,
                        minWidth: 120, width: 120, 
                }}>
                <FormControl fullWidth>
                    <InputLabel id="user-type-select-label">법인형태</InputLabel>
                    <Select
                    labelId="user-type-select-label"
                    id="user-type-select"
                    label="법인형태"
                    name="type"
                    value={props.joinGroup.type}
                    onChange={handleInputChange}
                    size="small"
                    >
                    <MenuItem value={0}>
                        <em>선택</em>
                    </MenuItem>
                    <MenuItem value={1}>개인사업자</MenuItem>
                    <MenuItem value={2}>영리법인</MenuItem>
                    <MenuItem value={3}>비영리법인</MenuItem>
                    <MenuItem value={4}>기타공식단체</MenuItem>
                    <MenuItem value={5}>비공식단체</MenuItem>
                    </Select>
                </FormControl>
                </Box>
                <Box
                    component="span"
                    sx={{
                    display: 'block',
                    p: 1,
                    m: 1,
                    }}
                >
                    <Button variant="contained" style={{ textTransform: 'none' }} size='small' onClick={openPostCode}>우편번호 검색</Button>
                    {/* 팝업 생성 기준 div */}
                    <div id='popupDom'>
                    { isPopupOpen && (
                        <PopupDom>
                            <PopupPostCode onClose={closePostCode} 
                            setAddressFun={setAddressFun}
                            />
                        </PopupDom>
                    )}
                    </div>
                </Box>
                <Box
                    component="span"
                    sx={{
                    display: 'block',
                    p: 1,
                    m: 1,
                    }}
                >
                    <TextField
                        required
                        fullWidth
                        id="zoneCode"
                        label="우편번호"
                        name="zoneCode"
                        value={props.zoneCode}
                        autoComplete="username"
                        size="small"
                    />
                </Box>
                <Box
                    component="span"
                    sx={{
                    display: 'block',
                    p: 1,
                    m: 1,
                    }}
                >
                    <TextField
                        required
                        fullWidth
                        id="fullAddress"
                        label="주소"
                        name="fullAddress"
                        value={props.fullAddress}
                        autoComplete="username"
                        size="small"
                    />
                </Box>
                <Box
                    component="span"
                    sx={{
                    display: 'block',
                    p: 1,
                    m: 1,
                    }}
                >
                    <TextField
                        required
                        fullWidth
                        id="plusAddress"
                        label="상세주소"
                        name="plusAddress"
                        autoComplete="username"
                        onChange={handleInputChange}
                        size="small"
                    />
                </Box>
			</>
	);
  };

export default function UserWritePopup(props) {

    const initialJoinState = {
        email: "", 
        password: "",
        confirmPassword: "",
        name: "",
        nickname: "",
        type: 0,
        phonenumber1: "010",
        phonenumber2: "",
        phonenumber3: "",
      };

    const initialJoinGroupState = {
        companyName: "", 
        bizCode1: "",
        bizCode2: "",
        bizCode3: "",
        companyPhone1: "",
        companyPhone2: "",
        companyPhone3: "",
        companyFax1: "",
        companyFax2: "",
        companyFax3:"",
        type:0,
        plusAddress: "",
    };

  const [open, setOpen] = React.useState(false);
  const [join, setJoin] = useState(initialJoinState);
  const [joinGroup, setJoinGroup] = useState(initialJoinGroupState);

  const [addressJson, setAddressJson] = useState(null);
  const [zoneCode, setZoneCode] = useState("");
  const [fullAddress, setFullAddress] = useState("");
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleInputChange = event => {
    const { name, value } = event.target;
    console.log("event target name : ", name);
    console.log("event target value : ", value);

    setJoin({ ...join, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let body = {
      email: join.email,
      password: join.password,
      name: join.name,
      nickname: join.nickname,
      type: join.type,
      phone: join.phonenumber1 + "-" + join.phonenumber2 + "-" + join.phonenumber3
    };

    console.log("body : ", body);

    if(join.password !== join.confirmPassword)
    {
      return alert('비밀번호와 비밀번호 확인은 같아야 합니다.')
    }

    const regEmail = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
    if (regEmail.test(body.email) === false) 
    {
        alert('입력된 값은 이메일 형식이 아닙니다.');
    }

    const regPhone = /^01([0|1|6|7|8|9])-([0-9]{3,4})-([0-9]{4})$/;
    if (regPhone.test(body.phone) === false) 
    {
        alert('입력된 값은 휴대전화번호 형식에 맞지 않습니다.');
    }

    console.log("user type : ", join.type);
    
    if (join.type === 0 )
    {
        alert("회원가입 유형을 선택해주세요!");
        return;
    }
    else if (join.type === 1 || join.type === 2)            // 관리자, 일반 가입을 추가 정보가 필요없음
    {
      // 여기서 디스패치로~
      dispatch(userCreate(body))
      .then(data => 
      {
          console.log("data : ", data);
          if (!data.success)
          {
              alert(data.msg);
              return;
          }
          else
          {
            alert("가입완료");
            // navigate("/auth/login");

            // 데이터 초기화
            setJoin(initialJoinState);
            setJoinGroup(initialJoinGroupState);
            setAddressJson(null);
            setZoneCode("");
            setFullAddress("");

            props.onReloadWindow();
            handleClose();
          }
        })
        .catch(e => {
            console.log(e);
        });
    }
    else if (join.type === 4)            // 단체 가입은 추가 정보가 필요
    {

        const companyInfo = {
            name: joinGroup.companyName,
            biz_code: joinGroup.bizCode1 + "-" + joinGroup.bizCode2 + "-" + joinGroup.bizCode3,
            phone: joinGroup.companyPhone1 + "-" + joinGroup.companyPhone2 + "-" + joinGroup.companyPhone3,
            fax: joinGroup.companyFax1 + "-" + joinGroup.companyFax2 + "-" + joinGroup.companyFax3,
            type: joinGroup.type,
            addr_part1: fullAddress,
            addr_part2: addressJson.bname,
            addr_detail: joinGroup.plusAddress,
            addr_sinm: addressJson.sido,
            addr_sggnm: addressJson.sigungu,
            addr_emdnm: addressJson.bname2,
            addr_zip: zoneCode
        };
    
        console.log("companyInfo : ", companyInfo);

      // 여기서 디스패치로~
      dispatch(userGroupCreate({join: body, joinGroup: companyInfo}))
      .then(data => 
      {
          console.log("data : ", data);
          if (!data.success)
          {
              alert(data.msg);
              return;
          }
          else
          {
            alert("가입완료");
            // navigate("/auth/login");

            // 데이터 초기화
            setJoin(initialJoinState);
            setJoinGroup(initialJoinGroupState);
            setAddressJson(null);
            setZoneCode("");
            setFullAddress("");

            props.onReloadWindow();
            handleClose();
          }
        })
        .catch(e => {
            console.log(e);
        });
    }
  }

  const hasError = passwordEntered =>
        join.password.length < 5 ? true : false;
    
  const hasNotSameError = passwordEntered =>
      join.password != join.confirmPassword ? true : false;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {

    // 데이터 초기화
    setJoin(initialJoinState);
    setJoinGroup(initialJoinGroupState);
    setAddressJson(null);
    setZoneCode("");
    setFullAddress("");
    
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" style={{ textTransform: 'none' }} onClick={handleClickOpen}>
        {props.buttonText}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>사용자 추가</DialogTitle>
        <DialogContent>
          <DialogContentText>
            관리자 사용자 추가는 관리자를 추가하는 상황이나 테스트 계정이 필요한 특수한 경우에만 해주세요!
            일반적으로 회원가입이 원칙입니다.
          </DialogContentText>
          <Box
            component="span"
            sx={{
            display: 'block',
            p: 1,
            m: 1,
            }}
        >
            <TextField
                required
                fullWidth
                id="email"
                label="이메일"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={handleInputChange}
                size="small"
              />
        </Box>
        <Box
            component="span"
            sx={{
            display: 'block',
            p: 1,
            m: 1,
            }}
        >
            <TextField
                required
                fullWidth
                name="password"
                label="패스워드(5글자 이상 필수)"
                type="password"
                id="password"
                error={hasError('password')} // 해당 텍스트필드에 error 핸들러 추가
                onChange={handleInputChange}
                autoComplete="current-password"
                size="small"
              />
        </Box>
        <Box
            component="span"
            sx={{
            display: 'block',
            p: 1,
            m: 1,
            }}
        >
            <TextField
                required
                fullWidth
                name="confirmPassword"
                label="패스워드 확인"
                type="password"
                id="confirmPassword"
                error={hasNotSameError('confirmPassword')} // 해당 텍스트필드에 error 핸들러 추가
                helperText={
                    hasNotSameError('confirmPassword') ? "입력한 비밀번호와 일치하지 않습니다." : null
                } // 에러일 경우에만 안내 문구 표시
                onChange={handleInputChange}
                autoComplete="current-password"
                size="small"
              />
        </Box>
        <Box
            component="span"
            sx={{
            display: 'block',
            p: 1,
            m: 1,
            }}
        >
            <TextField
                required
                fullWidth
                id="name"
                label="Name"
                name="name"
                onChange={handleInputChange}
                size="small"
              />
        </Box>
        <Box
            component="span"
            sx={{
            display: 'block',
            p: 1,
            m: 1,
            }}
        >
            <TextField
                required
                fullWidth
                id="nickname"
                label="Nick Name"
                name="nickname"
                onChange={handleInputChange}
                size="small"
              />
        </Box>
        <Box
            sx={{
            display: 'flex',
            flexDirection: 'row',
            p: 1,
            m: 1,
            }}
        >
            <div style={{width:'20%', display:'flex', alignItems:'center'}}>전화번호</div>
            <TextField
                sx={{
                    width:'25%',
                    marginRight:'1em'
                    }}
                  required
                  fullWidth
                  id="phonenumber1"
                  label=""
                  name="phonenumber1"
                  value={join.phonenumber1}
                  InputProps={{
                    readOnly: true,
                  }}
                  onChange={handleInputChange}
                  size="small"
                />
            <TextField
                sx={{
                    width:'25%',
                    marginRight:'1em'
                    }}
                  required
                  fullWidth
                  id="phonenumber2"
                  name="phonenumber2"
                  onChange={handleInputChange}
                  size="small"
                />
            <TextField
                sx={{
                    width:'25%'
                    }}
                  required
                  fullWidth
                  id="phonenumber3"
                  name="phonenumber3"
                  onChange={handleInputChange}
                  size="small"
                />
        </Box>
        <Box component="span" 
        sx={{ 
            display: 'block',
            float: 'right',
            p: 1,
            m: 1,
            minWidth: 120, width: 120 
            }}>
        <FormControl fullWidth>
            <InputLabel id="user-type-select-label">유저타입</InputLabel>
            <Select
            labelId="user-type-select-label"
            id="user-type-select"
            label="유저타입"
            name="type"
            value={join.type}
            onChange={handleInputChange}
            size="small"
            >
            <MenuItem value={0}>
                <em>선택</em>
            </MenuItem>
            <MenuItem value={1}>관리자</MenuItem>
            <MenuItem value={2}>일반</MenuItem>
            <MenuItem value={4}>단체</MenuItem>
            </Select>
        </FormControl>
        </Box>
        { join.type === 4 ? <GroupUserInfo 
                                joinGroup={joinGroup} 
                                zoneCode={zoneCode} 
                                fullAddress={fullAddress} 
                                setJoinGroup={setJoinGroup} 
                                setAddressJson={setAddressJson}
                                setZoneCode={setZoneCode}
                                setFullAddress={setFullAddress}
                                /> 
                        : ""}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ textTransform: 'none' }}>취소</Button>
          <Button onClick={handleSubmit} style={{ textTransform: 'none' }}>확인</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}