import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import styles from "../../css/common.module.css";
import $ from 'jquery';

import { userSelect, userModify, userGroupModify } from "../../actions/users";

import PopupDom from '../../common/PopupDom';
import PopupPostCode from '../../common/PopupPostCode';

import MemberLeaveDialog from './MemberLeaveDialog';

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

export default function GroupMypageParentsInfo() {

	// 일반 회원정보
	const [email, setEmail] = useState("");
	const [name, setName] = useState("");
	const [nickname, setNickname] = useState("");
	const [phone, setPhone] = useState("");
	const [type, setType] = useState("");
	const [smsRecive, setsmsRecive] = useState(0);
	const [emailRecive, setEmailRecive] = useState(0);
	const [password, setPassword] = useState("");
	const [repassword, setRepassword] = useState("");

	// 단체 회원의 추가 정보
	const [companyName, setCompanyName] = useState("");
	const [bizCode, setBizCode] = useState("");
	const [companyPhone, setCompanyPhone] = useState("");
	const [companyFax, setCompanyFax] = useState("");
	const [companyType, setCompanyType] = useState(0);
	const [addrPart1, setAddrPart1] = useState("");
	const [addrPart2, setAddrPart2] = useState("");
	const [addrDetail, setAddrDetail] = useState("");
	const [addrSinm, setAddrSinm] = useState("");
	const [addrSggnm, setAddrSggnm] = useState("");
	const [addrEmdnm, setAddrEmdnm] = useState("");

	const [addressJson, setAddressJson] = useState(null);
	const [zoneCode, setZoneCode] = useState("");
	const [fullAddress, setFullAddress] = useState("");
	const [plusAddress, setPlusAddress] = useState("");
	const [status, setStatus] = useState("");
    const [prdIdx, setPrdIdx] = useState(0);

	// 팝업창 상태 관리
	const [isPopupOpen, setIsPopupOpen] = useState(false)

	const authState = useSelector(state => state.auth);
    // console.log("authState : ", authState);

	const profile = useSelector(state => state.users.user);
    // console.log("profile : ", profile);

	const dispatch = useDispatch();	

	const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

	let language = localStorage.getItem("language");

	const mypage_tab_btn_areaRef =  useRef();
    const hover_bgRef =  useRef();
    const onRef =  useRef();

	const sms_add_smsRed =  useRef();
	const sms_add_emailRed =  useRef();

	// 사업자 번호 유효성 체크 함수
	const checkCorporateRegistrationNumber = (value) => 
	{
		let valueMap = value.replace(/-/gi, '').split('').map(function(item) 
		{
			return parseInt(item, 10);
		});
	
		if (valueMap.length === 10) 
		{
			var multiply = new Array(1, 3, 7, 1, 3, 7, 1, 3, 5);
			var checkSum = 0;
	
			for (var i = 0; i < multiply.length; ++i) 
			{
				checkSum += multiply[i] * valueMap[i];
			}
	
			checkSum += parseInt((multiply[8] * valueMap[8]) / 10, 10);
			return Math.floor(valueMap[9]) === (10 - (checkSum % 10));
		}
	
		return false;
  	}

	// 팝업창 열기
	const openPostCode = () => {
		setIsPopupOpen(true)
	};
   
	  // 팝업창 닫기
	const closePostCode = () => {
	  	setIsPopupOpen(false)
	};
  
	// 자식의 주소 값을 가져와서 갱신합니다.
	const setAddressFun = (addr1, addr2, addr3) => 
	{
		// console.log("주소 객체 : ", addr1);
		setAddressJson(addr1);
		
		// console.log("주소 : ", addr2);
		setFullAddress(addr2);
		
		// console.log("우편번호 : ", addr3);
		setZoneCode(addr3);

		// 상세주소를 초기화 합니다.
		setPlusAddress("")

		setAddrPart1(addr2);
		setAddrPart2(addr1.bname);
		setAddrSinm(addr1.sido);
		setAddrSggnm(addr1.sigungu);
		setAddrEmdnm(addr1.bname2);
	};

	const handleSubmit = (event) => {

		event.preventDefault();

		// console.log("email : ", email);
		// console.log("name : ", name);
		// console.log("nickname : ", nickname);
		// console.log("phone : ", phone);
		// console.log("smsRecive : ", smsRecive);
		// console.log("emailRecive : ", emailRecive);
		// console.log("password : ", password);
		// console.log("repassword : ", repassword);

		// console.log("companyName : ", companyName);
		// console.log("bizCode : ", bizCode);
		// console.log("companyPhone : ", companyPhone);
		// console.log("companyFax : ", companyFax);
		// console.log("companyType : ", companyType);
		// console.log("addrPart1 : ", addrPart1);
		// console.log("addrPart2 : ", addrPart2);
		// console.log("addrDetail : ", addrDetail);
		// console.log("addrSinm : ", addrSinm);
		// console.log("addrSggnm : ", addrSggnm);
		// console.log("addrEmdnm : ", addrEmdnm);
		// console.log("addressJson : ", addressJson);
		// console.log("zoneCode : ", zoneCode);
		// console.log("fullAddress : ", fullAddress);
		// console.log("plusAddress : ", plusAddress);

		// if (nickname === "")
		// {
		// 	return alert('닉네임 값을 입력해주세요.');
		// }

		const regPhone = /^01([0|1|6|7|8|9])-([0-9]{3,4})-([0-9]{4})$/;
		const regPhone2 = /^\d{3}-\d{3,4}-\d{4}$/;

		const regPhone_foreign = /^([0-9]{2,3})-([0-9]{3,4})-([0-9]{3,4})-([0-9]{4})$/;  // 외국의 경우 국가번호와 숫자로 이뤄진 번호를 받습니다.

		if (language === 'ko')
		{
			if (regPhone.test(phone) === false) 
			{
				// return alert('000-0000-0000 형식으로 중간에 -(하이픈)을 넣어주세요.');
				return alert(`${t("MypageParentsInfo.alert.string1")}`);
			}
			else if (regPhone2.test(phone) === false) 
			{
				// return alert('000-0000-0000 형식으로 중간에 -(하이픈)을 넣어주세요.');
				return alert(`${t("MypageParentsInfo.alert.string1")}`);
			}
		}
		else
		{
			if (regPhone_foreign.test(phone) === false) 
			{
				// return alert('000-0000-0000 형식으로 중간에 -(하이픈)을 넣어주세요.');
				return alert(`${t("MypageParentsInfo.alert.string1")}`);
			}
		}

		

		if (password !== "" || repassword !== "")
		{
			// 정규 표현식으로 비밀번호 생성 규칙에 맞는지 체크합니다.
			let reg = new RegExp(/(?=.*\d{1,20})(?=.*[~`!@#$%\^&*()-+=]{1,20})(?=.*[a-zA-Z]{1,20}).{8,20}$/);
			
			// console.log(reg.test(join.password));
			if( !reg.test(password) ) 
			{
				// return  alert("비밀번호 생성 규칙은 영문, 숫자, 특수문자 포함 8자 이상 20자가지 입니다.");
				return alert(`${t("MypageParentsInfo.alert.string2")}`);
			}

			if (password !== repassword)
			{
				// return alert("비밀번호가 일치하지 않습니다.");
				return alert(`${t("MypageParentsInfo.alert.string3")}`);
			}
		}
		else
		{
			// 비밀번호가 둘 다 있어야지 변경합니다.
			// 아니면 초기화 합니다.
			setPassword("");
			setRepassword("");
		}

		// 여기에서 데이터를 입력합니다.
		let sendData = {
			email:email,
			type:type,
			name:name,
			nickname:nickname,
			phone: phone,
			password:password,
			recive_sms:smsRecive,
			recive_email:emailRecive
		};

		// console.log("sendData : ", sendData);

		if (type === 4)
		{
			const regPhone3 = /^(0(2|3[1-3]|4[1-4]|5[1-5]|6[1-4]))-(\d{3,4})-(\d{4})$/;
			if(companyName === "")
			{
				// return alert('회사 이름을 입력해주세요.');
				return alert(`${t("MypageParentsInfo.alert.string4")}`);
			}
	
			// 한국계정일 경우에만 사업자 번호를 검증합니다.
			if (language === 'ko')
			{
				if(bizCode === "")
				{
					// return alert('사업자 번호를 입력해주세요.');
					return alert(`${t("MypageParentsInfo.alert.string5")}`);
				}	

				if(!checkCorporateRegistrationNumber(bizCode))
				{
					// return alert('유효한 사업자번호를 입력하세요.');
					return alert(`${t("MypageParentsInfo.alert.string6")}`);
				}

				if(companyPhone === "")
				{
					// return alert('전화 번호를 입력해주세요.');
					return alert(`${t("MypageParentsInfo.alert.string7")}`);
				}
				
				if (regPhone3.test(companyPhone) === false) 
				{
					// return alert('입력된 값은 전화번호(000-0000-0000) 형식에 맞게 중간에 -(하이픈)을 넣어주세요.');
					return alert(`${t("MypageParentsInfo.alert.string8")}`);
				}
		
				if(companyFax === "")
				{
					// return alert('팩스 번호를 입력해주세요.');
					return alert(`${t("MypageParentsInfo.alert.string9")}`);
				}
		
				if (regPhone3.test(companyFax) === false) 
				{
					// return alert('입력된 값은 팩스번호(000-0000-0000) 형식에 맞게 중간에 -(하이픈)을 넣어주세요.');
					return alert(`${t("MypageParentsInfo.alert.string10")}`);
				}
			}
	
			if(companyType === 0)
			{
				// return alert('법인형태를 선택해주세요.');
				return alert(`${t("MypageParentsInfo.alert.string11")}`);
			}
	
			if(zoneCode === "")
			{
				// return alert('우편번호를 입력해주세요.');
				return alert(`${t("MypageParentsInfo.alert.string12")}`);
			}
	
			if(fullAddress === "")
			{
				// return alert('주소를 입력해주세요.');
				return alert(`${t("MypageParentsInfo.alert.string13")}`);
			}
	
			if(plusAddress === "")
			{
				// return alert('상세 주소를 입력해주세요.');
				return alert(`${t("MypageParentsInfo.alert.string14")}`);
			}
	
			const companyInfo = {
				name: companyName,
				biz_code: bizCode,
				phone: companyPhone,
				fax: companyFax,
				type: companyType,
				addr_part1: fullAddress || addrPart1,
				addr_part2: addressJson?.bname || addrPart2,
				addr_detail: plusAddress || "",
				addr_sinm: addressJson?.sido || addrSinm,
				addr_sggnm: addressJson?.sigungu || addrSggnm,
				addr_emdnm: addressJson?.bname2 || addrEmdnm,
				addr_zip: zoneCode || "",
				mg_status: status,
                prd_idx: prdIdx
			};
	
			// console.log("companyInfo : ", companyInfo);

			dispatch(userGroupModify({join: sendData, joinGroup: companyInfo}))
			.then(data => 
			{
				// console.log("userGroupModify data : ", data);
				if (!data.success)
				{
					alert(data.msg);
					return;
				}
				else
				{
					// alert("수정완료");
					alert(`${t("MypageParentsInfo.alert.string15")}`);
					document.location.reload();
				}
			})
			.catch(e => {
				console.log(e);
			});
		}
		else
		{
			// 여기서 디스패치로~
			dispatch(userModify(sendData))
			.then(data => 
			{
				// console.log("data : ", data);
				if (!data.success)
				{
					alert(data.msg);
					return;
				}
				else
				{
					// alert("수정완료");
					alert(`${t("MypageParentsInfo.alert.string15")}`);
					document.location.reload();
				}
			})
			.catch(e => {
				console.log(e);
			});
		}

	};

	const handleInputChange = event => {

        const { name, value } = event.target;

		if (name === "nickname")
		{
			setNickname(value);
		}
		else if (name === "phone")
		{
			setPhone(value);
		}
		else if (name === "password")
		{
			setPassword(value);
		}
		else if (name === "repassword")
		{
			setRepassword(value);
		}
		else if (name === "companyName")
		{
			setCompanyName(value);
		}
		else if (name === "bizCode")
		{
			setBizCode(value);
		}
		else if (name === "companyPhone")
		{
			setCompanyPhone(value);
		}
		else if (name === "companyFax")
		{
			setCompanyFax(value);
		}
		else if (name === "companyType")
		{
			setCompanyType(parseInt(value));
		}
		else if (name === "plusAddress")
		{
			setPlusAddress(value);
		}

		if (language !== 'ko' || language !== '')
		{
			if (name === "zoneCode")
			{
				setZoneCode(value);
			}
			else if (name === "fullAddress")
			{
				setFullAddress(value);
			}
		}
    };

	useEffect(() => {

		// 여기에서 jQuery를 초기화 합니다.
		// jquery ready 호출. jquery3 부터 변경됨
		$(function(){
			// console.log("jquery ready!");

            //마이페이지 탭 버튼(활성화 효과)
            // $(mypage_tab_btn_areaRef.current).children('li').on('click',function(){
            //     let tg=$(this);
                
            //     $(mypage_tab_btn_areaRef.current).children('li').removeClass('on');
            //     tg.addClass('on');
            // });

			//마이페이지 탭 버튼(활성화 효과)
			$(`.${styles.mypage_tab_btn_area} li`).on('click',function()
			{
				let tg=$(this);
				
				$(`.${styles.mypage_tab_btn_area} li`).removeClass(`${styles.on}`);
				tg.addClass(`${styles.on}`);
			});
            
            //마이페이지 탭 버튼(후버 효과)
            $(window).resize(function()
			{
				if (window.innerWidth < 1200) 
				{ 
					$(`.${styles.mypage_tab_btn_area} li.${styles.hover_bg}`).css('display','none');
				}
				else
				{
					let nowbtnNum=$(`.${styles.mypage_tab_btn_area} li.${styles.on}`).index();
					let hoverBg=$(`.${styles.mypage_tab_btn_area} li.${styles.hover_bg}`);

					$(`.${styles.mypage_tab_btn_area} ul`).on('mouseenter',function()
					{
						
						$(`.${styles.mypage_tab_btn_area} li`).removeClass(`${styles.on}`);
						hoverBg.css('display','block');
						
						$(`.${styles.mypage_tab_btn_area} li`).on('mouseenter',function()
						{
							let tgIndexinfo=$(this);
							hoverBg.stop().css('left',tgIndexinfo['0']['offsetLeft']).css('width',tgIndexinfo['0']['clientWidth']);

							// let tgIndex=$(this).index();
							
							// if(tgIndex===1)
							// {
							// 	hoverBg.stop().css('left','167px').css('width','116px');
							// }
							// else if(tgIndex===2)
							// {
							// 	hoverBg.stop().css('left','300px').css('width','150px');
							// }
							// else if(tgIndex===3)
							// {
							// 	hoverBg.stop().css('left','467px').css('width','116px');
							// }
							// else if(tgIndex===4)
							// {
							// 	hoverBg.stop().css('left','624px').css('width','102px');
							// }
							// else if(tgIndex===5)
							// {
							// 	hoverBg.stop().css('left','774px').css('width','102px');
							// }
							// else if(tgIndex===6)
							// {
							// 	hoverBg.stop().css('left','924px').css('width','102px');
							// };
						});
						
					}).on('mouseleave',function()
					{
						$(`.${styles.mypage_tab_btn_area} li`).eq(nowbtnNum).addClass(`${styles.on}`);
						hoverBg.css('display','none');
						
						if(nowbtnNum===0)
						{
							hoverBg.css({left:'167px'}).css({width:'116px'});
						}
						else if(nowbtnNum===1)
						{
							hoverBg.css({left:'300px'}).css({width:'150px'});
						}
						else if(nowbtnNum===2)
						{
							hoverBg.css({left:'467px'}).css({width:'116px'});
						}
						else if(nowbtnNum===3)
						{
							hoverBg.css({left:'624px'}).css({width:'102px'});
						}
						else if(nowbtnNum===4)
						{
							hoverBg.css({left:'774px'}).css({width:'102px'});
						}
						else if(nowbtnNum===5)
						{
							hoverBg.css({left:'924px'}).css({width:'102px'});
						};

					});
					
				}
			}).resize();

			//셀렉트 박스 이벤트
			let sms;
			let email;

			$(sms_add_smsRed.current).find('input').on('click',function()
			{
				sms=$(sms_add_smsRed.current).find('input[name=sms]:checked').val();
				if (sms === "smsOk")
				{
					setsmsRecive(1)
				}
				else
				{
					setsmsRecive(0)
				}
				
				// console.log(sms);
			});

			$(sms_add_emailRed.current).find('input').on('click',function()
			{
				email=$(sms_add_emailRed.current).find('input[name=email]:checked').val();
				if (email === "emailOk")
				{
					setEmailRecive(1)
				}
				else
				{
					setEmailRecive(0)
				}

				// console.log(email);
			});

		});

		// console.log("dispatch userSelect");
		// console.log("authState.user.email : ", authState.user.email);

		if (authState?.user?.email !== null && authState?.user?.email !== undefined)
		{
			dispatch(userSelect(authState.user.email))
			.then(data => 
			{
				// console.log("data : ", data);
	
				setEmail(data.mem_email);
				setName(data.mem_name);
				setNickname(data.mem_nickname);
				setPhone(data.mem_phone);
				setType(data.mem_type);
				setsmsRecive(data.mem_recive_sms);
				setEmailRecive(data.mem_recive_email);
	
				defaultSNS(data.mem_recive_sms);
				defaultEmail(data.mem_recive_email);
	
				// 법인정보 추가
				setCompanyName(data.mg_name);
				setBizCode(data.mg_biz_code);
				setCompanyPhone(data.mg_phone);
				setCompanyFax(data.mg_fax);
				setCompanyType(data.mg_type);
				setAddrPart1(data.mg_addr_part1);
				setFullAddress(data.mg_addr_part1)
				setAddrPart2(data.mg_addr_part2);
				setAddrDetail(data.mg_addr_detail);
				setPlusAddress(data.mg_addr_detail);
				setAddrSinm(data.mg_addr_sinm);
				setAddrSggnm(data.mg_addr_sggnm);
				setAddrEmdnm(data.mg_addr_emdnm);
				setZoneCode(data.mg_addr_zip);
				setStatus(data.mg_status);
				setPrdIdx(data.prd_idx)
			})
			.catch(e => {
				console.log(e);
			});
		}

	}, [$, dispatch]);

	const defaultSNS = (sns) => 
	{
		if (sns)
		{
			$(sms_add_smsRed.current).find(`.${styles.radio_btn_area}`).find(":radio[name='sms'][value='smsOk']").attr('checked', true);
		}
		else
		{
			$(sms_add_smsRed.current).find(`.${styles.radio_btn_area}`).find(":radio[name='sms'][value='smsNo']").attr('checked', true);
		}
	}

	const defaultEmail = (email) => 
	{
		if (email)
		{
			$(sms_add_emailRed.current).find(`.${styles.radio_btn_area}`).find(":radio[name='email'][value='emailOk']").attr('checked', true);
		}
		else
		{
			$(sms_add_emailRed.current).find(`.${styles.radio_btn_area}`).find(":radio[name='email'][value='emailNo']").attr('checked', true);
		}
	}

	const goURL = (event, url) => 
	{
		window.location.href = url;
	}

	const onReadyClick = () => 
	{
		// alert("서비스 중비중입니다.");
		alert(`${t("Common.service_wait")}`);
	};

  return (
    <>
      	{/* Header 분리됨 MainLayout Header에 있음 */}

		<div className={styles.mypage_top_line_banner}>
			<div className={styles.inner}>
				<span>{/* 학부모 페이지 */}{t('Common.Mypage_title2')}</span>
			</div>
		</div>
		<div className={styles.mypage_tab_btn_area} ref={mypage_tab_btn_areaRef}>
			<div className={styles.inner}>
				{/* <ul>
					<li className={styles.hover_bg} ref={hover_bgRef}></li>
					<li><a href="/GroupUserMypage/GroupReport">학습 리포트</a></li>
					<li><a href="/GroupUserMypage/GroupStudentInfo">학습자 정보/관리</a></li>
					<li className={styles.on} ref={onRef}><a href="/GroupUserMypage/GroupParentsInfo">학부모 정보</a></li>
					<li><a href="/GroupUserMypage/GroupPayInfo">결제 정보</a></li>
					<li><a href="/GroupUserMypage/GroupCouponInfo">쿠폰 관리</a></li>
					<li><a href="/GroupUserMypage/GroupQnaInfo">문의 내역</a></li>
				</ul> */}
				<ul>
					<li className={styles.hover_bg} ref={hover_bgRef}></li>
					<li><a href="/GroupUserMypage/GroupReport">{/* 클래스 관리 */}{t('Common.Group_SubMenu.menu1')}</a></li>
					<li className={styles.on} ref={onRef}><a href="/GroupUserMypage/GroupParentsInfo">{/* 관리자 정보 */}{t('Common.Group_SubMenu.menu2')}</a></li>
					{/* <li><a href="/GroupUserMypage/GroupPayInfo"> */}{/* 결제 관리 */}{/*{t('Common.Group_SubMenu.menu3')} </a></li> */}
					{ language === null || language === 'ko' ? <li><a href="/GroupUserMypage/GroupPayInfo">{/* 결제 관리 */}{t('Common.Group_SubMenu.menu3')}</a></li> : "" }
					<li><a href="/GroupUserMypage/GroupCouponInfo">{/* 쿠폰관리 */}{t('Common.Group_SubMenu.menu4')}</a></li>
					{/* <li><a href="/GroupUserMypage/GroupStudentInfo">공지사항</a></li>
					<li><a href="/GroupUserMypage/GroupQnaInfo">질문과 답변</a></li> */}
					<li><a href="/GroupUserMypage/GroupQnaInfo">{/* 문의 내역 */}{t('Common.SubMenu.menu6')}</a></li>
					<li><a href="#!" onClick={()=>onReadyClick()}>{/* 공지사항 */}{t('Common.Group_SubMenu.menu5')}</a></li>
					<li><a href="#!" onClick={()=>onReadyClick()}>{/* 질문과 답변 */}{t('Common.Group_SubMenu.menu6')}</a></li>
				</ul>
			</div>
		</div>
		<div className={`${styles.mypage_tab} ${styles.mypage_parents_info}`}>
			<div className={styles.inner}>
				<span className={styles.my_tit}>{/* 학부모 정보 */}{t('MypageParentsInfo.title2')}</span>
				<div className={styles.parents_info_area}>
					 <ul>
						<li>
							<span className={styles.head}>{/* 이메일 주소 */}{t('MypageParentsInfo.string1')}</span>
							<div className={styles.right_area}>
								<p>{email || ""}</p>
							</div>
						</li>
						<li>
							<span className={styles.head}>{/* 이름 */}{t('MypageParentsInfo.string2')}</span>
							<div className={styles.right_area}>
								<p>{name || ""}</p>
							</div>
						</li>
						{/* <li className={styles.type1}>
							<span className={styles.head}>닉네임</span>
							<div className={styles.right_area}>
								<input type="text" placeholder="닉네임" maxLength='7' id={styles.parents_info_nikname} value={nickname || ""}  name="nickname" onChange={handleInputChange} />
								<p className={styles.info}>* 닉네임은 7자까지 입력 가능합니다.</p>
							</div>
						</li> */}
						<li className={styles.phone_number}>
							<span className={styles.head}>{/* 휴대폰번호 */}{t('MypageParentsInfo.string3')}</span>
							<div className={styles.right_area}>
								<input type="text" /* placeholder="휴대폰 번호가 없습니다" */ placeholder={t('MypageParentsInfo.string4')} id={styles.parents_info_phone_number} value={phone || ""}  name="phone" onChange={handleInputChange} />
								{/* <button type="button">휴대폰번호 변경</button> */}
							</div>
						</li>
						<li className={styles.type1}>
							<span className={styles.head}>{/* 비밀번호 */}{t('MypageParentsInfo.string5')}</span>
							<div className={styles.right_area}>
								<input type="password" /* placeholder="비밀번호" */ placeholder={t('MypageParentsInfo.string5')} id={styles.parents_info_password}  name="password" onChange={handleInputChange} maxLength={100} />
								<p className={styles.info}>{/* * 비밀번호는 영문, 숫자, 특수문자 조합으로 8~20자리를 권장합니다 */}{t('MypageParentsInfo.string6')}</p>
							</div>
						</li>
						<li className={styles.base}>
							<span className={styles.head}>{/* 비밀번호 확인 */}{t('MypageParentsInfo.string7')}</span>
							<div className={styles.right_area}>
								<input type="password" /* placeholder="비밀번호 확인" */ placeholder={t('MypageParentsInfo.string7')} id={styles.parents_info_password_confirm} name="repassword" onChange={handleInputChange} maxLength={100} />
							</div>
						</li>
						{
							type === 4 && 
							<>
								<li className={styles.base}>
									<span className={styles.head}>{/* 회사 이름 */}{t('Auth.common.company_name')}</span>
									<div className={styles.right_area}>
										<input type="text" /* placeholder="회사 이름" */ placeholder={t('Auth.common.company_name')} id="parents_info_company_name" name="companyName" value={companyName || ""} onChange={handleInputChange} />
									</div>
								</li>
								<li className={styles.base}>
									<span className={styles.head}>{/* 사업자번호 */}{t('Auth.common.biz_code')}</span>
									<div className={styles.right_area}>
										<input type="text" /* placeholder="사업자번호" */ placeholder={t('Auth.common.biz_code')} id="parents_info_company_number" name="bizCode" value={bizCode || ""} onChange={handleInputChange} />
									</div>
								</li>
								<li className={styles.base}>
									<span className={styles.head}>{/* 전화번호 */}{t('Auth.common.phone_number')}</span>
									<div className={styles.right_area}>
										<input type="text" /* placeholder="전화번호" */ placeholder={t('Auth.common.phone_number')} id="parents_info_company_tel" name="companyPhone" value={companyPhone || ""} onChange={handleInputChange} />
									</div>
								</li>
								<li className={styles.base}>
									<span className={styles.head}>{/* 팩스번호 */}{t('Auth.common.fax_number')}</span>
									<div className={styles.right_area}>
										<input type="text" /* placeholder="팩스번호" */ placeholder={t('Auth.common.fax_number')} id="parents_info_fax" name="companyFax" value={companyFax || ""} onChange={handleInputChange} />
									</div>
								</li>
								<li className={styles.base}>
									<span className={styles.head}>{/* 법인형태 */}{t('Auth.common.company_type')}</span>
									<div className={styles.right_area}>
										<select name="companyType" id={styles.parents_info_company_type} value={companyType} onChange={handleInputChange}>
											<option value="0" disabled>{/* 선택 */}{t('Auth.common.select')}</option>
											<option value="1">{/* 개인사업자 */}{t('Auth.common.company_type1')}</option>
											<option value="2">{/* 영리법인 */}{t('Auth.common.company_type2')}</option>
											<option value="3">{/* 비영리법인 */}{t('Auth.common.company_type3')}</option>
											<option value="4">{/* 기타공식단체 */}{t('Auth.common.company_type4')}</option>
											<option value="5">{/* 비공식단체 */}{t('Auth.common.company_type5')}</option>
										</select>
									</div>
								</li>
								{
									language === 'ko' || language === '' ? (
								<li className={styles.adress_area}>
									<span className={styles.head}>{/* 주소 */}{t('Auth.common.address')}</span>
									<div className={styles.right_area}>
										<div className={styles.top_area}>
											<input type="text" id={styles.parents_info_adress_number} name="zoneCode" value={zoneCode} onChange={handleInputChange} />
											<button type="button" onClick={openPostCode}>{/* 우편번호 검색 */}{t('Auth.common.postal_code_search')}</button>
											{/* 팝업 생성 기준 div */}
											<div id='popupDom'>
											{ isPopupOpen && (
												<PopupDom>
													<PopupPostCode onClose={closePostCode} 
													setAddressFun={setAddressFun}
													/>
												</PopupDom>
											)}
											</div>
										</div>
										<input type="text" /* placeholder="기본주소" */ placeholder={t('Auth.common.address')} id={styles.parents_info_adress} name="fullAddress" value={fullAddress}  onChange={handleInputChange} />
										<input type="text" /* placeholder="상세주소" */ placeholder={t('Auth.common.detailed_address')} id={styles.parents_info_adress_more} name="plusAddress" value={plusAddress} onChange={handleInputChange} />
									</div>
								</li>

									)
									:
									(
								<li className={styles.adress_area}>
									<span className={styles.head}>{/* 주소 */}{t('Auth.common.address')}</span>
									<div className={styles.right_area}>
										<div className={styles.top_area}>
											<input type="text" id={styles.parents_info_adress_number} name="zoneCode" value={zoneCode} onChange={handleInputChange} />
										</div>
										<input type="text" /* placeholder="기본주소" */ placeholder={t('Auth.common.address')} id={styles.parents_info_adress} name="fullAddress" value={fullAddress}  onChange={handleInputChange} />
										<input type="text" /* placeholder="상세주소" */ placeholder={t('Auth.common.detailed_address')} id={styles.parents_info_adress_more} name="plusAddress" value={plusAddress} onChange={handleInputChange} />
									</div>
								</li>
		
									)
								}
								
							</>
						}
						<li className={`${styles.sms_add} ${styles.sms}`} ref={sms_add_smsRed}>
							<span className={styles.head}>{/* SMS 수신여부 (선택) */}{t('MypageParentsInfo.string8')}</span>
							<div className={styles.right_area}>
								<div className={styles.top_area}>
									<div className={styles.radio_btn_area}>
										<input type="radio" name="sms" value="smsOk" defaultChecked />
										<span>{/* 수신 */}{t('MypageParentsInfo.string9')}</span>
									</div>
									<div className={styles.radio_btn_area}>
										<input type="radio" name="sms" value="smsNo"/>
										<span>{/* 수신거부 */}{t('MypageParentsInfo.string10')}</span>
									</div>
								</div>
								<p className={styles.info}>{/* * 학습리포트 발행소식, 업데이트, 이벤트 참여와 관련된 소식을 받아 보겠습니다. */}{t('MypageParentsInfo.string11')}</p>
							</div>
						</li>
						<li className={`${styles.sms_add} ${styles.email}`} ref={sms_add_emailRed}>
							<span className={styles.head}>{/* 이메일 수신여부 (선택) */}{t('MypageParentsInfo.string12')}</span>
							<div className={styles.right_area}>
								<div className={styles.top_area}>
									<div className={styles.radio_btn_area}>
										<input type="radio" name="email" value="emailOk" defaultChecked />
										<span>{/* 수신 */}{t('MypageParentsInfo.string9')}</span>
									</div>
									<div className={styles.radio_btn_area}>
										<input type="radio" name="email" value="emailNo"/>
										<span>{/* 수신거부 */}{t('MypageParentsInfo.string10')}</span>
									</div>
								</div>
								<p className={styles.info}>{/* * 학습리포트 발행소식, 업데이트, 이벤트 참여와 관련된 소식을 받아 보겠습니다. */}{t('MypageParentsInfo.string11')}</p>
							</div>
						</li>
					 </ul>
					 <div className={styles.btn_area}>
						<button onClick={(e)=>goURL(e, "/GroupUserMypage/GroupReport")}>{/* 취소 */}{t('Common.cancel')}</button>
						<button onClick={handleSubmit} type="submit">{/* 수정 */}{t('Common.confirm')}</button>
					 </div>
					 <div className={styles.btn_area}>
						<MemberLeaveDialog />
					 </div>
				</div>
			</div>{/* inner */}
		</div>{/* mypage_tab */}
		
		{/* Footer 분리됨 MainLayout Footer에 있음 */}
    </>
  );
}