import * as React from 'react';
import styles from "../../css/common.module.css";

import Grid from '@mui/material/Grid';
import { useState } from "react";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from 'react-router-dom';
import UseCommon_ko from '../../common/UseCommon_ko';
import UseCommon_en from '../../common/UseCommon_en';
import UseCommon_jp from '../../common/UseCommon_jp';
import PrivacyCommon_ko from '../../common/PrivacyCommon_ko';
import PrivacyCommon_en from '../../common/PrivacyCommon_en';
import PrivacyCommon_jp from '../../common/PrivacyCommon_jp';

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

export default function RegisterAgree() {

    const navigate = useNavigate();

    const [checkBox, setCheckBox] = useState({agreement1: false, agreement2: false});

    const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

    let language = localStorage.getItem("language");

    // 체크박스 체크함수
    const handleCheckBoxChange = (event) => {
        setCheckBox({
        ...checkBox,
        [event.target.name]: event.target.checked,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    
        if (!checkBox.agreement1)
        {
            // alert("회원가입을 위해 서비스 이용약관의 동의가 필요합니다. \n약관을 읽고 체크해주세요.");
            return alert(`${t("Auth.alert.string3")}`);
            return;
        }

        if (!checkBox.agreement2)
        {
            // alert("회원가입을 위해 개인정보처리방침의 동의가 필요합니다. \n방침을 읽고 체크해주세요.");
            return alert(`${t("Auth.alert.string4")}`);
            return;
        }

        navigate("/auth/register");
    };

    return (
        <Grid container component={styles.main} sx={{ height: '100vh' }}>
            <div className={`${styles.login_bg_wrapper} ${styles.join_agree_bg_wrapper}`}>
                <div className={`${styles.join_agree_area} ${styles.middle}`}>
                    <a href="/" className={styles.agree_logo}>
                        {/* <img src="/assets/logo@2x.png" alt="logo"/> */}
                        {
                            { 
                                'ko' : <img src="/assets/logo@2x.png" alt="logo"/>,
                                'en' : <img src="/assets/logo@2x_en.png" alt="logo"/>,
                                'jp' : <img src="/assets/logo@2x_jp.png" alt="logo"/>
                            }[language]
                        }
                    </a>
                    <span className={styles.login_title}>{/* 야미코딩 약관동의 */}{t('Auth.agree')}</span>
                    <div className={styles.join_agree_wrap}>
                        <span className={styles.sub_tit}>{/* 서비스 이용 약관 */}{t('Auth.agree_string1')}</span>
                        <div className={styles.agree_use_wrap}>
                            <div>
                                <span className={styles.tit}>{/* 서비스 이용약관 */}{t('Auth.agree_string2')}</span>
                                {(() => {
                                    switch (language) {
                                    case "ko":
                                        return <UseCommon_ko />;
                                    case "en":
                                        return <UseCommon_en />;
                                    case "jp":
                                        return <UseCommon_jp />;
                                    default:
                                        return <UseCommon_ko />;
                                    }
                                })()}
                            </div>
                        </div>
                        <div className={styles.check_wrap}>
                            {/* <input type="checkbox"/>
                            <p className={styles.continue}>동의합니다</p> */}
                            <FormControlLabel
                                control={<Checkbox value="allowExtraEmails" color="primary" onChange={handleCheckBoxChange} name="agreement1" />}
                                // label="동의합니다."
                                label={t('Auth.agree_string3')}
                            />
                        </div>
                        <span className={styles.sub_tit}>{/* 개인정보 처리방침 */}{t('Auth.agree_string4')}</span>
                        <div className={`${styles.agree_use_wrap} ${styles.agree_privacy_wrap}`}>
                            <div>
                                <span className={styles.tit}>{/* 개인정보처리방침 */}{t('Auth.agree_string5')}</span>
                                {(() => {
                                    switch (language) {
                                    case "ko":
                                        return <PrivacyCommon_ko />;
                                    case "en":
                                        return <PrivacyCommon_en />;
                                    case "jp":
                                        return <PrivacyCommon_jp />;
                                    default:
                                        return <PrivacyCommon_ko />;
                                    }
                                })()}
                            </div>
                        </div>
                        <div className={styles.check_wrap}>
                            <FormControlLabel
                                control={<Checkbox value="allowExtraEmails" color="primary" onChange={handleCheckBoxChange} name="agreement2" />}
                                // label="동의합니다."
                                label={t('Auth.agree_string3')}
                            />
                            {/* <p className={styles.continue}>동의합니다</p> */}
                        </div>
                        <div className={styles.agree_btn}>
                            <a href="/" className={styles.agree_back}>{/* 취소 */}{t('Common.cancel')}</a>
                            <a href="#" className={styles.agree_continue} onClick={handleSubmit}>{/* 계속 */}{t('Common.continue')}</a>
                        </div>
                    </div>
                </div>
            </div>
        </Grid>
    );
}