import * as React from 'react';
import { useEffect } from 'react';
import styles from "../css/common.module.css";
import $ from 'jquery';
import PrivacyCommon_ko from '../common/PrivacyCommon_ko';
import PrivacyCommon_en from '../common/PrivacyCommon_en';
import PrivacyCommon_jp from '../common/PrivacyCommon_jp';

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

export default function Use() {

	let language = localStorage.getItem("language");

	const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

	useEffect(() => {

		// 여기에서 jQuery를 초기화 합니다.
		$(document).ready(function(){

		});

	}, [$]);

  	return (
    <>
		<div id={styles.content}>
			<div className={styles.inner}>
				<span className={styles.index_title}>{/* 개인정보처리방침 */}{t("Common.Personal_information_Processing_Policy")}</span>
				{(() => {
					switch (language) {
					case "ko":
						return <PrivacyCommon_ko />;
					case "en":
						return <PrivacyCommon_en />;
					case "jp":
						return <PrivacyCommon_jp />;
					default:
						return <PrivacyCommon_ko />;
					}
				})()}
				<div className={styles.to_top}>
					<a href="/Privacy">Top</a>
				</div>
			</div>
		</div>
    </>
  );
}