import * as React from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import styles from "../css/common.module.css";
import $ from 'jquery';

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

export default function Main() {

	const authState = useSelector(state => state.auth);
    // console.log("authState : ", authState);

	const dispatch = useDispatch();	

	const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

	let language = localStorage.getItem("language");

	useEffect(() => {

		// 여기에서 jQuery를 초기화 합니다.
		$(document).ready(function(){

			// console.log("jquery ready!");

		});

	}, [$]);

	const downloadLink = () => {
		
		// 브라우저가 사용된 운영체제 정보
		// console.log(navigator.platform);
		// 브라우저가 실행된 운영체제 정보
		// console.log(navigator.userAgent);

		if (navigator.userAgent.indexOf('Windows') > -1)
		{
			// console.log('윈도우');
			window.open('about:blank').location.href = 'https://play.google.com/store/apps/details?id=com.Weclover.YamE_PackageVersion';
		}
		else if (navigator.userAgent.indexOf('Macintosh') > -1)
		{
			// console.log('맥킨토시');
			// window.open('about:blank').location.href = 'https://www.apple.com/kr/app-store/';
			// alert("ios는 출시 준비중입니다. 안드로이드는 위에 다운로드하기를 클릭하세요.");
			// window.open('about:blank').location.href = 'https://apps.apple.com/kr/app/%EC%95%BC%EB%AF%B8-%EC%BD%94%EB%94%A9-3d%EC%BD%94%EB%94%A9%EA%B5%90%EC%9C%A1-%EC%84%9C%EB%B9%84%EC%8A%A4/id1631781495';
			window.open('about:blank').location.href = 'https://apps.apple.com/us/app/yam-e-coding/id1631781495';
			// window.open('about:blank').location.href = 'https://play.google.com/store/apps/details?id=com.Weclover.YamE_PackageVersion';
		}
		else if (navigator.userAgent.indexOf('iPhone') > -1)
		{
			// console.log('아이폰');
			// window.open('about:blank').location.href = 'https://www.apple.com/kr/app-store/';
			// alert("ios는 출시 준비중입니다. 안드로이드는 위에 다운로드하기를 클릭하세요.");
			// window.open('about:blank').location.href = 'https://apps.apple.com/kr/app/%EC%95%BC%EB%AF%B8-%EC%BD%94%EB%94%A9-3d%EC%BD%94%EB%94%A9%EA%B5%90%EC%9C%A1-%EC%84%9C%EB%B9%84%EC%8A%A4/id1631781495';
			window.open('about:blank').location.href = 'https://apps.apple.com/us/app/yam-e-coding/id1631781495';
			// window.open('about:blank').location.href = 'https://play.google.com/store/apps/details?id=com.Weclover.YamE_PackageVersion';
		}
		else if (navigator.userAgent.indexOf('Android') > -1)
		{
			// console.log('안드로이드');
			window.open('about:blank').location.href = 'https://play.google.com/store/apps/details?id=com.Weclover.YamE_PackageVersion';
		}
		else
		{
			// console.log('기타 os');
			window.open('about:blank').location.href = 'https://play.google.com/store/apps/details?id=com.Weclover.YamE_PackageVersion';
		}
	};

	const redayFunction = () =>
	{
		// alert("ios는 출시 준비중입니다.");
		alert(`${t("Download.alert.string1")}`);
	}

  return (
    <>
      	{/* Header 분리됨 MainLayout Header에 있음 */}

		  <div className={styles.download_area}>
			<div className={styles.download_top_line_banner}>
				<div className={styles.inner}>
					<span>{/* 야미 다운로드 */}{t("Download.title")}</span>
				</div>
			</div>
			<div className={styles.download_wrap}>
				<div className={styles.top_top_area}>
					<div className={styles.left_area}>
						<div className={styles.download_title_wrap}>
							<span>{/* 언제 어디서나 즐겁게 코딩! */}{t("Download.string1")}</span>
							{/* <img src="/assets/logo@2x.png" alt="logo" /> */}
							{
								{ 
									'ko' : <img src="/assets/logo@2x.png" alt="logo"/>,
									'en' : <img src="/assets/logo@2x_en.png" alt="logo"/>,
									'jp' : <img src="/assets/logo@2x_jp.png" alt="logo"/>
								}[language]
							}
						</div>
						<p className={styles.sub_title}>{/* Full 3D 야미코딩의 세계로 어서오세요. */}{t("Download.string2")}</p>
						<p>{/* 그동안 경험해보지 못한 <br/>코딩블록의 새로운 개념을 <br/>체험판을 통해 느껴보세요! */}
						<Trans i18nKey="Download.string3"/></p>
						<div className={styles.down_link_area}>
							<a href="https://play.google.com/store/apps/details?id=com.Weclover.YamE_PackageVersion" target="_blank" rel='noopener noreferrer'>
								{/* <img src="/assets/down_google.png" alt="google"/> */}
								{
									{ 
										'ko' : <img src="/assets/down_google.png" alt="google"/>,
										'en' : <img src="/assets/down_google_en.png" alt="google"/>,
										'jp' : <img src="/assets/down_google_jp.png" alt="google"/>
									}[language]
								}
							</a>&nbsp;        
							{/* <a href="javascript:alert('출시준비중입니다.')" target="_blank" rel='noopener noreferrer'><img src="/assets/down_apple@2x.png" alt="apple"/></a> */}
							{/* <a href="#!" onClick={redayFunction} className={styles.apple}><img src="/assets/down_apple@2x.png" alt="apple"/></a> */}
							{/* <a href="https://apps.apple.com/kr/app/%EC%95%BC%EB%AF%B8-%EC%BD%94%EB%94%A9-3d%EC%BD%94%EB%94%A9%EA%B5%90%EC%9C%A1-%EC%84%9C%EB%B9%84%EC%8A%A4/id1631781495" target="_blank" rel='noopener noreferrer'><img src="/assets/down_apple.png" alt="apple"/></a> */}
							<a href="https://apps.apple.com/us/app/yam-e-coding/id1631781495" target="_blank" rel='noopener noreferrer'>
								{/* <img src="/assets/down_apple.png" alt="apple"/> */}
								{
									{ 
										'ko' : <img src="/assets/down_apple.png" alt="apple"/>,
										'en' : <img src="/assets/down_apple_en.png" alt="apple"/>,
										'jp' : <img src="/assets/down_apple_jp.png" alt="apple"/>
									}[language]
								}
							</a>
						</div>
						<div className={styles.down_link_area}>
							<a href="https://wecloveryame.s3.ap-northeast-2.amazonaws.com/YameCodingSetup_1.1.26.exe" download>
								PC Version Download
							</a>
						</div>
						<div className={styles.down_link_area}>
							PC Version은 학부모 계정의 소셜 로그인을 지원하지 않습니다. 일반으로 가입하신 고객님들만 로그인이 됩니다. (이메일 로그인) 다만 학습자는 모두 로그인 됩니다. 학습자로 로그인하세요.
						</div>
					</div>
					<div className={styles.right_area}>
						<div className={styles.top_area}>
							<img src="/assets/download_img1@2x.png" alt="img1"/>
						</div>
						<div className={styles.bottom_area}>
							<img src="/assets/download_img2@2x.png" alt="img2"/>
							<img src="/assets/download_img3@2x.png" alt="img3"/>
							<img src="/assets/download_img4@2x.png" alt="img4"/>
							<img src="/assets/download_img5@2x.png" alt="img5"/>
							<img src="/assets/download_img6@2x.png" alt="img6"/>
							<img src="/assets/download_img7@2x.png" alt="img7"/>
						</div>
					</div>
				</div>
				<div className={styles.btn_area}>
					<button type="button" onClick={downloadLink}>{/* 다운로드 */}{t("Download.string4")}</button>
				</div>
			</div>
		</div>{/* download_area */}
		
		{/* Footer 분리됨 MainLayout Footer에 있음 */}
    </>
  );
}