import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';

import Divider from '@mui/material/Divider';

import { useCallback, useState } from 'react';

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';

import { useDispatch, useSelector } from "react-redux";
import { classManagementListAll, classManagementSet, classManagementStatusGet, classManagementStatusSet, classManagementSavedListAll } from "../../actions/class";
import { missionCategoryList } from "../../actions/mission_category";

import * as common from "../../lib";

import YameLoading from '../../common/YameLoding';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const AntSwitch = styled(Switch)(({ theme }) => ({
	width: 28,
	height: 16,
	padding: 0,
	display: 'flex',
	'&:active': {
	  '& .MuiSwitch-thumb': {
		width: 15,
	  },
	  '& .MuiSwitch-switchBase.Mui-checked': {
		transform: 'translateX(9px)',
	  },
	},
	'& .MuiSwitch-switchBase': {
	  padding: 2,
	  '&.Mui-checked': {
		transform: 'translateX(12px)',
		color: '#fff',
		'& + .MuiSwitch-track': {
		  opacity: 1,
		  backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
		},
	  },
	},
	'& .MuiSwitch-thumb': {
	  boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
	  width: 12,
	  height: 12,
	  borderRadius: 6,
	  transition: theme.transitions.create(['width'], {
		duration: 200,
	  }),
	},
	'& .MuiSwitch-track': {
	  borderRadius: 16 / 2,
	  opacity: 1,
	  backgroundColor:
		theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
	  boxSizing: 'border-box',
	},
  }));

export default function StudentExcelUploadDialog(props) {
  const [open, setOpen] = React.useState(false);

  // 강제로 재렌더링을 하게 하는 방법
  const [, updateState] = useState();	// 상태변수는 선언하지 않는다.
  const forceUpdate = useCallback(() => updateState({}), []);

  const [checkItems, setCheckItems] = useState([]);
  const [originalcheckItems, setOriginalcheckItems] = useState([]);
  const [categoryIdx, setCategoryIdx] = useState('');
  const [levelValue, setLevelValue] = useState('');

  const [switchState, setSwicthState] = React.useState({
    used: false,
  });

  const [selectDisabled, setSelectDisabled] = useState(true);

  const [display, setDisplay] = useState(false); 
  const [loading, setLoading] = useState(false);

  const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언
  const dispatch = useDispatch();	

  const authState = useSelector(state => state.auth);
  const classManagementList = useSelector(state => state.classReducer.managementList);
// console.log("classManagementList : ", classManagementList);
  const category = useSelector(state => state.mission_category);
// console.log("category : ", category);

  const handleClickOpen = () => {
    setOpen(true);

	setDisplay(true);

	// console.log(props.RowData);

	let sendData = {
		mc_idx:props.RowData.mc_idx,
		category_idx: "",
		level: ""
	}

	// 창이 열릴 때 데이터를 불러온다.
	dispatch(classManagementListAll(sendData))
	.then(data => 
	{
		console.log("classManagementListAll data : ", data);

		let temp = data.list.filter(row => row.mission_active !== 0).map(function(row)
		{
			return row.mis_idx;
		});

		// console.log("temp : ", temp);
		setCheckItems(temp);			// 컨트롤 되는 값들 현재의 값들
		setLoading(true);
	})
	.catch(e => {
		console.log(e);
	});

	let sendData2 = {
		mc_idx:props.RowData.mc_idx,
	}

	// 창이 열릴 때 데이터를 불러온다.
	dispatch(classManagementSavedListAll(sendData2))
	.then(data => 
	{
		// console.log("classManagementSavedListAll data : ", data);

		// 필요한 값들만 추출해서 사용합니다.
		let temp = data.map(function(row)
		{
			return row.mis_idx;
		});
		// console.log("temp : ", temp);
		setOriginalcheckItems(temp);	// 컨트롤 되기 전의 원래의 값들
	})
	.catch(e => {
		console.log(e);
	});

	// 미션 카테고리 데이터 불러오기
	dispatch(missionCategoryList())
	.then(data => 
	{
		// console.log("data : ", data);
	})
	.catch(e => {
		console.log(e);
	});

	// 클래스 수업관리 옵션의 상태정보를 불러와서 셋팅한다.
	dispatch(classManagementStatusGet(props.RowData.mc_idx))
	.then(data => 
	{
		// console.log("data : ", data);
		// 클래스 수업관리 스위치 설정된 값으로 버튼 초기화
		if (data === 0)
		{
			setSwicthState({
				...switchState,
				["used"]: false,
			  });
		}
		else
		{
			setSwicthState({
				...switchState,
				["used"]: true,
			  });
		}
	})
	.catch(e => {
		console.log(e);
	});

  };

  const handleClose = () => {
    setOpen(false);

	setDisplay(false);
	setLoading(false);
  };

  const checkHandled = (e) => {
    // console.log('checkHandled')
    checkItemHandler(e.target.id, e.target.checked);
  }

  const checkItemHandler = (id, isChecked) => {

	let intID = parseInt(id);	// 숫자로 저장해야지 인식합니다. 아니면 문자로 인식해서 includes 함수가 제대로 작동하지 않습니다.

	if (isChecked) 
	{
		setCheckItems((prev) => [...prev, intID]) // 불변성을 지키기 위한 원본 배열을 복사 후 추가
		setOriginalcheckItems((prev) => [...prev, intID]);	// 컨트롤 되기 전의 원래의 값들 (전송을 위한 전체 리스트를 따로 관리합니다.)
	} 
	else 
	{
		// console.log('not')
		setCheckItems(checkItems.filter((item) => item !== intID)) // 현재 checkItems의 배열에서 해당 id를 제외한 새로운 배열 반환
		setOriginalcheckItems(originalcheckItems.filter((item) => item !== intID));	// 컨트롤 되기 전의 원래의 값들 (전송을 위한 전체 리스트를 따로 관리합니다.)
	}
	//   console.log(checkItems);
	//   console.log(originalcheckItems);
  }

  // 체크박스 전체 선택
  const handleAllCheck = (checked) => {

	const idArray = [];
	classManagementList.forEach((el) => idArray.push(el.mis_idx));

	let originCheckItemCopy = originalcheckItems;

    if(checked) 
	{
      // 전체 선택 클릭 시 데이터의 모든 아이템(id)를 담은 배열로 checkItems 상태 업데이트  
      setCheckItems(idArray);
	  for (let i=0; i<idArray.length; i++)
	  {
		originCheckItemCopy.push(idArray[i]);
	  }

	  // 중복제거
		let uniqueArr = [];
		originCheckItemCopy.forEach((element) => {
			if (!uniqueArr.includes(element)) {
				uniqueArr.push(element);
			}
		});

	  setOriginalcheckItems(uniqueArr);
    }
    else {
      // 전체 선택 해제 시 checkItems 를 빈 배열로 상태 업데이트
      setCheckItems([]);
	  for (let i=0; i<idArray.length; i++)
	  {
		originCheckItemCopy = originCheckItemCopy.filter((element) => element !== idArray[i]);
	  }

	  // 중복제거
	  let uniqueArr = [];
		originCheckItemCopy.forEach((element) => {
			if (!uniqueArr.includes(element)) {
				uniqueArr.push(element);
			}
		});

	  setOriginalcheckItems(uniqueArr);
    }

	// console.log("checkItems : ", checkItems);
	// console.log("originalcheckItems : ", originalcheckItems);
	// console.log("idArray : ", idArray);
  }

  const handleMissionSetting = (event) => {

	event.preventDefault();

	// console.log(checkItems);
	// console.log(originalcheckItems);
  
      const SendJaon = {
        mc_idx: props.RowData.mc_idx,
        mission: originalcheckItems
      }
  
    //   console.log("SendJaon : ", SendJaon);

      // 여기서 디스패치로~  
      dispatch(classManagementSet(SendJaon))
      .then(data => 
      {
        //   console.log("data : ", data);

		  if (data.success)
		  {
			let sendData = {
				mc_idx:props.RowData.mc_idx,
				category_idx: categoryIdx === "" ? "" : categoryIdx,
				level: levelValue === "" ? "" : levelValue,
			}

			// console.log("sendData : ", sendData);
		
			// 창이 열릴 때 데이터를 불러온다.
			dispatch(classManagementListAll(sendData))
			.then(data => 
			{
				// console.log("classManagementListAll data : ", data);
				let temp = data.list.filter(row => row.mission_active !== 0).map(function(row)
				{
					return row.mis_idx;
				});

				// console.log(temp);
				setCheckItems(temp);
			})
			.catch(e => {
				console.log(e);
			});

			let sendData2 = {
				mc_idx:props.RowData.mc_idx,
			}
		
			// 창이 열릴 때 데이터를 불러온다.
			dispatch(classManagementSavedListAll(sendData2))
			.then(data => 
			{
				// console.log("classManagementSavedListAll data : ", data);
		
				// 필요한 값들만 추출해서 사용합니다.
				let temp = data.map(function(row)
				{
					return row.mis_idx;
				});
				// console.log("temp : ", temp);
				setOriginalcheckItems(temp);	// 컨트롤 되기 전의 원래의 값들
			})
			.catch(e => {
				console.log(e);
			});

		  }
      })
      .catch(e => {
          console.log(e);
      });
  };

  const handleInputChange = event => {

	const { name, value } = event.target;
	setCategoryIdx(value);

	// 튜토리얼, 스페셜, 체험판은 레벨이 없으므로 레벨을 비활성화 시킵니다.
	if (value === 1 || value === 5 || value === 6)
	{
		setSelectDisabled(true);
		setLevelValue("");
	}
	else
	{
		setSelectDisabled(false);
	}
  };

  const onSearchList = (event) => {

	event.preventDefault();

	let sendData = {
		mc_idx:props.RowData.mc_idx,
		category_idx: categoryIdx,
		level: levelValue,
	}

	// 창이 열릴 때 데이터를 불러온다. (화면에 표시되는 데이터를 편집하기 위한 데이터)
	dispatch(classManagementListAll(sendData))
	.then(data => 
	{
		// console.log("classManagementListAll data : ", data);
		let temp = data.list.filter(row => row.mission_active !== 0).map(function(row)
		{
			return row.mis_idx;
		});

		// console.log(temp);
		setCheckItems(temp);
	})
	.catch(e => {
		console.log(e);
	});

	let sendData2 = {
		mc_idx:props.RowData.mc_idx,
	}

	// 창이 열릴 때 데이터를 불러온다.
	dispatch(classManagementSavedListAll(sendData2))
	.then(data => 
	{
		// console.log("classManagementSavedListAll data : ", data);

		// 필요한 값들만 추출해서 사용합니다.
		let temp = data.map(function(row)
		{
			return row.mis_idx;
		});
		// console.log("temp : ", temp);
		setOriginalcheckItems(temp);	// 컨트롤 되기 전의 원래의 값들
	})
	.catch(e => {
		console.log(e);
	});

	// 실제 전송을 위한 데이터들
  
  }

  const onSearchInit = (event) => {

	event.preventDefault();

	setCategoryIdx("");
	setLevelValue("");

	// console.log("onSearchInit call");

	let sendData = {
		mc_idx:props.RowData.mc_idx,
		category_idx: "",
		level: ""
	}

	// 창이 열릴 때 데이터를 불러온다.
	dispatch(classManagementListAll(sendData))
	.then(data => 
	{
		// console.log("classManagementListAll data : ", data);
		let temp = data.list.filter(row => row.mission_active !== 0).map(function(row)
		{
			return row.mis_idx;
		});

		// console.log(temp);
		setCheckItems(temp);
	})
	.catch(e => {
		console.log(e);
	});

	let sendData2 = {
		mc_idx:props.RowData.mc_idx,
	}

	// 창이 열릴 때 데이터를 불러온다.
	dispatch(classManagementSavedListAll(sendData2))
	.then(data => 
	{
		// console.log("classManagementSavedListAll data : ", data);

		// 필요한 값들만 추출해서 사용합니다.
		let temp = data.map(function(row)
		{
			return row.mis_idx;
		});
		// console.log("temp : ", temp);
		setOriginalcheckItems(temp);	// 컨트롤 되기 전의 원래의 값들
	})
	.catch(e => {
		console.log(e);
	});

  }

  const onLevelValueChange = (event) => {
	setLevelValue(event.target.value);
  }

  const handleSwitchChange = (event) => {
	// console.log(event.target.name);
    setSwicthState({
      ...switchState,
      [event.target.name]: event.target.checked,
    });

	let sendData = {
		mc_idx:props.RowData.mc_idx,
		mc_management_status: event.target.checked === false ? 0 : 1,
	}

	// 창이 열릴 때 데이터를 불러온다.
	dispatch(classManagementStatusSet(sendData))
	.then(data => 
	{
		// console.log("classManagementStatusSet data : ", data);
	})
	.catch(e => {
		console.log(e);
	});
  };

  return (

	<>
	{/* 로딩화면 */}
	<YameLoading display={display} loadingValue={loading} />

    <div>
      <Button variant="outlined" style={{ textTransform: 'none' }} onClick={handleClickOpen}>{/* 관리 */}{t('ClassManagement.string1')}</Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
		maxWidth="lg"
		fullWidth={true}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {/* 수업관리 */}{t('ClassManagement.title1')}
		  <Typography gutterBottom sx={{fontSize:15, marginTop: 2}}>

		  	<Box sx={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center'
				}}
			>
				<Box sx={{ minWidth: 140, width: 140, marginRight: 2}}>

				<FormControl fullWidth>
					<InputLabel id="category_idx">{/* 카테고리 */}{t('ClassManagement.string2')}</InputLabel>
					<Select
					labelId="category_idx"
					id="category_idx"
					name="category_idx"
					value={categoryIdx || ""}
					onChange={handleInputChange}
					// label="카테고리"
					label={t('ClassManagement.string2')}
					>
					<MenuItem value="" selected disabled>{/* 선택하세요 */}{t('ClassManagement.string3')}</MenuItem>
					{category && category.filter((x)=> x.mis_category_name!=="체험판").map((item, index) => (
					<MenuItem value={item.mis_category_idx} key={index}>
						{item.mis_category_name ? common.returnCategoryTranslation(item.mis_category_name) : ""}
					</MenuItem>
					))}
					</Select>
				</FormControl>
				
				</Box>

				<Box sx={{ minWidth: 140, width: 140, marginRight: 2}}>
				<FormControl fullWidth>
					<InputLabel id="demo-simple-select-label">{/* 레벨 */}{t('ClassManagement.string4')}</InputLabel>
					<Select
					labelId="classValue"
					id="classValue"
					value={levelValue}
					// label="레벨"
					label={t('ClassManagement.string4')}
					onChange={onLevelValueChange}
					disabled={selectDisabled}
					>
						<MenuItem value="" selected disabled>{/* 선택하세요 */}{t('ClassManagement.string3')}</MenuItem>
						<MenuItem value="B">{/* 초급 */}{t('ClassManagement.string5')}</MenuItem>
						<MenuItem value="M">{/* 중급 */}{t('ClassManagement.string6')}</MenuItem>
						<MenuItem value="H">{/* 고급 */}{t('ClassManagement.string7')}</MenuItem>
					</Select>
				</FormControl>
				</Box>
				<Button variant="contained" style={{ textTransform: 'none' }} size='small' onClick={onSearchList} sx={{marginRight:1}}>{/* 검색 */}{t('ClassManagement.string8')}</Button>
				<Button variant="contained" style={{ textTransform: 'none' }} size='small' onClick={onSearchInit} sx={{marginRight:10}}>{/* 검색 초기화 */}{t('ClassManagement.string9')}</Button>
				
				{/* on / off */}
				<Stack direction="row" spacing={1} alignItems="center">
					{/* 수업관리기능 사용 */}{t('ClassManagement.string10')} &nbsp;&nbsp;
					<Typography>Off</Typography>
					<AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} checked={switchState.used} onChange={handleSwitchChange} name="used" />
					<Typography>On</Typography>
				</Stack>

				<Button variant="outlined" style={{ textTransform: 'none' }} sx={{ marginLeft: 5}} onClick={handleMissionSetting}>{/* 미션 설정 */}{t('ClassManagement.string11')}</Button>
			</Box>

			{/* <Box sx={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center'
				}}
			>
				<Button variant="outlined" onClick={handleMissionSetting}
				sx={{ marginRight: 2}}
				>미션 설정</Button> */}
				{/* on / off */}
				{/* <Stack direction="row" spacing={1} alignItems="center">
					<Typography>Off</Typography>
					<AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} checked={switchState.used} onChange={handleSwitchChange} name="used" />
					<Typography>On</Typography>
				</Stack>
			</Box> */}
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          
        </DialogContent>
		<Box
			component="form"
			sx={{
				'& .MuiTextField-root': { m: 1, width: '670px', marginTop: '20px', marginLeft: '20px', marginRight: '20px' },
			}}
			noValidate
			autoComplete="off"
		>
		<div>
			<TableContainer sx={{ maxHeight: 670 }}>
				<Table stickyHeader aria-label="sticky table" size="small">
				<TableHead>
					<TableRow>
					<TableCell align="center">
						<Checkbox
							onChange={(e) => handleAllCheck(e.target.checked)}
							checked={checkItems.length === classManagementList?.length ? true : false}
							// 데이터 개수와 체크된 아이템의 개수가 다를 경우 선택 해제 (하나라도 해제 시 선택 해제)
							inputProps={{ 'aria-label': 'controlled' }}
						/>
					</TableCell>
					<TableCell align="center">{/* 번호 */}{t('ClassManagement.string12')}</TableCell>
					<TableCell align="center">{/* 미션명 */}{t('ClassManagement.string13')}</TableCell>
					<TableCell align="center">{/* 카테고리 */}{t('ClassManagement.string14')}</TableCell>
					<TableCell align="center">{/* 등급 */}{t('ClassManagement.string15')}</TableCell>
					{/* <TableCell align="center"> */}{/* 진도 */}{/*t('ClassManagement.string16')/*}{/* </TableCell> */}
					<TableCell align="center">{/* 상태 */}{t('ClassManagement.string17')}</TableCell>
					{/* <TableCell align="center"> */}{/* 자세히 */}{/* {t('ClassManagement.string18')}</TableCell> */}
					</TableRow>
				</TableHead>
				<TableBody>
				{
					classManagementList && classManagementList.length !== 0
					? 
					classManagementList
					.filter((x)=> x.mis_category_name!=="체험판")
					.map((row, index) => {
						return (
						<TableRow hover role="checkbox" tabIndex={-1} key={index}>
							<TableCell align="center" sx={{ width: 50 }}>
								<Checkbox
									key={row.mis_idx} 
									id={row.mis_idx} 
									onChange={checkHandled}
									checked={checkItems.includes(row.mis_idx) ? true : false}
									inputProps={{ 'aria-label': 'controlled' }}
								/>
							</TableCell>
							<TableCell component="th" scope="row" align="center" sx={{ width: 50 }}>
							{index+1}
							</TableCell>
							<TableCell align="center">{row.mis_id}</TableCell>
							<TableCell align="center">{ row.mis_category_name ? common.returnCategoryTranslation(row.mis_category_name) : "" }</TableCell>
							<TableCell align="center">{ row.mis_rating ? common.returnMissionRating(row.mis_rating) : "-" }</TableCell>
							{/* <TableCell align="center">{row.clear_count} / {row.total}</TableCell> */}
							<TableCell align="center"
								sx={{
									backgroundColor: row.mission_active == 0 ? "white" : "#B0BEC5"
								}}
							>{row.mission_active == 0 ? /*"미사용"*/t('ClassManagement.string19') : /*"사용"*/ t('ClassManagement.string20')}</TableCell>
							{/* <TableCell align="center">자세히</TableCell> */}
						</TableRow>
						);
					})
					:
					<TableRow>
						<TableCell component="th" scope="row" align="center" colSpan="9">{/* 데이터 없음 */}{t('Common.No_data')}</TableCell>
					</TableRow>
					}
				</TableBody>
				</Table>
			</TableContainer>
		</div>
		</Box>
		<Divider />
		
        <DialogActions>
		  <Button autoFocus onClick={handleClose} style={{ textTransform: 'none' }}>
            {/* 닫기 */}{t('Common.close')}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>

	</>
  );
}