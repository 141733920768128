import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import styles from "../css/common.module.css";
import $ from 'jquery';
import { userSelect, userPhoneModify } from "../actions/users";
import { studentList } from "../actions/student";
// import http from "../http-common";
// import { orderCreate } from "../actions/order";
import { useNavigate } from 'react-router-dom';

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

export default function Main() {

	// 일반 회원정보
	const [email, setEmail] = useState("");
	const [name, setName] = useState("");
	const [nickname, setNickname] = useState("");
	const [phone, setPhone] = useState("");
	const [type, setType] = useState("");
	const [smsRecive, setsmsRecive] = useState(0);
	const [emailRecive, setEmailRecive] = useState(0);

	const authState = useSelector(state => state.auth);
    // console.log("authState : ", authState);

	const dispatch = useDispatch();	
	const navigate = useNavigate();

	const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

	useEffect(() => {

		// 여기에서 jQuery를 초기화 합니다.
		$(document).ready(function(){

			// 언어가 바뀌었을 때 한글이 아니면 첫번쩨 페이지로 이동합니다.
			let language = localStorage.getItem("language");
			if (language !== 'ko')
			{
				document.location.href = '/';	// 메일 페이지로 이동
			}

			// console.log("jquery ready!");

			//전화번호 추가 입력 팝업에서 '확인' 클릭했을 때
			// $(`.${styles.pay_add_info_popup} .${styles.confirm}`).on('click',function(event)
			// {
			// 	event.preventDefault();

			// 	let phoneNumber = $(`#${styles.pay_add_phonenumber}`).val();
			// 	console.log(phoneNumber);
			// 	const phoneReg = /^01([0|1|6|7|8|9])-([0-9]{3,4})-([0-9]{4})$/;

			// 	if(phoneNumber === "")
			// 	{
			// 		return alert('전화번호를 입력해주세요.');
			// 	}
			// 	else
			// 	{
			// 		// 휴대전화 정규표현식
			// 		if (phoneReg.test(phoneNumber) === false) 
			// 		{
			// 			return alert('000-0000-0000 형식으로 중간에 -(하이픈)을 넣어주세요.');
			// 		}
			// 		else
			// 		{
			// 			console.log(phoneNumber);
			// 			setPhone(phoneNumber);

			// 			if (authState?.user?.email !== null && authState?.user?.email !== undefined)
			// 			{
			// 				// 전화번호를 받아서 수정합니다.
			// 				// 여기에서 데이터를 입력합니다.
			// 				let sendData = {
			// 					email:email,
			// 					type:type,
			// 					name:name,
			// 					nickname:"",
			// 					phone: phoneNumber,
			// 					password:"",
			// 					recive_sms:smsRecive,
			// 					recive_email:emailRecive
			// 				};

			// 				console.log("sendData : ", sendData);

			// 				// 여기서 디스패치로~
			// 				// dispatch(userPhoneModify(sendData))
			// 				// .then(data => 
			// 				// {
			// 				// 	console.log("data : ", data);
			// 				// 	if (!data.success)
			// 				// 	{
			// 				// 		alert(data.msg);
			// 				// 		return;
			// 				// 	}
			// 				// 	else
			// 				// 	{
			// 				// 		alert("수정완료");
			// 				// 		// document.location.reload();
			// 				// 	}
			// 				// })
			// 				// .catch(e => {
			// 				// 	console.log(e);
			// 				// });
			// 			}
			// 			else
			// 			{
			// 				alert("구매하기는 로그인이 필요합니다.");
			// 				document.location.href = "/auth/login";
			// 			}


			// 			$(`.${styles.pay_add_info_popup}`).css('display','none');
			// 			$(`#${styles.pay_add_phonenumber}`).val("");
			// 			blackBg(2);
			// 		}
			// 	};
			// });

			//전화번호 추가 입력 팝업에서 '취소' 클릭했을 때
			$(`.${styles.pay_add_info_popup} .${styles.cancle}`).on('click',function(event)
			{
				event.preventDefault();

				$(`.${styles.pay_add_info_popup}`).css('display','none');
				blackBg(2);
				$(`#${styles.pay_add_phonenumber}`).val("");
			});

			//천마다 콤마
			function priceToString(price) 
			{
				if (price === null)
					price = 0;

				return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
			}

			//구매하기 페이지 상품선택
			let chooseProduct;
			
			$(`.${styles.product_area} ul li`).on('click',function(){
				let tg=$(this);
				
				chooseProduct=tg.attr('name');
				// console.log(chooseProduct);
				
				$(`.${styles.product_area} ul li`).removeClass(`${styles.on}`);
				tg.addClass(`${styles.on}`);
			});
			
			//학습자 수 
			$(`.${styles.product_area} ul li`).each(function()
			{
				let minus=$(this).find(`.${styles.count_btn_area} button:last-child`);
				let plus=$(this).find(`.${styles.count_btn_area} button:first-child`);
				let inputArea=$(this).find('input[name=studentCount]');
				let student=1;
				let totalTxt=$(this).find(`.${styles.product_total_price}`);
				let price=$(this).find(`.${styles.product_total_price}`).attr('name');
				let total=price*student;
				
				const priceComma=priceToString(price);
				totalTxt.text(priceComma);
				
				minus.on('click',function()
				{
					student=inputArea.val();
					
					if(student==1)
					{
						return;
					};
					
					student--;
					total=price*student;
					const totalPrice=priceToString(total);
					
					inputArea.val(student);
					totalTxt.text(totalPrice);
					
					// console.log(student);
					// console.log(total);
					
				});

				plus.on('click',function()
				{
					student=inputArea.val();
					
					if(student>29)
					{
						// alert('31명 이상 구매 시 단체 구매로 문의하시기 바랍니다.');
						alert(`${t("Pay.alert.pay_string6")}`);
						return;
					};
					
					student++;
					total=price*student;
					const totalPrice=priceToString(total);
					
					inputArea.val(student);
					totalTxt.text(totalPrice);
					
					// console.log(student);
					// console.log(total);
					
				});

				//인풋의 값이 31 이상으로 수정했을때 alert, 입력된 학습자 수에 맞춰 총 상품 금액 교체
				inputArea.on('change', function()
				{
					if(inputArea.val() < 1)
					{
						// alert('학습자 수는 1명부터 입력해주세요.');
						alert(`${t("Pay.alert.pay_string7")}`);
						inputArea.val(1);
					}
					else if(inputArea.val()>=31)
					{
						// alert('31명 이상 구매 시 단체 구매로 문의하시기 바랍니다.');
						alert(`${t("Pay.alert.pay_string6")}`);
						inputArea.val(30);
					}
					else if(inputArea.val().includes('.')===true)
					{
						// alert('학습자 수는 1명부터 입력해주세요.');
						alert(`${t("Pay.alert.pay_string7")}`);
						inputArea.val(1);
					}
					else
					{
						// console.log('input의 값을 수정하였습니다');
					};
					
					student=inputArea.val();
					total=price*student;
					
					const totalPrice=priceToString(total);
					totalTxt.text(totalPrice);
				});

			});

		});

		//구매하기 팝업에서 '확인' 클릭했을 때
		// $(`.${styles.pay_popup} .${styles.confirm}`).on('click',function(event)
		// {
		// 	event.preventDefault();

		// 	let period=$(`.${styles.pay_popup} .${styles.period}`).text();
		// 	let productName=$(`.${styles.pay_popup} .${styles.name}`).text();
		// 	let student=$(`.${styles.pay_popup} .${styles.student}`).text();
		// 	let totalText=$(`.${styles.pay_popup} .${styles.total}`).text();
		// 	let prd_idx = 0;

		// 	// console.log("period : ", period);

		// 	//값들의 갯수 -> 배열 길이를 지정
		// 	var grpl = $('input[name=prd_idx]').length;
		// 	var grpl2 = $('input[name=prd_name]').length;

		// 	//배열 생성
		// 	var grparr = new Array(grpl);
		// 	var grparr2 = new Array(grpl2);

		// 	//배열에 값 주입
		// 	for(var i=0; i<grpl; i++)
		// 	{
		// 		grparr[i] = $("input[name=prd_idx]").eq(i).val();
		// 		grparr2[i] = $("input[name=prd_name]").eq(i).val();
		// 		// alert(grparr[i]);
		// 		// alert(grparr2[i]);

		// 		if (period === grparr2[i])
		// 		{
		// 			prd_idx = grparr[i];
		// 			// alert(grparr[i]);
		// 		}
		// 	}

		// 	//학습자 수가 0이거나 소수,공백이면 결제 정보 콘솔 안띄음
		// 	if(student==='0명')
		// 	{
		// 		alert('학습자는 1명부터 구매 가능합니다.');
		// 		$(`.${styles.pay_popup}`).css('display','none');
		// 		blackBg(2);
		// 	}
		// 	else if(student==='명')
		// 	{
		// 		alert('학습자는 1명부터 구매 가능합니다.');
		// 		$(`.${styles.pay_popup}`).css('display','none');
		// 		blackBg(2);
		// 	}
		// 	else
		// 	{
		// 		$(`.${styles.pay_popup}`).css('display','none');
		// 		blackBg(2);
		// 		// console.log('확인');
		// 		// console.log(`${period}-${productName}-${student}-${totalText}`);

		// 		if (!authState.isLoggedIn)
		// 		{
		// 			// console.log(" isLoggedIn check ");
		// 			alert("구매하기는 로그인이 필요합니다.")
		// 			document.location.href = "/auth/login";
		// 		}
		// 		else
		// 		{
		// 			const sendOrder = {
		// 				product_idx:prd_idx,
		// 				order_count:student.replace('명', ''),
		// 				order_amount:totalText.replace(',', '').replace('원', '')
		// 			};

		// 			// console.log("sendOrder : ", sendOrder);
					
		// 			// 여기서 디스패치로~
		// 			dispatch(orderCreate(sendOrder))
		// 			.then(data => 
		// 			{
		// 				let order = null;

		// 				// console.log("data : ", data);
		// 				if (!data.success)
		// 				{
		// 					alert(data.msg);
		// 					return;
		// 				}
		// 				else
		// 				{
		// 					order = data.data;

		// 					// 결제 창 호출
		// 					const IMP = window.IMP; // 생략 가능
		// 					// IMP.init("imp80753747"); // Example: imp00000000
		// 					IMP.init(process.env.REACT_APP_IMP_UID); // Example: imp00000000

		// 					// console.log("order : ", order);
							
		// 					let mobileCompleteUrl = process.env.REACT_APP_API_URL + "/payment/mobile/complete";
		// 					let param = { // param
		// 						pg: "html5_inicis.INIpayTest",
		// 						pay_method: "card",
		// 						merchant_uid: order.ord_no,
		// 						name: order.prd_name,
		// 						amount: order.ord_amt,
		// 						buyer_email: order.mem_email,
		// 						buyer_name: order.mem_name,
		// 						buyer_tel: order.mem_phone,
		// 						m_redirect_url: mobileCompleteUrl,
		// 					};

		// 					// console.log("param : ", param);

		// 					// IMP.request_pay(param, callback) 결제창 호출
		// 					IMP.request_pay(param, rsp => { // callback

		// 						console.log("rsp : ", rsp);

		// 						if (rsp.success) // 결제 성공 시: 결제 승인 또는 가상계좌 발급에 성공한 경우
		// 						{ 
		// 							// axios로 HTTP 요청
		// 							const sendData = { imp_uid: rsp.imp_uid, merchant_uid: rsp.merchant_uid };
								
		// 							http.post("payment/complete", sendData
		// 							).then((data) => {
		// 								// 서버 결제 API 성공시 로직
		// 								console.log(data);
		// 								window.location.reload();
		// 							})
		// 						} 
		// 						else 
		// 						{
		// 							alert(`결제에 실패하였습니다. 에러 내용: ${rsp.error_msg}`);
		// 						}
		// 					});
		// 				}

		// 			})
		// 			.catch(e => {
		// 				console.log(e);
		// 			});
		// 		}
				
		// 	};
			
		// });
		
		//구매하기 팝업에서 '취소' 클릭했을 때
		// $(`.${styles.pay_popup} .${styles.cancle}`).on('click',function()
		// {
		// 	$(`.${styles.pay_popup}`).css('display','none');
		// 	blackBg(2);
		// 	// console.log('취소');
		// });

	}, [$]);

	//구매하기 클릭시 팝업
	// const pay = (i) => 
	// {
	// 	// console.log("pay click");

	// 	let wrap=$(`.${styles.product_area} ul li`).eq(i-1);
	// 	let period=wrap.find(`.${styles.period}`).text();
	// 	let productName=wrap.find(`.${styles.product_name} p`).text();
	// 	let student=wrap.find('input[name=studentCount]').val();
	// 	let totalText=wrap.find(`.${styles.product_total_price}`).text();
		
	// 	blackBg(1);
	// 	$(`.${styles.pay_popup} .${styles.period}`).text(period);
	// 	$(`.${styles.pay_popup} .${styles.name}`).text(productName);
	// 	$(`.${styles.pay_popup} .${styles.student}`).text(`${student}명`);
	// 	$(`.${styles.pay_popup} .${styles.total}`).text(`${totalText}원`);
	// 	$(`.${styles.pay_popup}`).css('display','block');
		
	// };

	//검정 반투명 배경 제어
	const blackBg = (i) => 
	{
		if(i===1)
		{
			$(`.${styles.body_black}`).css('display','block');
			// $('body').css('overflow','hidden');
			// console.log('배경보임');
		}
		else
		{
			$(`.${styles.body_black}`).css('display','none');
			// $('body').css('overflow','visible');
			// console.log('배경꺼짐');
		};
	};

	//구매하기 클릭시 팝업
	const payPhoneNumber = (event, i) =>
	{
		event.preventDefault();

		// console.log("payPhoneNumber click ", i);

		// 화면에 디스플레이된 상품과 가격에 대한 정보를 가져옵니다.
		let wrap=$(`.${styles.product_area} ul li`).eq(i-1);
		let period=wrap.find($('input[name=prd_name]')).val();
		let period2=wrap.find(`.${styles.period}`).text();	// html에서 가져오면 번역에 따라서 값이 바꾼다.
		let productName=wrap.find(`.${styles.product_name} span`).text() + " " + wrap.find(`.${styles.product_name} p`).text();
		let student=wrap.find('input[name=studentCount]').val();
		let totalText=wrap.find(`.${styles.product_total_price}`).text();
		let prd_idx = 0;
		let sell_prc_unit = 0;

		//값들의 갯수 -> 배열 길이를 지정
		var grpl = $('input[name=prd_idx]').length;
		var grpl2 = $('input[name=prd_name]').length;
		var grpl3 = $('input[name=sell_prc_unit]').length;

		//배열 생성
		var grparr = new Array(grpl);
		var grparr2 = new Array(grpl2);
		var grparr3 = new Array(grpl3);

		//배열에 값 주입
		for(var i=0; i<grpl; i++)
		{
			grparr[i] = $("input[name=prd_idx]").eq(i).val();
			grparr2[i] = $("input[name=prd_name]").eq(i).val();
			grparr3[i] = $("input[name=sell_prc_unit]").eq(i).val();

			// alert(grparr[i]);
			// alert(grparr2[i]);
			// alert(grparr2[i]);

			if (period === grparr2[i])
			{
				prd_idx = grparr[i];
				sell_prc_unit = grparr3[i];
				// alert(grparr[i]);
			}
		}

		// console.log("prd_idx-sell_prc_unit-period-productName-student-totalText", prd_idx, "-", sell_prc_unit, "-", period, "-", productName, "-", student, "-", totalText);

		if(student==='0명')
		{
			// return alert('학습자는 1명부터 구매 가능합니다.');
			return alert(`${t("Pay.alert.pay_string1")}`);
		}
		else if(student==='명')
		{
			// return alert('학습자는 1명부터 구매 가능합니다.');
			return alert(`${t("Pay.alert.pay_string1")}`);
		}
		else
		{
			// 로그인 정보를 확인합니다.
			if (!authState.isLoggedIn)
			{
				// console.log(" isLoggedIn check ");
				// alert("구매하기는 로그인이 필요합니다.");
				alert(`${t("Pay.alert.pay_login")}`);
				// document.location.href = "/auth/login";
			}
			else
			{
				// 결제에 필요한 정보들이 모두 있는지 체크합니다.
				if (authState?.user?.email !== null && authState?.user?.email !== undefined)
				{
					// 학습자가 있는지 검사합니다. 소셜 로그인의 경우 학습자가 없을 수 있지만
					// 그래도 주문하면 시스템에서 주문을 해도 유료로 적용이 안되는 문제가 발생할 수 있습니다.
					// 그래서 학습자 생성 페이지로 이동시킵니다.

					// 학생 리스트 불러오기
					dispatch(studentList())
					.then(data => 
					{
						// console.log("studentList data : ", data);
						// 학생의 개수를 체크해서 하나도 없으면 페이지를 이동합니다.
			
						if (data.length === 0)
						{
							// alert("구매를 하시기 위해서는 최소 1명 이상의 학습자가 필요합니다. 학습자 생성 후에 다시 구매해주세요!");
							alert(`${t("Pay.alert.pay_string2")}`);
							window.location.href = "/UserMypage/MypageStudentAdd";
						}
						else
						{
							dispatch(userSelect(authState.user.email))
							.then(data => 
							{
								// console.log("data : ", data);
					
								setEmail(data.mem_email);
								setName(data.mem_name);
								setNickname(data.mem_nickname);
								setPhone(data.mem_phone);
								setType(data.mem_type);
								setsmsRecive(data.mem_recive_sms);
								setEmailRecive(data.mem_recive_email);

								// 전화번호가 있는지 검사합니다. 주문을 위해서는 전화번호 정보가 반드시 필요합니다.
								if (data.mem_phone === null || data.mem_phone === "")
								{
									blackBg(1);
									$(`.${styles.pay_add_info_popup}`).css('display','block');
									return false;
								}
								
								const OrderData = {
									product_idx:prd_idx,
									sell_prc_unit: sell_prc_unit,
									order_count:student.replace('명', ''),
									order_amount:totalText.replaceAll(',', '').replace('원', ''),
									product_name:productName,
									period:period,
									period2:period2,
									name:data.mem_name,
									phone:data.mem_phone,
									email:data.mem_email,
								};

								// console.log("OrderData : ", OrderData);

								navigate("/Order",
								{
									state: {
										OrderData: OrderData,
									},
								});
							})
							.catch(e => {
								console.log(e);
							});
						}
					})
					.catch(e => {
						console.log(e);
					});
				}

				// 현재 페이지의 결제 정보를 주문 페이지로 보내서 결제를 처리합니다.
				// document.location.href = "/Order";
			}
		}
	}

	// 전화번호 클릭시 함수
	const phoneConfirmClick = (event) =>
	{
		event.preventDefault();

		let phoneNumber = $(`#${styles.pay_add_phonenumber}`).val();
		// console.log(phoneNumber);
		const phoneReg = /^01([0|1|6|7|8|9])-([0-9]{3,4})-([0-9]{4})$/;

		if(phoneNumber === "")
		{
			// return alert('전화번호를 입력해주세요.');
			alert(`${t("Pay.alert.pay_string3")}`);
		}
		else
		{
			// 휴대전화 정규표현식
			if (phoneReg.test(phoneNumber) === false) 
			{
				// return alert('000-0000-0000 형식으로 중간에 -(하이픈)을 넣어주세요.');
				alert(`${t("Pay.alert.pay_string4")}`);
			}
			else
			{
				// console.log(phoneNumber);
				setPhone(phoneNumber);

				if (authState?.user?.email !== null && authState?.user?.email !== undefined)
				{
					// 전화번호를 받아서 수정합니다.
					// 여기에서 데이터를 입력합니다.
					let sendData = {
						email:email,
						type:type,
						name:name,
						nickname:nickname,
						phone: phoneNumber,
						password:"",
						recive_sms:smsRecive,
						recive_email:emailRecive
					};

					// console.log("sendData : ", sendData);

					// 여기서 디스패치로~
					dispatch(userPhoneModify(sendData))
					.then(data => 
					{
						// console.log("data : ", data);
						if (!data.success)
						{
							alert(data.msg);
							return;
						}
						else
						{
							// alert("전화번호가 수정되었습니다. 다시 구매버튼을 클릭해주세요!");
							alert(`${t("Pay.alert.pay_string5")}`);
							document.location.reload();
						}
					})
					.catch(e => {
						console.log(e);
					});
				}
				else
				{
					// alert("구매하기는 로그인이 필요합니다.");
					alert(`${t("Pay.alert.pay_login")}`);
					document.location.href = "/auth/login";
				}


				$(`.${styles.pay_add_info_popup}`).css('display','none');
				$(`#${styles.pay_add_phonenumber}`).val("");
				blackBg(2);
			}
		};
	}

  return (
    <>
      	{/* Header 분리됨 MainLayout Header에 있음 */}

		<div className={styles.body_black}></div>
		{/* <div className={styles.pay_popup}>
			<div>
				<p>이 상품을 구매하시겠습니까?</p>
				<span>이용기간 : <p className={styles.period}>30일</p></span>
				<span>상품명 : <p className={styles.name}>야미 이용권</p></span>
				<span>학습자 수 : <p className={styles.student}>1명</p></span>
				<span>결제 금액 : <p className={styles.total}>13,200원</p></span>
				<div className={styles.btn_area}>
					<button type="button" className={styles.confirm}>확인</button>
					<button type="button" className={styles.cancle}>취소</button>
				</div>
			</div>
		</div> */}

		<div className={styles.pay_add_info_popup}>
			<div>
				<p>{/* 전화번호를 입력해주세요. */}{t("Pay.popup.title")}</p>
				<input type="text" id={styles.pay_add_phonenumber}/>
				<div className={styles.btn_area}>
					<button type="button" className={styles.confirm} onClick={(e)=> phoneConfirmClick(e)}>{/* 확인 */}{t("Pay.popup.confirm")}</button>
					<button type="button" className={styles.cancle}>{/* 취소 */}{t("Pay.popup.cancel")}</button>
				</div>
			</div>
		</div>

		<div className={styles.pay_area}>
			<div className={styles.pay_top_line_banner}>
				<div className={styles.inner}>
					<span>{/* 야미 구매하기 */}{t("Pay.title")}</span>
				</div>
			</div>
			<div className={styles.pay_wrap}>
				<div className={styles.inner}> 
					<div className={styles.product_area}>
						<div className={styles.top_top_area}>
							<img src="/assets/pay_banner@2x_2.png" alt="pay banner"/>
						</div>
						<ul>
							<li className={styles.on} name="day30">
								<input type="hidden" name="prd_idx" value={process.env.REACT_APP_PRO_IDX30} />
								<input type="hidden" name="prd_name" value="30일" />
								<input type="hidden" name="sell_prc_unit" value="18000" />
								<div className={styles.scratch}></div>
								<div className={styles.product_name}>
									<span className={styles.period}>{/* 30일 */}{t("Pay.30day")}</span>
									<p>{/* 야미 이용권 */}{t("Pay.string1")}</p>
								</div>
								<div className={styles.product_price}>
									<div className={styles.left_area}>
										{/* <p>₩16,500</p> */}
										<span className={styles.price}>₩18,000</span>
									</div>
									{/* <span className={styles.discount_area}>-40%</span> */}
								</div>
								<div className={styles.student_count_area}>
									<div className={styles.count_area}>
										<span>{/* 학습자 */}{t("Pay.student")}</span>
										<input type="number" name="studentCount" defaultValue="1" />
									</div>
									<div className={styles.count_btn_area}>
										<button type="button"><img src="/assets/product_plus.png" alt="플러스"/></button>
										<button type="button"><img src="/assets/product_minus.png" alt="마이너스"/></button>
									</div>
								</div>
								<div className={styles.product_total_price_area}>
									<span>{/* 총 상품 금액 */}{t("Pay.string2")}</span>
									<span className={styles.product_total_price} name="18000">18000</span>
								</div>
								<div className={styles.btn_area}>
									<button type="button" onClick={(e) => payPhoneNumber(e, 1)}>{/* 구매 하기 */}{t("Pay.string3")}</button>
								</div>
							</li>
							<li name="day90">
							<input type="hidden" name="prd_idx" value={process.env.REACT_APP_PRO_IDX90} />
							<input type="hidden" name="prd_name" value="90일" />
							<input type="hidden" name="sell_prc_unit" value="48500" />
								<div className={styles.scratch}></div>
								<div className={styles.product_name}>
									<span className={styles.period}>{/* 90일 */}{t("Pay.90day")}</span>
									<p>{/* 야미 이용권 */}{t("Pay.string1")}</p>
								</div>
								<div className={styles.product_price}>
									<div className={styles.left_area}>
										{/* <p>₩53,800</p> */}
										<span className={styles.price}>₩48,500</span>
									</div>
									{/* <span className={styles.discount_area}>-10%</span> */}
								</div>
								<div className={styles.student_count_area}>
									<div className={styles.count_area}>
										<span>{/* 학습자 */}{t("Pay.student")}</span>
										<input type="number" name="studentCount" defaultValue="1" />
									</div>
									<div className={styles.count_btn_area}>
										<button type="button"><img src="/assets/product_plus.png" alt="플러스"/></button>
										<button type="button"><img src="/assets/product_minus.png" alt="마이너스"/></button>
									</div>
								</div>
								<div className={styles.product_total_price_area}>
									<span>{/* 총 상품 금액 */}{t("Pay.string2")}</span>
									<span className={styles.product_total_price} name="48500">48500</span>
								</div>
								<div className={styles.btn_area}>
									<button type="button" onClick={(e) => payPhoneNumber(e, 2)}>{/* 구매 하기 */}{t("Pay.string3")}</button>
								</div>
							</li>
							<li name="day180">
							<input type="hidden" name="prd_idx" value={process.env.REACT_APP_PRO_IDX180} />
							<input type="hidden" name="prd_name" value="365일" />
							<input type="hidden" name="sell_prc_unit" value="172000" />
								<div className={styles.scratch}></div>
								<div className={styles.product_name}>
									<span className={styles.period}>{/* 365일 */}{t("Pay.365day")}</span>
									<p>{/* 야미 이용권 */}{t("Pay.string1")}</p>
								</div>
								<div className={styles.product_price}>
									<div className={styles.left_area}>
										{/* <p>₩203,500</p> */}
										<span className={styles.price}>₩172,000</span>
									</div>
									{/* <span className={styles.discount_area}>-15%</span> */}
								</div>
								<div className={styles.student_count_area}>
									<div className={styles.count_area}>
										<span>{/* 학습자 */}{t("Pay.student")}</span>
										<input type="number" name="studentCount" defaultValue="1" />
									</div>
									<div className={styles.count_btn_area}>
										<button type="button"><img src="/assets/product_plus.png" alt="플러스"/></button>
										<button type="button"><img src="/assets/product_minus.png" alt="마이너스"/></button>
									</div>
								</div>
								<div className={styles.product_total_price_area}>
									<span>{/* 총 상품 금액 */}{t("Pay.string2")}</span>
									<span className={styles.product_total_price} name="172000">172000</span>
								</div>
								<div className={styles.btn_area}>
									<button type="button" onClick={(e) => payPhoneNumber(e, 3)}>{/* 구매 하기 */}{t("Pay.string3")}</button>
								</div>
							</li>
						</ul>
					</div>
					<ul className={styles.pay_guide}>
						<li><a href="/Use#refund_policy">{/* 본 상품은 당사의 환불정책에 따라 <br/>환불할 수 있습니다. */}<Trans i18nKey="Pay.guide.pay_string1"/></a></li>
						<li>{/* 서비스 이용 기간 <br/>: 서비스 종료 시까지 학습할 수 있습니다. <br/>야미의 학습 시간은 결제 시작부터 차감됩니다. */}<Trans i18nKey="Pay.guide.pay_string2"/></li>
						<li>{/* 야미코딩은 인터넷 연결이 필요합니다. */}<Trans i18nKey="Pay.guide.pay_string3"/></li>
					</ul>
				</div>{/* inner */}
				<div className={styles.pay_product_info_top_line}></div>
				<div className={styles.pay_product_info_area}>
					<div className={styles.inner}>
						<span className={styles.pay_product_title}>{/* '개념을 익히고, 원리를 알아내며,<br/>아이들이 스스로 학습할 수 있는 야미코딩' */}
						<Trans i18nKey="Pay.product.pay_string1"/></span>
						<div className={styles.pay_product_3d_area}>
							<div className={styles.pay_product_top_area}>
								<span>{/* Full 3D 야미월드 */}<Trans i18nKey="Pay.product.pay_string2"/></span>
								<p>{/* 2D 이미지로 구성된 코딩블록 서비스와 차별적으로 아이들이 창의력과 상상력을 최대한 이끌어내기 위해 야미 콘텐츠의 공간은 3D로 제작 되었습니다. */}
								<Trans i18nKey="Pay.product.pay_string3"/></p>
							</div>
							<div className={styles.pay_product_3d_bottom_area}>
								<div className={styles.img_area}>
									<img src="/assets/3d_img1@2x.png" alt="3d"/>
									<p>{/* 메타버스 컨셉의 야미 월드 <br/>지속 적으로 섬을 확장해 나갈 예정인 <br/>야미월드의 시작 섬. */}
									<Trans i18nKey="Pay.product.pay_string4"/></p>
								</div>
								<div className={styles.img_area}>
									<img src="/assets/3d_img2@2x.png" alt="3d"/>
									<p>{/* 순차, 반복, 선택 그리고 특별한 미션! <br/>오픈월드 수업 방식으로 원하는 <br/>수업을 선택해서 진행합니다. */}
									<Trans i18nKey="Pay.product.pay_string5"/></p>
								</div>
								<div className={styles.img_area}>
									<img src="/assets/3d_img3@2x.png" alt="3d"/>
									<p>{/* 지식 은행 <br/>매일 매일 출제되는 문제 풀이로 <br/>소프트웨어 지식을 쌓아 갑니다. */}
									<Trans i18nKey="Pay.product.pay_string6"/></p>
								</div>
							</div>
						</div>
						<div className={styles.pay_product_contents_area}>
							<div className={styles.pay_product_top_area}>
								<span>{/* 학습의 향상을 위한 다양한 콘텐츠 */}
								<Trans i18nKey="Pay.product.pay_string7"/></span>
								<p>{/* 흥미를 가지고 학습하기 어려운 코딩블록 수업을 아이들이 집중해서 재미있게 수업을 진행할 수 있도록 차별화된 부가적인 콘텐츠를 준비했습니다. */}
								<Trans i18nKey="Pay.product.pay_string8"/></p>
							</div>
							<div className={styles.pay_product_contents_bottom_area}>
								<div className={styles.top_area}>
									<div className={styles.img_area}>
										<div>
											<img src="/assets/contents_img1@2x.png" alt="contents"/>
											<img src="/assets/contents_img2@2x.png" alt="contents"/>
										</div>
										<p>{/* 야미 캐릭터<br/>코딩수업을 야미 캐릭터로 진행합니다. <br/>아이들이 자신만의 개성있는 캐릭터로 <br/>꾸미기가 가능합니다. */}
										<Trans i18nKey="Pay.product.pay_string9"/></p>
									</div>
								</div>
								<div className={styles.bottom_area}>
									<div className={styles.img_area}>
										<img src="/assets/contents_img3@2x.png" alt="contents"/>
										<p>{/* 컨트롤 미션<br/>직접 컨트롤 해서 사물을 움직여 <br/>미션을 수행합니다. */}
										<Trans i18nKey="Pay.product.pay_string10"/></p>
									</div>
									<div className={styles.img_area}>
										<div>
											<img src="/assets/contents_img4@2x.png" alt="contents"/>
											<img src="/assets/contents_img5@2x.png" alt="contents"/>
										</div>
										{/* <p>수학 미션 <br/>반복해서 수업을 진행해도 <br className={styles.mobile}/>같은 문제가 나오지 않습니다. <br className={styles.mobile}/>야미 랜덤 시스템으로 <br/>'백만가지의 수학 미션'을 제공합니다.</p> */}
										<p>
											{/* 수학 미션 */}<Trans i18nKey="Pay.product.pay_string11-1"/> <br/>{/* 반복해서 수업을 진행해도 */}<Trans i18nKey="Pay.product.pay_string11-2"/> <br className={styles.mobile}/>{/* 같은 문제가 나오지 않습니다. */}<Trans i18nKey="Pay.product.pay_string11-3"/> <br className={styles.mobile}/>{/* 야미 랜덤 시스템으로 <br/> '백만가지의 수학 미션'을 제공합니다. */}<Trans i18nKey="Pay.product.pay_string11-4"/>
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className={styles.pay_product_etc_area}>
							<div className={styles.pay_product_top_area}>
								<span>{/* 아니 이런것 까지?! */}<Trans i18nKey="Pay.product.pay_string12"/></span>
								<p>{/* 아이들의 지친 마음과 뇌를 달래주기 위해 특별한 음악을 야미코딩에 담았습니다.<br/>'뇌&음악' 이라는 뇌에 좋은 영향을 주는 곡들을 전문으로 작곡하는 스튜디오에서 야미를 위한 8개의 곡을 제작했습니다. */}
								<Trans i18nKey="Pay.product.pay_string13"/></p>
								<span className={styles.second}>{/* 뇌음악이란 */}<Trans i18nKey="Pay.product.pay_string14"/></span>
								<p>{/* 신경가소성과 관련된 진동활동을 유도하는 요소와 긍정적 정서와 낮은 각성 수준의 음계를 사용.<br/>스트레스의 지표가 되는 바이오마커들의 활성수준을 감소시키는 음계를 사용. */}
								<Trans i18nKey="Pay.product.pay_string15"/></p>
							</div>
							<img src="/assets/pay_product_box_bg3_img.png" alt="뇌음악"/>
						</div>
					</div>{/* inner */}
				</div>{/* pay_product_info_area */}
				<div className={styles.pay_bottom_btn_area}>
					<div className={styles.inner}>
						<a href="/Pay">{/* 야미 구매 하기 */}<Trans i18nKey="Pay.product.pay_string16"/></a>
					</div>
				</div>		
			</div>{/* pay_wrap */}
		</div>{/* pay_area */}
		
		{/* Footer 분리됨 MainLayout Footer에 있음 */}
    </>
  );
}