import * as React from 'react';
import styles from "../../css/common.module.css";

import Grid from '@mui/material/Grid';
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../actions/auth";
import GoogleLoginButton from './GoogleLoginButton';
import AppleSigninButton from '@allsquare/react-apple-signin-auth/dist/AppleSigninButton/AppleSigninButton';

import { socialLogin } from "../../actions/auth";

import * as jwt from 'jsonwebtoken';

import $ from 'jquery';

import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

export default function LoginView() {

    const initialAuthState = {
        email: "", 
        psword: ""
    };

    const [auth, setAuth] = useState(initialAuthState);

    const dispatch = useDispatch();
    const authState = useSelector(state => state.auth);
    // console.log("redux state : ", authState);

    const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

    let language = localStorage.getItem("language");

    if (authState.isLoggedIn)  
    {
        // console.log("authState.user.type value: ", authState.user.type);
        // console.log("authState.user.type typeof: ", typeof(authState.user.type));

        if (authState.user.type === 1)
        {
            document.location.href = "/dashboard/NoticeList/notice";
        }
        else if (authState.user.type === 4)
        {
            document.location.href = "/GroupUserMypage/GroupParentsInfo";
        }
        else
        {
            document.location.href = "/UserMypage/ParentsInfo";
        }
    }

    const handleInputChange = event => {
        const { name, value } = event.target;
        setAuth({ ...auth, [name]: value });
    };

    const handleSubmit = (event) => {

        event.preventDefault();

        const { email, psword } = auth;
        // console.log("auth : ", auth);

        if (email === "")
        {
            // return alert("이메일을 입력해주세요.");
            return alert(`${t("Auth.alert.string1")}`);
        }

        if (psword === "")
        {
            // return alert("패스워드를 입력해주세요.");
            return alert(`${t("Auth.alert.string2")}`);
        }

        // 여기서 디스패치로~
        dispatch(login(email, psword))
        .then(data => 
        {
            setAuth({
                email: email, 
                psword: psword
            });

            // console.log("data : ", data);
            if (!data.success)
                alert(data.msg);
        })
        .catch(e => {
            console.log(e);
        });
    };

    const AppleSuccess = (response) => 
    {
        // console.log(response);
        let userName = "";
        userName = response.user !== undefined ? response.user?.name?.firstName + " " + response.user?.name?.lastName : "0";

        const decode = jwt.decode(response.authorization.id_token);
        // console.log("decode : ", decode);

        // 여기서 디스패치로~
        dispatch(socialLogin("apple", decode.sub, decode.email, userName))
        .then(data => 
        {
            // console.log("socialLogin data : ", data);
            // if (data.success)
            //     navigate("/dashboard/NoticeListClient/notice");
        })
        .catch(e => {
            console.log(e);
        });
    }

    const AppleError = (error) => 
    {
        console.log(error);
    }

    useEffect(() => {

		// 여기에서 jQuery를 초기화 합니다.
		// jquery ready 호출. jquery3 부터 변경됨
		$(function()
        {
			// console.log("jquery ready!");
            $('#email, #psword').on('keydown', function(e){
                
                if (e.keyCode === 13)
                {
                    $(`.${styles.ok}`)[0].click();
                }
            })

            $('#email').on("keyup", function (e) 
            {
                // console.log("key up", e.key, e.code);

                if (!(e.key >=37 && e.key<=40)) 
                { 
                    var inputVal = $(this).val(); 
                    var check = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; 
                    
                    if(check.test(inputVal))
                    { 
                        $(this).val(""); 
                    } 
                } 

              });
        });

    }, [$]);

    return (
        <Grid container component={styles.main} sx={{ height: '100vh' }}>
            <div className={styles.login_bg_wrapper}>
                <div className={`${styles.login_area} ${styles.middle}`}>
                    <div className={styles.login_top}>
                        <a href="/" className={styles.logo}>
                            {/* <img src="/assets/logo@2x.png" alt="logo"/> */}
                            {
								{ 
									'ko' : <img src="/assets/logo@2x.png" alt="logo"/>,
									'en' : <img src="/assets/logo@2x_en.png" alt="logo"/>,
									'jp' : <img src="/assets/logo@2x_jp.png" alt="logo"/>
								}[language]
							}
                        </a>
                        <a href="/auth/RegisterAgree" className={styles.to_join}>{/* 회원가입 하러가기 */}{t('Auth.login_string1')}</a>
                    </div>
                    <span className={styles.login_title}>{/* 로그인 */}{t('Auth.login')}</span>
                    <div className={`${styles.loginpage_wrap} ${styles.center_align}`}>
                        <div className={`${styles.input_area} ${styles.name}`}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className={`${styles.input_area} ${styles.password}`}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="psword"
                                label="Password"
                                type="password"
                                id="psword"
                                autoComplete="current-password"
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className={styles.box_under_wrap}>
                            <div className={styles.check_wrap}>
                                {/* <div className={styles.check}> */}
                                    <FormControlLabel
                                        control={<Checkbox value="remember" color="primary" />}
                                        // label="로그인 상태 유지"
                                        label={t('Auth.login_string2')}
                                        labelPlacement="end"
                                        sx={{ '& .MuiFormControlLabel-label': { fontSize: 14, fontFamily: 'S-CoreDream-5Medium'} }}
                                    />
                                {/* </div> */}
                                {/* <p className={styles.continue}>로그인 상태 유지</p> */}
                            </div>
                            <a href="/auth/PasswordChange" className={styles.change}>{/* 비밀번호 변경 */}{t('Auth.login_string3')}</a>
                            <a href="/auth/EmailChange" className={styles.change2}>{/* 이메일 변경 */}{t('Auth.login_string4')}</a>
                        </div>
                        <a href="#!" className={styles.ok} onClick={handleSubmit}>{/* 로그인 */}{t('Auth.login')}</a>
                        <div className={styles.line}></div>
                        <span className={styles.sub_tit}>{/* 간편 로그인 */}{t('Auth.login_string5')}</span>
                        <ul className={styles.easy_login_info}>
                            <li>{/* 플레이될 기기에 맞는 소셜 로그인을 선택해 주세요. */}{t('Auth.login_string6')}</li>
                        </ul>
                        <GoogleLoginButton />
                        {/* <button type="button" className={styles.apple}>
                            <img src="/assets/Logo - SIWA - Logo-only - White@2x.png" alt="apple logo" />
                        </button> */}
                        <AppleSigninButton
                            /** Auth options passed to AppleID.auth.init() */
                            authOptions={{
                            clientId: 'com.Weclover.WebAppleLogin',
                            scope: 'email name',
                            redirectURI: process.env.REACT_APP_MAIN_URL,
                            state: '',
                            nonce: 'nonce',
                            usePopup: true,
                            }}
                            /** className */
                            className={styles.apple}
                            // onSuccess={(response) => console.error(response)} // default = undefined
                            onSuccess={AppleSuccess} // default = undefined
                            /** Called upon signin error */
                            // onError={(error) => console.error(error)} // default = undefined
                            onError={AppleError} // default = undefined
                            render={(props) => <button {...props} type="button" className={styles.apple}>
                            <img src="/assets/Logo - SIWA - Logo-only - White@2x.png" alt="apple logo" />
                        </button>}
                        />

                    </div>
                </div>
            </div>
        </Grid>
    );
}