import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import Box from '@mui/material/Box';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';

import Divider from '@mui/material/Divider';

import { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";

import { userLeave } from "../../actions/users";

import { logout } from "../../actions/auth"

import { useGoogleLogout } from 'react-google-login';

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function MemberLeaveDialog() {
  
  const [open, setOpen] = useState(false);
  const [reasons, setReasons] = useState(0);
  const [agree, setAgree] = useState(0);

  const dispatch = useDispatch();	

  const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

  let clientId = `${process.env.REACT_APP_CLIENT_ID}`;

  const handleSubmit = (event) => {

	event.preventDefault();
	// console.log("reasons : ", reasons);
	// console.log("agree : ", agree);
	
	// 구매 상품을 선택했는지 체크, 이름, 닉네임, 생년월일, 성별 데이터가 있는지 체크
	if (reasons === 0)
	{
		// return alert("탈퇴 이유를 선택해주세요.");
		return alert(`${t("MemberLeave.alert.string1")}`);
	}
	else if (agree === 0)
	{
		// return alert("동의 버튼을 체크해주세요.");
		return alert(`${t("MemberLeave.alert.string2")}`);
	}
	
	// 통과된 데이터로 학습자 정보를 변경합니다.
	let sendData = {
		reasons: reasons,
		agree: agree,
	};

	// console.log("sendData : ", sendData);

	// 여기서 디스패치로~
	dispatch(userLeave(sendData))
	.then(data => 
	{
		// console.log("data : ", data);
		if (!data.success)
		{
			alert(data.msg);
			return;
		}
		else
		{
			// alert("탈퇴가 완료되었습니다.");
			alert(`${t("MemberLeave.alert.string3")}`);

			// 세션을 날리고 로그아웃 처리함
			logOut();
		}
	})
	.catch(e => {
		console.log(e);
	});
	
};

const handleInputChange = event => {

	const { name, value } = event.target;

	if (name === "reasons")
	{
		setReasons(value);
	}
	else if (name === "agree")
	{
		setAgree(value);
	}
};

  const handleClickOpen = () => {
    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
  };

  const logOut = () => 
	{
		signOut();
		dispatch(logout());

		window.location.href = '/auth/login';
	};

	const onLogoutSuccess = (res) => {
		console.log('Logged out Success');
		// alert('Logged out Successfully ✌');
		dispatch(logout());
		window.location.href = '/auth/login';
	};
	
	const onFailure = () => {
		console.log('Handle failure cases');
	};

	const { signOut } = useGoogleLogout({
		clientId,
		onLogoutSuccess,
		onFailure,
	});

  return (
    <div>
      <Button variant="outlined" style={{ textTransform: 'none' }} onClick={handleClickOpen}>{/* 회원 탈퇴 */}{t('MemberLeave.string13')}</Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {/* 회원 탈퇴 안내 */}{t('MemberLeave.title')}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
		  	{/* * 회원탈퇴 유의사항 */}{t('MemberLeave.string1')}<br></br><br></br>
			{/* 1. 회원님의 아직 소멸되지 않는 이용권은 탈퇴하시면 이용권도 같이 소멸됩니다. */}{t('MemberLeave.string2')}<br></br>
			{/* 2. 소멸된 이용권은 환불처리되지 않으며 복구가 불가능합니다. */}{t('MemberLeave.string3')}<br></br>
			{/* 3. 회원님의 정보와 학습자의 정보 등 모든 정보가 삭제되며 복구가 불가능합니다. 신중히 부탁드립니다. */}{t('MemberLeave.string4')}<br></br>
          </Typography>
        </DialogContent>
		
		<Box
		component="form"
		sx={{
			'div': { m: 1, width: '350px', marginTop: '10px', marginLeft: '20px' },
		}}
		noValidate
		autoComplete="off"
		>
		<div>
			<FormLabel id="reasons">{/* 회원 탈퇴하시려는 이유를 알려주세요. */}{t('MemberLeave.string5')}</FormLabel>
            <RadioGroup
              row
              aria-labelledby="reasons"
              name="reasons"
              value={reasons}
              onChange={handleInputChange}
            >
              <FormControlLabel value="1" control={<Radio />} /* label="학습 품질 불만" */ label={t('MemberLeave.string6')} />
              <FormControlLabel value="2" control={<Radio />} /* label="이용빈도 낮음" */ label={t('MemberLeave.string7')} />
			  <FormControlLabel value="3" control={<Radio />} /* label="개인정보유출 우려" */ label={t('MemberLeave.string8')} />
			  <FormControlLabel value="4" control={<Radio />} /* label="기타" */ label={t('MemberLeave.string9')} />
            </RadioGroup>
		</div>
		</Box>

		<Divider />

		<Box
		component="form"
		sx={{
			'div': { m: 1, width: '300px', marginTop: '10px', marginLeft: '20px' },
		}}
		noValidate
		autoComplete="off"
		>
		<div>
			<FormLabel id="agree">{/* 동의 */}{t('MemberLeave.string10')}</FormLabel>
            <RadioGroup
              row
              aria-labelledby="agree"
              name="agree"
              value={agree}
              onChange={handleInputChange}
            >
              <FormControlLabel value="1" control={<Radio />} /* label="위의 사항을 모두 확인하였으며, 이에 동의합니다." */ label={t('MemberLeave.string11')} />
            </RadioGroup>
		</div>
		</Box>
	

		<Divider />
		
        <DialogActions>
          <Button autoFocus onClick={handleSubmit} style={{ textTransform: 'none' }}>
            {/* 야미코딩 회원 탈퇴 */}{t('MemberLeave.string12')}
          </Button>
		  <Button autoFocus onClick={handleClose} style={{ textTransform: 'none' }}>
            {/* 취소 */}{t('Common.cancel')}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}