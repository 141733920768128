import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';

import styles from "../../../css/common.module.css";

import { useDispatch, useSelector } from "react-redux";
import $ from 'jquery';

import { chartClassAvgStudyTime, chartClassLastLogin, chartClassStudentCount, chartClassAgeDiv, chartClassAgeRange } from "../../../actions/chart";
import { chartClassLearningTime, chartClassMissionStateProgress, chartClassMissionStateProgressTotal } from "../../../actions/chart";
import { classAdminListAll } from "../../../actions/class";
import { newClassAdminStudentList } from "../../../actions/student";

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

import ClassDataTable2 from '../../GroupParentMypage/ClassDataTable2';
import ClassChart2 from '../../GroupParentMypage/ClassChart2';
import ClassChart3 from '../../GroupParentMypage/ClassChart3';
import ClassChart4 from '../../GroupParentMypage/ClassChart4';

import StudentDataTable from './StudentDataTable';

import ClassStudentChart1 from '../../GroupParentMypage/ClassStudentChart1';
import ClassStudentChart2 from '../../GroupParentMypage/ClassStudentChart2';
import ClassStudentChart3 from '../../GroupParentMypage/ClassStudentChart3';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
  const [open, setOpen] = React.useState(false);

  const [mcIdx, setMcIdx] = useState(0);
  const [mcName, setMcName] = useState("");

	const dispatch = useDispatch();	
  const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

	// const chartClassAvgStudyTimeData = useSelector(state => state.chart.chartClassAvgStudyTime);
	const chartClassLastLoginData = useSelector(state => state.chart.chartClassLastLogin);
	const chartClassStudentCountData = useSelector(state => state.chart.chartClassStudentCount);
	const chartClassAgeDivData = useSelector(state => state.chart.chartClassAgeDiv);
	const chartClassAgeRangeData = useSelector(state => state.chart.chartClassAgeRange);

	// console.log("chartClassAvgStudyTimeData : ", chartClassAvgStudyTimeData);
	// console.log("chartClassLastLoginData : ", chartClassLastLoginData);
	// console.log("chartClassStudentCountData : ", chartClassStudentCountData);
	// console.log("chartClassAgeDivData : ", chartClassAgeDivData);
	// console.log("chartClassAgeRangeData : ", chartClassAgeRangeData);

  const chartClassLearningTimeData = useSelector(state => state.chart.chartClassLearningTime);
	const chartClassMissionStateProgressData = useSelector(state => state.chart.chartClassMissionStateProgress);
	const chartClassMissionStateProgressDataTotal = useSelector(state => state.chart.chartClassMissionStateProgressTotal);

  // console.log("chartClassLearningTimeData : ", chartClassLearningTimeData);
	// console.log("chartClassMissionStateProgressData : ", chartClassMissionStateProgressData);
	// console.log("chartClassMissionStateProgressDataTotal : ", chartClassMissionStateProgressDataTotal);

  const classAdminList = useSelector(state => state.classReducer.adminlist);

  // console.log("classAdminList : ", classAdminList);

  const handleClickOpen = () => {

    let mc_idx = 0;

    dispatch(classAdminListAll(props.userIdx))
    .then(data => 
    {
        // console.log("classAdminListAll data : ", data);
        setMcIdx(data[0].mc_idx);
        setMcName(data[0].mc_name);
        mc_idx = data[0].mc_idx;

        dispatch(chartClassLastLogin(props.userIdx))
        .then(data => 
        {
            // console.log("chartClassLastLogin data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

        dispatch(chartClassStudentCount(props.userIdx))
        .then(data => 
        {
            // console.log("chartClassStudentCount data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

        dispatch(chartClassAgeDiv(props.userIdx))
        .then(data => 
        {
            // console.log("chartClassAgeDiv data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

        dispatch(chartClassAgeRange(props.userIdx))
        .then(data => 
        {
            // console.log("chartClassAgeRange data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

        dispatch(chartClassLearningTime(mc_idx, props.userIdx))
        .then(data => 
        {
            // console.log("chartClassAgeRange data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

        dispatch(chartClassMissionStateProgress(mc_idx, props.userIdx))
        .then(data => 
        {
            // console.log("chartClassMissionStateProgress data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

        dispatch(chartClassMissionStateProgressTotal(mc_idx, props.userIdx))
        .then(data => 
        {
            // console.log("chartClassMissionStateProgressTotal data : ", data);
        })
        .catch(e => {
            console.log(e);
        });
        
    })
    .catch(e => {
        console.log(e);
    });

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const returnClassList = (classList) => 
	{
		// console.log("returnClassList classList : ", classList);
		let drawLiArray = [];
		let keyValue = 0;

		// 추출한 패키지 정보를 그림
		for (let i=0; i<classList.length; i++)
		{
			drawLiArray.push(<option value={classList[i].mc_idx} key={keyValue}>{classList[i].mc_name}</option>);
			keyValue++;
		}

		return <>
			{drawLiArray}
		</>; 
	};

  const onClassChange = (event) => {
    // console.log(event.target.value);
    setMcIdx(event.target.value);
    let mc_name = classAdminList?.filter(row => row?.mc_idx == event.target.value);
    // console.log(mc_name[0].mc_name);
    setMcName(mc_name[0].mc_name);

    dispatch(chartClassLearningTime(event.target.value, props.userIdx))
    .then(data => 
    {
        // console.log("chartClassAgeRange data : ", data);
    })
    .catch(e => {
        console.log(e);
    });

		dispatch(chartClassMissionStateProgress(event.target.value, props.userIdx))
    .then(data => 
    {
        // console.log("chartClassMissionStateProgress data : ", data);
    })
    .catch(e => {
        console.log(e);
    });

		dispatch(chartClassMissionStateProgressTotal(event.target.value, props.userIdx))
    .then(data => 
    {
        // console.log("chartClassMissionStateProgressTotal data : ", data);
    })
    .catch(e => {
        console.log(e);
    });

    const boardListSend = {
      board_page : 1,
      mc_idx: event.target.value,
      user_idx: props.userIdx,
    }

    // console.log(boardListSend);

		// 클래스의 리스트 불러오기
    dispatch(newClassAdminStudentList(boardListSend))
    .then(data => 
    {
      // console.log("classListAll data : ", data);
    })
    .catch(e => {
        console.log(e);
    });

  };

  return (
    <div>
      <Button variant="contained" style={{ textTransform: 'none' }} size='small' onClick={handleClickOpen}>
        확인
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              클래스 리포트
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose} style={{ textTransform: 'none' }}>
              닫기
            </Button>
          </Toolbar>
        </AppBar>
        <List>
            <br></br>
            {/* Header 분리됨 MainLayout Header에 있음 */}
            <div className={`${styles.mypage_tab} ${styles.mypage_report}`}>
              <div className={styles.inner}>
                <span className={styles.my_tit}>{/* 클래스 관리 */}{t('GroupMypageReport.title')}</span>
                <p className={styles.for_freeuser_info}>

                </p>

                <div className={styles.my_chart_missonprogress_area}>
                  <div className={styles.head}>
                    <span>{/* 최근 로그인 및 클래스별 학생수 */}{t('GroupMypageReport.string3')}</span>
                    {/* <p>쓰게 되면 쓰고</p> */}
                  </div>
                  <div className={styles.wrap}>
                    <div className={styles.misson_progress}>
                      <div className={styles.misson_class_txt}>
                        <span className={styles.misson_class_name}>{/* 최근 로그인 */}{t('GroupMypageReport.string4')}</span>
                        <p>{/* 가장 최근에 로그인한 6개의 학습자를 표시합니다. */}{t('GroupMypageReport.string5')}</p>
                      </div>
                      <div>
                        <ClassDataTable2 RowData={chartClassLastLoginData} />
                      </div>
                    </div>
                    <div className={styles.misson_progress}>
                      <div className={styles.misson_class_txt}>
                        <span className={styles.misson_class_name}>{/* 클래스별 학생수 */}{t('GroupMypageReport.string6')}</span>
                        <p>{/* 클래스별 학생 수를 표시하며 최대 5개까지 표시합니다. */}{t('GroupMypageReport.string7')}</p>
                      </div>
                      <div>
                        <ClassChart2 RowData={chartClassStudentCountData} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.my_chart_missonprogress_area}>
                  <div className={styles.head}>
                    <span>{/* 클래스 실시간 현황 */}{t('GroupMypageReport.string8')}</span>
                    {/* <p>쓰게 되면 쓰고</p> */}
                  </div>
                  <div className={styles.wrap}>
                    <div className={styles.misson_progress}>
                      <div className={styles.misson_class_txt}>
                        <span className={styles.misson_class_name}>{/* 성별 비율 */}{t('GroupMypageReport.string9')}</span>
                        <p>{/* 클래스 전체의 성별 비율입니다. */}{t('GroupMypageReport.string10')}</p>
                      </div>
                      <div>
                        <ClassChart3  RowData={chartClassAgeDivData} />
                      </div>
                    </div>
                    <div className={styles.misson_progress}>
                      <div className={styles.misson_class_txt}>
                        <span className={styles.misson_class_name}>{/* 연령별 학생수 */}{t('GroupMypageReport.string11')}</span>
                        <p>{/* 연령별 학생의 수입니다. */}{t('GroupMypageReport.string12')}</p>
                      </div>
                      <div>
                        <ClassChart4  RowData={chartClassAgeRangeData} />
                      </div>
                    </div>
                  </div>
                </div>

                <Divider />
                <span className={styles.my_tit}>{mcName}</span>
                <p className={styles.for_freeuser_info}>
                <select name="student_info_select" id={styles.student_info_product_select} onChange={onClassChange}>
                  {/* 클래스 리스트 불러오기 */}
                  {classAdminList !== undefined ? returnClassList(classAdminList) : ""}
                </select>
                </p>
                <div className={styles.my_report_bottom_area}>
                  <div className={styles.head}>
                    <span>{/* 학습자 리스트 */}{t('GroupMypageClassReport.string4')}</span>
                    {/* <p>이용권 {totalPackageCount}개 사용가능</p>
                    <Button variant="outlined" size="small">이용권 확인</Button> */}
                    {/* <PackageInfoDiv myPackageList={myPackageList} /> */}
                  </div>
                  <div className={styles.wrap}>
                    {
                      mcIdx !== 0 ? <StudentDataTable mcIdx={mcIdx} userIdx={props.userIdx} /> : <></>
                    }
                    
                  </div>
                </div>
                <div className={styles.my_class_chart_missonprogress_area}>
                  <div className={styles.head}>
                    <span>{/* 학습시간 */}{t('GroupMypageClassReport.string5')}</span>
                    <p>{/* 학습자의 총 학습시간입니다. */}{t('GroupMypageClassReport.string6')}</p>
                  </div>
                  <div className={styles.wrap}>
                    <ClassStudentChart1  RowData={chartClassLearningTimeData} />
                  </div>
                </div>
                <div className={styles.my_class_chart_missonprogress_area}>
                  <div className={styles.head}>
                    <span>{/* 미션진도 상황 (난이도별) */}{t('GroupMypageClassReport.string7')}</span>
                    <p>{/* 순차, 반복, 선택 미션에 대한 데이터 (코딩블록배우기, 특별미션은 난이도가 없음) */}{t('GroupMypageClassReport.string8')}</p>
                  </div>
                  <div className={styles.wrap}>
                    <ClassStudentChart2  RowData={chartClassMissionStateProgressData} />
                  </div>
                </div>
                <div className={styles.my_class_chart_missonprogress_area}>
                  <div className={styles.head}>
                    <span>{/* 각 구분별 미션진도 상황 */}{t('GroupMypageClassReport.string9')}</span>
                    <p>{/* 코딩배우기, 순차, 반복, 선택, 특별 미션에 대한 데이터 */}{t('GroupMypageClassReport.string10')}</p>
                  </div>
                  <div className={styles.wrap}>
                    <ClassStudentChart3  RowData={chartClassMissionStateProgressDataTotal} />
                  </div>
                </div>
                
              </div>{/* inner */}
            </div>
            
            {/* Footer 분리됨 MainLayout Footer에 있음 */}
            
            <br></br>
        </List>

      </Dialog>
    </div>
  );
}