import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import styles from "../../css/common.module.css";
import $ from 'jquery';

import { useNavigate } from 'react-router-dom';
import { studentCreate, studentIdCheck } from "../../actions/student";
import { packageList, packageStudentInfo } from "../../actions/package";

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

export default function MypageStudentAdd() {

	/************* 데이터 처리 영역 *************/

	const authState = useSelector(state => state.auth);
    // console.log("authState : ", authState);

	const myPackage = useSelector(state => state.myPackage.list);
    // console.log("myPackage : ", myPackage);

    const studentInfo = useSelector(state => state.myPackage.studentInfo);
    // console.log("studentInfo : ", studentInfo);

	const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

	const dispatch = useDispatch();	
	const navigate = useNavigate();

	const [name, setName] = useState("");
	const [id, setId] = useState("");
	const [nickname, setNickname] = useState("");
	const [birthdayYear, setBirthdayYear] = useState("");
	const [birthdayMonth, setBirthdayMonth] = useState("");
	const [birthdayDay, setBirthdayDay] = useState("");
	const [gender, setGender] = useState(1);
	const [status, setStatus] = useState(0);
	const [password, setPassword] = useState("");
	const [repassword, setRepassword] = useState("");
	const [liArray, setLiArray] = useState([]);
	const [idCheck, setIdCheck] = useState(false);

	/************* JQuery 처리 영역 *************/

	const mypage_tab_btn_areaRef = useRef();
    const hover_bgRef = useRef();
    const onRef = useRef();

	const my_register_year_selectboxRef = useRef();
	const product_list_select_areaRef = useRef();
	const month_list_select_areaRef = useRef();
	const mypage_student_registerRef = useRef();

	const handleSubmit = (event) => {

		event.preventDefault();
		// console.log("name : ", name);
		// console.log("id : ", id);
		// console.log("nickname : ", nickname);
		// console.log("birthdayYear : ", birthdayYear);
		// console.log("birthdayMonth : ", birthdayMonth);
		// console.log("gender : ", gender);
		// console.log("status : ", status);
		// console.log("password : ", password);
		// console.log("repassword : ", repassword);
		// console.log("idCheck : ", idCheck);

		// 임시 사용자의 정보는 반드시 변경되어야 사용이 가능합니다.
		// 그 조건을 만족해야지 업데이트를 하게 됩니다.

		// 학습자 아이디가 입력이 됬는지 중복체크는 통과했는지 검사함
		if (id === "")
		{
			// return alert("아이디를 입력해주세요!");
			return alert(`${t("MypageStudentRegister.alert.string1")}`);
		}
		else
		{
			if (!idCheck)
			{
				// return alert("아이디 중복확인을 해주세요!");
				return alert(`${t("MypageStudentRegister.alert.string2")}`);
			}

			const regId =  /^[A-Za-z0-9+]{2,50}$/;
			if (regId.test(id) === false) 
			{
				// return alert('아이디는 2자리 이상 50자리 이하의 영어와 숫자만 입력해주세요.');
				return alert(`${t("MypageStudentRegister.alert.string3")}`);
			}
		}
		
		// 구매 상품을 선택했는지 체크, 이름, 닉네임, 생년월일, 성별 데이터가 있는지 체크
		if (name === "")
		{
			// return alert("이름을 입력해주세요!");
			return alert(`${t("MypageStudentRegister.alert.string5")}`);
		}
		else if (nickname === "" || nickname === null)
		{
			// return alert("닉네임을 입력해주세요!");
			return alert(`${t("MypageStudentRegister.alert.string6")}`);
		}
		else if (birthdayYear === "" || birthdayMonth === "")
		{
			// return alert("생일 중에 누락된 부분을 선택해주세요!");
			return alert(`${t("MypageStudentRegister.alert.string7")}`);
		}
		else if (gender === "")
		{
			// return alert("성별을 선택해주세요!");
			return alert(`${t("MypageStudentRegister.alert.string8")}`);
		}		

		// 정규 표현식으로 비밀번호 생성 규칙에 맞는지 체크합니다.
		let reg = new RegExp(/(?=.*\d{1,20})(?=.*[~`!@#$%\^&*()-+=]{1,20})(?=.*[a-zA-Z]{1,20}).{8,20}$/);
		// console.log(reg.test(join.password));
		if( !reg.test(password) ) 
		{
			// return  alert("비밀번호 생성 규칙은 영문, 숫자, 특수문자 포함 8자 이상 20자가지 입니다.");
			return alert(`${t("MypageStudentRegister.alert.string9")}`);
		}

		// 패스워드가 정상적으로 입력됬는지 체크
		if (password !== "" && repassword !== "")
		{
			if (password !== repassword)
			{
				// return alert("비밀번호가 일치하지 않습니다.");
				return alert(`${t("MypageStudentRegister.alert.string10")}`);
			}
		}
		else
		{
			// return alert("비밀번호를 설정해주세요!");
			return alert(`${t("MypageStudentRegister.alert.string11")}`);
		}

		let memIdx = 0;

		if (authState.isLoggedIn)
		{
			memIdx = authState.user.idx;
		}
		else
		{
			// return alert("로그인이 필요합니다.");
			return alert(`${t("MypageStudentRegister.alert.string12")}`);
		}

		// 통과된 데이터로 학습자 정보를 변경합니다.
		let sendData = {
			mem_idx: memIdx,
			id:id,
			name:name,
			nickname:nickname,
			birthday:birthdayYear + "-" + birthdayMonth + "-" + birthdayDay,
			gender:gender,
			password:password,
		};

		// console.log("sendData : ", sendData);

		// 여기서 디스패치로~
		dispatch(studentCreate(sendData))
		.then(data => 
		{
			// console.log("data : ", data);
			if (!data.success)
			{
				alert(data.msg);
				return;
			}
			else
			{
				// alert("학습자 추가가 완료되었습니다. 즐거운 플레이 되세요~");
				alert(`${t("MypageStudentRegister.alert.string13")}`);
				document.location.href = "/UserMypage/StudentInfo";
			}
		})
		.catch(e => {
			console.log(e);
		});
		
	};

	const handleInputChange = event => {
        const { name, value } = event.target;

		if (name === "nickname")
		{
			setNickname(value);
		}
		else if (name === "name")
		{
			setName(value);
		}
		// else if (name === "id")
		// {
		// 	setId(value);

		// 	// 아이디 값이 변경되면 다시 중복 체크를 하도록 합니다.
		// 	setIdCheck(false);
		// 	$(`.${styles.student_register_area}`).find('ul').eq(0).children('li').eq(1).removeClass();
		// }
		else if (name === "id")
		{
			setId(value);

			// 아이디 값이 변경되면 다시 중복 체크를 하도록 합니다.
			setIdCheck(false);
			$(`.${styles.student_register_area}`).find('ul').eq(0).children('li').eq(0).removeClass();

			const regId =  /^[A-Za-z0-9+]{2,50}$/;
			// 아이디가 규칙에 맞는지 검사해서 규칙에 맞지 않으면 경고메세지를 뿌립니다.
			if(regId.test(value) === false)
			{
				$(`.${styles.student_register_area}`).find('ul').eq(0).children('li').eq(0).addClass(`${styles.nokorean}`);
			}

			// 아이디가 공백이면 스타일시트를 지웁니다.
			if (value === "")
			{
				$(`.${styles.student_register_area}`).find('ul').eq(0).children('li').eq(0).removeClass();
			}
		}
		else if (name === "password")
		{
			setPassword(value);
		}
		else if (name === "repassword")
		{
			setRepassword(value);
		}
    };

	useEffect(() => {

		// 여기에서 jQuery를 초기화 합니다.
		// jquery ready 호출. jquery3 부터 변경됨
		$(function(){
			// console.log("jquery ready!");

            //마이페이지 탭 버튼(활성화 효과)
			$(`.${styles.mypage_tab_btn_area} li`).on('click',function()
			{
				let tg=$(this);
				
				$(`.${styles.mypage_tab_btn_area} li`).removeClass(`${styles.on}`);
				tg.addClass(`${styles.on}`);
			});
            
            //마이페이지 탭 버튼(후버 효과)
            $(window).resize(function()
			{
				if (window.innerWidth < 1200) 
				{ 
					$(`.${styles.mypage_tab_btn_area} li.${styles.hover_bg}`).css('display','none');
				}
				else
				{
					let nowbtnNum=$(`.${styles.mypage_tab_btn_area} li.${styles.on}`).index();
					let hoverBg=$(`.${styles.mypage_tab_btn_area} li.${styles.hover_bg}`);

					$(`.${styles.mypage_tab_btn_area} ul`).on('mouseenter',function()
					{
						
						$(`.${styles.mypage_tab_btn_area} li`).removeClass(`${styles.on}`);
						hoverBg.css('display','block');
						
						$(`.${styles.mypage_tab_btn_area} li`).on('mouseenter',function()
						{
							let tgIndexinfo=$(this);
							hoverBg.stop().css('left',tgIndexinfo['0']['offsetLeft']).css('width',tgIndexinfo['0']['clientWidth']);
							
							// let tgIndex=$(this).index();
							
							// if(tgIndex===1)
							// {
							// 	hoverBg.stop().css('left','242px').css('width','116px');
							// }
							// else if(tgIndex===2)
							// {
							// 	hoverBg.stop().css('left','376px').css('width','150px');
							// }
							// else if(tgIndex===3)
							// {
							// 	hoverBg.stop().css('left','542px').css('width','116px');
							// }
							// else if(tgIndex===4)
							// {
							// 	hoverBg.stop().css('left','699px').css('width','102px');
							// }
							// else if(tgIndex===5)
							// {
							// 	hoverBg.stop().css('left','849px').css('width','102px');
							// };
						});
						
					}).on('mouseleave',function()
					{
						$(`.${styles.mypage_tab_btn_area} li`).eq(nowbtnNum).addClass(`${styles.on}`);
						hoverBg.css('display','none');
						
						if(nowbtnNum===0)
						{
							hoverBg.css({left:'242px'}).css({width:'116px'});
						}
						else if(nowbtnNum===1)
						{
							hoverBg.css({left:'376px'}).css({width:'150px'});
						}
						else if(nowbtnNum===2)
						{
							hoverBg.css({left:'542px'}).css({width:'116px'});
						}
						else if(nowbtnNum===3)
						{
							hoverBg.css({left:'699px'}).css({width:'102px'});
						}
						else if(nowbtnNum===4)
						{
							hoverBg.css({left:'849px'}).css({width:'102px'});
						};

					});
					
				}
			}).resize();

			// 학습자 아이디의 css에 있는 경고 메세지를 지우고 시작합니다.
			$(`.${styles.student_register_area}`).find('ul').eq(0).children('li').eq(0).removeClass();

		});

		//생년월일 셀렉트 박스
		//출처 : https://sawol-today.tistory.com/866

		var date=new Date();
		var selYear=date.getFullYear();
	
		//현재연도를 기준으로 호출
		getYears(selYear);
		//현재연도를 select함
		// $(my_register_year_selectboxRef.current).val(selYear);
		//바뀐 연도를 기준으로 다시 option을 세팅
		$(my_register_year_selectboxRef.current).change(function()
		{
			var chgYear=$(this).val();
			// getYears(chgYear);
			$(my_register_year_selectboxRef.current).val(chgYear);
			setBirthdayYear(chgYear);
			// console.log(chgYear);
		});

		function getYears(getY)
		{
			//올해 기준으로 -30년을 보여줌
			var stY=Number(getY)-80;
			var nY=Number(getY);
			
			// $(my_register_year_selectboxRef.current).append("<option value='' disabled selected>선택</option>");
			$(my_register_year_selectboxRef.current).append("<option value='' disabled selected>" + t('Common.Select') + "</option>");

			// for(var y=stY; y<=nY; y++)
			for(let y=nY; y>=stY; y--)
			{
				// $(my_register_year_selectboxRef.current).append("<option value='"+y+"'>"+y+"년</option>");
				$(my_register_year_selectboxRef.current).append("<option value='"+y+"'>"+y+"</option>");
			}
		}

		$(document).on('click',`.${styles.month_list_select_area}`,function()
		{
			$(month_list_select_areaRef.current).children('ul').toggle();
		});
		
		$('html').click(function(e)
		{ 
			
			if(!$(product_list_select_areaRef.current).has(e.target).length)
			{
				// console.log('레이어팝업 외의 영역입니다');
				$(product_list_select_areaRef.current).children('ul').slideUp();
			}
			
		});
		
		$('html').click(function(e)
		{ 
			
			if(!$(month_list_select_areaRef.current).has(e.target).length)
			{
				// console.log('레이어팝업 외의 영역입니다');
				$(month_list_select_areaRef.current).children('ul').css('display','none');
			}
			
		});
		
		//셀렉트 박스 리스트 클릭시 텍스트 변경, 선택한 리스트에 따라 값 변경
		
		// let product;
		// $(product_list_select_areaRef.current).find(`.${styles.select_list}`).on('click',function()
		// {
		// 	console.log("click!!!");
		// 	let tg=$(this);
		// 	let tgTxt=$(this).children(`.${styles.name}`);
		// 	let txt=tgTxt.text();
		// 	let val=tg.val();
			
		// 	product=val;
		// 	console.log(product);//등록 버튼 눌렀을때 서버에 구매상품명으로 저장되어야 하는 값
		// 	$(product_list_select_areaRef.current).find(`.${styles.select_list}`).removeClass(`.${styles.on}`);
		// 	tg.addClass(`.${styles.on}`);
			
		// 	$(product_list_select_areaRef.current).find(`.${styles.selected_option}`).children(`.${styles.name}`).css('display','block');
		// 	$(product_list_select_areaRef.current).find(`.${styles.selected_option}`).children(`.${styles.p_name}`).text(txt);

		// });
		
		let month;
		
		$(month_list_select_areaRef.current).find(`.${styles.select_list}`).on('click',function()
		{
			let tg=$(this);
			let txt=tg.text();
			let val=String(tg.val()).padStart(2, '0');
			
			month=val;
			// console.log(month);
			$(month_list_select_areaRef.current).find(`.${styles.select_list}`).removeClass(`.${styles.on}`);
			tg.addClass(`.${styles.on}`);
			
			$(month_list_select_areaRef.current).find(`.${styles.selected_option}`).text(txt);

			setBirthdayMonth(month);
		});
			
		//셀렉트 박스 이벤트
		let gender;
		$(mypage_student_registerRef.current).find(`.${styles.gender}`).find('input').on('click',function()
		{
			gender=$(mypage_student_registerRef.current).find(`.${styles.gender}`).find('input[name=gender]:checked').val();
			// console.log(gender);

			setGender(gender === "boy" ? 1 : 2);
		});

		// 여기서 디스패치로~
        dispatch(packageList())
        .then(data => 
        {
            // console.log("data : ", data);
			returnPackageList(data);
        })
        .catch(e => {
            console.log(e);
        });

        // 여기서 디스패치로~
        dispatch(packageStudentInfo())
        .then(data => 
        {
            // console.log("data : ", data);

			// 학습자가 1명 이상이면 리포트 페이지로 이동합니다.
			if (data.total > 0)
				document.location.href = "/UserMypage/Report";

        })
        .catch(e => {
            console.log(e);
        });

	}, [$, dispatch]);

	const defaultBirthday = (year, month) => 
	{
		let monthIndex = 0;
		let monthString = "";

		$(my_register_year_selectboxRef.current).val(year);

		switch (month) {
			case "01":
				monthIndex = 0;
				monthString = "1월";
				break;
			case "02":
				monthIndex = 1;
				monthString = "2월";
				break;
			case "03":
				monthIndex = 2;
				monthString = "3월";
				break;
			case "04":
				monthIndex = 3;
				monthString = "4월";
				break;
			case "05":
				monthIndex = 4;
				monthString = "5월";
				break;
			case "06":
				monthIndex = 5;
				monthString = "6월";
				break;
			case "07":
				monthIndex = 6;
				monthString = "7월";
				break;
			case "08":
				monthIndex = 7;
				monthString = "8월";
				break;
			case "09":
				monthIndex = 8;
				monthString = "9월";
				break;
			case "10":
				monthIndex = 9;
				monthString = "10월";
				break;
			case "11":
				monthIndex = 10;
				monthString = "11월";
				break;
			case "12":
				monthIndex = 11;
				monthString = "12월";
				break;
		
			default:
				break;
		}

		$(month_list_select_areaRef.current).find(`.${styles.select_list}`).removeClass(`.${styles.on}`);
		$(month_list_select_areaRef.current).find(`li`).eq(monthIndex).addClass(`.${styles.on}`)
		$(month_list_select_areaRef.current).find(`.${styles.selected_option}`).text(monthString);
	}

	const defaultGender = (gender) => 
	{
		if (gender === 1)
		{
			$(mypage_student_registerRef.current).find(`.${styles.gender}`).find(":radio[name='gender'][value='boy']").attr('checked', true);
		}
		else
		{
			$(mypage_student_registerRef.current).find(`.${styles.gender}`).find(":radio[name='gender'][value='girl']").attr('checked', true);
		}
	}

	const returnPackageList = (myPackage) => 
	{
		// console.log("myPackage : ", myPackage);

		// 패키지 정보에서 필요한 정보만 추출함

		for(let i=0; i<myPackage.length; i++)
		{
			if (myPackage[i].ord_stat_cd === "A" && myPackage[i].ord_count !== myPackage[i].student_count)
			{
				// console.log("유료 패키지");
				liArray.push(myPackage[i]);
			}
		}

		// console.log("liArray : ", liArray);
	};

	const studentIDCheck = (event, id) => {

		// 학습자 중복 아이디 검사
		// console.log("dispatch studentIDCheck");
		// console.log("id : ", id);

		if(id === "")
		{
			// return alert("아이디를 입력해주세요!");
			return alert(`${t("MypageStudentRegister.alert.string1")}`);
		}

        dispatch(studentIdCheck(id))
        .then(data => 
        {
            // console.log("data : ", data);

			if(!data.success)
			{
				const regId =  /^[A-Za-z0-9+]{2,50}$/;
				// 아이디가 규칙에 맞는지 검사해서 규칙에 맞지 않으면 경고메세지를 뿌립니다.
				if(regId.test(id) === false)
				{
					$(`.${styles.student_register_area}`).find('ul').eq(0).children('li').eq(0).addClass(`${styles.nokorean}`);
				}
				else
				{
					setIdCheck(true);
					$(`.${styles.student_register_area}`).find('ul').eq(0).children('li').eq(0).removeClass();
					$(`.${styles.student_register_area}`).find('ul').eq(0).children('li').eq(0).addClass(`${styles.overlapno}`);
				}
			}
			else
			{
				setIdCheck(false);
				$(`.${styles.student_register_area}`).find('ul').eq(0).children('li').eq(0).removeClass();
				$(`.${styles.student_register_area}`).find('ul').eq(0).children('li').eq(0).addClass(`${styles.overlap}`);
			}
        })
        .catch(e => {
            console.log(e);
        });
	}

	const onChangeMonth = (event) => {
		event.preventDefault();

		// console.log(event.target.value.padStart(2, '0'));
		setBirthdayMonth(event.target.value.padStart(2, '0'));
	}

	const onChangeDay = (event) => {
		event.preventDefault();

		// console.log(event.target.value.padStart(2, '0'));
		setBirthdayDay(event.target.value.padStart(2, '0'));
	}

  return (
    <>
      	{/* Header 분리됨 MainLayout Header에 있음 */}
		
		<div className={`${styles.mypage_tab} ${styles.mypage_student_register} ${styles.add_student_area}`} ref={mypage_student_registerRef}>
			<div className={styles.inner}>
				<span className={styles.my_tit}>{/* 학습자 등록 */}{t('MypageStudentRegister.title')}</span>
				<div className={styles.student_register_area}>
					 <ul>
						<li className={styles.nokorean}>
							<span className={styles.head}>{/* 학습자 아이디 */}{t('MypageStudentRegister.string3')}</span>
							<div className={styles.right_area}>
								<input type="text" /* placeholder="아이디" */ placeholder={t('MypageStudentRegister.string5')} id={styles.student_register_id_input} defaultValue={id || ""} name="id" onChange={handleInputChange}/>
								<button type="button" onClick={(e)=>studentIDCheck(e, id)}>{/* 중복확인 */}{t('MypageStudentRegister.string4')}</button>
							</div>
						</li>
						<li>
							<span className={styles.head}>{/* 이름 */}{t('MypageStudentRegister.string6')}</span>
							<div className={styles.right_area}>
								<input type="text" /* placeholder="이름" */ placeholder={t('MypageStudentRegister.string6')} id={styles.student_register_nikname_input} defaultValue={name || ""} name="name" onChange={handleInputChange}/>
							</div>
							
						</li>
						<li className={styles.type1}>
							<span className={styles.head}>{/* 닉네임 */}{t('MypageStudentRegister.string7')}</span>
							<div className={styles.right_area}>
								<input type="text" /* placeholder="닉네임" */ placeholder={t('MypageStudentRegister.string7')} id={styles.student_register_nikname_input} defaultValue={nickname || ""} name="nickname" onChange={handleInputChange}  maxLength='7'/>
								<p className={styles.info}>{/* * 닉네임은 7자까지 입력 가능합니다. */}{t('MypageStudentRegister.string8')}</p>
							</div>
						</li>
						<li className={styles.addstring}>
							<span className={styles.head}>{/* 생년월일 */}{t('MypageStudentRegister.string9')}</span>
							<div className={styles.right_area}>
								<select id={styles.my_register_year_selectbox} ref={my_register_year_selectboxRef}></select>
								{/* <div className={styles.month_list_select_area} ref={month_list_select_areaRef}>
									<div className={styles.select_head}>
										<span className={styles.selected_option}>선택</span>
									</div>
									<ul>
										<li className={styles.select_list} value="1">1월</li>
										<li className={styles.select_list} value="2">2월</li>
										<li className={styles.select_list} value="3">3월</li>
										<li className={styles.select_list} value="4">4월</li>
										<li className={styles.select_list} value="5">5월</li>
										<li className={styles.select_list} value="6">6월</li>
										<li className={styles.select_list} value="7">7월</li>
										<li className={styles.select_list} value="8">8월</li>
										<li className={styles.select_list} value="9">9월</li>
										<li className={styles.select_list} value="10">10월</li>
										<li className={styles.select_list} value="11">11월</li>
										<li className={styles.select_list} value="12">12월</li>
									</ul>
								</div> */}
								<select name="month_list" id={styles.my_register_month_list_select} value={birthdayMonth} onChange={(e)=>onChangeMonth(e)}>
									<option value="" disabled >{/* 선택 */}{t('Common.Select')}</option>
									<option value="01">1</option>
									<option value="02">2</option>
									<option value="03">3</option>
									<option value="04">4</option>
									<option value="05">5</option>
									<option value="06">6</option>
									<option value="07">7</option>
									<option value="08">8</option>
									<option value="09">9</option>
									<option value="10">10</option>
									<option value="11">11</option>
									<option value="12">12</option>
								</select>
								<select name="day_list" id={styles.my_register_day_list_select} value={birthdayDay} onChange={(e)=>onChangeDay(e)}>
									<option value="" disabled >{/* 선택 */}{t('Common.Select')}</option>
									<option value="01">1</option>
									<option value="02">2</option>
									<option value="03">3</option>
									<option value="04">4</option>
									<option value="05">5</option>
									<option value="06">6</option>
									<option value="07">7</option>
									<option value="08">8</option>
									<option value="09">9</option>
									<option value="10">10</option>
									<option value="11">11</option>
									<option value="12">12</option>
									<option value="13">13</option>
									<option value="14">14</option>
									<option value="15">15</option>
									<option value="16">16</option>
									<option value="17">17</option>
									<option value="18">18</option>
									<option value="19">19</option>
									<option value="20">20</option>
									<option value="21">21</option>
									<option value="22">22</option>
									<option value="23">23</option>
									<option value="24">24</option>
									<option value="25">25</option>
									<option value="26">26</option>
									<option value="27">27</option>
									<option value="28">28</option>
									<option value="29">29</option>
									<option value="30">30</option>
									<option value="31">31</option>
								</select>
							</div>
						</li>
						<li className={styles.gender}>
							<span className={styles.head}>{/* 성별 */}{t('MypageStudentRegister.string10')}</span>
							<div className={styles.right_area}>
								<div className={styles.radio_btn_area}>
									<input type="radio" name="gender" value="boy" defaultChecked />
									<span>{/* 남 */}{t('MypageStudentRegister.string11')}</span>
								</div>
								<div className={styles.radio_btn_area}>
									<input type="radio" name="gender" value="girl"/>
									<span>{/* 여 */}{t('MypageStudentRegister.string12')}</span>
								</div>
							</div>
						</li>
						<li className={styles.type1}>
							<span className={styles.head}>{/* 비밀번호 */}{t('MypageStudentRegister.string13')}</span>
							<div className={styles.right_area}>
								<input type="password" /* placeholder="비밀번호" */ placeholder={t('MypageStudentRegister.string13')} id={styles.parents_info_password}  name="password" onChange={handleInputChange} maxLength={100} />
								<p className={styles.info}>{/* * 비밀번호는 영문, 숫자, 특수문자 조합으로 8~20자리를 권장합니다 */}{t('MypageStudentRegister.string14')}</p>
							</div>
						</li>
						<li>
							<span className={styles.head}>{/* 비밀번호 확인 */}{t('MypageStudentRegister.string15')}</span>
							<div className={styles.right_area}>
								<input type="password" /* placeholder="비밀번호 확인" */ placeholder={t('MypageStudentRegister.string15')} id={styles.parents_info_password_confirm} name="repassword" onChange={handleInputChange} maxLength={100} />
							</div>
						</li>
					 </ul>
					 <div className={styles.btn_area}>
						<a href="/UserMypage/StudentInfo">{/* 취소 */}{t('Common.cancel')}</a>
						<button type="submit"  onClick={handleSubmit}>{/* 등록 */}{t('Common.confirm')}</button>
					 </div>
				</div>
			</div>{/* inner */}
		</div>{/* mypage_tab */}
		
		{/* Footer 분리됨 MainLayout Footer에 있음 */}
    </>
  );
}