import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { FormControl, TextField } from '@mui/material';

import { useDispatch } from "react-redux";
import { studentSelect, studentModify } from "../../../actions/student";

import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

export default function StudentModify() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const mst_id  = useParams().mst_id;
    console.log("mst_id : ", mst_id);

    const initialStudentState = {
        name: '',
        id: '',
    };

    const [student, setStudent] = useState(initialStudentState);

    const handleInputChange = event => {
        const { name, value } = event.target;
        setStudent({ ...student, [name]: value });
    };

    const handleSubmit = (event) => {

        event.preventDefault();

        console.log("student : ", student);

        const sendData = {
            id: student.id,
            name: student.name,
        };

        console.log("sendData : ", sendData);

        // 여기서 디스패치로~
        dispatch(studentModify(sendData))
        .then(data => 
        {
            console.log("response data : ", data);
            if (!data.success)
                alert(data.msg);
            
            navigate("/dashboard/StudentList");
        })
        .catch(e => {
            console.log(e);
        });

    };

    useEffect(() => {
        
        console.log("dispatch studentSelect");
        dispatch(studentSelect(mst_id))
        .then(data => 
        {
            console.log("data : ", data);
            setStudent({
                id:  data.id,
                name:  data.name,
            });
        })
        .catch(e => {
            console.log(e);
        });

        return () => {      // 언마운트
            console.log("언마운트!");
        };

    }, [dispatch])

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
      <FormControl>
            <TextField
                id="id"
                label="아이디"
                helperText="아이디는 수정할 수 없습니다."
                variant="standard"
                name="id"
                onChange={handleInputChange}
                value={student.id || ""}
                InputProps={{
                    readOnly: true,
                }}
            />
            <TextField
                id="name"
                label="이름"
                helperText="이름을 수정해주세요."
                variant="standard"
                name="name"
                onChange={handleInputChange}
                value={student.name || ""}
            />
        </FormControl>
        
      </CardContent>
      <CardActions>
        <Button onClick={handleSubmit} size="small" style={{ textTransform: 'none' }}>학습자 수정</Button>
      </CardActions>
    </Card>
  );
}