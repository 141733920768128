import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import Box from '@mui/material/Box';

import Divider from '@mui/material/Divider';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import * as common from "../../lib";

import { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";

// import { classCreate, classListAll, classNameCheck } from "../../actions/class";

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function PackageSelectDialog(props) {
  
  const [open, setOpen] = useState(false);
//   const [helperText, setHelperText] = useState("이용권을 선택해주세요.");
//   const [helperText, setHelperText] = useState(t('ClassStudentCommonDlg.string26'));
//   const [helperText, setHelperText] = useState("");

//   const dispatch = useDispatch();	

  const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  	useEffect(() => {

		// setHelperText(t('ClassStudentCommonDlg.string39'));

	}, []);

  return (
    <div>
      <Button variant="outlined" style={{ textTransform: 'none' }} size='small' onClick={handleClickOpen}>{/* 이용권 내역 */}{t('ClassStudentCommonDlg.string58')}</Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
		maxWidth="md"
		fullWidth={true}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
			{/* 이용권 내역 */}{t('ClassStudentCommonDlg.string58')}
        </BootstrapDialogTitle>
		
		<Box
		component="form"
		sx={{
			display: 'flex',
    		alignItems: 'center',
			'& .MuiTextField-root': { m: 1, width: '750px', marginTop: '20px', marginLeft: '20px', marginRight: '5px'},
			'& .MuiButton-root': { m: 1, marginLeft: '10px', marginRight: '10px'},
		}}
		noValidate
		autoComplete="off"
		>
			<Paper sx={{ width: '100%', overflow: 'hidden' }}>
				<TableContainer sx={{ maxHeight: 450 }}>
					<Table stickyHeader aria-label="sticky table" size="small">
					<TableHead>
						<TableRow>
						<TableCell align="center">{/* 번호 */}{t('ClassStudentCommonDlg.string18')}</TableCell>
						<TableCell align="center">{/* 이름 */}{t('ClassStudentCommonDlg.string19')}</TableCell>
						<TableCell align="center">{/* 종류 */}{t('ClassStudentCommonDlg.string20')}</TableCell>
						<TableCell align="center">{/* 사용여부(적용아이디) */}{t('ClassStudentCommonDlg.string59')}</TableCell>
						<TableCell align="center">{/* 유효기간 */}{t('ClassStudentCommonDlg.string60')}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
					{
						props.productInfo?.length !== 0 ? props.productInfo && props.productInfo?.filter(row => new Date(row?.mm_valid_end_date) > new Date() || row?.mm_valid_end_date === null, row => row?.mm_state_cd === "A")
						.sort((a, b) => 
						{
								if (a.mm_valid_end_date === null) {
									return 1;
								}
							
								if (b.mm_valid_end_date === null) {
									return -1;
								}
							
								if (a.mm_valid_end_date === b.mm_valid_end_date) {
									return 0;
								}
							
								return b.mm_valid_end_date?.replace("-", "").replace("-", "").substr(0, 8) < a.mm_valid_end_date?.replace("-", "").replace("-", "").substr(0, 8) ? -1 : 1;
							}
						)
						.map((row, index) => {
							return (
							<TableRow hover role="checkbox" tabIndex={-1} key={index}>
								<TableCell component="th" scope="row" align="center">
								{index+1}
								</TableCell>
								<TableCell align="center">{row.mm_name} ({row.expiration_date}{row.expiration_date_unit})</TableCell>
								<TableCell align="center">
								{
									{
										// PG : `유료결제`,
										// COUPON : `쿠폰`,
										// INAPP : `인앱`,
										PG : t('ClassStudentCommonDlg.string22'),
										COUPON : t('ClassStudentCommonDlg.string23'),
										INAPP : t('ClassStudentCommonDlg.string24'),
										Manual : t('ClassStudentCommonDlg.string42'),
									} [row.mm_type]
								}
								</TableCell>
								<TableCell align="center">{common.returnCouponState(row.mm_state)}{row.mst_id !== null ? '(' + row.mst_id + ')' : ""}</TableCell>
								<TableCell align="center">{row.mm_valid_end_date}</TableCell>
							</TableRow>
							);
						})
						:
						<TableRow>
							<TableCell component="th" scope="row" align="center" colSpan="5">{/* 데이터 없음 */}{t('Common.No_data')}</TableCell>
						</TableRow>
						}
					</TableBody>
					</Table>
				</TableContainer>
				
			</Paper>
		</Box>

		<Divider />
		
        <DialogActions>
		  <Button autoFocus onClick={handleClose} style={{ textTransform: 'none' }}>
            {/* 취소 */}{t('Common.cancel')}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}