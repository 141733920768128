import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import styles from "../../css/common.module.css";
import $ from 'jquery';
import { useNavigate, useParams } from 'react-router-dom';

import { inappPaymentSelect } from "../../actions/inapp_payment";
import * as common from "../../lib";

export default function GroupMypageInappInfoMore() {

	const authState = useSelector(state => state.auth);
    // console.log("authState : ", authState);

	const ina_idx  = useParams().ina_idx;

	const initialInappState = {
		ina_desc:null,
		ina_idx:null,
		ina_name:null,
		inp_cancel:null,
		inp_count:null,
		inp_date:null,
		inp_idx:null,
		inp_no:null,
		inp_package_name:null,
		inp_product_id:null,
		inp_purchase_token:null,
		inp_stat_cd:null,
		inp_type:null,
		mem_email:null,
		mem_idx:null,
		mem_name:null,
		sell_prc_unit: null,
		valid_date: null
	  };
  
	const [inapp, setInapp] = useState(initialInappState);

	const dispatch = useDispatch();	

	const mypage_tab_btn_areaRef =  useRef();
    const hover_bgRef =  useRef();
    const onRef =  useRef();

	const pay_info_list_pagebtn_areaRef =  useRef();

	useEffect(() => {

		// 여기에서 jQuery를 초기화 합니다.
		// jquery ready 호출. jquery3 부터 변경됨
		$(function(){
			// console.log("jquery ready!");

            //마이페이지 탭 버튼(활성화 효과)
			$(`.${styles.mypage_tab_btn_area} li`).on('click',function()
			{
				let tg=$(this);
				
				$(`.${styles.mypage_tab_btn_area} li`).removeClass(`${styles.on}`);
				tg.addClass(`${styles.on}`);
			});
            
            //마이페이지 탭 버튼(후버 효과)
            $(window).resize(function()
			{
				if (window.innerWidth < 1200) 
				{ 
					$(`.${styles.mypage_tab_btn_area} li.${styles.hover_bg}`).css('display','none');
				}
				else
				{
					let nowbtnNum=$(`.${styles.mypage_tab_btn_area} li.${styles.on}`).index();
					let hoverBg=$(`.${styles.mypage_tab_btn_area} li.${styles.hover_bg}`);

					$(`.${styles.mypage_tab_btn_area} ul`).on('mouseenter',function()
					{
						
						$(`.${styles.mypage_tab_btn_area} li`).removeClass(`${styles.on}`);
						hoverBg.css('display','block');
						
						$(`.${styles.mypage_tab_btn_area} li`).on('mouseenter',function()
						{
							let tgIndexinfo=$(this);
							hoverBg.stop().css('left',tgIndexinfo['0']['offsetLeft']).css('width',tgIndexinfo['0']['clientWidth']);
							
							// let tgIndex=$(this).index();
							
							// if(tgIndex===1)
							// {
							// 	hoverBg.stop().css('left','167px').css('width','116px');
							// }
							// else if(tgIndex===2)
							// {
							// 	hoverBg.stop().css('left','300px').css('width','150px');
							// }
							// else if(tgIndex===3)
							// {
							// 	hoverBg.stop().css('left','467px').css('width','116px');
							// }
							// else if(tgIndex===4)
							// {
							// 	hoverBg.stop().css('left','624px').css('width','102px');
							// }
							// else if(tgIndex===5)
							// {
							// 	hoverBg.stop().css('left','774px').css('width','102px');
							// }
							// else if(tgIndex===6)
							// {
							// 	hoverBg.stop().css('left','924px').css('width','102px');
							// };
						});
						
					}).on('mouseleave',function()
					{
						$(`.${styles.mypage_tab_btn_area} li`).eq(nowbtnNum).addClass(`${styles.on}`);
						hoverBg.css('display','none');
						
						if(nowbtnNum===0)
						{
							hoverBg.css({left:'167px'}).css({width:'116px'});
						}
						else if(nowbtnNum===1)
						{
							hoverBg.css({left:'300px'}).css({width:'150px'});
						}
						else if(nowbtnNum===2)
						{
							hoverBg.css({left:'467px'}).css({width:'116px'});
						}
						else if(nowbtnNum===3)
						{
							hoverBg.css({left:'624px'}).css({width:'102px'});
						}
						else if(nowbtnNum===4)
						{
							hoverBg.css({left:'774px'}).css({width:'102px'});
						}
						else if(nowbtnNum===5)
						{
							hoverBg.css({left:'924px'}).css({width:'102px'});
						};

					});
					
				}
			}).resize();
			
			//결제 정보 페이지 페이징 버튼	
			$(pay_info_list_pagebtn_areaRef.current).find('li').on('click',function()
			{
				let tg=$(this);
				let num=tg.index();
				let prev;
				let next;
				let last;
				let nowBtn=$(pay_info_list_pagebtn_areaRef.current).children(`${styles.on}`);
				let nowIndex=nowBtn.index();
				
				$(pay_info_list_pagebtn_areaRef.current).children('li').removeClass(`${styles.on}`);
				
				if(tg.hasClass(`${styles.arrow_btn}`)==true)
				{
					if(tg.hasClass(`${styles.first}`)==true)
					{
						tg=$(pay_info_list_pagebtn_areaRef.current).find('li').eq(2);
					}
					else if(tg.hasClass(`${styles.prev}`)==true)
					{
						prev=nowIndex-1;
						
						if(nowIndex===2)
						{
							tg=$(pay_info_list_pagebtn_areaRef.current).find('li').eq(2);
						}else
						{
							tg=$(pay_info_list_pagebtn_areaRef.current).find('li').eq(prev);
						};
					}
					else if(tg.hasClass(`${styles.next}`)==true)
					{
						let lastNum=$(pay_info_list_pagebtn_areaRef.current).find('li').last().index();
						next=nowIndex+1;
						last=lastNum-2;
						
						if(nowIndex===last)
						{
							tg=$(pay_info_list_pagebtn_areaRef.current).find('li').eq(last);
						}else
						{
							tg=$(pay_info_list_pagebtn_areaRef.current).find('li').eq(next);
						};
					}
					else if(tg.hasClass(`${styles.last}`)==true)
					{
						last=num-2;
						tg=$(pay_info_list_pagebtn_areaRef.current).find('li').eq(last);
					};
					
					tg.addClass(`${styles.on}`);
					
				}
				else
				{
					tg.addClass(`${styles.on}`);
				};
			});

		});

		// console.log("dispatch inappPaymentSelect");
        dispatch(inappPaymentSelect(ina_idx))
        .then(data => 
        {
            // console.log("data : ", data);

			setInapp({
				ina_desc:data.ina_desc,
				ina_idx:data.ina_idx,
				ina_name:data.ina_name,
				inp_cancel:data.inp_cancel,
				inp_count:data.inp_count,
				inp_date:data.inp_date,
				inp_idx:data.inp_idx,
				inp_no:data.inp_no,
				inp_package_name:data.inp_package_name,
				inp_product_id:data.inp_product_id,
				inp_purchase_token:data.inp_purchase_token,
				inp_stat_cd:data.inp_stat_cd,
				inp_type:data.inp_type,
				mem_email:data.mem_email,
				mem_idx:data.mem_idx,
				mem_name:data.mem_name,
				sell_prc_unit: data.sell_prc_unit,
				valid_date: data.valid_date
			});
            
        })
        .catch(e => {
            console.log(e);
        });

	}, [$, dispatch]);

	const vBankNumCopy = (event, value) =>
	{
		event.preventDefault();

		const t = document.createElement("textarea");
		document.body.appendChild(t);
		t.value = value;
		t.select();
		document.execCommand('copy');
		document.body.removeChild(t);

		return alert("복사완료!");
	}

	// console.log("order : ", order);

  return (
    <>
      	{/* Header 분리됨 MainLayout Header에 있음 */}

		<div className={styles.mypage_top_line_banner}>
			<div className={styles.inner}>
				<span>학부모 페이지</span>
			</div>
		</div>
		<div className={styles.mypage_tab_btn_area} ref={mypage_tab_btn_areaRef}>
			<div className={styles.inner}>
				<ul>
					<li className={styles.hover_bg} ref={hover_bgRef}></li>
					<li><a href="/GroupUserMypage/GroupReport">학습 리포트</a></li>
					<li><a href="/GroupUserMypage/GroupStudentInfo">학습자 정보/관리</a></li>
					<li><a href="/GroupUserMypage/GroupParentsInfo">학부모 정보</a></li>
					<li className={styles.on} ref={onRef}><a href="/GroupUserMypage/GroupPayInfo">결제 정보</a></li>
					<li><a href="/GroupUserMypage/GroupCouponInfo">쿠폰 관리</a></li>
					<li><a href="/GroupUserMypage/GroupQnaInfo">문의 내역</a></li>
				</ul>
			</div>
		</div>
		<div className={`${styles.mypage_tab} ${styles.mypage_pay_info_more}`}>
			<div className={styles.inner}>
				<span className={styles.my_tit}>결제 상세정보</span>
				<div className={styles.pay_info_more_area}>
					 <ul>
						<li>
							<span className={styles.head}>주문일자</span>
							<p>{common.payDateChangeReturn(inapp.inp_date)}{/*2022.03.25 - 16시35분*/}</p>
						</li>
						<li>
							<span className={styles.head}>만료일</span>
							<p>{common.payDateChangeReturn(inapp.valid_date)}{/*2022.04.25 - 16시35분*/}</p>
						</li>
						<li>
							<span className={styles.head}>주문번호</span>
							<p>{inapp.inp_no}</p>
						</li>
						<li>
							<span className={styles.head}>주문자</span>
							<p>{inapp.mem_name}</p>
						</li>
						<li>
							<span className={styles.head}>상품명</span>
							<p>{inapp.ina_name} (INAPP)</p>
						</li>
						<li>
							<span className={styles.head}>상품 설명</span>
							<p>{inapp.ina_desc}</p>
						</li>
						<li>
							<span className={styles.head}>인앱 결제 스토어</span>
							<p>{inapp.inp_type}</p>
						</li>
						<li>
							<span className={styles.head}>가격</span>
							<p>{common.priceToString(inapp.sell_prc_unit)}원</p>
						</li>
						<li>
							<span className={styles.head}>수량</span>
							<p>{inapp.inp_count || ""} 개</p>
						</li>
						{
							inapp.order_stat_cd === "A" ?
								<li className={styles.pay_more_total}>
									<span className={styles.head}>총 결제금액</span>
									<p>{common.priceToString(inapp.sell_prc_unit * inapp.inp_count) || ""}원</p>
								</li>
							:
							""
						}
						<li className={styles.pay_more_total}>
							<span className={styles.head}>결제상태</span>
							<p>{inapp.inp_stat_cd === "R" ? "환불" : common.returnOrderState(inapp.inp_stat_cd) || ""}</p>
						</li>
					 </ul>
					<a href="/GroupUserMypage/GroupPayInfo">확인</a>
				</div>
			</div>{/* inner */}
		</div>{/* mypage_tab */}
		
		{/* Footer 분리됨 MainLayout Footer에 있음 */}
    </>
  );
}