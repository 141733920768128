import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import Box from '@mui/material/Box';
import Input from '@mui/material/Input';

import { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { passwordUpdate } from "../../actions/auth";
import { useNavigate } from 'react-router-dom';

const ariaLabel = { 'aria-label': 'description' };

const UserPasswordChange = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const initialPasswordState = {
        current_password: null, 
        new_password: null,
        confirm_password: null,
    };

    const [password, setPassword] = useState(initialPasswordState);

    const handleInputChange = event => {
        const { name, value } = event.target;
        setPassword({ ...password, [name]: value });
    };

    const handleSubmit = (event) => {

        event.preventDefault();

        const { current_password, new_password, confirm_password } = password;

        if (new_password !== confirm_password)
        {
            alert("패스워드가 일치하지 않습니다.\n다시확인해주세요.");
            return;
        }

        const sendData = {
            current_password: current_password,
            new_password: new_password,
        };

        // 여기서 디스패치로~
        dispatch(passwordUpdate(sendData))
        .then(data => 
        {
            console.log("data : ", data);
            if (!data.success)
            {
                alert(data.msg);
                return;
            }
            else
            {
                alert("패스워드가 변경되었습니다.");
                navigate("/dashboard/profile");
                return;
            }
        })
        .catch(e => {
            console.log(e);
        });
    };

    useEffect(() => {
        
        return () => {      // 언마운트
            console.log("언마운트!");
        };

    }, [])

    return (
        <>
            사용자 패스워드 변경 <br /><br />
            <Card sx={{ minWidth: 275 }}>
            <CardContent>
                <Box
                sx={{
                    '& > :not(style)': { m: 1, width: '350px' },
                }}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
                >
                현재 패스워드 : <Input placeholder="현재 패스워드" type="password" id="current_password" name="current_password" inputProps={ariaLabel} onChange={handleInputChange} />
                </Box>
                <Box
                sx={{
                    '& > :not(style)': { m: 1, width: '350px' },
                }}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
                >
                새로운 패스워드 : <Input placeholder="새로운 패스워드" type="password" id="new_password" name="new_password" inputProps={ariaLabel} onChange={handleInputChange} />
                </Box>
                <Box
                sx={{
                    '& > :not(style)': { m: 1, width: '350px' },
                }}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
                >
                패스워드 확인 : <Input placeholder="패스워드 확인" type="password" id="confirm_password" name="confirm_password" inputProps={ariaLabel} onChange={handleInputChange} />
                </Box>
            </CardContent>
            <CardActions>
                <Button onClick={handleSubmit} size="small" style={{ textTransform: 'none' }}>패스워드 변경</Button>
            </CardActions>
            </Card>
        </>
    );
    
};

export default UserPasswordChange;