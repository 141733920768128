import * as React from 'react';
import { useEffect } from 'react';
import styles from "../css/common.module.css";
import $ from 'jquery';

export default function Use() {

	useEffect(() => {

		// 여기에서 jQuery를 초기화 합니다.
		$(document).ready(function(){

		});

	}, [$]);

  	return (
    <>
		
		<div className={styles.wrap}>
			<div className={`${styles.privacy_area} ${styles.area1}`}>
				<span className={styles.tit}>第1章 総則</span>
				<br/><span className={styles.sub_tit}>第1条（目的）</span>
				<p>
				本約款は、(株)ウィークラバー(以下「会社」という)が提供する教育コンテンツ及びこれに付随する諸サービスの利用に関して、会社と会員間の権利、義務及び責任事項、その他必要な事項を規定することを目的とします。
				</p>
				<span className={styles.sub_tit}>第2条（用語の定義）</span>
				<p>
				1. 本約款で使用する用語の定義は以下の通りです
				<br/><br/>* "会社"とは、オンラインまたはモバイル機器を通じてサービスを提供する事業者を意味します。
				<br/>* "会員"とは、本約款に基づいて利用契約を締結し、会社が提供するサービスを利用する者を意味します。
				<br/>* 「教育サービス」とは、会社が会員に提供する教育コンテンツ及びこれに付随する諸サービスを意味します。
				<br/>* 「モバイル機器」とは、コンテンツをダウンロードまたはインストールして使用できる機器で、携帯電話、スマートフォン、携帯情報端末(PDA)、タブレットとパソコン、ノートパソコンなども含みます。
				<br/>* 「モバイル教育サービス」とは、会社が会員にモバイル機器を通じて提供する教育コンテンツ及びこれに付随した諸サービスを意味します。
				<br/>* 「コンテンツ」とは、会社が教育サービスの提供と関連してデジタル方式で製作した有料または無料の内容物すべて(教育コンテンツおよびネットワークサービス、アプリケーションなど)を意味します。
				<br/>* "アカウント(ID)"とは、会員の識別と教育サービス利用のために会員が選定し、会社が付与する文字、数字、または特殊文字の組み合わせを意味します。
				<br/>* "アカウント情報"とは、会員のアカウント、パスワード、氏名など、会員が会社に提供した一般情報、機器情報、利用料金の決済情報などを総称します。
				<br/>* 「キャラクター」とは、会員が教育サービスの利用のために教育コンテンツ内で会社が提供する方法によって選定·操縦するデータを意味します。
				<br/>* "パスワード"とは、会員が付与されたアカウントと一致する会員であることを確認し、会員の情報及び権益保護のために会員自身が選定して秘密に管理する文字、数字、または特殊文字の組み合わせを意味します。
				<br/>* "掲示物"とは、会員がサービスを利用するにあたり、会員が掲示した文字、文書、絵、音声、映像、またはこれらの組み合わせからなるすべての情報をいいます。
				<br/>この約款で使用する用語の定義は、本条第1項で定めるものを除き、関係法令及びサービス別政策で定めるところにより、これに定めないものは一般的な商慣行に従います。
				<br/>* 本利用約款で"*"表示は"号"と同じ意味を意味します。
				</p>
				<span className={styles.sub_tit}>第3条（会社情報等の提供）</span>
				<p>
				会社は、次の各号の事項を会員が分かりやすいように教育サービス内に表示します。 但し、個人情報処理方針と約款は会員が接続画面を通じて閲覧できるようにすることができます

				<br/><br/>* 商号及び代表者の氏名
				<br/>* 営業所所在地住所(会員の苦情を処理できる所の住所を含む)
				<br/>* 電話番号、電子メールアドレス
				<br/>* 事業者登録番号
				<br/>* 通信販売業申告番号
				<br/>* 個人情報処理方針
				<br/>* サービス利用約款
				</p>
				<span className={styles.sub_tit}>第4条（約款の効力及び変更）</span>
				<p>
				1. 会社はこの約款の内容を会員が分かるように教育サービス内またはその連結画面、サービスホームページ(yamecoding.com )に掲示します。
				<br/><br/>2. 会社は、会員が会社と本約款の内容に関して質問及び回答ができるように措置をとります。
				<br/><br/>3. 会社は教育サービスを利用しようとする者(以下"利用者"という)が約款の内容を簡単に分かるように作成し、約款に同意する前に約款に定められている内容のうち、契約の申込みの撤回、過誤納金の払い戻し、契約解除·解約、会社の免責事項および会員に対する被害補償などの重要な内容を会員が簡単に理解できるように利用者の同意を得るようします。
				<br/><br/>4.会社は「電子商取引等における消費者保護に関する法律」、「約款の規制に関する法律」、「ゲーム産業振興に関する法律」、「情報通信網利用促進及び情報保護等に関する法律」、「コンテンツ産業振興法」等、関連法令に違反しない範囲でこの約款を改正することができます。
				<br/><br/>5. 会社が約款を改正する場合は、適用日及び改正内容、改正理由などを明示し、少なくともその適用日の7日前から教育サービス内またはその連結画面に掲示して会員にお知らせします。 ただし、変更された内容が会員に不利または重大な事項の変更である場合には、その適用日の30日以前までに本文と同じ方法で告知し、第34条第1項の方法で会員に通知します。 その場合は、改訂前の内容と改訂後の内容を明確に比較し、会員がわかりやすいように表示します。
				<br/><br/>6. 会社が約款を改正する場合、改正約款のお知らせ後、改正約款の適用に対する会員の同意可否を確認します。 会社は第2項のお知らせまたは通知をする場合、会員が改正約款に対して同意または拒否の意思表示をしなければ同意したと見ることができるという内容も一緒にお知らせまたは通知をし、会員がこの約款施行日までに拒否の意思表示をしない場合は、改正約款に同意したと見ることができます。
				<br/><br/>7.会員が改正約款の適用に同意しない場合、会社または会員は教育サービス利用契約を解約できます。					
				</p>
				<span className={styles.sub_tit}>第5条（約款外準則）</span>
				<p>
				本約款で定められていない事項と本約款の解釈については、「電子商取引等における消費者保護に関する法律」、「約款の規制に関する法律」、「ゲーム産業振興に関する法律」、「情報通信網利用促進及び情報保護等に関する法律」、「コンテンツ産業振興法」等、関連法令又は商慣例に従います。
				</p>
				<span className={styles.sub_tit}>第6条（運営政策）</span>
				<p>
				1. 約款を適用するために必要な事項と会員の権益を保護し、教育コンテンツ内の秩序を維持するために会社は約款で具体的な範囲を定め委任した事項を教育サービス運営政策(以下「運営政策」といいます)で定めることができます。
				<br/><br/>2. 会社は運営ポリシーの内容を会員が知ることができるよう、教育サービス内またはその接続画面、サービスホームページ(yamecoding.com )に掲示します。
				<br/><br/>3. 運営ポリシーを改正する場合、第4条第5項の手順に従います。 ただし、運営方針の改正が次の各号のいずれかに該当する場合には、第4条第2項の方法で事前にお知らせします。
				<br/><br/>* 約款で具体的に範囲を定めて委任した事項を改正する場合
				<br/>* 会員の権利·義務と関連のない事項を改正する場合
				<br/>* 運営ポリシーの内容が約款で定められた内容と根本的に異なることなく、会員が予測可能な範囲内で運営ポリシーを改正する場合
				</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area2}`}>
				<span className={styles.tit}>第2章 利用契約の締結</span>
				<br/><span className={styles.sub_tit}>第7条（利用契約の締結及び適用）</span>
				<p>
				1. 会社が提供する教育サービスを利用しようとする者は、会社がアプリケーションの初期画面やサービスホームページ(yamecoding.com )で提供する利用申込書を作成する方法で利用申請をしなければなりません。
				<br/><br/>2. 利用者は利用申請時に会社が要求する諸般の情報を提供しなければなりません。
				<br/><br/>3. 利用者は第1項の利用申請時に関係法令に従って本人の実際の情報を記載しなければなりません。 実名または識別情報を虚偽で記載したり、他人の名義を盗用した場合、この約款による会員の権利を主張することができず、会社は払い戻しなしで利用契約を取り消し、または解約することができます。
				<br/><br/>4. 会社は会社の方針及び関連法令に基づき、会員のサービス利用範囲、利用時間などに差をつけることができます。 会社は、選択的サービスの提供やサービス範囲の調整のために必要な場合、追加情報を要請することができます。
				<br/><br/>5. 会社は、会社が利用者に要求する情報について、利用者が実際の情報を正確に記載して利用申請をした場合に、相当な理由がない限り、利用申請を承諾します。
				<br/><br/>6. 会社は、次の各号のいずれかに該当する利用申請に対しては、承諾をしないか、又は事後承諾を取り消すことができます。
				<br/><br/>* 第7条に違反して利用申請を行う場合
				<br/>* 会社がサービスを提供していない国で異常または迂回的な方法でサービスを利用する場合
				<br/>* 「ゲーム産業振興に関する法律」、「情報通信網利用促進及び情報保護等に関する法律」及び関連法令で禁止する行為をする目的で申請する場合
				<br/>* 不正な用途または営利を追求する目的で教育サービスを利用しようとする場合
				<br/>* その他上記各号に準ずる事由として承諾が不適切であると判断される場合
				<br/><br/>7. 会社は、次の各号のいずれかに該当する場合には、その事由が解消されるまで承諾を留保することができます
				<br/><br/>* 会社の設備に余裕がなかったり、技術的障害があった場合
				<br/>* サービス上の障害またはサービス利用料金の決済手段に障害が発生した場合
				<br/>* その他上記各号に準ずる事由として利用申請の承諾が困難な場合
				</p>
				<span className={styles.sub_tit}>第8条(会員アカウント(ID)及びパスワード)</span>
				<p>
				1. 会社は会員に対し、会員の情報保護、サービス利用案内などの便宜のため、会員が選定した一定の文字、数字、または特殊文字の組み合わせをアカウントで付与します。
				<br/><br/>2. 会社はアカウント情報を通じて当該会員のサービス利用可否などの諸般会員管理業務を遂行します。
				<br/><br/>3. 会員は自分のアカウント情報を善良な管理者としての注意義務を果たして管理しなければなりません。 会員が本人のアカウント情報を疎かに管理したり、第三者に利用を承諾することによって発生する損害については、会員に責任があります。
				<br/><br/>4. パスワードの管理責任は会員にあり、会員が希望する場合はセキュリティ上の理由などでいつでも変更が可能です。
				<br/><br/>5. 会員は定期的にパスワードを変更してください
				</p>
				<span className={styles.sub_tit}>第9条（会員情報の提供及び変更）</span>
				<p>
				1. 会員は、本約款に基づいて会社に情報を提供しなければならない場合には、真実の情報を提供しなければならず、虚偽の情報提供によって発生した不利益については保護されません。
				<br/><br/>2. 会員は個人情報管理画面を通じていつでも自分の個人情報を閲覧·修正することができます。 ただし、サービス管理のために必要な一部の情報は、修正が制限されることがあります。
				<br/><br/>3. 会員は、会員登録申請時に記載した事項が変更された場合、オンラインで修正するか、その他の方法により会社にその変更事項を通知しなければなりません。
				<br/><br/>4. 第3項の変更事項を会社に知らせなかったため発生した不利益に対して、会社は責任を負いません。
				</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area3}`}>
				<span className={styles.tit}>第3章 個人情報の管理</span>
				<br/><span className={styles.sub_tit}>第10条(個人情報の保護及び使用)</span>
				<p>
				1. 会社は、関連法令の定めるところにより、アカウント情報を含む会員の個人情報を保護するために努力します。 会員個人情報の保護及び使用については、関連法令及び会社が別途に告知する個人情報処理方針が適用されます。
				<br/><br/>2.サービスの一部として提供される個別サービスを除いたもので、ホームページ及び教育サービス別ウェブサイトから単純にリンクされた第三者提供のサービスに対しては、会社の個人情報処理方針が適用されません。
				<br/><br/>3. 会社は関連法令により関連国家機関などの要請がある場合を除き、会員の個人情報を本人の同意なしに他人に提供しません。
				<br/><br/>4. 会社は会員の責に帰すべき事由により露出された会員のアカウント情報を含むすべての情報に対して一切の責任を負いません。
				</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area4}`}>
				<span className={styles.tit}>第4章 利用契約当事者の義務</span>
				<br/><span className={styles.sub_tit}>第11条 (会社の義務)</span>
				<p>
				1. 会社は関連法令、本約款に定める権利の行使及び義務の履行を信義に従って誠実に遵守します。
				<br/><br/>2.会社は、会員が安全にサービスを利用できるよう、個人情報(信用情報を含む)保護のためにセキュリティシステムを備える必要があり、個人情報処理方針を公示して遵守します。 会社は、本約款および個人情報処理方針で定められた場合を除き、会員の個人情報が第三者に公開または提供されないようにします。
				<br/><br/>3. 会社は継続的で安定したサービスの提供のためにサービス改善を行っている間、設備に障害が生じたりデータなどが滅失·毀損された時には天災地変、非常事態、現在の技術では解決が不可能な障害や欠陥などやむを得ない事由がない限り、遅滞なくこれを修理または復旧するよう最善の努力を尽くします。
				</p>
				<span className={styles.sub_tit}>第 12 条（会員の義務）</span>
				<p>
				1. 会員は、会社が提供するサービスの利用に関して、次の各号に該当する行為をしてはなりません。
				<br/><br/>* 利用申請または会員情報変更時に虚偽内容を記載
				<br/>* 会社が掲示した情報の変更
				<br/>* 他人のクレジットカード·有線/無線電話·銀行口座などを盗用して有料コンテンツを購入する行為、他の会員のID及びパスワードを不正使用する行為など、他人の情報(個人情報と決済情報を含む)盗用
				<br/>* 他の会員の個人情報を無断で収集·保存·掲示·流布する行為
				<br/>* 自分の個人情報を他人に無断で掲示または流布する行為
				<br/>* 会社が提供または承認していないコンピュータプログラムや機器または装置を製作、配布、利用、広告する行為
				<br/>* 法令により伝送または掲示が禁止された情報(コンピュータプログラム)やコンピュータソフトウェア·ハードウェアまたは電気通信装備の正常な作動を妨害·破壊する目的で考案されたウイルス·コンピュータコード·ファイル·プログラムなどを故意に伝送·掲示·流布または使用する行為
				<br/>* 会社から特別な権利を付与されずに教育サービスを変更したり、教育サービスに他のプログラムを追加·挿入したり、サーバーをハッキング·逆設計したり、ソースコードやデータを流出·変更したり、別のサーバーを構築したり、ウェブサイトの一部を任意に変更·盗用する行為
				<br/>* サービスを射幸性のある方法またはその他不健全な方法で利用する行為
				<br/>* 会社の同意なしに教育サービスを営利、営業、広告、広報、政治活動、選挙運動など本来の用途以外の用途で利用する行為
				<br/>* 会社のサービスを利用して得た情報を無断で複製·流通·助長したり、商業的に利用する行為、知られたり知られていないバグを悪用してサービスを利用する行為
				<br/>* 他人を欺いて利益を得る行為、会社のサービス利用に関して他人に被害を与える行為
				<br/>* 会社や他人の知的財産権または肖像権を侵害する行為、他人の名誉を毀損または損害を与える行為
				<br/>* その他関連法令に違反し、又は善良な風俗その他社会通念に反する行為
				<br/>* 1号から14号までの行為を誘導し、又は広告する行為
				<br/><br/>2. 会員は、本約款の規定、利用案内及び教育サービスに関してお知らせした注意事項、会社が通知する事項などを確認し、遵守する義務があります。
				<br/><br/>3. 会員のアカウント及びpc、モバイル機器に関する管理責任は会員にあり、これを他人が利用できるようにしてはいけません。 会員のアカウント及びパソコン、モバイル機器の管理不良や他人に利用を承諾することによって発生する損害について、会社は責任を負いません。
				<br/><br/>4. 不注意によって発生する損害について、会社は責任を負いません
				<br/><br/>5. 会社は1、2項及び次の各号に掲げる行為の具体的な内容を運営方針に定めることができ、会員はこれに従わなければなりません
				<br/><br/>* 掲示板利用及びサービス利用方法
				<br/>* その他会員の教育サービス利用に対する本質的権利を侵害しない範囲内で、会社が教育サービス運営上必要と認められる事項						
				</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area5}`}>
				<span className={styles.tit}>第5章 サービスの利用及び利用制限</span>
				<br/><span className={styles.sub_tit}>第13条(サービスの提供及び停止)</span>
				<p>
				1. 会社は第5条の規定により利用契約が完了した会員に直ちにサービスを利用できるようにします。 ただし、一部のサービスの場合、会社の必要に応じて指定された日時からサービスを開始することができます。
				<br/><br/>2. 会社は会員に教育サービスを提供する際、本約款に定めるサービスを含め、その他の付加的なサービスを一緒に提供することができます。
				<br/><br/>3. 会社は会員の等級を区分し、利用時間、利用回数、提供サービスの範囲などを細分化して利用に差をつけることができます。
				<br/><br/>4. 教育サービスは会社の営業方針に従って定められた時間内に提供します。 会社は、教育サービスの提供時間をアプリケーション利用案内画面やサービスホームページ(yamecoding.com )に適切な方法で案内します。
				<br/><br/>5. 第4項にもかかわらず、会社は次の各号の場合、一定の時間、教育サービスが提供されないことがあり、その時間、会社は教育サービスを提供する義務がありません
				<br/><br/>* パソコンなどの情報通信設備の保守点検、交換、定期点検またはゲーム内容や教育サービスの修正のために必要な場合
				<br/>* ハッキングなどの電子的侵害事故、通信事故、会員の異常なゲーム利用行動、予期せぬ教育サービスの不安定性に対応するために必要な場合
				<br/>* 関連法令で特定時間または方法で教育サービスの提供を禁止する場合
				<br/>* 天災地変、非常事態、停電、サービス設備の障害、またはサービス利用の急増などで正常な教育サービスの提供が不可能な場合
				<br/><br/>6. 会社は第5項第1号の場合、毎週または隔週単位で一定時間を定めて教育サービスを中止することができます。 この場合、会社は少なくとも24時間前にその事実を会員にアプリケーションの初期画面やサービスホームページ(yamecoding.com )に告知します。
				<br/><br/>7. 第5項第2号の場合、会社は事前の通知なしに教育サービスを一時停止することができます。 会社はこのような場合、その事実をアプリケーションの初期画面やサービスホームページ(yamecoding.com )に事後告知することができます。
				<br/><br/>8. 会社は会社が提供する無料サービスの利用に関して会員に発生したいかなる損害に対しても責任を負いません。 ただし、会社の故意または重大な過失により発生した損害の場合は除きます。
				<br/><br/>9. 第5項第3号から第4号までの場合、会社は技術上·運営上の必要により教育サービスの全てを中断することができ、30日前にホームページにこれを公示して教育サービスの提供を中断することができます。 事前に通知できないやむを得ない事情がある場合は、事後に通知することができます。
				<br/><br/>10. 会社が第10項に基づき教育サービスを終了する場合、会員は無料サービス及び使用期間が残っていない有料サービス·継続的有料利用契約·期間制有料アイテムに対して損害賠償を請求することができません。 使用期間に制限がない有料アイテムは、サービス終了日までを当該有料アイテムの使用期間とみなします。
				<br/><br/>11. ダウンロードしてインストールしたアプリケーションまたはネットワークを通じて利用するサービスの場合、モバイル機器または移動通信会社の特性に合わせて提供されます。 モバイル機器の変更·番号変更または海外ローミングの場合は、コンテンツの全部または一部の利用が不可能になることがあり、この場合、会社は責任を負いません。
				<br/><br/>12. ダウンロードしてインストールしたアプリケーションまたはネットワークを通じて利用するサービスの場合は、バックグラウンド操作が進行されることがあります。 この場合、モバイル機器または移動通信会社の特性に合うように追加料金が発生することがあり、これに関連して会社は責任を負いません。						
				</p>
				<span className={styles.sub_tit}>第14条(サービスの変更及び中断)</span>
				<p>
				1. 会員は、会社が提供する教育サービスを本約款、運営ポリシー、および会社が設定した規則に従って利用することができます。
				<br/><br/>2.会社は円滑な教育サービス提供のため、運営上または技術上の必要に応じてサービスを変更することができ、変更前に当該内容を教育サービス内に告知します。 ただし、バグ·不具合等の修正や緊急アップデート等やむを得ず変更する必要がある場合、重大な変更に該当しない場合、サーバ機器の不具合、緊急セキュリティ問題等やむを得ない事情がある場合には、事後にお知らせすることができます。
				<br/><br/>3.営業譲渡·分割·合併などによる営業の廃止、ゲーム提供の契約満了、当該教育サービスの著しい収益悪化など経営上の重大な事由により教育サービスを持続することが困難な場合、技術上運営上必要な場合、会社はサービス全部を中断することができます。 この場合、中断日の30日前までに中断日·中断理由·補償条件などをゲーム初期画面またはその連結画面を通じてお知らせし、第34条第1項の方法で会員に通知します。
				<br/><br/>4.会社が第5項に基づき教育サービスを終了する場合、会員は無料サービス及び使用期間が残っていない有料サービス·継続的有料利用契約·期間制有料アイテムに対して損害賠償を請求することができません。 使用期間に制限のない有料アイテムは、サービス終了日までを当該有料アイテムの使用期間とみなします
				</p>
				<span className={styles.sub_tit}>第15条(サービスの変更及び中断)</span>
				<p>
				1. 会社はサービスの円滑かつ安定的な運営及びサービス品質の改善のため、会員の個人情報を除いた会員PCなどの端末設定及び仕様情報、実行中のプログラム情報、会員のモバイル機器情報(設定、仕様、運営体制、バージョンなど)を収集·活用できます。
				<br/><br/>2. 会社はサービス改善及び会員対象サービス紹介などの目的で会員に追加情報を要請することができます。 この要請に対して会員は承諾または拒絶することができ、会社がこの要請をする場合には会員がこの要請を拒絶することができるという旨を一緒に告知します。					
				</p>
				<span className={styles.sub_tit}>第16条（広告の提供）</span>
				<p>
				1. 会社は、サービスの運営に関連して、教育サービス内に広告を掲載することができます。 また、受信に同意した会員に限り、電子メール、文字サービス(LMS/SMS)、プッシュメッセージ(Push Notification)などの方法で広告性情報を送信することができます。 この場合、会員はいつでも受信を拒否することができ、会社は会員の受信拒否時に広告性情報を送信しません。
				<br/><br/>2. 会社が提供するサービス中のバナー、またはリンクなどを通じて、他人が提供する広告やサービスにアクセスすることができます。
				<br/><br/>3. 第2項に基づき他人が提供する広告やサービスに接続された場合、当該領域で提供するサービスは会社のサービス領域ではないので、会社が信頼性、安定性などを保障せず、それによる会員の損害に対しても会社は責任を負いません。 ただし、会社が故意または重過失により損害の発生を容易にしたり、損害防止のための措置を取らなかった場合は、この限りではありません。
				</p>
				<span className={styles.sub_tit}>第17条(著作権等の帰属)</span>
				<p>
				1. 教育サービス内の会社が制作したコンテンツに対する著作権、その他の知的財産権は会社の所有であり、会社は会員にこれを教育サービスと関連して会社が定めた条件の下で利用できる権限のみを付与します。
				<br/><br/>2. 会員は、会社が提供する教育サービスを利用することによって得た情報のうち、会社または提供業者に知的財産権が帰属した情報を会社または提供業者の事前承諾なしに下記の各号の方法で利用したり、第三者に利用させてはなりません。
				<br/><br/>* 複製、転送、出版、配布、放送その他の方法による営利目的の利用
				<br/>* その他この約款または会社の政策で禁止する目的のための利用
				<br/><br/>3. 会員は、教育コンテンツ内で表示されたり、教育サービスと関連して会員または他の利用者がクライアントまたは教育サービスを通じてアップロードまたはイメージ、サウンドおよびすべての資料および情報(以下「利用者コンテンツ」といいます。)に対して、会社が次のような方法と条件で利用することを許可します。
				<br/><br/>* 当該利用者コンテンツを利用、編集形式の変更及びその他の変形するもの(公表、複製、公演、伝送、配布、放送)、2次的著作物の作成などいかなる形であれ利用してはならず、利用期間と地域にはやはり制限されます。
				<br/>* 利用者コンテンツを製作した利用者の事前同意なしに取引を目的に利用者コンテンツを販売、貸与、譲渡行為をしない
				<br/><br/>4. 教育コンテンツ内で表示されず、教育サービスと一体化されていない会員の利用者コンテンツ(例えば、一般掲示板などでの掲示物)について、会社は会員の明示的な同意なしに利用せず、会員はいつでもこのような利用者コンテンツを削除することができます。
				<br/><br/>5. 会社は会員が掲示または登録する教育サービス内の掲示物、掲示内容について第12条に規定する禁止行為に該当すると判断される場合、事前通知なしにこれを削除または移動または登録を拒否することができます。
				<br/><br/>6. この条(第15条)は、会社が教育サービスを運営する間有効であり、会員退会後も継続的に適用されます。
				</p>
				<span className={styles.sub_tit}>第18条 (利用商品)</span>
				<p>
				1. 会社は会員が別途費用を支払わずに利用できる教育サービス(以下「無料サービス」という)と、会社があらかじめ策定した料金を支払って利用する教育サービス(以下「有料サービス」という)を提供することができ、会員は教育サービスを選択して利用することができます。 モバイル教育サービス内で会員が購入した有料コンテンツは、該当アプリケーションをダウンロードしたり、インストールしたモバイル機器でのみ利用できます。
				<br/><br/>2. 教育サービスの利用に対する代金支給方法は、会社があらかじめ定めた方法を通じて行うことができます。
				<br/><br/>3. 有料サービスの利用を申し込んだ会員は、会社が定めた方法に従って代金を誠実に納付しなければなりません。
				<br/><br/>4. 会員が購入した有料サービスの使用期間は、有料アカウントを維持する間、維持されます。 ただし、有料サービスの購入時に別途に使用期間を定めた場合はそれに従い、第14条第5項によりサービス中断が行われる場合は、期間の定めのない有料コンテンツの利用期間は、サービス中断のお知らせ時に告知されたサービスの中断日までとします。
				</p>
				<span className={styles.sub_tit}>第19条（有料コンテンツ）</span>
				<p>
				1. 有料コンテンツには、アイテム、追加ミッション、音楽など、会社が指定したすべての有料コンテンツが含まれます。
				<br/><br/>2. 会社は決済業者(移動通信会社、クレジットカード会社など)及び会社の方針に従って、会員のアイテムチャージ·保有·利用限度を設定することができます。
				<br/><br/>3. 会社は次の各号に該当する場合、会員の決済申請に対して承諾しなかったり、事後に承諾を取り消すことができます。
				<br/><br/>* 1. 決済した料金を納付しなかったり、納付者の確認が不可能な場合
				<br/>* 2. 他人の決済情報を盗用した場合
				<br/>* 3. 未成年者の充電申請について法定代理人が同意しない場合
				<br/>* 4. その他に1号ないし3号に準ずる事由として承諾が不適切であると判断される場合
				</p>
				<span className={styles.sub_tit}>第20条(会員に対するサービス利用制限)</span>
				<p>
				1. 会社は、会員に対し、次の各号の区分により、会員の教育サービスの利用を制限することができます。 利用制限が行われる具体的な会員の義務違反理由は、第26条により個別運営政策で定めることにします。
				<br/><br/>* アカウントの一部権限制限:一定期間、または永久に掲示板の書き込みなど、会員アカウントの一定権限を制限
				<br/>* アカウント利用制限:一定期間、または永久に会員アカウントの利用を制限
				<br/>* 会員利用制限:一定期間または永久に会員の教育サービス利用を制限
				<br/><br/>2. 会社の利用制限が正当な場合、会社は利用制限により会員が被った損害を賠償しません。
				<br/><br/>3. 会員のアカウント(ID)を他の人が利用して約款または運営ポリシーに違反した場合でも、会員本人の教育サービス利用が第1項の基準によって制限されることがあります。
				<br/><br/>4. 会社はサービス改善と会員情報保護のために運営ポリシーで別途告知する一定期間以上教育コンテンツを利用していないアカウントなどユーザー情報を削除することができます。 ただし、会社は情報削除の7日前に会員にその事実を告知しなければなりません。
				</p>
				<span className={styles.sub_tit}>第21条（暫定措置としての利用制限）</span>
				<p>
				1. 会社は次の各号に該当する問題に対する調査が完了するまでアカウントを停止することができます..
				<br/><br/>* アカウントがハッキングまたは盗用されたという正当な申告が受け付けられた場合
				<br/>* 不法プログラムの使用者、作業場など違法行為者として合理的に疑われる場合
				<br/>* その他、上記各号に準ずる事由によりアカウントの暫定措置が必要な場合
				<br/><br/>2. 第1項の場合、会社は調査が完了した後、教育サービス利用期間に比例して一定額を支給し、利用する会員に停止された期間だけ会員の教育サービス利用期間を延長します。 ただし、第1項による違法行為者と判明した場合は、この限りではありません
				</p>
				<span className={styles.sub_tit}>第22条(利用制限措置の事由と手続)</span>
				<p>
				1. 会社は違反行為の内容、程度、回数、結果など諸般の事情を考慮し、利用制限が行われる具体的な事由及び手続きを運営政策で定めます。
				<br/><br/>会社が第20条第1項に定める利用制限措置を行う場合には、次の各号の事項を会員に事前通知します。 ただし、緊急に措置する必要がある場合は、事後に通知することができます。
				<br/><br/>* 利用制限措置の事由
				<br/>* 利用制限措置の類型及び期間
				<br/>* 利用制限措置に対する異議申し立て方法
				</p>
				<span className={styles.sub_tit}>第23条（利用制限措置に対する異議申立手続）</span>
				<p>
				1. 会員が会社の利用制限措置を不服とするときは、この措置の通知を受けた日から15日以内に不服理由を記載した異議申請書を書面、電子メールまたはこれに準ずる方法で会社に提出しなければなりません。
				<br/><br/>2. 会社は第1項の異議申請書を受け付けた日から15日以内に不服理由について、書面、電子メール、電話、またはこれに準ずる方法で回答します。 ただし、会社はこの期間内に回答が難しい場合は、その理由と処理日程を通知します。
				<br/><br/>3. 会社は不服理由が妥当な場合には、これに基づく措置をとります。
				</p>
				<span className={styles.sub_tit}>第 24 条（コンピュータプログラムの設置）</span>
				<p>
				1. 会社はサービス提供のために必要な場合、会員に会社が提供するコンピュータプログラムの設置を要請することができます。 会社は、会員が該当プログラムを設置する前まで、容量、機能、除去方法、他のプログラムに及ぼす影響などの重要事項をプログラムの性格によって適切な方法で告知し、プログラム設置に対する同意を得なければなりません。 ただし、会員がActiveXプログラムを自動的にインストールするようにブラウザオプションを設定した場合、会社は会員がActiveXプログラムのインストールに同意したものとみなし、別途の意思確認なしに該当プログラムをインストールすることができます。
				<br/><br/>2. 会社が第1項で告知した内容を大きく変更しない範囲内でプログラムをパッチしたりアップデートしたりする場合、重要事項告知及び同意を省略することができます。
				<br/><br/>3. 会社は、コンピュータプログラムのインストールまたはアップデート時にデータを円滑に送信するため、会員間でデータを中継·送信する技術を使用することができます。 会員は、PCリソース(ストレージ、ネットワーク機器など)を通じて他の会員にデータを中継伝送したり、自分が中継伝送されることに同意します。
				</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area6}`}>
				<span className={styles.tit}>第6章 請約撤回、契約解除·解約</span>
				<br/><span className={styles.sub_tit}>第 25条(モバイル代金決済)</span>
				<p>
				1. コンテンツに対する購入代金の賦課と納付は、原則として移動通信会社やオープンマーケット事業者等で定めるポリシーや方法に従います。 また、各決済手段別の限度が会社やオープンマーケット事業者が定める政策または政府の方針に従って付与されたり調整されたりすることがあります。
				<br/><br/>2. コンテンツの購入代金を外貨で決済する場合は、為替·手数料などにより実際の請求金額がサービスのショップなどで表示された価格と異なる場合があります。
				</p>
				<span className={styles.sub_tit}>第26条（申込みの撤回等）</span>
				<p>
				1. 会社と有料サービス利用に関する契約を締結した会員は、購入契約日と有料教育サービス利用可能日のうち、遅い日から7日以内に別途手数料·違約金などの負担なく契約の申込みの撤回が可能です。 ただし、サービスを使用していない場合です。 (第30条参照)
				<br/><br/>2. 会員は、次の各号に該当する場合には、会社の意思に反して第1項に基づく契約の申込みの撤回をすることができません。
				<br/><br/>* 会員に責任のある事由により財貨等が滅失したり毀損された場合
				<br/>* 消費者の使用または一部消費で財貨などの価値が顕著に減少した場合
				<br/>- 購入後すぐに使用または適用される有料コンテンツ
				<br/> - 開封行為を使用と見ることができるか、開封時に効用が決定されるコンテンツの開封行為がある場合
				<br/>* コンテンツの提供が開始された場合。 ただし、可分的コンテンツで構成された契約の場合、提供が開始されなかった部分はこの限りではありません。
				<br/><br/>3. 会社は第2項第2号から第3号の規定により契約の申込みの撤回などが不可能な財貨などの場合にはその事実を財貨などの包装やその他会員が簡単に分かる場所に明確に明示したり試験使用商品を提供するなどの方法で契約申込の撤回などの権利行使が妨害されないように措置しなければなりません。 ただし、第2項第3号により会員が請約撤回などができない場合には、請約撤回などが不可能だという事実の表示と共に試験使用商品を提供するなどの方法で請約撤回などの権利行使が妨害されないようにします。
				もし、会社がこのような措置を取らなかった場合は、第2項第2号から第3号までの契約の申込みの撤回制限事由にもかかわらず、会員は契約申込みの撤回をすることができます。
				<br/><br/>4. 会員は、第1項から第3項までの規定にかかわらず、有料サービスの内容が表示·広告内容と異なるか、契約内容と異なる場合は、購入日または有料サービス利用可能日から3ヶ月以内、その事実を知った日または分かった日から30日以内に契約申込みを撤回することができます。
				<br/><br/>5. アプリケーション関連会員が契約の申込みを撤回する場合、会社はプラットフォーム事業者またはオープンマーケット事業者を通じて購入内訳を確認します。 また、会社は会員の正当な撤回理由を確認するため、会員から提供された情報を通じて会員に連絡することができ、追加的な証拠を要求することができます。
				<br/><br/>6. 未成年者である会員が決済が必要な有料教育サービス契約を締結する場合、会社は法定代理人の同意がなければ未成年者本人または法定代理人がその契約を取り消すことができるという内容を告知し、未成年者が法定代理人の同意なしに契約を締結した場合、未成年者本人または法定代理人は会社にその契約を取り消すことができます。 ただし、未成年者が法定代理人が範囲を定めて処分を許した財産で有料教育サービス契約を締結した場合、または未成年者がトリックで自分を成年者と信じさせたり、法定代理人の同意があると信じさせた場合には取り消すことができません。
				<br/><br/>7。 教育サービス利用契約の当事者が未成年者であるかどうかは、決済が行われた機器、決済実行者情報、決済手段名義者などを根拠に判断します。 また、会社は正当な取り消しであることを確認するために、未成年者および法定代理人であることを証明できる書類の提出を要請することができます。
						
				</p>
				<span className={styles.sub_tit}>第27条（過誤納金の還付）</span>
				<p>
				1. 会社は過誤金が発生した場合、利用代金の決済と同じ方法で過誤金全額を払い戻さなければなりません。 ただし、同じ方法で払い戻しができない場合は、これを事前に告知します。
				<br/><br/>2. 会社の責任のある事由により課金が発生した場合、会社は契約費用、手数料などに関係なく課金全額を払い戻しします。 ただし、会員の責任ある事由で過誤金が発生した場合、会社が過誤金を払い戻しするのにかかる費用は合理的な範囲内で会員が負担しなければなりません。
				<br/><br/>3. 会社は会員が主張する過誤金に対して払い戻しを拒否する場合、正当に利用代金が課されたことを立証する責任を負います。
				<br/><br/>4. アプリケーションの場合、払い戻しはサービスを利用しているモバイル機器のオペレーティングシステムの種類によって、各オープンマーケット事業者または会社の払い戻しポリシーに従って行われます。
				<br/><br/>5. 会社は過誤納金の払い戻しを処理するため、会員から提供された情報から会員に連絡することができ、必要な情報の提供を要請することができます。
				<br/><br/>6. 会社は過誤金の払い戻し手続きをコンテンツ利用者保護指針に従って処理します。
				</p>
				<span className={styles.sub_tit}>第28条（会員の解除及び解約）</span>
				<p>
				1. 会社は、会員が本約款で定めた会員の義務に違反した場合、会員に対する事前通知後、契約を解約することができます。 ただし、会員が現行法違反及び故意または重大な過失により会社に損害を与えた場合は、事前通知なしに利用契約を解約することができます。
				<br/><br/>2. 会員が保有するアカウント(ID)に対して第1項の措置が3回以上適用された場合、会社は会員名義のすべてのアカウント(ID)に対して契約を解約することができます。
				<br/><br/>3. 会社は、会員の個人情報保護及びアカウント盗用防止のため、最後のサービス利用日から1年が経過したアカウント(ID)の個人情報を破棄して利用契約を解約したり、当該アカウント(ID)の個人情報を他の利用者の個人情報と分離して別途保存·管理し、サービス利用を制限することができます。
				<br/><br/>4. 第3項によるサービス制限後、2年が経過するまで会員がサービス利用再開の申請をしない場合、会社は当該アカウント(ID)に対して利用契約を解約し、関連情報を削除することができます。
				<br/><br/>5. 会社が利用契約を解約する場合、会社は会員に書面、電子メールまたはこれに準ずる方法で次の各号の事項を会員に通知します。
				<br/><br/>* 解約事由
				<br />* 解約日
				<br/><br/>6. 第1項ただし書の場合、会員は有料サービスの使用権限を喪失し、これによる払い戻し及び損害賠償を請求することができません
				</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area7}`}>
				<span className={styles.tit}>第7章 損害賠償及び免責条項等</span>
				<br/><span className={styles.sub_tit} id="refund_policy">第29条 (損害賠償)</span>
				<p>
				1. 会社が故意または重過失により会員に損害を与えた場合、損害に対して賠償する責任があります。
				<br/><br/>2. 会社は有料サービスの欠陥などによる会員被害補償の基準·範囲·方法及び手続きに関する事項をコンテンツ利用者保護指針に従って処理します。 ただし、有料サービスの購入時、またはこの約款で被害補償について別途に定めた場合、それに従います。
				<br/><br/>3. 会員が会社から購入した有料サービスが会社の責に帰すべき事由により損失した場合、会社はこれを損失以前の状態に原状復旧し、原状復旧が不可能だったり困難な場合、会社の選択によって同種の類似価値を持つ有料サービスを再び提供したり、有料サービス購入代金を払い戻ししなければなりません。
				<br/><br/>4. 会員が本約款を違反して会社に損害を与えた場合、会員は会社に対しその損害に対して賠償する責任があります。
				</p>
				<span className={styles.sub_tit}>第 30条 (払い戻し)</span>
				<p>
				1. 会員が購入した有料サービス利用契約の解約、契約の申込みの撤回などの理由で払い戻しを要請する場合は、次の各号の金額の払い戻しを受けることができます。
				<br/><br/>* 払い戻しは全額使用していない状態でなければ払い戻しができません。
				<br/>* お支払い後、有料サービスを使用していない状態で払い戻しができますが、一部でも使用した状態では払い戻しできません。
				<br/>* 払い戻しは購入日から1週間以内に可能です。
				<br/>* 仮想口座のお申し込みと払い戻しは一日に2回まで可能です。
				<br/><br/>2. 第1項で払い戻し基準を定めていない商品または会社が有料サービス販売の際、第1項と異なる払い戻し基準を定めて告知した商品については、会社が別途告知する払い戻し基準が適用されます。
				<br/><br/>3. 会社は、会員が第1項に払い戻し意思表示をした日から5営業日以内に第1項に規定した金額を払い戻ししなければなりません。 ただし、収納確認が必要な決済手段(例:携帯決済など)の場合は、収納確認日から5営業日以内にこれを払い戻すようにします。
				</p>
				<span className={styles.sub_tit}>第31条（会社の免責）</span>
				<p>
				1. 会社は戦時、事変、天災地変、非常事態、現在の技術では解決できない技術的欠陥、その他の不可抗力的な理由でサービスを提供できない場合は責任が免除されます。
				<br/><br/>2. 会社は会員の責に帰すべき事由による教育サービスの中止、利用障害及び契約解除について責任が免除されます。
				<br/><br/>3. 会社は、基幹通信事業者が電気通信サービスを中止したり、正常に提供しなかったりして会員に損害が発生した場合について、会社の故意または重大な過失がない限り、責任が免除されます。
				<br/><br/>4. 会社は事前に告知された教育サービス用設備の補修、交換、定期点検、工事などやむを得ない理由で教育サービスが中止されたり、障害が発生した場合について、会社の故意または重大な過失がない限り、責任が免除されます。
				<br/><br/>5. 会社は会員のコンピュータ環境によって発生する諸般の問題または会社の故意または重大な過失のないネットワーク環境によって発生する問題に対して責任が免除されます。
				<br/><br/>6. 会社は、会員または第三者が教育サービス内またはウェブサイト上に掲示または伝送した情報、資料、事実の信頼度、正確性などの内容については、会社の故意または重大な過失がない限り、責任が免除されます。
				<br/><br/>7. 会社は会員相互間または会員と第三者間の教育サービスを媒介に発生した紛争に対して介入する義務がなく、これによる損害を賠償する責任もありません。
				<br/><br/>8。会社が提供する教育サービスのうち、無料サービスの場合は、会社の故意または重大な過失がない限り、会社は損害賠償をいたしません。
				<br/><br/>9. 本教育サービスの一部の教育サービスは、他の事業者が提供する教育サービスを通じて提供されることができ、会社は他の事業者が提供する教育サービスによって発生した損害などについては、会社の故意または重大な過失がない限り、責任が免除されます。
				<br/><br/>10. 会社は、会員が教育サービスを利用し、教育サービスに対する取捨選択または利用によって発生する損害などについて、会社の故意または重大な過失がない限り、責任が免除されます。
				<br/><br/>11. 会社は、会員のアプリケーション·サイバー資産の損失に対し、会社の故意または重大な過失がない限り、責任が免除されます。
				<br/><br/>12. 会社は会員のコンピュータエラーによる損害が発生した場合、または個人情報及び電子メールアドレスを不正確に記載したり、未記載で損害が発生した場合について、会社の故意または重大な過失がない限り、責任が免除されます。
				<br/><br/>13. 会社は関連法令、政府政策などによって教育サービスまたは会員によって教育サービス利用時間などを制限することができ、このような制限事項および制限によって発生する教育サービス利用関連諸般事項に対しては責任が免除されます。
				<br/><br/>14. 会社は会員がパスワードを管理していないために発生する第三者決済に対して責任を負いません。 ただし、会社の故意または重過失による場合は、この限りではありません。
				<br/><br/>15。会員がモバイル機器の変更、モバイル機器の番号変更、運営体制(OS)バージョンの変更、海外ローミング、通信会社の変更などにより、コンテンツの全部や一部の機能を利用できない場合、会社はこれに対して責任を負いません。 ただし、会社の故意または重過失による場合は、この限りではありません。
				<br/><br/>16. 会員が会社が提供するコンテンツやアカウント情報を削除した場合、会社はこれに対して責任を負いません。 ただし、会社の故意または重過失による場合は、この限りではありません。
				<br/><br/>17. 会社は臨時会員(guest)のサービス利用によって発生した損害に対しては責任を負いません。 ただし、会社の故意または重過失による場合は、この限りではありません。
				</p>
				<span className={styles.sub_tit}>第32条（会員への通知及びお知らせ）</span>
				<p>
				1. 会社が会員に通知する場合、会員の電子メールアドレス、文字メッセージ(LMS/SMS)などで行うことができます。
				<br/><br/>2. 会社は会員全体に通知をする場合、7日以上教育サービス内に掲示したりポップアップ画面などを提示することで、第1項の通知に代えることができます。					
				</p>
				<span className={styles.sub_tit}>第33条(裁判権及び準拠法)</span>
				<p>
				この約款は大韓民国の法律に従って規律され解釈されます。 会社と会員の間で発生した紛争で訴訟が提起される場合は、法令に定めた手続きに従った裁判所を管轄裁判所とします。						
				</p>
				<span className={styles.sub_tit}>第34条 (会員の苦情処理及び紛争解決)</span>
				<p>
				1. 会社は会員の便宜を考慮して会員の意見や不満を提示する方法を教育サービス内またはその連結画面に案内します。 会社はこのような会員の意見や不満を処理するための専担人材を運営します。
				<br/><br/>2. 会社は会員から提起される意見や不満が正当だと客観的に認められる場合には、合理的な期間内にこれを迅速に処理します。 ただし、処理に長期間がかかる場合は、会員に長期間がかかる理由と処理日程を教育サービス内でお知らせするか、第32条第1項に従って通知します。
				<br/><br/>3. 会社と会員の間で紛争が発生し、第3の紛争調停機関が調整する場合、会社は利用制限など会員に措置した事項を誠実に証明し、調停機関の調整に従うことができます。
				</p>
			</div>

		</div>
				
    </>
  );
}