import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import styles from "../../css/common.module.css";
import $ from 'jquery';
import { useParams } from 'react-router-dom';

import { couponUserSelect } from "../../actions/coupon";

import * as common from "../../lib";

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

export default function MypagePayInfo() {

    const coupon = useSelector(state => state.coupon.userselect);
    // console.log("coupon : ", coupon);

	const dispatch = useDispatch();	

	const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

	let language = localStorage.getItem("language");

	const mypage_tab_btn_areaRef =  useRef();
    const hover_bgRef =  useRef();
    const onRef =  useRef();

	let cou_number  = useParams().cou_number;
    // console.log("cou_number : ", cou_number);
	let board_page  = useParams().page;
    // console.log("board_page : ", board_page);
	let board_condition  = useParams().condition;
    // console.log("board_condition : ", board_condition);

    if (cou_number === undefined)
		cou_number = "";

	useEffect(() => {

		// 여기에서 jQuery를 초기화 합니다.
		// jquery ready 호출. jquery3 부터 변경됨
		$(function(){
			// console.log("jquery ready!");

            //마이페이지 탭 버튼(활성화 효과)
			$(`.${styles.mypage_tab_btn_area} li`).on('click',function()
			{
				let tg=$(this);
				
				$(`.${styles.mypage_tab_btn_area} li`).removeClass(`${styles.on}`);
				tg.addClass(`${styles.on}`);
			});
            
            //마이페이지 탭 버튼(후버 효과)
            $(window).resize(function()
			{
				if (window.innerWidth < 1200) 
				{ 
					$(`.${styles.mypage_tab_btn_area} li.${styles.hover_bg}`).css('display','none');
				}
				else
				{
					let nowbtnNum=$(`.${styles.mypage_tab_btn_area} li.${styles.on}`).index();
					let hoverBg=$(`.${styles.mypage_tab_btn_area} li.${styles.hover_bg}`);

					$(`.${styles.mypage_tab_btn_area} ul`).on('mouseenter',function()
					{
						
						$(`.${styles.mypage_tab_btn_area} li`).removeClass(`${styles.on}`);
						hoverBg.css('display','block');
						
						$(`.${styles.mypage_tab_btn_area} li`).on('mouseenter',function()
						{
							let tgIndexinfo=$(this);
							hoverBg.stop().css('left',tgIndexinfo['0']['offsetLeft']).css('width',tgIndexinfo['0']['clientWidth']);

							// let tgIndex=$(this).index();
							
							// if(tgIndex===1)
							// {
							// 	hoverBg.stop().css('left','167px').css('width','116px');
							// }
							// else if(tgIndex===2)
							// {
							// 	hoverBg.stop().css('left','300px').css('width','150px');
							// }
							// else if(tgIndex===3)
							// {
							// 	hoverBg.stop().css('left','467px').css('width','116px');
							// }
							// else if(tgIndex===4)
							// {
							// 	hoverBg.stop().css('left','624px').css('width','102px');
							// }
							// else if(tgIndex===5)
							// {
							// 	hoverBg.stop().css('left','774px').css('width','102px');
							// }
							// else if(tgIndex===6)
							// {
							// 	hoverBg.stop().css('left','924px').css('width','102px');
							// };
						});
						
					}).on('mouseleave',function()
					{
						$(`.${styles.mypage_tab_btn_area} li`).eq(nowbtnNum).addClass(`${styles.on}`);
						hoverBg.css('display','none');
						
						if(nowbtnNum===0)
						{
							hoverBg.css({left:'167px'}).css({width:'116px'});
						}
						else if(nowbtnNum===1)
						{
							hoverBg.css({left:'300px'}).css({width:'150px'});
						}
						else if(nowbtnNum===2)
						{
							hoverBg.css({left:'467px'}).css({width:'116px'});
						}
						else if(nowbtnNum===3)
						{
							hoverBg.css({left:'624px'}).css({width:'102px'});
						}
						else if(nowbtnNum===4)
						{
							hoverBg.css({left:'774px'}).css({width:'102px'});
						}
						else if(nowbtnNum===5)
						{
							hoverBg.css({left:'924px'}).css({width:'102px'});
						};

					});
					
				}
			}).resize();

		});

		// 여기서 디스패치로~
        dispatch(couponUserSelect(cou_number))
        .then(data => 
        {
            // console.log("data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

	}, [$, dispatch]);

	const onReadyClick = () => 
	{
		// alert("서비스 중비중입니다.");
		alert(`${t("Common.service_wait")}`);
	};

  return (
    <>
      	{/* Header 분리됨 MainLayout Header에 있음 */}

		<div className={styles.mypage_top_line_banner}>
			<div className={styles.inner}>
				<span>{/* 학부모 페이지 */}{t('Common.Mypage_title2')}</span>
			</div>
		</div>
		<div className={styles.mypage_tab_btn_area} ref={mypage_tab_btn_areaRef}>
			<div className={styles.inner}>
				<ul>
					<li className={styles.hover_bg} ref={hover_bgRef}></li>
					<li><a href="/GroupUserMypage/GroupReport">{/* 클래스 관리 */}{t('Common.Group_SubMenu.menu1')}</a></li>
					<li><a href="/GroupUserMypage/GroupParentsInfo">{/* 관리자 정보 */}{t('Common.Group_SubMenu.menu2')}</a></li>
					{/* <li><a href="/GroupUserMypage/GroupPayInfo"> */}{/* 결제 관리 */}{/* {t('Common.Group_SubMenu.menu3')}</a></li> */}
					{ language === null || language === 'ko' ? <li><a href="/GroupUserMypage/GroupPayInfo">{/* 결제 관리 */}{t('Common.Group_SubMenu.menu3')}</a></li> : "" }
					<li className={styles.on} ref={onRef}><a href="/GroupUserMypage/GroupCouponInfo">{/* 쿠폰관리 */}{t('Common.Group_SubMenu.menu4')}</a></li>
					{/* <li><a href="/GroupUserMypage/GroupStudentInfo">공지사항</a></li>
					<li><a href="/GroupUserMypage/GroupQnaInfo">질문과 답변</a></li> */}
					<li><a href="/GroupUserMypage/GroupQnaInfo">{/* 문의 내역 */}{t('Common.SubMenu.menu6')}</a></li>
					<li><a href="#!" onClick={()=>onReadyClick()}>{/* 공지사항 */}{t('Common.Group_SubMenu.menu5')}</a></li>
					<li><a href="#!" onClick={()=>onReadyClick()}>{/* 질문과 답변 */}{t('Common.Group_SubMenu.menu6')}</a></li>
				</ul>
			</div>
		</div>
		<div className={`${styles.mypage_tab} ${styles.mypage_pay_info_more} ${styles.coupon_info_more}`}>
			<div className={styles.inner}>
				<span className={styles.my_tit}>{/* 쿠폰 상세정보 */}{t('MypageCoupon.string18')}</span>
				<div className={styles.pay_info_more_area}>
					 <ul>
						<li>
							<span className={styles.head}>{/* 시작일 */}{t('MypageCoupon.string19')}</span>
							{/* <p>{common.payDateChangeReturn(coupon?.cou_valid_start_date) || "사용전"}</p> */}
							<p>{common.payDateChangeReturn(coupon?.cou_valid_start_date) || t('MypageCoupon.string12')}</p>
						</li>
						<li>
							<span className={styles.head}>{/* 종료일 */}{t('MypageCoupon.string20')}</span>
							{/* <p>{common.payDateChangeReturn(coupon?.cou_valid_end_date) || "사용전"}</p> */}
							<p>{common.payDateChangeReturn(coupon?.cou_valid_end_date) || t('MypageCoupon.string12')}</p>
						</li>
						<li>
							<span className={styles.head}>{/* 쿠폰번호 */}{t('MypageCoupon.string21')}</span>
							<p>{coupon?.cou_number || ""}</p>
						</li>
						{/* <li>
							<span className={styles.head">주문번호</span>
							<p>ORD-202203-000043</p>
						</li> */}
						<li>
							<span className={styles.head}>{/* 쿠폰명 */}{t('MypageCoupon.string8')}</span>
							<p>{coupon?.cou_name || ""}</p>
						</li>
						<li className={styles.pay_more_product_info}>
							<span className={styles.head}>{/* 쿠폰설명 */}{t('MypageCoupon.string22')}</span>
							<p>{coupon?.cou_desc || ""}</p>
						</li>
						{/* <li className={styles.apply_coupon_count">
							<span className={styles.head">적용가능 학습자 수</span>
							<p>1 명</p>
						</li> */}
						<li className={styles.coupon_state}>
							<span className={styles.head}>{/* 쿠폰상태 */}{t('MypageCoupon.string10')}</span>
							<p>{common.returnCouponState(coupon?.cou_state) || ""}</p>
						</li>
					 </ul>
					<a href={`/GroupUserMypage/GroupCouponInfo${board_page ? "/" + board_page : ""}${board_condition ? "/" + board_condition : ""}`}>{/* 확인 */}{t('Common.confirm')}</a>
				</div>
			</div> {/* inner */}
		</div> {/* mypage_tab */}
		
		{/* Footer 분리됨 MainLayout Footer에 있음 */}
    </>
  );
}