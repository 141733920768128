import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import styles from "../../css/common.module.css";
import $ from 'jquery';
import { useParams, useNavigate } from 'react-router-dom';

import { packageInfoList } from "../../actions/package";
import { chartClassLearningTime, chartClassMissionStateProgress, chartClassMissionStateProgressTotal } from "../../actions/chart";
import * as common from "../../lib";

import Button from '@mui/material/Button';

import StudentDataTable from './StudentDataTable';

import StudentAddDialog from './StudentAddDialog';
import StudentExcelUploadDialog from './StudentExcelUploadDialog';

import ClassStudentChart1 from './ClassStudentChart1';
import ClassStudentChart2 from './ClassStudentChart2';
import ClassStudentChart3 from './ClassStudentChart3';

import StudentRankingDialog from './StudentRankingDialog';

import PackageInfoDiv from './PackageInfoDiv';

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

export default function GroupMypageReport() {

	const dispatch = useDispatch();	
	const navigate = useNavigate();

	const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

	let language = localStorage.getItem("language");

	let mcIdx  = useParams().idx;
    // console.log("mcIdx : ", mcIdx);

    if (mcIdx === undefined)
		mcIdx = "";

	let mcName  = useParams().mc_name;
	// console.log("mcName : ", mcName);

	if (mcName === undefined)
		mcName = "";

	let prePage  = useParams().pre_page;

	if (prePage === undefined)
		prePage = 1;

	const authState = useSelector(state => state.auth);
    // console.log("authState : ", authState);

	const myPackageList = useSelector(state => state.myPackage.infoList);
    // console.log("myPackageList : ", myPackageList);

	const chartClassLearningTimeData = useSelector(state => state.chart.chartClassLearningTime);
	const chartClassMissionStateProgressData = useSelector(state => state.chart.chartClassMissionStateProgress);
	const chartClassMissionStateProgressDataTotal = useSelector(state => state.chart.chartClassMissionStateProgressTotal);

	// console.log("chartClassLearningTimeData : ", chartClassLearningTimeData);
	// console.log("chartClassMissionStateProgressData : ", chartClassMissionStateProgressData);
	// console.log("chartClassMissionStateProgressDataTotal : ", chartClassMissionStateProgressDataTotal);

	const mypage_tab_btn_areaRef =  useRef();
    const hover_bgRef =  useRef();
    const onRef =  useRef();

	const print_areaRef = useRef();

	useEffect(() => {

		// 여기에서 jQuery를 초기화 합니다.
		// jquery ready 호출. jquery3 부터 변경됨
		$(function(){
			// console.log("jquery ready!");

            //마이페이지 탭 버튼(활성화 효과)
			$(`.${styles.mypage_tab_btn_area} li`).on('click',function()
			{
				let tg=$(this);
				
				$(`.${styles.mypage_tab_btn_area} li`).removeClass(`${styles.on}`);
				tg.addClass(`${styles.on}`);
			});
            
            //마이페이지 탭 버튼(후버 효과)
            $(window).resize(function()
			{
				if (window.innerWidth < 1200) 
				{ 
					$(`.${styles.mypage_tab_btn_area} li.${styles.hover_bg}`).css('display','none');
				}
				else
				{
					let nowbtnNum=$(`.${styles.mypage_tab_btn_area} li.${styles.on}`).index();
					let hoverBg=$(`.${styles.mypage_tab_btn_area} li.${styles.hover_bg}`);

					$(`.${styles.mypage_tab_btn_area} ul`).on('mouseenter',function()
					{
						
						$(`.${styles.mypage_tab_btn_area} li`).removeClass(`${styles.on}`);
						hoverBg.css('display','block');
						
						$(`.${styles.mypage_tab_btn_area} li`).on('mouseenter',function()
						{
							let tgIndexinfo=$(this);
							hoverBg.stop().css('left',tgIndexinfo['0']['offsetLeft']).css('width',tgIndexinfo['0']['clientWidth']);

							// let tgIndex=$(this).index();
							
							// if(tgIndex===1)
							// {
							// 	hoverBg.stop().css('left','167px').css('width','116px');
							// }
							// else if(tgIndex===2)
							// {
							// 	hoverBg.stop().css('left','300px').css('width','150px');
							// }
							// else if(tgIndex===3)
							// {
							// 	hoverBg.stop().css('left','467px').css('width','116px');
							// }
							// else if(tgIndex===4)
							// {
							// 	hoverBg.stop().css('left','624px').css('width','102px');
							// }
							// else if(tgIndex===5)
							// {
							// 	hoverBg.stop().css('left','774px').css('width','102px');
							// }
							// else if(tgIndex===6)
							// {
							// 	hoverBg.stop().css('left','924px').css('width','102px');
							// };
						});
						
					}).on('mouseleave',function()
					{
						$(`.${styles.mypage_tab_btn_area} li`).eq(nowbtnNum).addClass(`${styles.on}`);
						hoverBg.css('display','none');
						
						if(nowbtnNum===0)
						{
							hoverBg.css({left:'167px'}).css({width:'116px'});
						}
						else if(nowbtnNum===1)
						{
							hoverBg.css({left:'300px'}).css({width:'150px'});
						}
						else if(nowbtnNum===2)
						{
							hoverBg.css({left:'467px'}).css({width:'116px'});
						}
						else if(nowbtnNum===3)
						{
							hoverBg.css({left:'624px'}).css({width:'102px'});
						}
						else if(nowbtnNum===4)
						{
							hoverBg.css({left:'774px'}).css({width:'102px'});
						}
						else if(nowbtnNum===5)
						{
							hoverBg.css({left:'924px'}).css({width:'102px'});
						};

					});
					
				}
			}).resize();

		});

		// 여기서 디스패치로~
        dispatch(packageInfoList())
        .then(data => 
        {
            // console.log("packageInfoList data : ", data);
			// 사용가능한 이용권의 갯수
        })
        .catch(e => {
            console.log(e);
        });

		dispatch(chartClassLearningTime(mcIdx, authState.user.idx))
        .then(data => 
        {
            // console.log("chartClassAgeRange data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

		dispatch(chartClassMissionStateProgress(mcIdx, authState.user.idx))
        .then(data => 
        {
            // console.log("chartClassMissionStateProgress data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

		dispatch(chartClassMissionStateProgressTotal(mcIdx, authState.user.idx))
        .then(data => 
        {
            // console.log("chartClassMissionStateProgressTotal data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

	}, [$, dispatch]);

	const onReadyClick = () => 
	{
		// alert("서비스 중비중입니다.");
		alert(`${t("Common.service_wait")}`);
	};

	const onBackClick = () => 
	{
		navigate("/GroupUserMypage/GroupReport", {
			state: {
			  prePage: prePage
			},
		  });
	};
	
  return (
    <>
      	{/* Header 분리됨 MainLayout Header에 있음 */}

        <div className={styles.mypage_top_line_banner}>
			<div className={styles.inner}>
				<span>{/* 관리자 페이지 */}{t('Common.Mypage_title2')}</span>
			</div>
		</div>
		<div className={styles.mypage_tab_btn_area} ref={mypage_tab_btn_areaRef}>
			<div className={styles.inner}>
				<ul>
					<li className={styles.hover_bg} ref={hover_bgRef}></li>
					<li className={styles.on} ref={onRef}><a href="/GroupUserMypage/GroupReport">{/* 클래스 관리 */}{t('Common.Group_SubMenu.menu1')}</a></li>
					<li><a href="/GroupUserMypage/GroupParentsInfo">{/* 관리자 정보 */}{t('Common.Group_SubMenu.menu2')}</a></li>
					{ language === null || language === 'ko' ? <li><a href="/GroupUserMypage/GroupPayInfo">{/* 결제 관리 */}{t('Common.Group_SubMenu.menu3')}</a></li> : "" }
					<li><a href="/GroupUserMypage/GroupCouponInfo">{/* 쿠폰관리 */}{t('Common.Group_SubMenu.menu4')}</a></li>
					{/* <li><a href="/GroupUserMypage/GroupStudentInfo">공지사항</a></li>
					<li><a href="/GroupUserMypage/GroupQnaInfo">질문과 답변</a></li> */}
					<li><a href="/GroupUserMypage/GroupQnaInfo">{/* 문의 내역 */}{t('Common.SubMenu.menu6')}</a></li>
					<li><a href="#!" onClick={()=>onReadyClick()}>{/* 공지사항 */}{t('Common.Group_SubMenu.menu5')}</a></li>
					<li><a href="#!" onClick={()=>onReadyClick()}>{/* 질문과 답변 */}{t('Common.Group_SubMenu.menu6')}</a></li>
				</ul>
			</div>
		</div>
		<div className={`${styles.mypage_tab} ${styles.mypage_report}`} ref={print_areaRef}>
			<div className={styles.inner}>
				<div className={styles.my_report_top_btn_area}>
					<Button variant="outlined" style={{ textTransform: 'none' }} href="#" onClick={onBackClick}>❮❮ {/* 클래스 전체 관리로 이동 */}{t('GroupMypageClassReport.string1')}</Button>
					{/* <StudentRankingDialog /> */}
				</div>
				<span className={styles.my_tit}>{mcName} {/* 학습자 관리 */}{t('GroupMypageClassReport.string2')}</span>
				<p className={styles.for_freeuser_info}>{/* * 무료체험은 데이터가 부족하여 제대로 표시되지 않을 수 있습니다. */}{t('GroupMypageClassReport.string3')}</p>
				<div className={styles.my_report_top_area}>
					<StudentAddDialog myPackageList={myPackageList} mcIdx={mcIdx} />
					<StudentExcelUploadDialog mcIdx={mcIdx} />
				</div>
				<div className={styles.my_report_bottom_area}>
					 <div className={styles.head}>
						<span>{/* 학습자 리스트 */}{t('GroupMypageClassReport.string4')}</span>
						{/* <p>이용권 {totalPackageCount}개 사용가능</p>
						<Button variant="outlined" size="small">이용권 확인</Button> */}
						<PackageInfoDiv myPackageList={myPackageList} />
					 </div>
					 <div className={styles.wrap}>
					 	<StudentDataTable myPackageList={myPackageList} mcIdx={mcIdx} />
					 </div>{/* wrap */}
				</div>{/* my_report_cognitive_ability_area */}
				<div className={styles.my_class_chart_missonprogress_area}>
					 <div className={styles.head}>
						<span>{/* 학습시간 */}{t('GroupMypageClassReport.string5')}</span>
						<p>{/* 학습자의 총 학습시간입니다. */}{t('GroupMypageClassReport.string6')}</p>
					 </div>
					 <div className={styles.wrap}>
						<ClassStudentChart1  RowData={chartClassLearningTimeData} />
					 </div>
				</div>
				<div className={styles.my_class_chart_missonprogress_area}>
					 <div className={styles.head}>
						<span>{/* 미션진도 상황 (난이도별) */}{t('GroupMypageClassReport.string7')}</span>
						<p>{/* 순차, 반복, 선택 미션에 대한 데이터 (코딩블록배우기, 특별미션은 난이도가 없음) */}{t('GroupMypageClassReport.string8')}</p>
					 </div>
					 <div className={styles.wrap}>
						<ClassStudentChart2  RowData={chartClassMissionStateProgressData} />
					 </div>
				</div>
				<div className={styles.my_class_chart_missonprogress_area}>
					 <div className={styles.head}>
						<span>{/* 각 구분별 미션진도 상황 */}{t('GroupMypageClassReport.string9')}</span>
						<p>{/* 코딩배우기, 순차, 반복, 선택, 특별 미션에 대한 데이터 */}{t('GroupMypageClassReport.string10')}</p>
					 </div>
					 <div className={styles.wrap}>
						<ClassStudentChart3  RowData={chartClassMissionStateProgressDataTotal} />
					 </div>
				</div>
			</div>{/* inner */}
		</div>
		
		{/* Footer 분리됨 MainLayout Footer에 있음 */}
    </>
  );
}