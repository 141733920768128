import * as React from 'react';
import { useEffect } from 'react';
import styles from "../css/common.module.css";
import $ from 'jquery';
import UseCommon_ko from '../common/UseCommon_ko';
import UseCommon_en from '../common/UseCommon_en';
import UseCommon_jp from '../common/UseCommon_jp';

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

export default function Use() {

	let language = localStorage.getItem("language");

	const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

	useEffect(() => {

		// 여기에서 jQuery를 초기화 합니다.
		$(document).ready(function(){

		});

	}, [$]);

  	return (
    <>
		<div id={styles.content}>
			<div className={styles.inner}>
				<span className={styles.index_title}>{/* 이용약관 */}{t("Common.Terms_and_Conditions_of_Use")}</span>
				{(() => {
					switch (language) {
					case "ko":
						return <UseCommon_ko />;
					case "en":
						return <UseCommon_en />;
					case "jp":
						return <UseCommon_jp />;
					default:
						return <UseCommon_ko />;
					}
				})()}
				<div className={styles.to_top}>
					<a href="/Use">Top</a>
				</div>
			</div>
		</div>
    </>
  );
}