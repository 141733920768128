import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { grpupUserListAll, userDelete, userActive } from "../../../actions/users";
import { useParams, useNavigate } from 'react-router-dom';

import UserModifyPopup from './UserModifyPopup';
import UserInfoPopup from './UserInfoPopup';
import UserInfoDialog from './UserInfoDialog';

export default function UserList() {

    const auth = useSelector(state => state.auth);  
    const users = useSelector(state => state.users);
    // console.log("users : ", users);
    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    let board_page  = useParams().page;
    // console.log("board_page : ", board_page);
    let board_condition  = useParams().condition;
    // console.log("board_condition : ", board_condition);
    let board_keyword  = useParams().keyword;
    // console.log("board_keyword : ", board_keyword);
    let board_classvalue  = useParams().classvalue;
    // console.log("board_classvalue : ", board_classvalue);

    if (board_page === undefined)
      board_page = 1;

    if (board_condition === undefined)
      board_condition = "";
    else if(board_condition === "선택")
      board_condition = "";

    if (board_keyword === undefined)
      board_keyword = "";

    if (board_classvalue === undefined)
      board_classvalue = "";

    const [searchCondition, setSearchCondition] = useState(board_condition);
    const [searchKeyword, setSearchKeyword] = useState(board_keyword);
    const [searchClassValue, setSearchClassValue] = useState(board_classvalue);

    // 게시물의 번호를 계산하는 함수
    const listNumberReturn = (index) => {
      let listNumber;
      return listNumber = ((users.paging.totalPageCount - index) - ((users.paging.curPage -1) * users.paging.page_size));	// eslint-disable-line no-unused-vars
    }

    const onSearchKeywordChange = (event) => {
      setSearchKeyword(event.target.value);
    }
    
    const onSearchConditionChange = (event) => {
      setSearchCondition(event.target.value);
    }

    const onSearchClassValueChange = (event) => {
      setSearchClassValue(event.target.value);
    }

    const onReloadWindow = () => {
      // window.location.reload();
      if (auth.isLoggedIn)
      {
          const boardListSend = {
            board_page : board_page,
            condition : searchCondition,
            keyword : searchKeyword,
          }

          // console.log("dispatch grpupUserListAll");
          // console.log("usersListSend : ", boardListSend);
          dispatch(grpupUserListAll(boardListSend))
          .then(data => 
          {
              // console.log("data : ", data);
          })
          .catch(e => {
              console.log(e);
          });
      }
    }

    const onSearchList = (event) => {

      event.preventDefault();

      // console.log("onSearchList call");
    
      // if (searchCondition === "선택" || searchCondition === "")
      // {
      //   setSearchCondition("");
      //   alert("검색 조건을 선택해주세요!");
      //   return;
      // }
    
      // if (searchKeyword === "")
      // {
      //   setSearchCondition("");
      //   alert("검색어를 입력해주세요!");
      //   return;
      // }

      board_page = 1;
    
      const boardListSend = {
        board_page : board_page, 
        condition : searchCondition === "" ? null : searchCondition,
        keyword : searchKeyword === "" ? null : searchKeyword,
        classvalue : searchClassValue === "" ? null : searchClassValue,
      }
    
      // console.log("onSearchList boardListSend : ", boardListSend);
    
      // 여기서 디스패치로~
      dispatch(grpupUserListAll(boardListSend))
      .then(data => 
      {
        // console.log("data : ", data);
        if (searchCondition !== "" || searchKeyword !== "" || searchClassValue !== "")
          navigate(`/dashboard/Group/UserList/${boardListSend.board_page}/${boardListSend.condition}/${boardListSend.keyword}/${boardListSend.classvalue}`);
        else
          navigate(`/dashboard/Group/UserList/${boardListSend.board_page}`);
      })
      .catch(e => {
        console.log(e);
      });
    
    }

    const onUserDelete = (event, idx, email) => {
      // console.log(idx);

      const sendDelete = {
        idx: idx, 
        email: email
      }
  
      // console.log("sendDelete : ", sendDelete);

      if (window.confirm("정말 삭제하시겠습니까??") === true)   //확인
      {
        // 여기서 디스패치로~
        dispatch(userDelete(sendDelete))
        .then(data => 
        {
            // console.log("data : ", data);
            onReloadWindow();
        })
        .catch(e => {
            console.log(e);
        });
      }
      else  //취소
      {
        return false;
      }

    };

    const onUserActive = (event, idx, email) => {
      // console.log(idx);

      const sendDelete = {
        idx: idx, 
        email: email
      }
  
      // console.log("sendDelete : ", sendDelete);

      if (window.confirm("활성화를 변경하시겠습니까??") === true)   //확인
      {
        // 여기서 디스패치로~
        dispatch(userActive(sendDelete))
        .then(data => 
        {
            // console.log("data : ", data);
            onReloadWindow();
        })
        .catch(e => {
            console.log(e);
        });
      }
      else  //취소
      {
        return false;
      }
    }

    const onSearchInit = (event) => {

      event.preventDefault();

      // console.log("onSearchInit call");
        
      // if (board_page === undefined)
      //   board_page = 1;
      board_page = 1;
    
      setSearchCondition("");
      setSearchKeyword("");
      setSearchClassValue("");
    
      const boardListSend = {
        board_page : board_page, 
        condition : "",
        keyword : "",
        classvalue : "",
      }
    
      // console.log("onSearchList boardListSend : ", boardListSend);
    
      // 여기서 디스패치로~
      dispatch(grpupUserListAll(boardListSend))
      .then(data => 
      {
        // console.log("data : ", data);
        navigate(`/dashboard/Group/UserList/${boardListSend.board_page}`);
      })
      .catch(e => {
        console.log(e);
      });
    
    }

    const onPaging = (e, page) => {
      
      if (!page) return;
      // console.log("page : ", page);
      
      const boardListSend = {
        board_page : page, 
        condition : searchCondition === "" ? null : searchCondition,
        keyword : searchKeyword === "" ? null : searchKeyword,
        classvalue : searchClassValue === "" ? null : searchClassValue,
      }

      // 여기서 디스패치로~
      dispatch(grpupUserListAll(boardListSend))
      .then(data => 
      {
          // console.log("data : ", data);
          // navigate(`/dashboard/Group/UserList/${page}`);

          if (searchCondition !== "" || searchKeyword !== "" || searchClassValue !== "")
            navigate(`/dashboard/Group/UserList/${page}/${boardListSend.condition}/${boardListSend.keyword}/${boardListSend.classvalue}`);
          else
            navigate(`/dashboard/Group/UserList/${page}`);
      })
      .catch(e => {
          console.log(e);
      });
    }

    useEffect(() => {
        
      const boardListSend = {
        board_page : board_page,
        condition : searchCondition,
        keyword : searchKeyword,
      }

      // console.log("dispatch grpupUserListAll");
      console.log("usersListSend : ", boardListSend);
      dispatch(grpupUserListAll(boardListSend))
      .then(data => 
      {
          console.log("dispatch grpupUserListAll data : ", data);
          return;
      })
      .catch(e => {
          console.log(e);
      });

      // 언마운트
      return () => {
          // console.log("언마운트!");
      };

    }, [dispatch]);

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
      단체 사용자 리스트 [회사 총 개수 : {users?.totalInfo?.totalCompanyCount || 0}개, 전체 단체회원 : {users?.totalInfo?.totalGroupCount || 0}개, 
      전체 클래스 : {users?.totalInfo?.totalClassCount || 0}명, 전체 학생수 : {users?.totalInfo?.totalStudentCount || 0}명]<br></br><br></br>

      <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <Box sx={{ minWidth: 120, width:120}}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">검색조건</InputLabel>
            <Select
              labelId="condition"
              id="condition"
              value={searchCondition}
              label="검색조건"
              onChange={onSearchConditionChange}
            >
              <MenuItem value="email">이메일</MenuItem>
              <MenuItem value="name">이름</MenuItem>
              <MenuItem value="nickname">닉네임</MenuItem>
              <MenuItem value="phone">전화번호</MenuItem>
              <MenuItem value="company_name">회사명</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box
          component="form"
          sx={{
            '& > :not(style)': { m: 1, width: '25ch' }
          }}
          noValidate
          autoComplete="off"
        >
          
        <TextField id="keyword" label="검색어" variant="outlined" onChange={onSearchKeywordChange} value={searchKeyword} />
        </Box>

        <Box sx={{ minWidth: 120, width:120}}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">생성여부</InputLabel>
            <Select
              labelId="classValue"
              id="classValue"
              value={searchClassValue}
              label="생성여부"
              onChange={onSearchClassValueChange}
            >
              <MenuItem value="1">생성</MenuItem>
              <MenuItem value="2">미생성</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Button variant="contained" style={{ textTransform: 'none' }} size='small' onClick={onSearchList} sx={{margin:2}}>검색</Button>
        <Button variant="contained" style={{ textTransform: 'none' }} size='small' onClick={onSearchInit}>초기화</Button>
      </Box>
      <br></br>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">번호</TableCell>
              <TableCell align="center">이름</TableCell>
              <TableCell align="center">이메일</TableCell>
              <TableCell align="center">유형</TableCell>
              <TableCell align="center">회사명</TableCell>
              <TableCell align="center">클래스</TableCell>
              <TableCell align="center">학습자</TableCell>
              <TableCell align="center">상태</TableCell>
              <TableCell align="center">사용여부</TableCell>
              <TableCell align="center">수정</TableCell>
              {/* <TableCell align="center">삭제</TableCell> */}
              <TableCell align="center">리포트</TableCell>
              <TableCell align="center">최근 로그인</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.list && users.list.map((row, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row" align="center">
                  {listNumberReturn(index)}
                </TableCell>
                <TableCell align="center"><UserInfoPopup memIdx={row.mem_idx} memName={row.mem_name} /></TableCell>
                <TableCell align="center">{row.mem_email}</TableCell>
                <TableCell align="center">
                {
                    (() => {
                        if (row.mem_type === 1) return ("관리자");
                        if (row.mem_type === 2) return ("일반");
                        if (row.mem_type === 3) return ("소셜");
                        if (row.mem_type === 4) return ("그룹");
                    })()
                    
                }
                </TableCell>
                <TableCell align="center">{row.mg_name || ""}</TableCell>
                <TableCell align="center">{row.class_count || 0}개</TableCell>
                <TableCell align="center">{row.student_count || 0}명</TableCell>
                <TableCell align="center">{row.mg_status === 0 ? "승인대기" : "승인"}</TableCell>
                <TableCell align="center">
                  {
                    row.mem_status ? 
                    <Button variant="contained" style={{ textTransform: 'none' }} size='small' color="error" onClick={(event) => onUserActive(event, row.mem_idx, row.mem_email)}>정지</Button> 
                    : 
                    <Button variant="contained" style={{ textTransform: 'none' }} size='small' onClick={(event) => onUserActive(event, row.mem_idx, row.mem_email)}>사용</Button>
                  }
                </TableCell>
                <TableCell align="center"><UserModifyPopup buttonText="수정" userData={row} onReloadWindow={onReloadWindow} /></TableCell>
                <TableCell align="center"><UserInfoDialog userIdx={row.mem_idx} /></TableCell>
                {/* <TableCell align="center"><Button variant="contained" size='small' color="error" onClick={(event) => onUserDelete(event, row.mem_idx, row.mem_email)}>삭제</Button></TableCell> */}
                <TableCell align="center">{row.mem_lastlogin_date}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {
        users && 
          <Stack spacing={2} sx={{ height: 60, display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
            <Pagination count={ users?.paging?.totalPage || 0} page={ users?.paging?.curPage || 0} onChange={onPaging} color="primary" />
          </Stack>
      }
      </CardContent>
      {/* <CardActions>
        <UserWritePopup buttonText="사용자 추가" onReloadWindow={onReloadWindow} />
      </CardActions> */}
      
    </Card>
  );
}