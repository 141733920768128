import React from 'react';
import styles from "../../css/common.module.css";

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

const Footer = () => {

	const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언
	let language = localStorage.getItem("language");

    return (
        <>
            <section className={styles.footer_section}>
			<div className={styles.footer}>
				<div className={styles.inner90}>
					<div className={styles.f_left}>
						<ul>
							<li><img src="/assets/f_logo@2x.png" alt="f_logo"/></li>
							<li>
								<a target="_blank" rel="noopener noreferrer" href="http://www.weclover.co.kr/">{/*회사소개*/}{t('Common.Company_introduction')}</a>
								<a href="/Use">{/*이용약관*/}{t('Common.Company_Terms_Conditions')}</a>
								<a href="/Privacy">{/*개인정보처리방침*/}{t('Common.Company_Personal_information_processing_policy')}</a>
							</li>
							<li>
							{/*(주)위클러버*/}{t('Common.Company_name')} ({/*대표자*/}{t('Common.Company_CEO')}:{/*이성진*/}{t('Common.Company_CEO_name')}) | {/*서울특별시 관악구 호암로22길 26 관악S밸리 신림벤처창업센터2 4층 위클러버*/}<Trans i18nKey="Common.Company_address" />{/*t('Common.Company_address')*/} <br/>
							{/*통신판매업신고*/}{language === 'ko' ? t('Common.Mail_order_business_report') + ' : 제2018-서울관악-0254호' : "" } 
							{language === 'ko' && <>&nbsp;|&nbsp;</> }
							{/*사업자등록번호*/}{language === 'ko' ? t('Common.Business_registration_number') + ' : 179-87-00821 |' : ""} {/*전화번호*/}{t('Common.phone')} : {/*02-875-3330*/}{t('Common.phone_number')}			
							</li>
							<li>© 2022.weclover Inc. all rights reserved.</li>
						</ul>
					</div>
					<div className={styles.f_right}>
						<ul>
							<li className={styles.faq}><a href="/Faq">{/*자주묻는질문*/}{t('Common.frequently_asked_question')}</a></li>
							{/* <li class="faq">{/*채팅상담</li> */}
							<li className={styles.inquiry}>{/*고객/제휴문의*/}{t('Common.Customer_Partnership_Inquiry')}</li>
							<li className={styles.inquiry}>admin@weclover.co.kr</li>
						</ul>
					</div>
				</div>
			</div>
		    </section>
        </>
    );
    
};

export default Footer;