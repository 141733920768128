import * as React from 'react';
import { useEffect } from 'react';
import styles from "../css/common.module.css";
import $ from 'jquery';

export default function Use() {

	useEffect(() => {

		// 여기에서 jQuery를 초기화 합니다.
		$(document).ready(function(){

		});

	}, [$]);

  	return (
    <>
		<div className={styles.wrap}>
			<div className={styles.privacy_info}>
				<p>
				‘<span className={styles.color}>株式会社ウィクル・ラバー</span>'は（以下「会社」といいます。） お客様の個人情報を重要視し、「情報通信網利用促進及び情報保護等に関する法律」及び「個人情報保護法」等、会社が遵守すべき関連法令上の個人情報保護規定を遵守しています。
				<br/>会社は個人情報処理方針を通じて、お客様が提供される個人情報がどのような用途と方式で利用されており、個人情報保護のためにどのような措置が取られているのかをお知らせします。
				<br/><br/>会社は個人情報処理方針を改正する場合、ウェブサイトのお知らせ(または個別のお知らせ)を通じてお知らせします。
				</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area1}`}>
				<span className={styles.tit}>1. 収集する個人情報の項目及び収集方法</span>
				<p>
				(1) 収集する個人情報の項目
				<br/><br/>* 会員登録時に以下のような情報が収集されます
				<br/>- ウェブサイトをご利用の際:メールアドレス(アカウント名)、ニックネーム、パスワード、携帯番号、お名前
				<br/><br/>* サービス利用過程で以下のような情報が収集されます
				<br/>- サービス利用記録、アクセスログ、IP情報、不具合利用記録、モバイル機器情報（モデル名、移動通信会社情報、OS情報、言語·国家情報、機器固有識別番号·広告IDなど）
				<br/><br/>* 年齢確認および本人認証が必要なサービス提供時、以下のような情報が収集されることがあります。
				<br/>- 携帯電話本人認証者:名前、生年月日、性別、重複加入確認情報(DI)、連携情報(CI)、内·外国人情報
				<br/>- 満14歳未満の児童と満18歳未満の青少年利用者:法定代理人の本人認証情報(法定代理人のEメール、名前、生年月日、性別、重複加入確認情報(DI)、連携情報(CI))、本人認証情報(名前、生年月日、性別、重複加入確認情報)
				<br/><br/>* 会社は以下の顧客関連サービスを利用する際、付加的な情報を収集することができ、この場合、別途の個人情報収集及び利用同意を得ます。<br/>
				</p>
				<div className={styles.box_wrap}>
					<div className={styles.title}>
						<span>サービス名</span>
						<span>収集する個人情報項目</span>
					</div>
					<div className={styles.list}>
						<span className={styles.name}>イベント参加</span>
						<span>
						-配送商品: 住所、電話番号<br/>
						-モバイル商品: 携帯電話番号
						</span>
					</div>
					<div className={styles.list}>
						<span className={styles.name}>顧客管理サービス<br/>(カカオトークサービスセンター)</span>
						<span>
						-必須項目:携帯電話番号、ニックネーム<br/>
						-選択項目:端末アドレス帳内に保存されている連絡先情報(氏名、電話番号)、プロフィール写真
						</span>
					</div>
					<div className={styles.list}>
						<span className={styles.name}>有料決済の際</span>
						<span>
						- 携帯電話決済時:移動通信会社<br/>
						- リアルタイム口座振替決済時: 銀行名<br/>
						- 払い戻しの際:銀行名、振込者名、口座番号、連絡先<br/>
						- クーポン登録時:連絡先、クーポン情報<br/>
						- インターネットバンキング決済時: 生年月日<br/>
						- クレジットカードの場合:カード会社名
						</span>
					</div>
				</div>
				<p>
				(2) 個人情報の収集方法<br/><br/>
				ホームページ会員登録、サービス利用(モバイルサービス含む)、会員情報修正、電話、ファックス、カスタマーサポート相談、イベント応募、協力会社からの提供、生成情報収集ツールによる収集ツールを通じて収集します。
				</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area2}`}>
				<span className={styles.tit}>2. 固有識別情報の処理</span>
				<p>
				(1) 固有識別情報とは、個人情報保護法および施行令で「大統領令で定める情報」で、住民登録番号、パスポート番号、運転免許番号、外国人登録番号を言います。<br/><br/>
				(2) 会社は以下の各号の用途で固有識別情報を収集して処理しており、収集時に別途の同意を経て収集することになります。<br/><br/>
				* 現物景品当選者の諸税公課金賦課
				<br/>* その他の法律で要求する場合
				<br/><br/>(3) 収集された固有識別情報は、法律で異なるように規定されている場合を除き、(2)項により定められた目的外の用途で使用·提供されず、保存時に暗号化され安全に管理されます。
				</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area3}`}>
				<span className={styles.tit}>3. 個人情報の収集及び利用目的</span>
				<p>
				会社は収集した個人情報を次の目的のために活用します。<br/><br/>

				(1) サービス提供に関する契約履行及びサービス提供に伴う料金精算、コンテンツ提供、購入及び料金決済、払い戻し、物品配送、本人認証、料金取り立て<br/><br/>
				(2) 会員管理<br/><br/>
				会員制サービスの利用による本人確認、個人識別、不良会員の不正利用防止と非認可使用防止、加入意思確認、年齢確認、満14歳未満児童の個人情報収集時に法定代理人の同意有無確認、満18歳未満利用者の会員加入時に同意有無確認、法定代理人本人確認、苦情処理及び顧客相談などの苦情処理、紛争調整のための記録保存·告知事項伝達<br/><br/>

				(3) マーケティングや統計分析に活用<br/><br/>
				新規サービス(製品)の開発及び特化、イベント等の広報性情報の伝達、人口統計学的特性に応じたサービス提供及び広告の掲載、アクセス頻度の把握又は会員のサービス利用に関する統計分析						</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area4}`}>
				<span className={styles.tit}>4. 個人情報の保有及び利用期間</span>
				<p>
				原則として、個人情報の収集及び利用目的が達成された後は、当該情報を遅滞なく破棄します。 (但し、会社はアカウント盗用などによる被害を最小限に抑えるため、会員退会要請後、15日間個人情報を保有します。) また、関連法令の規定に基づき、収集及び利用目的の達成後も保存する必要がある場合、会社は以下のように関連法令に定める一定期間、会員情報を保管します<br/><br/>

				- 表示/広告に関する記録 : 6ヶ月（電子商取引等における消費者保護に関する法律）<br/>
				- 契約または申込みの撤回などに関する記録 : 5年(電子商取引等における消費者保護に関する法律)<br/>
				- 代金決済及び財貨等の供給に関する記録 : 5年(電子商取引等における消費者保護に関する法律)<br/>
				- 消費者の不満または紛争処理に関する記録 : 3年 (電子商取引等における消費者保護に関する法律)<br/>
				- ウェブサイトへの訪問に関する記録 : 3ヶ月(通信秘密保護法)
				</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area5}`}>
				<span className={styles.tit}>5. 個人情報の破棄手続き及び方法</span>
				<p>
				会社は原則的に個人情報の収集及び利用目的が達成された後は、当該情報を遅滞なく破棄し、破棄手続き及び方法は次のとおりです。<br/><br/>

				(1) 破棄手続き<br/>
				- 利用者が会員登録などのために入力した情報は、目的が達成された後(退会申請時、15日猶予期間後)に内部方針及びその他の関連法令による情報保護事由により(保有及び利用期間参照)一定期間保存された後、破棄されます。<br/><br/>
				(2) 破棄方法
				<br/>- 電子ファイル形式で保存された個人情報は、記録を再生できない技術的方法を使用して削除します。
				<br/>- 書面上に出力された個人情報は、シュレッダーで粉砕するか、焼却を通じて破棄します。					
				</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area6}`}>
				<span className={styles.tit}>6. 長期未利用アカウントの個人情報分離保存·管理</span>
				<p>
				この1年間、サービス利用記録のないアカウントを長期未利用アカウントに切り替え、該当アカウントの個人情報を分離して保存·管理されます。<br/>
				(ただし、本人認証を受けていないCATメッセンジャーの電話番号アカウントは、分離·保管なしに直ちに削除されます。)<br/>
				長期未利用アカウント転換により個人情報が分離保存·管理される場合、ゲーム利用を含むすべてのサービス利用が不可能であり、再びサービスを利用するためには別途の復帰手続きを経なければなりません。<br/>
				期間満了日前までにサービスを再利用する場合、長期未利用アカウントの転換が取り消されますので、長期未利用アカウントの転換と個人情報の分離保管を望まない場合は、YAM-Eアプリにログインするか、少しでもYAM-Eアプリを利用してください。<br/><br/>

				- 分離保存·管理対象:1年間サービス利用記録がないアカウント<br/>
				- 期間満了日:サービス利用記録なしで1年が到来する時点<br/>
				- 分離保存·管理される個人情報の項目:アカウント情報、連絡先、個人情報、サービス利用記録、決済記録、相談記録など
				</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area7}`}>
				<span className={styles.tit}>7. 個人情報の提供</span>
				<p>
				会社はサービス履行のために下記のように外部専門業者に委託して運営しています。<br/><br/>

				受託業者:株式会社 ウィクラバー<br />
				委託目的:顧客相談、苦情処理などの苦情処理、DM発送、SMS発送、イベント景品配送業務運営、管理代行<br/><br/>
				会社は利用者の個人情報を「3.個人情報の収集目的及び利用目的」で告知した範囲内で使用し、個人情報を第三者に提供しなければならない場合、法令に基づく同意を得ています。 ただし、次の場合は例外としております。<br/><br/>

				- 法令の規定に基づき、又は捜査目的で法令に定められた手続及び方法により捜査機関の要求がある場合<br/>
				- サービスの提供に伴う料金精算のために必要な場合<br/>
				- 他の法律に特別の定めがある場合
				</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area8}`}>
				<span className={styles.tit}>8. 利用者及び法定代理人の権利及びその行使方法</span>
				<p>
				利用者および法定代理人は、いつでも登録されている自分または当該満14歳未満の児童の個人情報を照会または修正することができ、加入解約を要請することもできます。 利用者または満14歳未満の児童の個人情報照会/修正のためには「個人情報変更」(または「会員情報修正」など)をクリックし、加入解約(同意撤回)のためには「会員退会申請」をクリックして本人確認手続きを経た後、直接閲覧、訂正、または退会が可能です。 もしくは、個人情報保護責任者に書面、電話、または電子メールでご連絡いただければ、遅滞なく措置いたします。<br/>
				利用者が個人情報の誤りに対する訂正を要請された場合は、訂正を完了するまで当該個人情報を利用または提供しません。 また、間違った個人情報を第三者にすでに提供している場合は、訂正処理の結果を第三者に遅滞なく通知し、訂正が行われるようにします。 会社は利用者または法定代理人の要請により解約または削除された個人情報は「3.収集する個人情報の保有および利用期間」に明示されたとおり処理し、その他の用途で閲覧または利用できないように処理しています					
				</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area9}`}>
				<span className={styles.tit}>9. インターネット接続情報ファイル等の個人情報を自動的に収集する装置の設置運営及びその拒否に関する事項</span>
				<p>
				会社は利用者の情報を随時保存して捜し出す「クッキー(cookie)」などを運用します。 クッキーとは、会社のウェブサイトを運営するのに利用されるサーバーが利用者のブラウザに送る非常に小さなテキストファイルで、利用者のコンピュータハードディスクに保存されます。 会社は次の目的のためにクッキーを使用します。<br/><br/>

				(1) クッキー等使用目的<br/>
				会員と非会員の接続頻度や訪問時間などを分析し、利用者の好みと関心分野を把握および自炊追跡、訪問回数把握などを通じた個人オーダーメードサービスを提供します<br/><br/>

				(2) クッキー設定拒否方法<br/>
				ユーザーは、クッキーのインストールの選択権を持っています。 したがって、ユーザーはウェブブラウザでオプションを設定することによって、すべてのクッキーを許可したり、クッキーが保存されるたびに確認を経たり、またはすべてのクッキーの保存を拒否することもできます。					
				</p>
				<div className={styles.txt_wrap}>
					<p>
					[クッキー設定方法]<br/><br/>
					① Internet Explorer: ウェブブラウザ上段の[ツール]→[インターネットオプション]→[個人情報]→[高級]
					<br/>②Chrome:ウェブブラウザ右上の[⋮]→[設定]→[高級設定表示]→個人情報[コンテンツ設定]メニューから設定できます。
					<br/>ただし、会員がクッキーの保存を拒否される場合、一部のサービスの提供に困難があることがあります
					</p>
				</div>
			</div>
			<div className={`${styles.privacy_area} ${styles.area10}`}>
				<span className={styles.tit}>10. 利用者及び法定代理人の権利及びその行使方法</span>
				<p>
				(1) 個人情報保護のための技術的対策<br/><br/>
				会社は利用者の個人情報を処理するにあたって、個人情報が紛失、盗難、流出、変造または毀損されないように、安全性確保のために次のような技術的対策を講じています。<br/>

				<br/>利用者の個人情報はパスワードによって保護されており、利用者アカウントのパスワードは本人だけが知ることができ、個人情報の確認および変更もパスワードを知っている本人によってのみ可能です。
				<br/>会社はハッキングなどの外部侵入に備えて利用者の個人情報が流出することを防ぐために、現在外部からの侵入を遮断する装置を利用してハッキングなどの攻撃などを防いでいます。 特に、利用者の個人情報を持っているサーバーは、外部のインターネットラインと直接接続せずに別途に管理するなど、最高レベルのセキュリティを維持しています。
				<br/>会社は万一の事態に備えてシステムとデータをバックアップする体制を整えています。
				<br/>会社はワクチンプログラムを利用してコンピュータウイルスによる被害を防止するための措置を取っています。 ワクチンプログラムは定期的にアップデートされ、突然のウイルスが出現した場合、ワクチンが出てすぐにこれを提供することで個人情報が侵害されることを防止しています。
				<br/><br/>(2)個人情報保護のための管理的対策
				<br/>会社は利用者の個人情報へのアクセス権限を最小限の人数に制限しています。 その最小限の人員に該当する者は次のとおりです。
				<br/><br/>* 利用者を直接相手にしてマーケティング業務を遂行する者
				<br/>* 個人情報苦情処理部署及び担当者など個人情報管理業務を遂行する者
				<br/>* その他業務上個人情報の処理が避けられない者
				<br/><br/>会社は、個人情報を処理する職員を対象に、新たなセキュリティ技術の習得及び個人情報保護義務などに関して、定期的な社内教育及び外部委託教育を実施しています。
				<br/>会社は入社時に全職員のセキュリティ誓約書を通じて人による情報流出を事前に防止し、個人情報処理方針に対する履行事項および職員の遵守可否を監査するための内部手続きを用意しています。
				<br/>会社は個人情報関連処理者の業務引継ぎは、セキュリティが維持された状態で徹底的に行われており、入社および退社後の個人情報事故に対する責任を明確化しています。
				<br/>会社は電算室及び資料保管室などを特別保護区域に設定し、出入りを統制しています。
				<br/>会社は利用者個人のミスまたはインターネットの本質的な危険性によって引き起こされる個人情報流出に対して責任を負いません。 利用者は本人の個人情報を保護するために自分のアカウントとパスワードを適切に管理し、それに対する責任を負わなければなりません。
				</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area11}`}>
				<span className={styles.tit}>11. 個人情報処理方針の変更</span>
				<p>
				会社は、個人情報処理方針の変更(内容の追加、削除及び修正)がある場合、ホームページのお知らせを通じて告知しています。 また、変更された内容をお客様が簡単に確認できるよう、変更前·後を比較して公開しています。
				</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area12}`}>
				<span className={styles.tit}>12. 個人情報保護責任者及び個人情報苦情処理責任者の連絡先</span>
				<p>
				会社は顧客の個人情報を保護し、個人情報に関する苦情を処理するために、以下のように関連部署および個人情報保護責任者を指定しています。<br/>

				<br/>個人情報保護責任者
				<br/><br/>* 個人情報保護責任者
				<br/>氏名 : パク·ユンファン
				<br/>連絡先 : (+82)02-875-3330
				<br/>Eメール : admin@weclover.co.kr
				<br/>※個人情報保護担当へその個人情報を破棄します。
				<br/><br/>* 個人情報保護担当部署
				<br/>部署名 : 開発チーム
				<br/>担当者 : 個人情報保護責任者及び管理責任者
				<br/>連絡先 : (+82)02-875-3330
				<br/><br/>利用者は会社のサービスを利用する際に発生するすべての個人情報保護に関する苦情を個人情報保護責任者または個人情報苦情処理責任者に申告することができます。 会社は利用者たちの申告事項に対して迅速に十分な返事を差し上げます。

				{/* <br/><br/>その他、個人情報侵害に対する申告や相談が必要な場合は、下記の機関にお問い合わせください。

				<br/><br/>1.個人情報侵害申告センター(privacy.kisa.or.kr / ☎ 국번없이 118)
				<br/>2. 最高検察庁サイバー捜査課(spo.go.kr / ☎ 국번없이 1301)
				<br/>3. 警察庁サイバー安全局(cyberbureau.police.go.kr / ☎ 국번없이 182)
				<br/>4. 個人情報紛争調停委員会(kopico.go.kr / ☎ 국번없이 1833-6972)						 */}
				</p>
			</div>
		</div>
    </>
  );
}