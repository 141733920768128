import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import styles from "../../css/common.module.css";
import $ from 'jquery';
import * as common from "../../lib";

import { studentList, newStudentList } from "../../actions/student";
import { userSelect } from "../../actions/users";
import { packageList, packageStudentInfo, packageUseStudentModify, packageInfoUseStudentModify, packageInfoList, packageInfoStudentInfo } from "../../actions/package";
import { couponAllList } from "../../actions/coupon";

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

export default function MypageStudentInfo() {

	const [totalPackageCount, setTotalPackageCount] = useState(0);

	const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

	/************* 데이터 처리 영역 *************/

	const authState = useSelector(state => state.auth);
    // console.log("authState : ", authState);

	const parent = useSelector(state => state.users.user);
    // console.log("parent : ", parent);

	const student = useSelector(state => state.student.list);
    // console.log("student : ", student);

	const newStudent = useSelector(state => state.student.newList);
    // console.log("newStudent : ", newStudent);

	const myPackage = useSelector(state => state.myPackage.list);
    // console.log("myPackage : ", myPackage);

    const studentInfo = useSelector(state => state.myPackage.studentInfo);
    // console.log("studentInfo : ", studentInfo);

	const myCoupon = useSelector(state => state.coupon.all);
    // console.log("myCoupon : ", myCoupon);

	const myPackageList = useSelector(state => state.myPackage.infoList);
    // console.log("myPackageList : ", myPackageList);

    const studentTotalInfo = useSelector(state => state.myPackage.infoStudentInfo);
    // console.log("studentTotalInfo : ", studentTotalInfo);

	const dispatch = useDispatch();	

	/************* JQuery 처리 영역 *************/

	const mypage_tab_btn_areaRef =  useRef();
    const hover_bgRef =  useRef();
    const onRef =  useRef();

	useEffect(() => {

		// 여기에서 jQuery를 초기화 합니다.
		// jquery ready 호출. jquery3 부터 변경됨
		$(function(){
			// console.log("jquery ready!");

            //마이페이지 탭 버튼(활성화 효과)
			$(`.${styles.mypage_tab_btn_area} li`).on('click',function()
			{
				let tg=$(this);
				
				$(`.${styles.mypage_tab_btn_area} li`).removeClass(`${styles.on}`);
				tg.addClass(`${styles.on}`);
			});
            
            //마이페이지 탭 버튼(후버 효과)
            $(window).resize(function()
			{
				if (window.innerWidth < 1200) 
				{ 
					$(`.${styles.mypage_tab_btn_area} li.${styles.hover_bg}`).css('display','none');
				}
				else
				{
					let nowbtnNum=$(`.${styles.mypage_tab_btn_area} li.${styles.on}`).index();
					let hoverBg=$(`.${styles.mypage_tab_btn_area} li.${styles.hover_bg}`);

					$(`.${styles.mypage_tab_btn_area} ul`).on('mouseenter',function()
					{
						
						$(`.${styles.mypage_tab_btn_area} li`).removeClass(`${styles.on}`);
						hoverBg.css('display','block');
						
						$(`.${styles.mypage_tab_btn_area} li`).on('mouseenter',function()
						{
							let tgIndexinfo=$(this);
							hoverBg.stop().css('left',tgIndexinfo['0']['offsetLeft']).css('width',tgIndexinfo['0']['clientWidth']);
							
							// let tgIndex=$(this).index();
							
							// if(tgIndex===1)
							// {
							// 	hoverBg.stop().css('left','167px').css('width','116px');
							// }
							// else if(tgIndex===2)
							// {
							// 	hoverBg.stop().css('left','300px').css('width','150px');
							// }
							// else if(tgIndex===3)
							// {
							// 	hoverBg.stop().css('left','467px').css('width','116px');
							// }
							// else if(tgIndex===4)
							// {
							// 	hoverBg.stop().css('left','624px').css('width','102px');
							// }
							// else if(tgIndex===5)
							// {
							// 	hoverBg.stop().css('left','774px').css('width','102px');
							// }
							// else if(tgIndex===6)
							// {
							// 	hoverBg.stop().css('left','924px').css('width','102px');
							// };
						});
						
					}).on('mouseleave',function()
					{
						$(`.${styles.mypage_tab_btn_area} li`).eq(nowbtnNum).addClass(`${styles.on}`);
						hoverBg.css('display','none');
						
						if(nowbtnNum===0)
						{
							hoverBg.css({left:'167px'}).css({width:'116px'});
						}
						else if(nowbtnNum===1)
						{
							hoverBg.css({left:'300px'}).css({width:'150px'});
						}
						else if(nowbtnNum===2)
						{
							hoverBg.css({left:'467px'}).css({width:'116px'});
						}
						else if(nowbtnNum===3)
						{
							hoverBg.css({left:'624px'}).css({width:'102px'});
						}
						else if(nowbtnNum===4)
						{
							hoverBg.css({left:'774px'}).css({width:'102px'});
						}
						else if(nowbtnNum===5)
						{
							hoverBg.css({left:'924px'}).css({width:'102px'});
						};

					});
					
				}
			}).resize();

			//마이페이지 학습자 정보관리 학부모 기간 정보 팝업
			$(`.${styles.info_popup_btn}`).on('click',function(event)
			{
				event.preventDefault();

				// console.log($('input[name=packageCount]').attr('value'));
				let count = parseInt($('input[name=packageCount]').attr('value'));
				if (count > 0)
					$(`.${styles.info_popup}`).toggle();	
				else
					return alert(`${t("MypageStudentInfo.alert.string2")}`);
					// return alert('이용권이 없습니다. 이용권을 구매해주세요.');
			});

			//이용권 변경 팝업에서 '확인' 클릭했을 때
			$(`.${styles.translate_popup} .${styles.confirm}`).on('click', function(e)
			{
				e.preventDefault();
				
				// console.log($(`#${styles.student_info_product_select}`).val());
				let ordNo = $(`#${styles.student_info_product_select}`).val();
				// console.log($('input[name=mst_idx]').attr('value'));
				let mst_idx = $('input[name=mst_idx]').attr('value');

				if(ordNo === null || ordNo === "")
					return alert(`${t("MypageStudentInfo.alert.string3")}`);
					// return alert("상품을 선택해주세요.");

				let arr = new Array(mst_idx);

				const SendJaon = {
					mst_idx: arr[0],
					package_no: ordNo
				}

				// console.log("SendJaon : ", SendJaon);

				// 여기서 디스패치로~
				dispatch(packageInfoUseStudentModify(SendJaon))
				.then(data => 
				{
					// console.log("packageUseStudentModify data : ", data);

					$(`#${styles.student_info_product_select} option:eq(0)`).prop("selected", true); //첫번째 option 선택
					$(`.${styles.translate_popup}`).css('display','none');
					blackBg(2);

					window.location.reload();
				})
				.catch(e => {
					console.log(e);
				});

				// dispatch(packageUseStudentModify(SendJaon))
				// .then(data => 
				// {
				// 	// console.log("packageUseStudentModify data : ", data);

				// $(`#${styles.student_info_product_select} option:eq(0)`).prop("selected", true); //첫번째 option 선택
				// 	$(`.${styles.translate_popup}`).css('display','none');
				// 	blackBg(2);

				// 	window.location.reload();
				// })
				// .catch(e => {
				// 	console.log(e);
				// });
				
			});

			//이용권 변경 팝업에서 '취소' 클릭했을 때
			$(`.${styles.translate_popup} .${styles.cancle}`).on('click',function()
			{
				$(`#${styles.student_info_product_select} option:eq(0)`).prop("selected", true); //첫번째 option 선택
				$(`.${styles.translate_popup}`).css('display','none');
				blackBg(2);
			});
			
			$('html').click(function(e)
			{ 
				
				if(!$(`.${styles.info_popup_btn}`).has(e.target).length)
				{
					$(`.${styles.info_popup}`).css('display','none');
				}
				
			});
		});

		// 여기서 디스패치로~
		// 부모 정보 불러오기
        dispatch(userSelect(authState.user.email))
        .then(data => 
        {
            // console.log("userSelect data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

		// 학생 리스트 불러오기
        dispatch(studentList())
        .then(data => 
        {
            // console.log("studentList data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

		// 학생 리스트 불러오기
        dispatch(newStudentList())
        .then(data => 
        {
            // console.log("newStudentList data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

		// 여기서 디스패치로~
        dispatch(packageList())
        .then(data => 
        {
            // console.log("packageList data : ", data);
			let totalCount= 0;
			for (let i=0; i<data.length; i++)
			{
				if (data[i].ord_stat_cd === 'A')
					totalCount += data[i].ord_count;
			}
			// setTotalPackageCount(totalCount);

			// 쿠폰 리스트 불러오기
			dispatch(couponAllList())
			.then(data => 
			{
				// console.log("couponAllList data : ", data);
				for (let i=0; i<data.length; i++)
				{
					if (data[i].cou_state === 'A' || data[i].cou_state === 'W')
						totalCount += 1;
				}
				// setTotalPackageCount(totalCount);
			})
			.catch(e => {
				console.log(e);
			});
        })
        .catch(e => {
            console.log(e);
        });

        // 여기서 디스패치로~
        dispatch(packageStudentInfo())
        .then(data => 
        {
            // console.log("packageStudentInfo data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

		// 여기서 디스패치로~
        dispatch(packageInfoList())
        .then(data => 
        {
            // console.log("packageInfoList data : ", data);
			setTotalPackageCount(data.length);
        })
        .catch(e => {
            console.log(e);
        });

        // 여기서 디스패치로~
        dispatch(packageInfoStudentInfo())
        .then(data => 
        {
            // console.log("packageInfoStudentInfo data : ", data);
        })
        .catch(e => {
            console.log(e);
        });


	}, [$, dispatch]);

	const returnPackageList_old = (myPackage, myCoupon) => 
	{
		// console.log("myPackage : ", myPackage);
		// console.log("myCoupon : ", myCoupon);
		let myPackageCopy = myPackage.sort((a, b) => a.ord_valid_date?.replace("-", "").replace("-", "").substr(0, 8) - b.ord_valid_date?.replace("-", "").replace("-", "").substr(0, 8));
		let myCouponCopy = myCoupon.sort((a, b) => a.cou_valid_end_date?.replace("-", "").replace("-", "").substr(0, 8) - b.cou_valid_end_date?.replace("-", "").replace("-", "").substr(0, 8));

		// 패키지 정보에서 필요한 정보만 추출함
		let liArray = [];
		let liArray2 = [];

		for(let i=0; i<myPackageCopy.length; i++)
		{
			if (myPackageCopy[i].ord_stat_cd === "A")
			{
				// console.log("유료 패키지");
				// console.log(myPackage[i].ord_valid_date.replace("-", "").replace("-", "").substr(0, 8));
				liArray.push(myPackageCopy[i]);
			}
		}

		for(let i=0; i<myCouponCopy.length; i++)
		{
			if (myCouponCopy[i].cou_state === "A" || myCouponCopy[i].cou_state === "W")
			{
				// console.log("쿠폰");
				liArray2.push(myCouponCopy[i]);
			}
		}

		// console.log(liArray);
		// console.log(liArray2);

		let drawLiArray = [];
		let globalkey = 0;
		drawLiArray.push(<li className={styles.info_popup_close_btn} key={-1}>
			<img src="/assets/back_icon.png" alt="btn"/>
		</li>);

		// 추출한 패키지 정보를 그림
		for (let i=0; i<liArray.length; i++)
		{
			drawLiArray.push(<li key={globalkey}>
				<span className={styles.ellip}>{liArray[i].prd_name}({liArray[i].ord_count})&nbsp;&nbsp;</span>
				<p>{common.dateChangeReturn(liArray[i].ord_valid_date) || "-"} 까지</p>
			</li>);
			globalkey = globalkey + 1;
		}

		// 추출한 쿠폰 정보를 그림
		for (let i=0; i<liArray2.length; i++)
		{
			drawLiArray.push(<li key={globalkey}>
				<span className={styles.ellip}>{liArray2[i].cou_name}&nbsp;&nbsp;</span>
				<p>{common.dateChangeReturn(liArray2[i].cou_valid_end_date) || "-"} 까지</p>
			</li>);
			globalkey = globalkey + 1;
		}

		return <ul className={styles.info_popup}>
			{drawLiArray}
		</ul>; 
	};

	const returnPackageList = (myPackageList) => 
	{
		// console.log("myPackageList : ", myPackageList);
		let myPackageCopy = myPackageList?.sort((a, b) => a.mm_valid_end_date?.replace("-", "").replace("-", "").substr(0, 8) - b.mm_valid_end_date?.replace("-", "").replace("-", "").substr(0, 8));

		// 패키지 정보에서 필요한 정보만 추출함
		let liArray = [];

		for(let i=0; i<myPackageCopy?.length; i++)
		{
			// console.log("유료 패키지");
			// console.log(myPackage[i].ord_valid_date.replace("-", "").replace("-", "").substr(0, 8));
			liArray.push(myPackageCopy[i]);
		}

		// console.log(liArray);

		let drawLiArray = [];
		let globalkey = 0;
		drawLiArray.push(<li className={styles.info_popup_close_btn} key={-1}>
			<img src="/assets/back_icon.png" alt="btn"/>
		</li>);

		// 추출한 패키지 정보를 그림
		for (let i=0; i<liArray.length; i++)
		{
			drawLiArray.push(<li key={globalkey}>
				<span className={styles.ellip}>{liArray[i].mm_name}({liArray[i].mm_type})&nbsp;&nbsp;</span>
				{/* <p>{liArray[i].mm_valid_end_date !== null ? common.dateChangeReturn(liArray[i].mm_valid_end_date) + " 까지" : "미사용"}</p> */}
				<p>{liArray[i].mm_valid_end_date !== null ? common.dateChangeReturn(liArray[i].mm_valid_end_date) + t("MypageStudentInfo.string14") : t("MypageStudentInfo.string15")}</p>
			</li>);
			globalkey = globalkey + 1;
		}

		return <ul className={styles.info_popup}>
			{drawLiArray}
		</ul>; 
	};

	//이용권 변경 클릭시 팝업
	const translateProduct = (event, i) =>
	{
		event.preventDefault();

		let myPackageLength = myPackageList && myPackageList.filter(row => row?.mm_state === 'W' && row?.mm_state_cd === 'A') || 0;	// 패키지의 갯수를 구합니다.
		let myPackageCount = myPackageLength.length;
		
		// console.log("myPackageLength : ", myPackageLength);
		// console.log("myPackageCount : ", myPackageCount);

		if ((myPackageCount) <= 0)
		{
			// return alert("이용권 추가구매가 필요합니다.");
			return alert(`${t("MypageStudentInfo.alert.string4")}`);
		}
		else
		{
			// console.log(i);
			$('input[name=mst_idx]').attr('value', i);
			blackBg(1);
			$(`.${styles.translate_popup}`).css('display','block');
		}
	};

	const translateProduct_old = (event, i) =>
	{
		event.preventDefault();

		// console.log("myPackage.length : ", myPackage && myPackage.filter(row => row?.ord_stat_cd === 'A' && row?.ref_stat_cd !== 'cancelled').length || 0);
		let used = studentInfo?.used || 0;
		let myPackageLength = myPackage && myPackage.filter(row => row?.ord_stat_cd === 'A' && row?.ref_stat_cd !== 'cancelled') || 0;	// 유료 패키지의 갯수를 구합니다.
		let myPackageLength2 = myCoupon && myCoupon.filter(row => row?.cou_state === 'A' || row?.cou_state === 'W') || 0;
		let myPackageCount = 0;

		for (let i=0; myPackageLength.length > i; i++)
		{
			myPackageCount += myPackageLength[i].ord_count;
		}

		myPackageCount = myPackageCount + myPackageLength2.length;
		
		// console.log("myPackage : ", myPackage);
		// console.log("myPackageLength : ", myPackageLength);
		// console.log("myPackageCount : ", myPackageCount);
		// console.log("used : ", used);

		if ((myPackageCount - used) <= 0)
		{
			// return alert("이용권 추가구매가 필요합니다.");
			return alert(`${t("MypageStudentInfo.alert.string4")}`);
		}
		else
		{
			// console.log(i);
			$('input[name=mst_idx]').attr('value', i);
			blackBg(1);
			$(`.${styles.translate_popup}`).css('display','block');
		}
	};

	//검정 반투명 배경 제어
	const blackBg = (i) =>
	{
		if(i===1)
			{
				$(`.${styles.body_black}`).css('display','block');
				// $('body').css('overflow','hidden');
				// console.log('배경보임');
			}
			else
			{
				$(`.${styles.body_black}`).css('display','none');
				// $('body').css('overflow','visible');
				// console.log('배경꺼짐');
			};
	};

	const returnAddStudent = (length, total, used) => {

		// console.log("length : ", length);	// 패키지 전체 갯수
		// console.log("total : ", total);	// 전체 사용자의 수
		// console.log("used : ", used);		// 패키지 사용하지 않은 갯수

		// 사용자의 총갯수가 30미만이면 총사용자의 갯수에서 현재 사용하고 있는 학습자의 수를 빼고
		// 30개가 넘어가면 총갯수가 아니고 30개에서 뺍니다.

		let createLength = 0;
		let studentLength = used;	// 생성할 수 있는 학습자

		// console.log("studentLength : ", studentLength);
		if (total >= 30)
		{
			// 최대 30개까지 생성됩니다.
			// 생성된 학습자가 31명 이상이면 더 이상 생성하지 않습니다.
			createLength = 0;
		}
		else if (total < 31)
		{
			if (studentLength > 0)	// 패키지가 없으면 생성 안함
			{
				createLength = studentLength;	// 학습자가 한명이고 그 학습자가 사용자가 아닌 경우
			}
			
			// else if (length === used)
			// {
			// 	createLength = 0;
			// }
			// else if (length > 31)	// 위의 조건을 만족하더라도 학습자의 최대의 생성갯수는 30개이다.
			// {
			// 	createLength = 30 - studentLength;
			// }
			// else if (length > studentLength)
			// {
			// 	createLength = length - studentLength;
			// }
		}

		let drawArray = [];

		// 추출한 패키지 정보를 그림
		for (let i=0; i<createLength; i++)
		{
			drawArray.push(

				<div className={`${styles.student_state_box} ${styles.make}`} key={i}>
						<div className={styles.state_box_cover}>
							<div>
								<p>{/* * '학습자 등록'을 해주셔야 야미에서 플레이가 가능합니다. */}{t('MypageStudentInfo.string3')}</p>
								<a href={`/UserMypage/StudentRegister`}>{/* 학습자 추가하기 */}{t('MypageStudentInfo.string4')}</a>
							</div>
						</div>
						<div className={styles.state_head}>
							<div className={styles.profile_wrap}>
								<span>3P</span>
							</div>
							<p className={styles.ellip}>김철수</p>
						</div>
						<div className={styles.right_area}>
							<div className={styles.state_list}>
								<span>이름</span>
								<p className={styles.ellip}>와르바시와르바시와르바시</p>
							</div>
							<div className={styles.state_list}>
								<span>아이디</span>
								<p className={styles.ellip}>ayayayayayayyayay</p>
							</div>
							<div className={styles.state_list}>
								<span>기간</span>
								<p>2022.05.03 까지</p>
							</div>
							<div className={styles.state_list}>
								<a href="student_info_fix.html">정보 수정</a>
								<button type="button" onClick={translateProduct}>이용권 변경</button>
							</div>
						</div>
					</div>

			);
		}

		return <>
			{drawArray}
		</>; 
	};

	const returnPopupPackageList = (myPackageList) => 
	{
		// console.log("returnPopupPackageList myPackageList : ", myPackageList);
		let myPackageCopy = myPackageList.filter(row => row?.mm_state === "W" && row?.mm_state_cd === "A").sort((a, b) => a.mm_idx - b.mm_idx);

		let drawLiArray = [];
		let keyValue = 0;

		// 추출한 패키지 정보를 그림
		for (let i=0; i<myPackageCopy.length; i++)
		{
			drawLiArray.push(<option value={myPackageCopy[i].mm_idx} key={keyValue}>{myPackageCopy[i].mm_name}</option>);
			keyValue++;
		}

		return <>
			{drawLiArray}
		</>; 
	};

	const returnPopupPackageList_old = (myPackage, myCoupon) => 
	{
		// console.log("returnPopupPackageList myPackage : ", myPackage);
		// console.log("returnPopupPackageList myCoupon : ", myCoupon);
		let myPackageCopy = myPackage.filter(row => row?.ord_count !== row?.student_count).sort((a, b) => a.ord_valid_date?.replace("-", "").replace("-", "").substr(0, 8) - b.ord_valid_date?.replace("-", "").replace("-", "").substr(0, 8));
		let myCouponCopy = myCoupon.filter(row => row?.cou_state === "W").sort((a, b) => a.cou_valid_end_date?.replace("-", "").replace("-", "").substr(0, 8) - b.cou_valid_end_date?.replace("-", "").replace("-", "").substr(0, 8));

		// 패키지 정보에서 필요한 정보만 추출함
		let liArray = [];
		let liArray2 = [];

		for(let i=0; i<myPackageCopy.length; i++)
		{
			if (myPackageCopy[i].ord_stat_cd === "A"  & myPackageCopy[i].student_count !== myPackageCopy[i].ord_count)
			{
				// console.log("유료 패키지");
				liArray.push(myPackageCopy[i]);
			}
		}

		for(let i=0; i<myCouponCopy.length; i++)
		{
			// console.log("쿠폰");
			liArray2.push(myCouponCopy[i]);
		}

		// console.log(liArray);
		// console.log(liArray2);

		let drawLiArray = [];
		let keyValue = 0;

		// 추출한 패키지 정보를 그림
		for (let i=0; i<liArray.length; i++)
		{
			drawLiArray.push(<option value={liArray[i].ord_no} key={keyValue}>{liArray[i].prd_name}</option>);
			keyValue++;
		}

		// 추출한 패키지 정보를 그림
		for (let i=0; i<liArray2.length; i++)
		{
			drawLiArray.push(<option value={liArray2[i].cou_number} key={keyValue}>{liArray2[i].cou_name}</option>);
			keyValue++;
		}

		return <>
			{drawLiArray}
		</>; 
	};

	const validCheck = (valid_date) =>
	{
		const validDate = new Date(valid_date);
		const nowDate = new Date();
		// console.log(validDate < nowDate);

		return validDate < nowDate;
	}

	const studentValidDateCheck = (data) => {

		// console.log("data : ", data);
		let returnValue;

		// 무료 사용자인지 유료 사용자 인지 구분합니다.
		if (data.mm_state_cd === "F")
		{
			returnValue = '';
		}
		else	// 유료 사용자일 경우 (쿠폰 사용자인지 유료 결제 사용자인지 구분함)
		{
			
			//returnValue = validCheck(data.mm_valid_end_date) ? styles.new : ``
			// 기간 만료와 상관없이 학습자 추가 버튼을 노출한다.
			returnValue = validCheck(data.mm_valid_end_date) ? styles.new : ``
		}

		return returnValue;
	}

	const studentValidDateCheck_old = (data) => {

		// console.log("data : ", data);
		let returnValue;

		// 무료 사용자인지 유료 사용자 인지 구분합니다.
		if (data.ord_stat_cd === "F")
		{
			returnValue = '';
		}
		else	// 유료 사용자일 경우 (쿠폰 사용자인지 유료 결제 사용자인지 구분함)
		{
			if (data.cou_number === null)	// 유료 결제 사용자
			{
				returnValue = data.ord_valid_date === null || data.ref_stat_cd === "cancelled" || validCheck(data.ord_valid_date) ? styles.new : ``
			}
			else
			{
				returnValue = validCheck(data.cou_valid_end_date) ? styles.new : ``
			}
		}

		return returnValue;
	}

	const validDataDisplay_old = (Package, Coupon) =>
	{
		let returnValue = "";
		let packageData;
		let couponDate;

		packageData = Package?.filter(row => row?.ord_stat_cd === 'A').length !== 0 ? common.dateChangeReturn(Package?.filter(row => row?.ord_stat_cd === 'A').sort((a, b) => a.ord_valid_date?.replace("-", "").replace("-", "").substr(0, 8) - b.ord_valid_date?.replace("-", "").replace("-", "").substr(0, 8))[0].ord_valid_date) : "-";
		couponDate = Coupon?.filter(row => row?.cou_state === 'A' || row?.cou_state === 'W').length !== 0 ? common.dateChangeReturn(Coupon?.filter(row => row?.cou_state === 'A' || row?.cou_state === 'W').sort((a, b) => a.cou_valid_end_date?.replace("-", "").replace("-", "").substr(0, 8) - b.cou_valid_end_date?.replace("-", "").replace("-", "").substr(0, 8))[0].cou_valid_end_date) : "-";

		// console.log("packageData : ", packageData);
		// console.log("couponDate : ", couponDate);

		// 패키지 날짜와 쿠폰 날짜를 비교해서 가장 빠른 날짜를 넣는다.
		let date1 = new Date(packageData);
		let date2 = new Date(couponDate);

		// console.log("date1 : ", date1);
		// console.log("date2 : ", date2);

		if (date1 > date2)
		{
			returnValue = packageData;
		}
		else
		{
			returnValue = couponDate;
		}

		return returnValue;
	}

	const validDataDisplay = (myPackageList) =>
	{
		// console.log("myPackageList : ", myPackageList);

		let returnValue = "";
		let packageData;

		packageData = myPackageList?.length !== 0 ? common.dateChangeReturn(myPackageList?.sort((a, b) => a.mm_valid_end_date?.replace("-", "").replace("-", "").substr(0, 8) - b.mm_valid_end_date?.replace("-", "").replace("-", "").substr(0, 8))[0].mm_valid_end_date) : "-";

		// console.log("packageData : ", packageData);
		// returnValue = packageData !== "" ? packageData : "미사용";
		returnValue = packageData !== "" ? packageData : t('MypageStudentInfo.string15');

		return returnValue;
	}

  return (
    <>
      	{/* Header 분리됨 MainLayout Header에 있음 */}
		{/* <input type='hidden' name='packageCount' value={myPackage && myPackage.filter(row => row?.ord_stat_cd === 'A').length || 0 + myCoupon && myCoupon.filter(row => row?.cou_state === 'A' || row?.cou_state === 'W').length || 0}></input> */}
		<input type='hidden' name='packageCount' value={myPackageList && myPackageList.filter(row => row?.mm_state_cd === 'A').length || 0}></input>
		<div className={styles.body_black}></div>
		<div className={styles.translate_popup}>
			<div>
				<p>{/* 이용권을 선택해주세요. */}{t('MypageStudentInfo.popup.string1')}</p>
				<select name="student_info_select" id={styles.student_info_product_select} defaultValue={'DEFAULT'}>
					<option value="DEFAULT" disabled >{/* 선택 */}{t('Common.Select')}</option>
					{/* <option value="1">야미코딩(1개월 이용권)</option>
					<option value="2">야미코딩(3개월 이용권)</option>
					<option value="3">야미코딩(6개월 이용권)</option> */}
					
					{/* 패키지 리스트 불러오기 */}
					{/*myPackage !== undefined && myCoupon !== undefined ? returnPopupPackageList(myPackage, myCoupon) : ""*/}
					{myPackageList !== undefined ? returnPopupPackageList(myPackageList) : ""}
				</select>
				<div className={styles.btn_area}>
					<button type="button" className={styles.confirm}>{/* 확인 */}{t('Common.confirm')}</button>
					<button type="button" className={styles.cancle}>{/* 취소 */}{t('Common.cancel')}</button>
				</div>
			</div>
		</div>

		<div className={styles.mypage_top_line_banner}>
			<div className={styles.inner}>
				<span>{/* 학부모 페이지 */}{t('Common.Mypage_title1')}</span>
			</div>
		</div>
		<div className={styles.mypage_tab_btn_area} ref={mypage_tab_btn_areaRef}>
			<div className={styles.inner}>
				<ul>
					<li className={styles.hover_bg} ref={hover_bgRef}></li>
					<li><a href="/UserMypage/Report">{/* 학습 리포트 */}{t('Common.SubMenu.menu1')}</a></li>
					<li className={styles.on} ref={onRef}><a href="/UserMypage/StudentInfo">{/* 학습자 정보/관리 */}{t('Common.SubMenu.menu2')}</a></li>
					<li><a href="/UserMypage/ParentsInfo">{/* 학부모 정보 */}{t('Common.SubMenu.menu3')}</a></li>
					<li><a href="/UserMypage/PayInfo">{/* 결제 정보 */}{t('Common.SubMenu.menu4')}</a></li>
					<li><a href="/UserMypage/CouponInfo">{/* 쿠폰 관리 */}{t('Common.SubMenu.menu5')}</a></li>
					<li><a href="/UserMypage/QnaInfo">{/* 문의 내역 */}{t('Common.SubMenu.menu6')}</a></li>
				</ul>
			</div>
		</div>
		<div className={`${styles.mypage_tab} ${styles.mypage_stuedent_info}`}>
			<div className={styles.inner}>
				<span className={styles.my_tit}>{/* 학습자 정보/관리 */}{t('MypageStudentInfo.title')}</span>
				<input type='hidden' name='mst_idx' value={''}></input>
				<div className={styles.parents_state_area}>
					<p>{/* 일반 계정의 학습자는 최대 30명까지 입니다. 31명 부터는 단체로 가입하셔야 합니다. */}
					{t('MypageStudentInfo.string1')}</p>
					<div className={styles.parents_state_box}>
						<div className={styles.state_head}>
							<div className={styles.profile_wrap}>
								<img src="/assets/my_info_basic_profile@2x.png" alt="profile"/>
							</div>
							<span>{/* 학부모 */}{t('Common.school_parent')}</span>
						</div>
						<div className={styles.right_area}>
							<div className={styles.state_list}>
								<span>{/* 이름 */}{t('Common.Name')}</span>
								<p className={styles.ellip}>{parent?.mem_name || ""}</p>
							</div>
							<div className={styles.state_list}>
								<span>{/* 아이디 */}{t('Common.ID')}</span>
								<p className={styles.ellip}>{parent?.mem_email || ""}</p>
							</div>
							<div className={styles.state_list}>
								<span>{/* 기간 */}{t('Common.Period')}</span>
								<div>
									{/* <p className={styles.pay}>{ validDataDisplay(myPackage, myCoupon) }</p> */}
									<p className={styles.pay}>{ validDataDisplay(myPackageList) }</p>
									<button type="button" className={styles.info_popup_btn}><img src="/assets/plus_on@2x.png" alt="btn"/></button>
									{/* 패키지 리스트 불러오기 */}
									{/* myPackage !== undefined && myCoupon !== undefined ? returnPackageList(myPackage, myCoupon) : "" */}
									{myPackageList?.length !== 0 ? returnPackageList(myPackageList) : ""}
								</div>
							</div>
							<div className={styles.state_list}>
								<span>{/* 상태 */}{t('Common.State')}</span>
								<p className={styles.pay}>{/* 이용권 */}{t('Common.ticket')} : {/* {totalPackageCount}개 보유 중 */}{t('MypageStudentInfo.string2', {totalPackageCount : totalPackageCount})}</p>
							</div>
						</div>
					</div>
				</div>
				<div className={styles.line}></div>
				<div className={styles.student_state_area}>
				{
					newStudent && newStudent.map((row, index) => (
					
					<div className={`
										${styles.student_state_box}
										${row.mm_state === `A` && row.mm_state_cd === `A` ? `${styles.pay}` : ``}
										${studentValidDateCheck(row)}
									`} key={index}>
						<div className={styles.state_box_cover}>
							<div>
								<p>{/* * '학습자 등록'을 해주셔야 야미에서 플레이가 가능합니다. */}{t('MypageStudentInfo.string3')}</p>
								<a href={`/UserMypage/StudentRegister`}>{/* 학습자 추가하기 */}{t('MypageStudentInfo.string4')}</a>
							</div>
						</div>
						<div className={styles.state_head}>
							<div className={styles.profile_wrap}>
								<span>{index+1}P</span>
							</div>
							<p className={styles.ellip}>{/* 학습자 */}{t('MypageStudentInfo.string5')}<br/>
							{
								// row.mm_state_cd === "F" ? `(무료 사용자)` :
								// {
								// 	PG : row.mm_state === "A" && row.mm_state_cd === "A" ? `(유료결제 적용)` : `(사용중지)`,
								// 	COUPON : row.mm_state === "A" && row.mm_state_cd === "A" ? `(쿠폰 적용)` : `(사용중지)`,
								// 	INAPP : row.mm_state === "A" && row.mm_state_cd === "A" ? `(인앱 적용)` : `(사용중지)`,
								// } [row.mm_type]

								row.mm_state_cd === "F" ? t('MypageStudentInfo.string6') :
								{
									PG : row.mm_state === "A" && row.mm_state_cd === "A" ? t('MypageStudentInfo.string7') : t('MypageStudentInfo.string10'),
									COUPON : row.mm_state === "A" && row.mm_state_cd === "A" ? t('MypageStudentInfo.string8') : t('MypageStudentInfo.string10'),
									INAPP : row.mm_state === "A" && row.mm_state_cd === "A" ? t('MypageStudentInfo.string9') : t('MypageStudentInfo.string10'),
								} [row.mm_type]
							}
							</p>
						</div>
						<div className={styles.right_area}>
							<div className={styles.state_list}>
								<span>{/* 이름 */}{t('Common.Name')}</span>
								<p className={styles.ellip}>{row.mst_name || "-"}</p>
							</div>
							<div className={styles.state_list}>
								<span>{/* 아이디 */}{t('Common.ID')}</span>
								<p className={styles.ellip}>{row.mst_id}</p>
							</div>
							<div className={styles.state_list}>
								<span>{/* 기간 */}{t('Common.Period')}</span>
								<p className={`${row.mm_state === `A` && row.mm_state_cd === `A` ? `${styles.end}` : ``}`}>
									{
										// row.mm_state_cd === "F" ? `무제한` : `${common.dateChangeReturn(row.mm_valid_end_date)} 까지`
										row.mm_state_cd === "F" ? t('Common.Unlimited') : `${common.dateChangeReturn(row.mm_valid_end_date)} ${t('Common.Until')}`
									}
								</p>
							</div>
							<div className={styles.state_list}>
								<a className={`${row.ord_valid_date !== null ? `${styles.active}` : ``}`} href={`/UserMypage/StudentInfoFix/${row.mst_id}`}>{/* 정보 수정 */}{t('MypageStudentInfo.string11')}</a>
								{/* <a className={`${row.ord_valid_date === null ? `${styles.active}` : ``}`} href={`/UserMypage/StudentRegister/${row.mst_id}`}>학습자 등록</a> */}
								<button type="button" onClick={(e) => translateProduct(e, row.mst_idx)}>{/* 이용권 추가 */}{t('MypageStudentInfo.string12')}</button>
							</div>
						</div>
					</div>
					
					))
				}
				{/*
					student && student.map((row, index) => (
					
					<div className={`
										${styles.student_state_box}
										${row.ord_stat_cd === `A` || row.cou_state === `A` ? `${styles.pay}` : ``}
										${studentValidDateCheck(row)}
									`} key={index}>
						<div className={styles.state_box_cover}>
							<div>
								<p>* '학습자 등록'을 해주셔야 야미에서 플레이가 가능합니다.</p>
								<a href={`/UserMypage/StudentRegister`}>학습자 추가하기</a>
							</div>
						</div>
						<div className={styles.state_head}>
							<div className={styles.profile_wrap}>
								<span>{index+1}P</span>
							</div>
							<p className={styles.ellip}>학습자<br/>
							{
								row.cou_state === "A" || row.cou_state === "W" ?
								{
									A : `(쿠폰 사용중)`,
									W : `(쿠폰 대기중)`,
								} [row.cou_state]
								:
								{
									A : `(${row.prd_name})`,
									F : `(무료체험)`,
								} [row.ord_stat_cd]
							}
							</p>
						</div>
						<div className={styles.right_area}>
							<div className={styles.state_list}>
								<span>이름</span>
								<p className={styles.ellip}>{row.mst_name || "-"}</p>
							</div>
							<div className={styles.state_list}>
								<span>아이디</span>
								<p className={styles.ellip}>{row.mst_id}</p>
							</div>
							<div className={styles.state_list}>
								<span>기간</span>
								<p className={`${row.ord_stat_cd === `A` || row.cou_state === `A` ? `${styles.end}` : ``}`}>
									{
										`${
											row.cou_state === `A` ?
												`${common.dateChangeReturn(row.cou_valid_end_date)} 까지`
											:
											common.dateChangeReturn(row.ord_valid_date) !== "" && row.ref_stat_cd !== "cancelled" 
											? 
												row.ord_stat_cd === `F` 
												? `무제한` 
												: 
												`${common.dateChangeReturn(row.ord_valid_date)} 까지`
											: 	
											`-`
										}`
									}
								</p>
							</div>
							<div className={styles.state_list}>
								<a className={`${row.ord_valid_date !== null ? `${styles.active}` : ``}`} href={`/UserMypage/StudentInfoFix/${row.mst_id}`}>정보 수정</a>
								<a className={`${row.ord_valid_date === null ? `${styles.active}` : ``}`} href={`/UserMypage/StudentRegister/${row.mst_id}`}>학습자 등록</a>
								<button type="button" onClick={(e) => translateProduct(e, row.mst_idx)}>이용권 변경</button>
							</div>
						</div>
					</div>
					
					))
				*/}
					
				{returnAddStudent(totalPackageCount, studentTotalInfo?.total || 0, studentTotalInfo?.number_of_unused || 0)}

				</div>
				<div className={styles.line}></div>
				<a href="/Pay" className={styles.go_price}>{/* 학습자 추가 구매 */}{t('MypageStudentInfo.string13')}</a>
			</div>{/* inner */}
		</div>{/* mypage_tab */}
		
		{/* Footer 분리됨 MainLayout Footer에 있음 */}
    </>
  );
}